import { colorMap } from '~/utils/colors'
import { ColorName } from '~/models/Content/Color'

export default () => {
  const colors = [
    ColorName.YellowPop,
    ColorName.Orange30,
    ColorName.CoralPop,
    ColorName.Violet10,
    ColorName.PurplePop,
    ColorName.Blue20,
    ColorName.GreenPop,
    ColorName.Coral20,
    ColorName.White,
    ColorName.VioletPop,
    ColorName.SeagreenPop,
    ColorName.Green10,
    ColorName.Blue10,
    ColorName.Seagreen20,
    ColorName.Orange20,
    ColorName.Blue5,
  ]

  const getStyles = (index: number) => {
    const color = `rgb(var(${colorMap.get(colors[index % colors.length])?.var ?? '--au-white'}))`

    return {
      '--ks-secondary': color,
      '--ks-primarytext': color,
      'border': `1px solid ${color}`,
    }
  }

  return {
    getStyles,
  }
}
