<script setup lang="ts">
import type { BasePageProps } from '~/models/Presentation/BasePage'
import type { ImageAndMaybeText } from '~/models/Presentation/Pages/ImageAndMaybeText'
import { computed } from 'vue'
import useImage from '~/composables/useImage'
import useHiddenHeading from '~/composables/useHiddenHeading'
import LottiePlayer from '~/components/media/LottiePlayer.vue'
import ImageWithCaption from '~/components/media/ImageWithCaption.vue'

const textPositions: Record<string,string> = {
  'center': 'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center',
  'top-center': 'top-16 left-1/2 -translate-x-1/2 text-center',
  'bottom-center': 'bottom-32 left-1/2 -translate-x-1/2 text-center',
  'top-left': 'top-16 left-16 text-left',
  'top-right': 'top-16 right-16 text-right',
  'bottom-left': 'bottom-32 left-16 text-left',
  'bottom-right': 'bottom-32 right-16 text-right',
}

const textTransitions: Record<string,Record<string,string>> = {
  'center': { enterFrom: 'opacity-0', enterTo: 'opacity-100' },
  'top-center': { enterFrom: 'opacity-0 translate-y-96', enterTo: 'opacity-100 translate-y-0' },
  'bottom-center': { enterFrom: 'opacity-0 -translate-y-96', enterTo: 'opacity-100 translate-y-0' },
  'top-left': { enterFrom: 'opacity-0 translate-y-96', enterTo: 'opacity-100 translate-y-0' },
  'top-right': { enterFrom: 'opacity-0 -translate-x-96', enterTo: 'opacity-100 translate-x-0' },
  'bottom-left': { enterFrom: 'opacity-0 -translate-y-96', enterTo: 'opacity-100 translate-y-0' },
  'bottom-right': { enterFrom: 'opacity-0 -translate-x-96', enterTo: 'opacity-100 translate-x-0' },
}

const lineHeights: Record<string,string> = {
  'font-tiempos': '!leading-[1.14]',
  'font-inter': '!leading-[1.2]',
  'font-calistoga': '!leading-tight',
  'font-playpen': '!leading-normal',
}

const props = defineProps<BasePageProps & { page: ImageAndMaybeText }>()
const { getSvgOrImage, getImageSizeClass } = useImage()
const { hasHeading } = useHiddenHeading()

const image = getSvgOrImage(props.page.svg, props.page.image, 'presentation_full')

const colorPair = computed(() => props.page.colorPair)
const lottieId = computed( () => Number(props.page.lottie.destinationContentId))
const textPosition = computed(() => textPositions[props.page.textPlacement])
const transition = computed(() => textTransitions[props.page.textPlacement])
const headerClasses = computed(() => {
  return `${props.page.textSizes.heading} ${props.page.fontFamily} ${props.page.fontWeight} ${lineHeights[props.page.fontFamily]}`
})
const backgroundAndText = computed(() => ({
  backgroundColor: colorPair.value.background.rgb,
  color: colorPair.value.text.rgb,
}))

</script>
<template>
  <div
    :style="backgroundAndText"
    class="h-screen w-screen"
  >
    <h1
      v-if="!hasHeading"
      class="sr-only"
      v-text="page.title"
    />
    <LottiePlayer
      v-if="lottieId"
      :content-id="lottieId"
      :color-pair="colorPair"
    />
    <ImageWithCaption
      v-else-if="image"
      :image="image"
      :classes="`h-full w-full ${getImageSizeClass(page.imageSize)}`"
      :color-pair="page.colorPair"
    />
    <div
      v-if="page.header"
      class="absolute flex max-w-[calc(100vw-8rem)]"
      :class="textPosition"
    >
      <Transition
        enter-active-class="duration-1000 ease delay-1000"
        :enter-from-class="transition.enterFrom"
        :enter-to-class="transition.enterTo"
        appear
      >
        <h1 :class="headerClasses">
          <span
            :style="backgroundAndText"
            class="box-decoration-clone px-4"
            v-text="page.header"
          />
        </h1>
      </Transition>
    </div>
  </div>
</template>
