import { externalClient } from './client/externalClient'
import type { LicenseDetails } from '~/models/License'

export default function useLicenseApi() {

  const getLicenseDetails = async (): Promise<LicenseDetails[]> => {
    return (await externalClient.get('/frontend/licenses/details?site=AUNIVERS,GAN')).data
  }

  return {
    getLicenseDetails,
  }
}
