import type { PlannerUser } from './PlannerUser'
import { Plan } from './Plan'
import { SubjectCode } from '../Subject'
import { GradeCode } from '../Grade'
import { Group } from '../Group'

export enum YearPlanRole {
  Editor = 'editor',
  Viewer = 'viewer',
  Member = 'member',
  Owner = 'owner',
}
export interface YearPlan {
  '@id': string
  '@type': 'YearPlan'
  createdAt: string | null
  createdBy: PlannerUser | null
  description: string
  distribute: boolean
  distributeDay: string | null
  distributeHour: string | null
  distributeMinute: string | null
  grades: GradeCode[]
  groups: Group[] | null
  id: string
  identifier: string
  locked: boolean
  plans: Plan[]
  remoteId: string
  roles: YearPlanRole[]
  schoolYear: string
  subjects: SubjectCode[]
  title: string
  type: ''
  updatedAt: string
  visible: boolean
  waiting: boolean
}

export type YearPlansBySubject = Record<SubjectCode, YearPlan[]>
