<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useBlogStore from '~/stores/blog'
import BlogSkeleton from '~/components/skeletons/BlogSkeleton.vue'
import { useI18n } from '~/composables/useI18n'

const { t } = useI18n()
const { isLoading, posts } = storeToRefs(useBlogStore())
</script>

<template>
  <div
    v-if="isLoading"
    class="relative mx-auto my-14 max-w-5xl gap-20 px-5 sm:px-8"
  >
    <BlogSkeleton />
  </div>
  <div
    v-if="!isLoading && posts.length"
    class="relative mx-auto my-14 flex max-w-screen-au flex-col justify-center gap-12 px-5 sm:px-8 md:gap-20"
  >
    <router-view />
  </div>
  <div
    v-if="!isLoading && !posts.length"
    class="mx-auto mt-20 flex max-w-xl flex-col items-center gap-5 px-5 md:px-8"
  >
    <h2
      class="mb-4 text-xl font-medium"
      v-text="t('blog.noPosts')"
    />
  </div>
</template>
