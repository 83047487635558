<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    aria-hidden="true"
    viewBox="0 0 1439 188"
    class="z-10 w-full"
  >
    <path
      fill="#165851"
      d="m1440.18 187.268-48.01-4.736c-48.01-5.075-144.03-14.038-240.09-14.783-96.07-.237-192.18 7.746-288.177-6.048-95.995-14.301-191.878-50.378-287.919-55.699-96.042-4.981-192.246 21.642-288.335 25.642-96.09 4.339-192.06-14.793-240.048-23.765L-.385 98.567.308-37l48.033.245 240.163 1.227L576.7-34.056l288.197 1.472 288.193 1.472 240.17 1.227 48.03.245-1.11 216.908Z"
    />
  </svg>
</template>
