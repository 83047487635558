<script setup lang="ts">
import { KsCallout } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>

<template>
  <KsCallout
    variant="warning"
    class="mb-4"
  >
    {{ t('register.error.loggedIn') }}
  </KsCallout>
</template>
