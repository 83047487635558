<script setup lang="ts">

</script>

<template>
  <section
    class="
      relative
      grid
      auto-rows-max
      grid-cols-header-view-mobile
      gap-y-12
      px-4
      grid-areas-header-view-mobile
      sm:px-8
      md:grid-cols-header-view-tablet
      md:px-0
      md:grid-areas-header-view-tablet
      lg:grid-areas-header-view-desktop
      xl:gap-y-24
    "
  >
    <slot />
  </section>
</template>
