import { useI18n } from 'vue-i18n'

const mimeTypeMap: Record<string, string[]> = {
  pdf: ['application/pdf'],
  word: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  excel: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  video: ['video/webm', 'video/mpeg', 'video/ogg', 'video/x-msvideo'],
  audio: ['audio/webm', 'audio/wav', 'audio/ogg', 'audio/mpeg', 'audio/midi', 'audio/x-midi', 'video/x-msvideo', 'audio/aac'],
  image: ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/tiff', 'image/webp'],
  powerpoint: ['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  archive: ['application/zip', 'application/x-7z-compressed', 'application/gzip', 'application/x-bzip', 'application/x-bzip2'],
}

export function useFile() {
  const { t } = useI18n()

  function readableFileSize(bytes: number) {
    return bytes > 1024 * 1024
      ? `${Math.round(bytes / 1024 / 1024)} MB`
      : `${Math.round(bytes / 1024)} kB`
  }

  function readableMimeType(mimeType: string) {
    mimeType = mimeType.toLowerCase()
    for (const key in mimeTypeMap) {
      if (mimeTypeMap[key].includes(mimeType)) {
        return t(`mimeType.${key}`)
      }
    }
    return ''
  }

  return {
    readableFileSize,
    readableMimeType,
  }
}

export default useFile
