<script setup lang="ts">
import type { Group } from '~/models/Group'
import { KsButton, KsCallout, KsDrawer } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'
import useGroupsStore from '~/stores/groups'
import { storeToRefs } from 'pinia'
import SettingsGroupList from './SettingsGroupList.vue'
import SettingsGroupView from './SettingsGroupView.vue'
import { TeleportationTarget } from '~/models/TeleportationTarget'

const props = defineProps<{
  open: boolean
}>()

const openGroup = ref<Nullable<Group>>(null)
const { t } = useI18n()
const groupsStore = useGroupsStore()
const { loadGroups } = groupsStore
const { hasGroups, isLoading } = storeToRefs(groupsStore)

watch(props, (value) => {
  if (value.open) {
    openGroup.value = null
    loadGroups()
  }
})

const setOpenGroup = (group: Group) => {
  openGroup.value = group
}

function goBack() {
  if (openGroup.value) {
    openGroup.value = null
    return
  }
  emit('back')
}

const emit = defineEmits(['back', 'close'])
</script>

<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDrawer
      :title="t('settings.drawers.groups')"
      :open="props.open"
      :strict="false"
      :backdrop="false"
      size="600px"
      @close="emit('close')"
    >
      <template #header>
        <KsButton
          shape="round"
          icon-left="arrow-left"
          :aria-label="t('back')"
          @click="goBack"
        />
      </template>
      <template #body>
        <div
          v-if="!hasGroups && !isLoading"
          class="flex flex-col gap-6 border-t border-gray-10 py-6"
        >
          <KsCallout
            variant="warning"
            :title="t('groups.noGroupsTitle')"
            :dismissable="false"
          >
            {{ t('groups.noGroups') }}
          </KsCallout>
        </div>
        <SettingsGroupView
          v-if="openGroup"
          :group="openGroup"
          @delete-group="goBack"
          @back="goBack"
          @group-created="setOpenGroup"
        />
        <SettingsGroupList
          v-else-if="!openGroup && !isLoading"
          @open-group="setOpenGroup"
        />
      </template>
    </KsDrawer>
  </Teleport>
</template>
