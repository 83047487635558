<script setup lang="ts">
import type { WizardSteps } from '~/models/WizardSteps'
import { KsButton, KsDialog } from '@aschehoug/kloss'
import { computed, nextTick, ref, shallowRef, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { FOCUSABLE } from '~/utils/dom'
import { useAuthStore } from '~/stores/auth'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import {
  WizardGrades,
  WizardLanguage,
  WizardOrganization,
} from '.'

const { t } = useI18n()
const authStore = useAuthStore()
const { updateUser } = authStore
const {
  isAuthenticated,
  sessionUserInfo,
  needsOrganization,
  hasGrades,
  hasPreferredLanguage,
  isLoading,
} = storeToRefs(authStore)

const open = ref(false)
const shake = ref(false)
const stepIndex = ref(0)
const activeSteps = shallowRef<WizardSteps[]>([])
const content = ref<HTMLElement>()

const buttonState = computed((): boolean => {
  switch (activeSteps.value[stepIndex.value].component) {
    case WizardOrganization: return !needsOrganization.value
    case WizardGrades: return hasGrades.value
    case WizardLanguage: return hasPreferredLanguage.value
  }
  return false
})

const isLastStep = computed((): boolean =>
  stepIndex.value + 1 === activeSteps.value.length,
)

const title = computed((): string =>
  stepIndex.value === 0
    ? t('home.welcome')
    : activeSteps.value[stepIndex.value].title,
)

const canSave = computed((): boolean =>
  hasGrades.value
  && hasPreferredLanguage.value
  && !needsOrganization.value,
)

function closeWizard(): void {
  open.value = !canSave.value
  shake.value = !canSave.value
}

function saveUser(): void {
  updateUser(sessionUserInfo.value)
  open.value = false
}

async function onStepChange() {
  await nextTick()
  const focusables = content.value?.querySelectorAll<HTMLElement>(FOCUSABLE)
  if (focusables) setTimeout(() => focusables[0].focus(), 0)
}

function onAuthentication(newAuthenticated: boolean) {
  open.value = newAuthenticated && (
    !hasGrades.value
    || !hasPreferredLanguage.value
    || needsOrganization.value
  )
  const steps = [
    {
      active: needsOrganization.value,
      title: t('home.welcome'),
      component: WizardOrganization,
    },
    {
      active: !hasGrades.value,
      title: t('wizard.grades'),
      component: WizardGrades,
    },
    {
      active: !hasPreferredLanguage.value,
      title: t('wizard.language'),
      component: WizardLanguage,
    },
  ]
  activeSteps.value = steps.filter(step => step.active)
}

watch(isAuthenticated, onAuthentication)
watch([open, stepIndex], onStepChange)

</script>

<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      v-if="activeSteps.length > 0"
      :title="title"
      :open="open"
      :strict="true"
      :dismissable="false"
      class="wizard"
      :class="{
        'wizard--shaking': shake,
      }"
      @close="closeWizard"
    >
      <template #body>
        <div ref="content">
          <component :is="activeSteps[stepIndex].component" />
        </div>
      </template>
      <template #footer>
        <div class="flex items-center justify-end gap-4">
          <span class="text-sm text-gray-40">
            {{ t('wizard.step', { activeStep: stepIndex + 1, steps: activeSteps.length }) }}
          </span>
          <div class="ml-auto flex gap-2">
            <KsButton
              v-if="stepIndex > 0"
              variant="secondary"
              shape="rounded"
              @click.prevent="stepIndex--"
            >
              {{ t('wizard.prevStep') }}
            </KsButton>
            <KsButton
              v-if="!isLastStep"
              variant="primary"
              shape="rounded"
              :disabled="!buttonState"
              @click.prevent="stepIndex++"
            >
              {{ t('wizard.nextStep') }}
            </KsButton>
            <KsButton
              v-if="isLastStep"
              variant="primary"
              shape="rounded"
              :disabled="!canSave || isLoading || !buttonState"
              @click.prevent="saveUser"
            >
              {{ t('wizard.finish') }}
            </KsButton>
          </div>
        </div>
      </template>
    </KsDialog>
  </Teleport>
</template>
