import type { Plugin } from '~/models/Plugin'
import { createApp } from 'vue'
import { KsTooltip } from '@aschehoug/kloss'
import '~/styles/index.css'
import disableTabindex from '~/directives/disable-tabindex'
import App from '~/App.vue'
import FileDownloadBlock from '~/components/utils/FileDownloadBlock.vue'
import TipBlock from '~/components/TipBlock.vue'
import '../__setups__/luxonSetup'

const app = createApp(App)
app.directive('ks-tooltip', KsTooltip)
app.directive('focus', {
  mounted: (el) => el.focus()
})
app.directive('disable-tabindex', disableTabindex)
const plugins = Object.values(import.meta.glob('./plugins/*.ts', { eager: true })) as Plugin[]
plugins.forEach((p) => p.install(app))
app.component('TipBlock', TipBlock)
app.component('FileDownloadBlock', FileDownloadBlock)
app.mount('#app')
