export const useBreakpoint = () => {
  const breakpointElement = document.querySelector('#breakpointCheck')

  /**
   * Get a number representing the current breakpoint:
   *   less than xs => 0
   *   xs => 1
   *   sm => 2
   *   md => 3
   *   lg => 4
   *   xl => 5
   *   more than xl => 6+
   */
  const getBreakpoint = () => {
    if (!breakpointElement) return 0
    const breakpoint = parseInt(getComputedStyle(breakpointElement).width)
    return (breakpoint / 4)
  }

  return { getBreakpoint }
}

export default useBreakpoint
