import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { htmlContainsText } from '~/utils/dom'
import useContentApi from '~/api/contentApi'

interface Messages {
  messageTitle: string
  message: string
  loginMessageTitle: string
  loginMessage: string
}

export const useMessagesStore = defineStore('messages',  () => {
  const { getSettingsMessages } = useContentApi()
  const messages = ref<Nullable<Messages>>(null)
  const hasMessages = computed(() => messages.value)
  const hasMessage = computed(() => messages.value && htmlContainsText(messages.value.message))
  const hasLoginMessage = computed(() => messages.value && htmlContainsText(messages.value.loginMessage))

  async function getMessages() {
    try {
      const response = await getSettingsMessages()
      messages.value = {
        messageTitle: response['message_title'] ?? '',
        message: response['message'] ?? '',
        loginMessage: response['login_message'] ?? '',
        loginMessageTitle: response['login_message_title'] ?? '',
      }
    } catch (_) {
      return
    }
  }

  return {
    messages,
    hasMessage,
    hasLoginMessage,
    hasMessages,
    getMessages,
  }
})
