<script setup>
import { compile, computed } from 'vue'

const props = defineProps({
  text: {
    type: String,
    required: true,
  }
})

const richtext = computed(() => compile(props.text))
</script>

<template>
  <richtext />
</template>
