<script lang="ts" setup>
import type { BaseItem } from '~/models/Content/BaseItem'
import type { Component } from 'vue'
import Card from './Components/Card.vue'

interface Components {
  [key: string]: Component | string
}

interface ResourceRendererProps {
  items: BaseItem[]
  isAnchor?: boolean
}

withDefaults(defineProps<ResourceRendererProps>(), {
  isAnchor: false
})

const getComponent = (resource: BaseItem) => {
  return Card
}
</script>

<template>
  <component
    :is="getComponent(item)"
    v-for="item in items"
    :key="item.locationId"
    :resource="item"
    :resources="[]"
    :featured="[]"
    :is-anchor="isAnchor"
  >
    <template #resource="{ resourceItem }">
      <ResourceRenderer
        :items="[ resourceItem ]"
        :is-anchor="isAnchor"
      />
    </template>
  </component>
</template>
