<script setup lang="ts">
import NotFound from '~/components/ResourceEngine/Components/NotFound.vue'
import { useI18n } from 'vue-i18n'
import { setTitle } from '~/utils/dom'

const { t } = useI18n()
setTitle(t('notFound.title'))
</script>
<template>
  <NotFound class="mt-18" />
</template>
