import type { ContentLottie } from '~/models/Content/ContentLottie'
import { ContentType } from '~/models/Content/ContentType'
import { Subtree } from '~/models/Content/Subtree'
import { ref } from 'vue'
import useContentApi from '~/api/contentApi'

export const useLottie = () => {
  const { findContents } = useContentApi()

  const lottie = ref<ContentLottie>()
  const lottieJSON = ref<JSON>()
  const isLoading = ref(false)

  const getLottie = async (contentId: number) => {
    try {
      isLoading.value = true

      const [lottieContent, lottieFile] = await Promise.all([
        findContents<ContentLottie>({
          contentIdCriterion: [contentId],
          contentTypeCriterion: [ContentType.Lottie],
          subtreeCriterion: [Subtree.Content, Subtree.Media],
          mainLocationCriterion: true,
        }),
        fetch(`${import.meta.env.VITE_AUNIVERS_SITEURL}/lottie/${contentId}/file.json`)
      ])

      lottie.value = lottieContent[0]
      lottieJSON.value = await lottieFile.json()
    } catch (e) {
      lottie.value = undefined
      lottieJSON.value = undefined
      throw e
    } finally {
      isLoading.value = false
    }
  }

  return {
    lottie,
    lottieJSON,
    isLoading,
    getLottie,
  }
}
