<script setup lang="ts">
import type { ColorPair } from '~/models/Presentation/BasePage'
import { computed, onMounted, ref, watch } from 'vue'
import { KsButton } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { useLottie } from '~/composables/useLottie'

const props = withDefaults(defineProps<{
  contentId: number
  autoPlay?: boolean
  pauseAnimation?: boolean
  colorPair?: ColorPair
}>(), {
  autoPlay: !matchMedia('(prefers-reduced-motion)').matches,
  pauseAnimation: false
})

const { lottie, lottieJSON, getLottie } = useLottie()
const { t } = useI18n()

const lottieAnimation = ref()
const isPlaying = ref(props.autoPlay)
const showButton = ref(false)
const buttonHasFocus = ref(false)

const playOrPause = () => {
  if (!props.autoPlay) return

  showButton.value = true
  setTimeout(() => showButton.value = false, 500)

  isPlaying.value
    ? lottieAnimation.value.pause()
    : lottieAnimation.value.play()

  isPlaying.value = !isPlaying.value
}

const iconLeft = computed(() => {
  if ((buttonHasFocus.value && isPlaying.value) || (!buttonHasFocus.value && !isPlaying.value)) return 'pause'
  if ((buttonHasFocus.value && !isPlaying.value) || (!buttonHasFocus.value && isPlaying.value)) return 'play'
  return ''
})

// This line updates the isPlaying value when animations are paused in ThreeStepPage.vue
watch(() => props.pauseAnimation, () => isPlaying.value = !props.pauseAnimation)

onMounted(() => props.contentId && getLottie(props.contentId))
</script>

<template>
  <div
    v-if="lottieJSON"
    class="relative h-full w-full"
  >
    <p
      :id="`lottie-${contentId}`"
      class="hidden"
      v-text="lottie?.alternativeText"
    />
    <LottieAnimation
      ref="lottieAnimation"
      :animation-data="lottieJSON"
      :auto-play="autoPlay"
      :pause-animation="pauseAnimation"
      :aria-labelledby="`lottie-${contentId}`"
      role="img"
      width="100%"
      @click="playOrPause"
    />
    <div
      class="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transition-all duration-300"
      :class="showButton || buttonHasFocus ? 'scale-100 opacity-100' : 'scale-0 opacity-0'"
    >
      <KsButton
        shape="round"
        class="pointer-events-none"
        size="large"
        :aria-label="isPlaying ? t('lottie.pause') : t('lottie.play')"
        :style="colorPair && { background: colorPair.background.rgb, color: colorPair.text.rgb }"
        :icon-left="iconLeft"
        @click="playOrPause"
        @focusin="buttonHasFocus = true"
        @focusout="buttonHasFocus = false"
      />
    </div>
  </div>
</template>

