<template>
  <div class="presentation-scroll">
    <slot />
  </div>
</template>

<style scoped>
  .presentation-scroll {
    overflow: auto;
    scrollbar-width: auto;
    scrollbar-color: currentColor transparent;

    @supports selector(::-webkit-scrollbar) and (not (scrollbar-color: auto)) {
      &::-webkit-scrollbar {
        width: 12px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: currentColor;
        border: 2px solid transparent;
        background-clip: content-box;
        border-radius: 1rem;
      }
    }
  }
</style>
