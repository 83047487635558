<script setup lang="ts">
  defineProps<{
    label: string
  }>()

  const model = defineModel<boolean>({ default: false })
</script>

<template>
  <label
    class="relative grid flex-none grid-cols-[4fr,1fr,4fr] rounded-full border border-[var(--media-color,white)] p-1 transition [&:has(:focus-visible)]:ring"
    :class="[model ? 'bg-[var(--media-color,white)]' : 'bg-[var(--media-bg-color,black)]']"
  >
    <input
      v-model="model"
      type="checkbox"
      class="absolute appearance-none"
    >

    <div
      class="absolute inset-0 flex items-center justify-between p-3 text-lg font-semibold transition" 
      aria-hidden="true"
    >
      <span class="text-[var(--media-bg-color,black)]">{{ $t('dialog.on').toUpperCase() }}</span>
      <span class="text-[var(--media-color,white)]">{{ $t('dialog.off').toUpperCase() }}</span>
    </div>

    <div
      class="row-start-1 grid place-items-center rounded-full p-2 transition"
      :class="[model ? 'translate-x-[125%] bg-[var(--media-bg-color,black)]' : 'translate-x-0 bg-[var(--media-color,white)]']"
    >
      <div :class="[model ? 'text-[var(--media-color,white)]' : 'text-[var(--media-bg-color,black)]']">
        <slot />
      </div>
    </div>
    <div class="sr-only">
      {{ label }}
    </div>
  </label>
</template>
