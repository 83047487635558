<script setup lang="ts">
import type { AggregationTermEntry } from '~/models/Content/Response'
import { KsInput } from '@aschehoug/kloss'
import AggregationButton from '~/components/search/AggregationButton.vue'

defineProps<{
  title: string
  aggregations: AggregationTermEntry[]
  criterions: string[]
  isLoading: boolean
  translator: (a: AggregationTermEntry) => string
}>()

const emit = defineEmits([ 'addCriterion' ])
</script>
<template>
  <div class="space-y-4">
    <span
      class="text-sm font-medium uppercase tracking-wider text-gray-40"
      v-text="title"
    />
    <ul class="mt-2 flex flex-col gap-2">
      <li
        v-for="(entry, i) in aggregations"
        :key="`aggregation-${i}`"
      >
        <AggregationButton
          :name="translator(entry)"
          :entry="entry"
          :disabled="isLoading"
          variant="border"
          type="label"
          stretch
        >
          <KsInput
            type="checkbox"
            class="shrink-0"
            :checked="criterions.includes(entry.key)"
            @click="emit('addCriterion', entry.key)"
          />
        </AggregationButton>
      </li>
    </ul>
  </div>
</template>
