<script lang="ts" setup>
import type { GridPage, TextAndImage } from '~/models/Presentation/Pages/GridPage'
import { computed } from 'vue'
import useImage from '~/composables/useImage'
import PresentationRichText from '~/components/PresentationPages/Components/PresentationRichText.vue'
import LottiePlayer from '~/components/media/LottiePlayer.vue'
import ImageWithCaption from '~/components/media/ImageWithCaption.vue'
import ScrollBox from '~/components/utils/ScrollBox.vue'

const props = defineProps<{
  page: GridPage
  textAndImage: TextAndImage
  index: number
}>()

const { getSvgOrImage } = useImage()

const image = getSvgOrImage(props.textAndImage.svg, props.textAndImage.image)

const lottieId = computed(() => Number(props.textAndImage.lottie.destinationContentId))
</script>

<template>
  <li
    :style="{ '--i': index }"
    class="grid h-screen w-screen grid-rows-2 text-balance"
  >
    <ScrollBox>
      <div class="flex flex-col justify-center p-8">
        <h1
          v-if="textAndImage.header"
          :class="[textAndImage.textSizes.heading, textAndImage.fontFamily, textAndImage.fontWeight]"
          v-text="textAndImage.header"
        />
        <PresentationRichText
          v-if="textAndImage.body"
          :class="[textAndImage.textSizes.paragraph]"
          :page="{...page, ...textAndImage, textSizes: { paragraph: 'fluid-text-lg', heading: '' }}"
        />
      </div>
    </ScrollBox>
    <LottiePlayer
      v-if="lottieId"
      :content-id="lottieId"
      :color-pair="page.colorPair"
    />
    <ImageWithCaption
      v-else-if="image"
      :image="image"
      classes="aspect-video h-full w-full object-cover"
      :color-pair="page.colorPair"
    />
  </li>
</template>
