<script setup lang="ts">
import type { GroupMember } from '~/models/Group'
import type { Week } from '~/models/Planner/Week'
import type { StudentProgress, TaskProgress } from '~/models/Activity'
import { computed, defineAsyncComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsPopover, KsProgress, KsButton } from '@aschehoug/kloss'
import useActivityStore from '~/stores/activity'
import usePlannerStore from '~/stores/planner'
import useDialogStore from '~/stores/dialog'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import CopyUrlButton from '~/components/cards/buttons/CopyUrlButton.vue'
import { useContentHelper } from '~/composables/useContentHelper'
import { ContentType } from '~/models/Content/ContentType'
import { waitFor } from '~/utils/asyncUtils'
import { storeToRefs } from 'pinia'

const emit = defineEmits(['update:students'])

const props = defineProps<{
  activity: TaskProgress
  students: GroupMember[]
  fromDate: string
  toDate: string
}>()

const { t } = useI18n()
const { buildResourceUrl } = useUrlBuilder()
const activityStore = useActivityStore()
const { loadCompoundProgressByDatesAndLocationIds } = activityStore
const { isEnrichingActivities } = storeToRefs(activityStore)
const { findWeeksWithResourceInSelectedYearPlan } = usePlannerStore()
const { getTitle } = useContentHelper()

const expanded = ref(false)
const studentProgress = ref<StudentProgress[]>([])
const isLoadingStudentProgress = ref(false)

const weeksWithResource = computed(() => findWeeksWithResourceInSelectedYearPlan(props.activity))
const itemUrl = computed(() => buildResourceUrl(props.activity))
const title = computed(() => getTitle(props.activity))

const learningPathPartsCount = computed(() => {
  if (props.activity.contentTypeIdentifier === ContentType.LearningPath) {
    if (studentProgress.value.length > 0) {
      return studentProgress.value.reduce((longest, currentProgress) => {
        return Math.max(props.activity.learningPathPartsCount, longest, currentProgress.sectionCount, currentProgress.read)
      }, 0)
    }
  }
  return 1
})

const label = computed(() => {
  if (props.activity.label) return t(`contentTypes.${props.activity.label}`)
  if (props.activity.contentTypeIdentifier) return t(`contentTypes.${props.activity.contentTypeIdentifier}`)
  return ''
})

const loadStudentProgress = async () => {
  try {
    isLoadingStudentProgress.value = true
    studentProgress.value = await loadCompoundProgressByDatesAndLocationIds([props.activity.locationId], props.fromDate, props.toDate)
  } finally {
    isLoadingStudentProgress.value = false
    emit('update:students', studentProgress.value.filter((progress) => {
      return progress.status !== t('activity.status.notStarted')
    }).length)
  }
}

const toggleExpanded = () => {
  expanded.value = !expanded.value
  if (expanded.value) {
    loadStudentProgress()
  }
}

const findActivityInWeek = (week: Week, activity: TaskProgress) => {
  return week.resources.find((resource) => resource.locationId === activity.locationId)
}

const removePlannedActivity = () => {
  const { removeFromWeek } = usePlannerStore()

  weeksWithResource.value.forEach((week) => {
    const resourceInWeek = findActivityInWeek(week, props.activity)
    if (!resourceInWeek) return
    removeFromWeek(week, resourceInWeek)
  })
}

const planActivity = async () => {
  const { addToWeek } = usePlannerStore()
  const { showDialog } = useDialogStore()

  const weekNumber = await showDialog<number>(
    defineAsyncComponent(() => import('~/components/planner/WeekNumberDialog.vue')),
    { resource: props.activity }
  )
  await addToWeek(props.activity, weekNumber)
}

const openActivity = async () => {
  await waitFor(() => !isEnrichingActivities.value, 500)
  window.open(itemUrl.value, '_self')
}

</script>
<template>
  <div class="my-2 flex min-h-4 flex-row justify-center gap-2 align-middle text-lg">
    <div class="w-full rounded-md border border-gray-20 px-4 py-1">
      <div class="flex w-full">
        <div class="flex w-96">
          <KsButton
            :icon-left="expanded ? 'chevron-up' : 'chevron-down'"
            :variant="expanded ? 'primary' : 'tertiary'"
            class="mr-4 mt-1 max-h-8 w-8 !min-w-8 max-w-8 !p-[6px] text-center"
            @click="toggleExpanded"
          />
          <div class="flex shrink gap-8 ">
            <a
              class="inline-block align-middle font-medium leading-10"
              :href="itemUrl"
              @click.prevent="toggleExpanded"
              v-text="title"
            />
          </div>
        </div>
        <div class="flex w-[60%] flex-1 flex-col gap-4 md:flex-row md:items-center">
          <div class="flex min-w-40">
            <span
              v-if="label"
              class="my-1 inline-flex max-h-8 items-center text-nowrap rounded-md border-2 border-gray-50 px-1 py-0 align-middle"
              v-text="label"
            />
          </div>
          <KsProgress
            class="max-w-52"
            :values="[activity.noOfUsers]"
            :max="students.length"
            :colors="[activity.noOfUsers !== students.length ? '#A8A8A8' : undefined ]"
          />
          <div class="min-w-48">
            <span
              class="inline-flex w-11 justify-center rounded-full bg-seagreen-20 align-middle font-medium "
              v-text="activity.noOfUsers"
            />
            <span
              class="pl-1 align-middle"
              v-text="t('activity.nOfTotal', { total: students.length })"
            />
          </div>
        </div>
        <div class="flex justify-end gap-2">
          <span
            v-if="weeksWithResource.length > 0"
            v-ks-tooltip="t('activity.plannedInWeek')"
            v-text="weeksWithResource.map((week: Week) =>
              t('time.weekWithNumber', {number:week.weekNumber})). join(', ')"
          />
          <KsPopover
            :title="t('activity.actionOnLine', { title: activity.title })"
            class="!flex !items-center"
            direction="down left"
          >
            <template #toggle>
              <KsButton
                icon-left="ellipsis-vertical"
                class="w-8 !min-w-8 max-w-8 !p-[6px] text-center"
              />
            </template>
            <template #content>
              <KsButton
                icon-left="link"
                :stretch="true"
                :href="itemUrl"
                @click.stop.prevent="openActivity"
              >
                {{ t('activity.openActivity') }}
              </KsButton>
              <CopyUrlButton
                :url="itemUrl"
                :stretch="true"
                icon="copy"
              />
              <KsButton
                :stretch="true"
                icon-left="calendar"
                @click="planActivity"
              >
                {{ weeksWithResource.length > 0
                  ? t('activity.planActivityInMoreWeeks')
                  : t('activity.planUnplanned') }}
              </KsButton>
              <KsButton
                v-if="weeksWithResource.length > 0"
                icon-left="trash"
                :stretch="true"
                @click="removePlannedActivity"
              >
                {{ t('activity.removePlannedActivity') }}
              </KsButton>
            </template>
          </KsPopover>
        </div>
      </div>
      <div
        v-if="expanded"
        class="mt-2"
      >
        <div
          v-for="progress in studentProgress"
          :key="progress.user.userId + activity.locationId"
          class="my-2 flex w-full gap-2 border-t-[1px] border-t-gray-20 pb-0 pl-8 pt-4"
        >
          <div class="flex w-64">
            <span v-text="progress.user.fullName" />
          </div>
          <div class="flex flex-1 flex-col md:flex-row md:items-center">
            <KsProgress
              class="max-w-52"
              :values="[progress.read]"
              :max="learningPathPartsCount"
              :colors="[progress.read < learningPathPartsCount ? '#A8A8A8' : '' ]"
            />
          </div>
          <div class="w-16">
            <span v-text="`${progress.read}/${learningPathPartsCount}`" />
          </div>
          <div class="w-36">
            <span v-text="progress.read === learningPathPartsCount ? t('activity.completed') : progress.status" />
          </div>
          <div class="w-44">
            <span
              v-ks-tooltip="t('activity.lastInteraction')"
              v-text="progress.lastInteraction ? progress.lastInteraction : '-'"
            />
          </div>
          <div class="w-36">
            <!-- Placeholder for skriverammer med lenke direkte til elevens oppgave -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
