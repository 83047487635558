export default () => {
  /**
   * Checks if string ends with char
   */
  const endsWith = (text: string, char: string): boolean => {
    return text.charAt(text.length - 1) === char
  }

  /**
   * Returns last item in path ("/first/second/last") with optional typing
   */
  const lastInPath = <T extends string>(path: string): T => {
    if (!path.includes('/')) {
      return path as T
    }
    if (endsWith(path, '/')) {
      path = path.substring(0, path.length - 1) as T
    }
    const parts = path.split('/')
    return parts[parts.length - 1] as T
  }

  const isString = (value: any): value is string => {
    return typeof value === 'string'
  }
  const ensureStartsWith = (text: string, char: string): string => {
    if (text.charAt(0) !== char) {
      return `${char}${text}`
    }
    return text
  }

  const richTextIsEmpty = (richText: string | undefined): boolean => {
    if (!richText) return true
    return richText === '<?xml version="1.0" encoding="UTF-8"?>\n'
  }

  const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1)

  return {
    endsWith,
    lastInPath,
    isString,
    ensureStartsWith,
    richTextIsEmpty,
  }
}
