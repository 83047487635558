<script setup lang="ts">
import type { AggregationTermEntry } from '~/models/Content/Response'
import { onMounted, ref } from 'vue'
import { KsIcon, KsInput } from '@aschehoug/kloss'
import AggregationButton from '~/components/search/AggregationButton.vue'

const props = defineProps<{
  title: string
  isRefetching: boolean
  aggregations: { group: string; key: string[] }[]
  criterions: string[]
  translator: (entry: AggregationTermEntry) => string
  openOnMount?: boolean
}>()

const emit = defineEmits(['toggleCriterion'])

const open = ref(false)

const isChecked = (entryKey: string[]) => props.criterions.includes(...entryKey)

const handleCheckboxClick = (entry: { group: string; key: string[] }) =>
  entry.key.forEach(key => emit('toggleCriterion', key))

onMounted(() => {
  if (props.criterions.length || props.openOnMount) {
    open.value = true
  }
})
</script>

<template>
  <div class="rounded bg-white">
    <button
      class="group flex w-full items-center justify-between rounded px-4 py-3 focus-visible:ring"
      @click="open = !open"
    >
      <span
        class="font-bold underline-offset-4 group-hover:underline"
        v-text="title"
      />
      <KsIcon
        :icon="open ? 'minus' : 'plus'"
        :spin="open"
        animation-duration="200ms"
      />
    </button>
    <Transition
      :duration="500"
      name="nested"
    >
      <div
        v-show="open"
        class="outer mx-4 border-t border-t-gray-10 py-3"
      >
        <ul class="inner flex flex-col gap-2">
          <li
            v-for="(entry) in aggregations"
            :key="entry.group"
          >
            <AggregationButton
              :name="translator(entry)"
              :entry="entry"
              type="label"
              :disabled="isRefetching"
              stretch
              size="small"
            >
              <KsInput
                :id="entry.group"
                type="checkbox"
                class="shrink-0"
                :checked="isChecked(entry.key)"
                :disabled="isRefetching"
                @click="handleCheckboxClick(entry)"
              />
            </AggregationButton>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.nested-enter-active, .nested-leave-active {
  transition: all 0.3s linear;
  max-height: 500px;
}
/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.2s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(-5px);
  opacity: 0;
  max-height: 0;
}

.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  will-change: transform, opacity;
}
/* delay enter of nested element */
.nested-enter-active .inner {
  transition-delay: 0.2s;
}

.nested-enter-from .inner {
  transform: translateY(-5px);
  opacity: 0.001;
}

.nested-leave-to .inner {
  transform: translateY(-5px);
  opacity: 0.001;
}
</style>
