<script setup lang="ts">
import type { Component } from 'vue'
import type { AuniversMetadata } from '~/models/Product'
import type { ColorPair } from '~/models/Presentation/BasePage'
import type { BaseItem } from '~/models/Content/BaseItem'
import type { ViewMode } from '~/models/Content/BaseField'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import { useStaticProduct } from '~/composables/useStaticProduct'
import { useContentHelper } from '~/composables/useContentHelper'
import UploadedResourceCard from '~/components/cards/UploadedResourceCard.vue'
import ResourceCard from '~/components/cards/ResourceCard.vue'
import PackageCard from '~/components/cards/PackageCard.vue'
import ListCard from '~/components/cards/ListCard.vue'
import FileCard from '~/components/cards/FileCard.vue'

const { isPlannedItem, isUploadedFile, isLink, isPackage, isProduct } = useContentHelper()
const { isFileView, isListView } = useFilterStore()
const { products } = storeToRefs(useProductStore())
const { ALWAYS_SEARCHABLE_PRODUCTS } = useStaticProduct()

const props = withDefaults(defineProps<{
  resource: BaseItem
  viewMode?: ViewMode
  isAnchor?: boolean
  accessControl?: boolean
  colorPair?: ColorPair
  loading?: 'eager' | 'lazy'
}>(), {
  isAnchor: false,
  accessControl: false,
  loading: 'lazy'
})

const isUploadedResourceCard = computed(() => isPlannedItem(props.resource) && (isUploadedFile(props.resource) || isLink(props.resource)))
const isPackageCard = computed(() => isPackage(props.resource) || isProduct(props.resource))
const isFileCard = computed(() => props.viewMode && isFileView(props.viewMode))
const isListCard = computed(() => props.viewMode && isListView(props.viewMode))

const matchesProduct = (metadata: AuniversMetadata) => metadata.pathString && props.resource.pathString.includes(metadata.pathString)

const belongsToAlways = computed(() => ALWAYS_SEARCHABLE_PRODUCTS.find(({ aunivers }) => matchesProduct(aunivers)))
const belongsToProduct = computed(() => products.value.find(({ aunivers }) => matchesProduct(aunivers)))
const hasAccess = computed(() => !props.accessControl || !(belongsToAlways.value && !belongsToProduct.value))

const renderCard = computed((): Component => {
  if (isListCard.value) return ListCard
  if (isFileCard.value) return FileCard
  if (isUploadedResourceCard.value) return UploadedResourceCard
  if (isPackageCard.value) return PackageCard
  return ResourceCard
})
</script>

<template>
  <component
    :is="renderCard"
    :resource="resource"
    :is-anchor="props.isAnchor"
    :has-access="hasAccess"
    :color-pair="colorPair"
    :product="belongsToProduct || belongsToAlways"
    :loading
  />
</template>
