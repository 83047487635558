<script setup lang="ts">
import { ColorName } from '~/models/Content/Color'
import { useAppColor } from '~/composables/useAppColor'
import DefaultSections from '~/components/home/DefaultSections.vue'
import DefaultMessages from '~/components/home/DefaultMessages.vue'
import DefaultBanner from '~/components/home/DefaultBanner.vue'

useAppColor(ColorName.White)
</script>

<template>
  <DefaultBanner />
  <DefaultMessages />
  <DefaultSections />
</template>
