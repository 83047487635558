<script setup lang="ts">
import type { SectionBaseItem } from '~/models/Content/SectionBaseItem'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { KsSkeleton } from '@aschehoug/kloss'
import { ContentType } from '~/models/Content/ContentType'
import useContentApi from '~/api/contentApi'
import ProductSearchRelevantCard from '~/components/products/ProductSearchRelevantCard.vue'

const props = defineProps<{ productPathstring: string }>()

const { findContents } = useContentApi()

const relevantSections = computed<SectionBaseItem[]>(() => sections?.value ?? [])

const { data: sections, isLoading } = useQuery({
  queryKey: ['product_relevant_list', props.productPathstring],
  queryFn: async () => (await findContents<SectionBaseItem>({
    subtreeCriterion: [props.productPathstring],
    sortField: 'priority',
    sortOrder: 'asc',
    contentTypeCriterion: [ContentType.SectionStandalone],
  }, 2)) ?? [],
  enabled: !!props.productPathstring,
  staleTime: Infinity
})
</script>

<template>
  <div class="mb-6 mt-2 grid-in-relevant">
    <ul
      v-if="isLoading"
      class="grid grid-cols-1 gap-4 lg:grid-cols-2"
    >
      <KsSkeleton
        v-for="i in 2"
        :key="i"
        height="190px"
        border-radius="0"
      />
    </ul>
    <ul
      v-else
      class="grid grid-cols-1 gap-4"
      :class="relevantSections.length > 1 ? 'lg:grid-cols-2' : 'lg:grid-cols-1'"
    >
      <li
        v-for="section in relevantSections"
        :key="section.locationId"
      >
        <ProductSearchRelevantCard :section />
      </li>
    </ul>
  </div>
</template>
