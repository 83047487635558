<script lang="ts" setup>
import type { ContentBookReader } from '~/models/Content/ContentBookReader'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useLocation } from '~/composables/useLocation'
import { setTitle } from '~/utils/dom'

const props = defineProps<{ locationId: number }>()

const { t } = useI18n()
const router = useRouter()
const { location, fetchLocation, isLoading } = useLocation<ContentBookReader>(props.locationId)

const iframeUrl = computed(() => `${import.meta.env.VITE_BOOKREADER_URL}/${location.value?.identifier}`)

watch(() => props.locationId, fetchLocation)
watch(location, (location) => {
  if (!location) return
  setTitle(location.title)
})

const closePage = (event: MessageEvent) => {
  if (!event.isTrusted) return
  if (event.origin !== new URL(import.meta.env.VITE_BOOKREADER_URL).origin) return
  if (event.data !== 'CLOSE') return

  router.push({ name: 'bookreader_summary', params: router.currentRoute.value.params })
}

window.addEventListener('message', closePage)
</script>

<template>
  <iframe
    v-if="location?.identifier"
    class="h-full w-full"
    :src="iframeUrl"
  />

  <div
    v-else-if="isLoading"
    class="h-full p-6"
  >
    <KsSkeletonWrapper
      class="grid h-full place-content-center gap-2"
      :text="t('bookreader.loading')"
    >
      <KsSkeleton
        width="30ch"
        height="2rem"
      />
      <KsSkeleton width="50ch" />
      <KsSkeleton width="40ch" />
      <KsSkeleton width="45ch" />
      <KsSkeleton width="43ch" />
      <KsSkeleton width="52ch" />
      <KsSkeleton width="35ch" />
    </KsSkeletonWrapper>
  </div>

  <div
    v-else
    class="grid h-full place-content-center p-6"
  >
    {{ t('bookreader.error') }}
  </div>
</template>
