<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { colors, forceColorShade, getColorForId } from '~/utils/colors'
import { useContentHelper } from '~/composables/useContentHelper'
import StackedCard from '~/components/cards/StackedCard.vue'
import SearchCard from '~/components/cards/SearchCard.vue'

defineProps<{
  results: BaseItem[]
  isLoading: boolean
}>()

const { isPackage, isProduct } = useContentHelper()

const isPackageCard = (resource: BaseItem) => isPackage(resource) || isProduct(resource)
</script>

<template>
  <KsSkeletonWrapper
    v-if="isLoading"
    class="grid grid-cols-1 gap-6 xs:grid-cols-2"
  >
    <KsSkeleton
      v-for="i in 16"
      :key="i"
      height="160px"
    />
  </KsSkeletonWrapper>
  <ul
    v-else
    class="grid grid-cols-1 gap-4 xs:grid-cols-2"
  >
    <li
      v-for="result in results"
      :key="`resource-${result.locationId}`"
      :class="isPackageCard(result) && result.thumbnail ? 'row-span-2' : 'row-span-1'"
    >
      <StackedCard
        v-if="isPackageCard(result)"
        :item="result"
        class="size-full"
        :depth="3"
        element="div"
        :stack-offset="3"
        padded-stack
        :colors="[forceColorShade(getColorForId(result.locationId, colors), '10')]"
      >
        <template #default="{ item }">
          <SearchCard
            v-if="item"
            :item="item"
            package-style
          />
        </template>
      </StackedCard>
      <SearchCard
        v-else
        :item="result"
      />
    </li>
  </ul>
</template>
