import axios from 'axios'
import type { AxiosInstance } from 'axios'
import { MaxTimeoutMs } from '~/constants/api'

const baseUrl = import.meta.env.VITE_ACTIVITY_API_URL

export const activityClient: AxiosInstance = axios.create({
  timeout: MaxTimeoutMs,
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/ld+json',
    'Accept': 'application/ld+json',
  },
})
