import type { Group, GroupMember } from '~/models/Group'
import { externalClient } from './client/externalClient'

export default function useGroupsApi() {
  const getGroups = async (): Promise<Group[]> => {
    return (await externalClient.get('/frontend/groups')).data
  }

  const postGroup = async (group: Group): Promise<Group> => {
    return (await externalClient.post('/frontend/groups', group)).data
  }

  const putGroup = async (group: Group): Promise<Group> => {
    return (await externalClient.put(`/frontend/groups/${encodeURIComponent(group.groupId)}`, group)).data
  }

  const deleteGroup = async (groupId: string): Promise<Group> => {
    return (await externalClient.delete(`/frontend/groups/${encodeURIComponent(groupId)}`)).data
  }

  const getGroupMembers = (groupId: string): Promise<GroupMember[]> => {
    return externalClient.get(`/frontend/groups/${encodeURIComponent(groupId)}/members`)
      .then((response) => response.data['members'] ?? [])
  }

  const putGroupMembers = async (groupId: string, members: GroupMember[]): Promise<GroupMember[]> => {
    const response = await externalClient.put(`/frontend/groups/${encodeURIComponent(groupId)}/members`, {
      unknownMembers: 0,
      members: [...members]
    })
    return response?.data?.members ?? []
  }

  const searchUsers = async (query: string): Promise<GroupMember[]> => {
    return (await externalClient.get(`/frontend/users?query=${encodeURIComponent(query)}`)).data
  }

  return {
    getGroups,
    putGroup,
    deleteGroup,
    getGroupMembers,
    putGroupMembers,
    searchUsers,
    postGroup,
  }
}
