<script lang="ts" setup>
import type { ContentResource } from '~/models/Content/ContentResource'
import { computed, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { KsSpinner } from '@aschehoug/kloss'
import { prepEducationGrades, upperSecondaryGrades } from '~/utils/gradeSorter'
import { waitFor } from '~/utils/asyncUtils'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import useAuthStore from '~/stores/auth'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import { useContentHelper } from '~/composables/useContentHelper'

const { buildResourceUrl } = useUrlBuilder()
const { isPackage } = useContentHelper()
const { intersect } = arrayUtils()

const route = useRoute()
const previousRoutePath = ref<string>('')
const redirectPath = ref<string>('')
const { hasProducts } = storeToRefs(useProductStore())
const { userGrades } = storeToRefs(useAuthStore())

const props = defineProps<{
  resource: ContentResource
}>()

const redirectGrades = [...upperSecondaryGrades, ...prepEducationGrades]
const resourceGrades = computed(() => props.resource?.grades || [])
const userShouldRedirect = computed(() => intersect(redirectGrades, userGrades.value).length > 0)

const redirectToResourceUrl = computed(() => resourceGrades.value.length > 0
  ? intersect(redirectGrades, resourceGrades.value).length > 0
  : userShouldRedirect.value)

onBeforeMount(async () => {
  previousRoutePath.value = <string>route.params.returnPath
  if (redirectToResourceUrl.value && props.resource.url) {
    redirectPath.value = props.resource.url
    return
  }

  redirectPath.value = <string>buildResourceUrl(props.resource, previousRoutePath.value)
  if (isPackage(props.resource)) {
    await waitFor(() => hasProducts.value, 1000)
    redirectPath.value = buildResourceUrl(props.resource)
  }
})

setTimeout(() => {
  /*
  Tl;dr: Avoid back-button ping-pong (redirect-loop) by mutating the history.

  When someone hits this redirect-component it means that they have hit a path that could also render a resource.
  But for redirects only we want to mutate the browser-history so that the user can go back (AU1 -> AU2) not just
  by clicking the context-based exit-button, but also by using the browser back-button. If we did not mutate the
  history here, the user would hit the redirect-component when going back.
  */
  window.history.replaceState(null, '', previousRoutePath.value)
  document.location = redirectPath.value.replace('/nn/', '/')
}, 0)
</script>

<template>
  <KsSpinner position="center" />
</template>
