import type { SubscriptionDetails } from '~/models/Subscription'
import type { SubscriptionContractDto } from '~/models/Ecommerce/SubscriptionContractDto'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { defineStore, storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import { SubscriptionType } from '~/models/Subscription'
import useTime from '~/composables/useTime'
import useSubscription from '~/composables/useSubscription'
import useEcommerceApi from '~/api/ecommerceApi'

const useSubscriptionsStore = defineStore('subscriptions', () => {

  const { t } = useI18n()
  const { isoStringToDateTime, dateTimeToStandardOutputFormat } = useTime()
  const { selectedGradeType } = storeToRefs(useAuthStore())
  const { setSubscriptionsDetails, isLoading } = useSubscription()
  const { getSubscriptionContracts } = useEcommerceApi()

  const subscriptionContracts = ref<SubscriptionContractDto[]>([])
  const subscriptionsDetails = ref<SubscriptionDetails[]>([])
  const relevantSubscription = ref<SubscriptionDetails>()

  onMounted(async () => {
    subscriptionsDetails.value = await setSubscriptionsDetails()
    subscriptionContracts.value = await getSubscriptionContracts()
  })

  watch([selectedGradeType, subscriptionsDetails], async () => {
    if (!selectedGradeType.value || !subscriptionsDetails.value.length) return

    return relevantSubscription.value = subscriptionsDetails.value
      .find((subscription) => selectedGradeType.value && subscription.gradeTypes.includes(selectedGradeType.value))
  })

  const isLoadingSubscriptions = computed(() => isLoading.value)

  const showUpgradeFromBasic = computed(() => {
    return subscriptionsDetails.value.some(subscription => subscription.type === SubscriptionType.Basic)
  })

  const showUpgradeFromPlus = computed(() => {
    return subscriptionsDetails.value.some(subscription => subscription.type === SubscriptionType.Plus)
  })

  const trialSubscriptions = computed(() => {
    return subscriptionsDetails.value.filter(subscription => subscription.type === SubscriptionType.Trial)
  })

  const trialEndDateMessages = computed(() => {
    return trialSubscriptions.value.map(subscription => {
      const name = t(`subscription.name.${subscription.name}`)
      const date = dateTimeToStandardOutputFormat(isoStringToDateTime(subscription.endDate))
      return t('subscription.messages.endDate', { name: name, date: date })
    })
  })

  const webshopMessages = computed(() => {
    const messages: string[] = []

    if (subscriptionsDetails.value.some((details) => !Object.prototype.hasOwnProperty.call(details, 'endDate'))) {
      messages.push(t('subscription.messages.webshop.internal'))
      messages.push(t('subscription.messages.webshop.external'))
      return messages
    }

    if (subscriptionsDetails.value.length === 0) {
      messages.push(t('subscription.messages.webshop.external'))
      return messages
    }

    if (subscriptionsDetails.value.length) {
      messages.push(t('subscription.messages.webshop.internal'))
      return messages
    }

    return messages
  })

  const hasSubscription = computed(() => subscriptionsDetails.value.length > 0)

  return {
    subscriptionContracts,
    subscriptionsDetails,
    relevantSubscription,
    showUpgradeFromBasic,
    showUpgradeFromPlus,
    trialEndDateMessages,
    webshopMessages,
    hasSubscription,
    isLoadingSubscriptions,
  }
})

export default useSubscriptionsStore
