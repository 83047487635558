import type { Ref } from 'vue'
import type { SortField, SortOrder } from '~/models/Content/Sort'
import type { ContentProduct } from '~/models/Content/ContentProduct'
import { computed, ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { sortByGradeIndex } from '~/utils/gradeSorter'
import truthy from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import useAuthStore from '~/stores/auth'

interface SearchParamsOptions {
  product?: ContentProduct
  setGradesOnMount?: boolean
}

export default function useSearchParams(options: SearchParamsOptions = {}) {
  const route = useRoute()
  const { userGrades } = storeToRefs(useAuthStore())
  const { isLibrary } = useProductStore()

  const searchQuery = ref(route.query?.fullText as string || '')
  const page = ref(Number(route.query?.page) || 1)

  const splitQueryToArray = (name: string): string[] => {
    const queryValue = (route.query[name] || '') as string
    return queryValue ? queryValue.split(',').map((s) => s.trim()).filter(truthy) : []
  }

  const subjectCriterion = ref<string[]>(splitQueryToArray('subject'))
  const gradeCriterion = ref<string[]>(splitQueryToArray('grade'))
  const labelCriterion = ref<string[]>(splitQueryToArray('label'))
  const activityCriterion = ref<string[]>(splitQueryToArray('activity'))
  const accessCriterion = ref<string[]>(splitQueryToArray('access'))
  const languageCriterion = ref<string[]>(splitQueryToArray('language'))
  const themeCriterion = ref<string[]>(splitQueryToArray('theme'))
  const genreCriterion = ref<string[]>(splitQueryToArray('genre'))
  const seriesCriterion = ref<string[]>(splitQueryToArray('series'))
  const checkboxCriterion = ref<string[]>(splitQueryToArray('checkbox'))


  if (options.setGradesOnMount) {
    if (!gradeCriterion.value.length) gradeCriterion.value = userGrades.value

    watch(userGrades, () => gradeCriterion.value = userGrades.value)
  }

  const sortedGradeCriterion = computed(() => gradeCriterion.value.sort(sortByGradeIndex))

  const hasQuery = computed(() => searchQuery.value.length > 2)
  const hasCriterions = computed(() => [
    ...labelCriterion.value,
    ...activityCriterion.value,
    ...subjectCriterion.value,
    ...accessCriterion.value,
    ...languageCriterion.value,
    ...seriesCriterion.value,
    ...themeCriterion.value,
    ...genreCriterion.value,
    ...checkboxCriterion.value,
  ].length > 0)

  const hasCriterionsOrQuery = computed(() => hasQuery.value || hasCriterions.value)

  const isProductSearch = computed(() => route && route.name === 'product')

  const defaultProductSort = computed((): { field: SortField; order: SortOrder } => ({
    field: options.product && isLibrary(options.product) ? 'library' : 'published',
    order: 'desc',
  }))

  const sort = ref<{ field: SortField; order: SortOrder }>({
    field: route.query['sortField'] as SortField ?? 'published',
    order: route.query['sortOrder'] as SortOrder ?? 'desc',
  })

  watchEffect(() => {
    if (hasQuery.value) {
      sort.value.field = 'score'
      sort.value.order = 'desc'
      return
    }
    if (seriesCriterion.value.length > 0) {
      sort.value.field = 'priority'
      sort.value.order = 'asc'
      return
    }
    if (hasCriterions.value) {
      sort.value.field = 'published'
      sort.value.order = 'desc'
      return
    }
    sort.value.field = isProductSearch.value ? defaultProductSort.value.field : 'published'
    sort.value.order = isProductSearch.value ? defaultProductSort.value.order : 'desc'
  })

  const queryParams = computed(() => {
    const params: Record<string, string> = {}

    params.page = page.value.toString()

    if (hasQuery.value) {
      params.fullText = searchQuery.value
    }
    if (subjectCriterion.value.length > 0) {
      params.subject = [...subjectCriterion.value].sort().join(',')
    }
    if (gradeCriterion.value.length > 0) {
      params.grade = [...sortedGradeCriterion.value].join(',')
    }
    if (labelCriterion.value.length > 0) {
      params.label = [...labelCriterion.value].sort().join(',')
    }
    if (activityCriterion.value.length > 0) {
      params.activity = [...activityCriterion.value].sort().join(',')
    }
    if (accessCriterion.value.length > 0) {
      params.access = [...accessCriterion.value].sort().join(',')
    }
    if (languageCriterion.value.length > 0) {
      params.language = [...languageCriterion.value].sort().join(',')
    }
    if (seriesCriterion.value.length > 0) {
      params.series = [...seriesCriterion.value].sort().join(',')
    }
    if (themeCriterion.value.length > 0) {
      params.theme = [...themeCriterion.value].sort().join(',')
    }
    if (genreCriterion.value.length > 0) {
      params.genre = [...genreCriterion.value].sort().join(',')
    }
    if (checkboxCriterion.value.length > 0) {
      params.checkbox = [...checkboxCriterion.value].sort().join(',')
    }
    params.sortField = sort.value.field
    params.sortOrder = sort.value.order

    return params
  })

  function toggleCriterion(type: string, value: string) {
    page.value = 1

    const criterionMap: Record<string, Ref<string[]>> = {
      grade: gradeCriterion,
      activity: activityCriterion,
      access: accessCriterion,
      subject: subjectCriterion,
      language: languageCriterion,
      theme: themeCriterion,
      series: seriesCriterion,
      genre: genreCriterion,
      checkbox: checkboxCriterion,
      label: labelCriterion,
    }

    const criterion = criterionMap[type]
    if (criterion) {
      if (criterion.value.includes(value)) {
        criterion.value = criterion.value.filter((s) => s !== value)
      } else {
        criterion.value.push(value)
      }
    }
  }

  function setSortField(field: SortField) {
    sort.value.field = field
  }

  function resetCriterions() {
    page.value = 1
    subjectCriterion.value = []
    labelCriterion.value = []
    activityCriterion.value = []
    gradeCriterion.value = []
    accessCriterion.value = []
    languageCriterion.value = []
    themeCriterion.value = []
    seriesCriterion.value = []
    genreCriterion.value = []
    checkboxCriterion.value = []
  }

  function resetSearch() {
    page.value = 1
    searchQuery.value = ''
    resetCriterions()
  }

  return {
    page,
    searchQuery,
    queryParams,
    hasQuery,
    hasCriterions,
    hasCriterionsOrQuery,
    subjectCriterion,
    gradeCriterion,
    sortedGradeCriterion,
    labelCriterion,
    activityCriterion,
    accessCriterion,
    languageCriterion,
    themeCriterion,
    seriesCriterion,
    genreCriterion,
    checkboxCriterion,
    sort,
    setSortField,
    toggleCriterion,
    resetCriterions,
    resetSearch,
  }
}
