import { contentClient } from './client/contentClient'
import { externalClient } from './client/externalClient'
import { Product } from '../models/Product'
import { ProductGroup } from '../models/ProductGroup'

export default function useProductsApi() {
  const getOpenProducts = async (): Promise<Product[]> => {
    return (await contentClient.get('/account/products/open')).data
  }

  const getGroupedProducts = async (): Promise<ProductGroup[]> => {
    return (await contentClient.get('/account/products/groups')).data
  }

  const getLicensedProducts = async (language: string): Promise<Product[]> => {
    return (await externalClient.get(`/frontend/licenses/products?site=AUNIVERS,GAN&preferredLangague=${language}`)).data
  }

  return {
    getOpenProducts,
    getGroupedProducts,
    getLicensedProducts,
  }
}
