<script setup lang="ts">
import type { ContentArticle } from '~/models/Content/ContentArticle'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import { ContentType } from '~/models/Content/ContentType'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsDialog } from '@aschehoug/kloss'
import useContentApi from '~/api/contentApi'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'

const { t } = useI18n()
const { findContents } = useContentApi()

const teacherArticle = ref<ContentArticle>()
const emit = defineEmits(['closeGuide'])

const props = defineProps<{
  isOpen: boolean
  contentId: number|string
}>()

watch(props, async () => {
  if (!props.isOpen || !props.contentId) return
  teacherArticle.value = (await findContents<ContentArticle>({
    contentIdCriterion: [Number(props.contentId)],
    contentTypeCriterion: [ContentType.Article],
  }, 1))[0]
})
</script>
<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :title="t('presentation.teacherGuide')"
      :open="isOpen"
      size="medium"
      @close="emit('closeGuide')"
    >
      <template #body>
        <article class="prose prose-au mx-auto prose-img:rounded-xl">
          <RichTextRenderer :text="teacherArticle?.body ?? ''" />
        </article>
      </template>
    </KsDialog>
  </Teleport>
</template>
