import type { GradeCode } from '~/models/Grade'
import type { SubjectCode } from '~/models/Subject'
import type { Product } from '~/models/Product'
import { ProductVariation } from '~/models/Product'

export default () => {
  const hasRelatedLocations = (product: Product) => {
    const metadata = product.aunivers
    return !!(metadata?.relatedLocations
      && (metadata.relatedLocations.flexibleContentLocationId
        || metadata.relatedLocations.learningPathsLocationId
        || metadata.relatedLocations.interdisciplinaryContentLocationId
        || metadata.relatedLocations.teacherContentLocationId))
    }

  const productHasSubjectAndGrade = (product: Product, grade: GradeCode, subject: SubjectCode) =>
    product.subjects.includes(subject) && product.grades.includes(grade)

  const isProductVariation = (product: Product, variation: ProductVariation) =>
    product.aunivers?.productVariation === variation

  return {
    hasRelatedLocations,
    productHasSubjectAndGrade,
    isProductVariation,
  }
}
