<script setup lang="ts">
import { type Group, type GroupMember, GroupRole, GroupSource, GroupType } from '~/models/Group'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import useGroupsStore from '~/stores/groups'
import SettingsGroup from '~/components/settings/SettingsGroups/SettingsGroup.vue'
import SettingsGroupCreate from '~/components/settings/SettingsGroups/SettingsGroupCreate.vue'
import { UserRole } from '~/models/User/UserRole'

const props = defineProps<{ group: Group }>()

const { t } = useI18n()

const groupMembers = ref<GroupMember[]>([])
const edit = ref(false)
const copy = ref(false)

const groupCopy = computed(() => ({
  ...props.group,
  name: t('groups.copyOf', { groupName: props.group.name }),
  groupId: '',
  groupSource: GroupSource.Local,
  groupType: GroupType.Adhoc,
} as Group))

const groupMembersCopy = computed(() => groupMembers.value.map((member) => ({
  ...member,
  groupMemberId: '',
  groupRole: member.role === UserRole.Teacher ? GroupRole.Admin : GroupRole.Member,
})) as GroupMember[])

const emit = defineEmits(['deleteGroup', 'back', 'groupCreated'])

const groupsStore = useGroupsStore()
const { loadGroupMembers, findGroupMembers } = groupsStore

const setEdit = () => edit.value = !edit.value
const setCopy = () => copy.value = !copy.value

onMounted(() => {
  if (!Object.keys(props.group).length) {
    setEdit()
    return
  }
  loadGroupMembers(props.group)
    .finally(() => groupMembers.value = findGroupMembers(props.group))
})

watch(() => edit.value, () => {
  if (!edit.value) {
    loadGroupMembers(props.group)
      .finally(() => groupMembers.value = findGroupMembers(props.group))
  }
})

const reemitGroupCreated = (group: Group) => {
  emit('groupCreated', group)
}

</script>

<template>
  <SettingsGroup
    v-if="!edit && !copy"
    :group-members="groupMembers"
    :group="group"
    @delete-group="emit('deleteGroup')"
    @change-view="setEdit"
    @copy-group="setCopy"
  />
  <SettingsGroupCreate
    v-else-if="edit"
    :group-members="groupMembers"
    :group="group"
    @change-view="setEdit"
    @group-created="reemitGroupCreated"
    @back="emit('back')"
  />
  <SettingsGroupCreate
    v-else-if="copy"
    :group-members="groupMembersCopy"
    :group="groupCopy"
    @change-view="setCopy"
    @group-created="reemitGroupCreated"
    @back="emit('back')"
  />
</template>
