<script setup lang="ts">
import type { Group } from '~/models/Group'
import type { SubjectCode } from '~/models/Subject'
import type { Week } from '~/models/Planner/Week'
import type { YearPlan } from '~/models/Planner/YearPlan'
import { QueryParamKey } from '~/models/QueryParamKeys'
import type { PlannedItem } from '~/models/Content/PlannedItem'
import { KsCallout } from '@aschehoug/kloss'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '~/stores/auth'
import { setTitle } from '~/utils/dom'
import useQueryParams from '~/composables/queryParams'
import usePlannerStore from '~/stores/planner'
import useGroupsStore from '~/stores/groups'
import useSubjectsStore from '~/stores/subjects'
import arrayUtils from '~/utils/arrayUtils'
import stringUtils from '~/utils/stringUtils'
import CalendarBanner from '~/components/banners/CalendarBanner.vue'
import PlannedResourcesToolbar from '~/components/PlannedResources/PlannedResourcesToolbar.vue'
import PlannedResourcesList from '~/components/PlannedResources/PlannedResourcesList.vue'
import useWeekStore from '~/stores/week'

const queryParams = useQueryParams<{
  [QueryParamKey.Subject]: SubjectCode
}>(QueryParamKey.PlannedResources)

const { t } = useI18n()
const { lastInPath } = stringUtils()
const { truthy, unique } = arrayUtils()
const { findGroupById } = useGroupsStore()
const { setCurrentSubject } = useSubjectsStore()
const { currentSubject } = storeToRefs(useSubjectsStore())
const { activeUserGroup, isTeacher, userSubjectsByGrades } = storeToRefs(useAuthStore())
const { getYearPlansBySchoolYear, isRelevantYearPlan } = usePlannerStore()
const { relevantYearPlans, yearPlans, selectedSchoolYear } = storeToRefs(usePlannerStore())
const { selectedWeekNumber } = storeToRefs(useWeekStore())

setTitle(t('plan.title'))

const yearPlansReloader = ref(false)

const subjectForActiveGroup = computed(() => activeUserGroup.value?.subjects[0])
const teacherWithNoGroup = computed(() => isTeacher.value && !activeUserGroup.value)

const groupedResources = computed(() => {
  const groupedResources: Record<string, PlannedItem[]> = {}
  const yearPlanList = activeUserGroup.value ? relevantYearPlans.value : yearPlans.value.filter(isRelevantYearPlan)
  yearPlanList.forEach((yp) => {
    if (!yp.groups || !yp.subjects.some((url) => lastInPath(url) === currentSubject.value)) return
    yp.groups.forEach((group) => {
      const resources = yp.plans.flatMap((p) => p.weeks
        .filter(w => Number(w.weekNumber) === selectedWeekNumber.value)
        .flatMap((w: Week) => w.resources.map((r) => {
          r.yearPlan = yp.identifier
          return r
        })))

      // Lookup name from store since group details are not synced to planner-api immediately
      const groupName = findGroupById(group.groupId)?.name ?? group.name
      if (!(groupName in groupedResources)) groupedResources[groupName] = []
      groupedResources[groupName] = groupedResources[groupName].concat(resources)
        .sort((a, b) => (new Date(a.publishedDate)).getTime() - (new Date(b.publishedDate)).getTime())
    })
  })

  return groupedResources
})

const yearPlansForSelectedGroup = computed((): YearPlan[] => {
  if (isTeacher.value && !activeUserGroup.value) return []
  return yearPlans.value
    .filter((yearPlan) => (yearPlan.groups ?? []) // .groups is possibly null, handle with empty array
      .filter(truthy)
      .map((group: Group) => group.groupId)
      .includes(activeUserGroup.value?.groupId || ''),
    )
})

const subjectsWithPlannedResources = computed(() => yearPlansForSelectedGroup.value
  .filter((yearPlan) => yearPlan.plans[0].weeks[0].resources.length)
  .flatMap((yearPlan) => yearPlan.subjects)
  .filter(unique)
  .map((subject) => lastInPath(subject)))

const studentSubjectsWithPlannedResources = computed(() => yearPlans.value
  .flatMap((yp) => yp.subjects[0])
  .filter(unique)
  .map((subject) => lastInPath(subject)))

const availableSubjects = computed(() => {
  if (!isTeacher.value && studentSubjectsWithPlannedResources.value.length) {
    return studentSubjectsWithPlannedResources.value
  }
  if (isTeacher.value && subjectForActiveGroup.value) {
    return subjectsWithPlannedResources.value.length ? subjectsWithPlannedResources.value : [subjectForActiveGroup.value]
  }
  return userSubjectsByGrades.value
})

const activeGroupSubjectHasPlannedResources = computed(() => subjectForActiveGroup.value
  && subjectsWithPlannedResources.value.includes(subjectForActiveGroup.value))

const subjectInUrl = computed(() => queryParams.get()[QueryParamKey.Subject])

const reloadYearPlans = () => yearPlansReloader.value = !yearPlansReloader.value

onMounted(() => {
  if (subjectInUrl.value) return setCurrentSubject(subjectInUrl.value)
  if (activeGroupSubjectHasPlannedResources.value) return setCurrentSubject(subjectForActiveGroup.value)
  return setCurrentSubject(availableSubjects.value[0])
})

watch(activeUserGroup, () => {
  if (activeGroupSubjectHasPlannedResources.value) return setCurrentSubject(subjectForActiveGroup.value)
  if (subjectsWithPlannedResources.value.length) return setCurrentSubject(availableSubjects.value[0])
  return setCurrentSubject(subjectForActiveGroup.value)
})

watch(selectedSchoolYear, () => {
  if (!selectedWeekNumber.value) return
  if (!currentSubject.value) return
  yearPlans.value = []
  reloadYearPlans()
})

watch(yearPlansReloader, () => {
  if (!selectedWeekNumber.value) return
  if (!currentSubject.value) return
  getYearPlansBySchoolYear(selectedSchoolYear.value, currentSubject.value, selectedWeekNumber.value)
}, { immediate: true })

onBeforeUnmount(() => {
  setCurrentSubject('')
})
</script>

<template>
  <CalendarBanner />
  <div class="mx-auto max-w-screen-au px-4 sm:px-8">
    <KsCallout
      v-if="teacherWithNoGroup"
      variant="info"
      :title="t('groups.noGroup')"
      class="mt-8"
    >
      {{ t('groups.chooseGroup') }}
    </KsCallout>
    <PlannedResourcesToolbar
      :resources="groupedResources"
      @reload-year-plans="reloadYearPlans"
    />
    <PlannedResourcesList :grouped-resources="groupedResources" />
  </div>
</template>
