import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import useSettingsStore from '~/stores/settings'

export function useSettings() {
  const {
    drawerOpen,
    groupsOpen,
    subscriptionsOpen,
    plansOpen,
  } = storeToRefs(useSettingsStore())

  const toggleSettings = () => drawerOpen.value = !drawerOpen.value
  const toggleGroups = () => groupsOpen.value = !groupsOpen.value
  const toggleSubscriptions = () => subscriptionsOpen.value = !subscriptionsOpen.value
  const togglePlans = () => plansOpen.value = !plansOpen.value

  watch(() => drawerOpen.value, (newValue, oldValue) => {
    if(newValue === false && oldValue === true) {
      groupsOpen.value = false
      subscriptionsOpen.value = false
      plansOpen.value = false
    }
  })

  return {
    toggleSettings,
    toggleGroups,
    toggleSubscriptions,
    togglePlans,
  }
}

export default useSettings
