<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink } from 'vue-router'
import useTransition from '~/composables/useTransition'

const props = withDefaults(defineProps<{
  link: string
  newTab?: boolean
  isAnchor?: boolean
  transitionEffect?: boolean
}>(), {
  newTab: false,
  isAnchor: false,
  transitionEffect: true,
})

const { fadeOut } = useTransition()

const isHref = computed(() => props.isAnchor || (props?.link ?? '').startsWith('https'))

const linkOrAnchor = computed(() =>
  isHref.value ? 'a' : RouterLink)

const linkAttributes = computed(() => {
  if (props.newTab) return isHref.value ? { href: props.link, target: '_blank' } : { to: props.link }
  return isHref.value ? { href: props.link } : { to: props.link }
})

const shouldTransition = computed(() => props.transitionEffect && props.isAnchor && !props.newTab)
const transition = (event: PointerEvent) => {
  if (shouldTransition.value) fadeOut(event)
}
</script>
<template>
  <component
    :is="linkOrAnchor"
    v-bind="linkAttributes"
    @click="transition"
  >
    <slot />
  </component>
</template>
