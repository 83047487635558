<script setup lang="ts">
import type { CreateResource } from '~/models/Content/CreateResource'
import type { Component } from 'vue'
import { defineAsyncComponent, inject, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { KsButton, KsPopover } from '@aschehoug/kloss'
import { useAuthStore } from '~/stores/auth'
import useDialogStore from '~/stores/dialog'
import usePlannerStore from '~/stores/planner'
import useError from '~/composables/useError'
import ConditionalTooltip from '~/components/utils/ConditionalTooltip.vue'
import useSubjectsStore from '~/stores/subjects'
import useWeekStore from '~/stores/week'

const ksToast = <Toast>inject('ksToast')
const { t } = useI18n()
const { activeUserGroup } = storeToRefs(useAuthStore())
const { showDialog } = useDialogStore()

const plannerStore = usePlannerStore()
const { addToWeek } = plannerStore
const { selectedWeekNumber } = storeToRefs(useWeekStore())
const { selectedSubject } = storeToRefs(plannerStore)
const { getErrorMessage } = useError()
const { currentSubject } = storeToRefs(useSubjectsStore())

const isCreatingResource = ref(false)

function uploadResource() {
  createResourceDialog(defineAsyncComponent(() => import('~/components/planner/FileUploadDialog.vue')))
}

function uploadUrl() {
  createResourceDialog(defineAsyncComponent(() => import('~/components/planner/ExternalUrlDialog.vue')))
}

async function createResourceDialog(component: Component) {
  if (!activeUserGroup.value) {
    ksToast.error(t('planner.createYearPlan.missingActiveGroup'))
    return
  }
  if (currentSubject.value) {
    selectedSubject.value = currentSubject.value
  }
  const resource = await showDialog<CreateResource>(component)
  isCreatingResource.value = true
  try {
    await addToWeek(resource, selectedWeekNumber.value)
    ksToast.success(t('planner.createResource.created', { weekNumber: selectedWeekNumber.value }))
  } catch (e) {
    ksToast.info(getErrorMessage(e))
    throw e
  } finally {
    isCreatingResource.value = false
    selectedSubject.value = ''
  }
}
</script>a
<template>
  <ConditionalTooltip
    :condition="!activeUserGroup"
    :text="t('planner.createResource.unable')"
  >
    <KsPopover
      :title="t('planner.createResource.title')"
      direction="down right"
    >
      <template #toggle>
        <KsButton
          shape="rounded"
          icon-left="share"
          :disabled="!activeUserGroup"
        >
          {{ t('planner.createResource.title') }}
        </KsButton>
      </template>
      <template #content>
        <KsButton
          variant="tertiary"
          icon-left="floppy-disk"
          stretch
          :disabled="!activeUserGroup"
          @click.prevent="uploadResource"
        >
          {{ t('planner.createResource.upload') }}
        </KsButton>
        <KsButton
          variant="tertiary"
          icon-left="link"
          stretch
          :disabled="!activeUserGroup"
          @click.prevent="uploadUrl"
        >
          {{ t('planner.createResource.shareUrl') }}
        </KsButton>
      </template>
    </KsPopover>
  </ConditionalTooltip>
</template>
