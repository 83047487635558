<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsCarousel, KsCarouselItem } from '@aschehoug/kloss'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import { useContentHelper } from '~/composables/useContentHelper'
import useContentApi from '~/api/contentApi'
import CardRenderer from '~/components/cards/CardRenderer.vue'

const props = defineProps<{
  subjectCode?: SubjectCode
  gradeCode?: GradeCode
  locationId?: number
}>()

const { t } = useI18n()

const filterStore = useFilterStore()
const { getHeaderChildren, setSelectedHeader } = filterStore
const { productHeaders } = storeToRefs(filterStore)

const { isPackage } = useContentHelper()
const { findContents } = useContentApi()
const { truthy } = arrayUtils()

const { getRelatedLocationsByGradeAndSubject, getRelatedLocationsByLocationId } = useProductStore()

const teacherLocationIds = ref<number[]>([])
const teacherContent = computed(() => (productHeaders.value?.forTeacher ?? [])[0])

const intro = computed(() => props.subjectCode && props.gradeCode
  ? t('sections.teacherResources', {
      subject: t(`metadata.subjects.${props.subjectCode}`).toLowerCase(),
      grade: t(`metadata.gradesPunctuated.${props.gradeCode}`)
    }) : '')

function selectHeader(event: MouseEvent, child: BaseItem) {
  if (!teacherContent.value || !isPackage(child) || event.shiftKey || event.metaKey || event.ctrlKey) return
  setSelectedHeader([teacherContent.value, child])
  document.getElementById('resource-finder')?.scrollIntoView()
}

const loadTeacherHeaders = async () => {
  if (!teacherLocationIds.value.length) return
  try {
    productHeaders.value.forTeacher = (await findContents<BaseItem>({
      locationIdCriterion: teacherLocationIds.value,
      sortField: 'priority',
      sortOrder: 'asc'
    }))
    productHeaders.value.forTeacher
      .forEach((content) => getHeaderChildren(content))
  } catch (error) {
    productHeaders.value.forTeacher = []
    throw error
  }
}

watch(props, async () => {
  if (!(props.gradeCode && props.subjectCode)) return
  const { forTeacherLocationIds } = await getRelatedLocationsByGradeAndSubject(props.gradeCode, props.subjectCode)
  teacherLocationIds.value = [...forTeacherLocationIds].filter(truthy<number>)
  await loadTeacherHeaders()
}, { immediate: true, deep: true })

watch(props, async () => {
  if (!props.locationId) return
  const { forTeacherLocationIds } = await getRelatedLocationsByLocationId(props.locationId)
  teacherLocationIds.value = [...forTeacherLocationIds].filter(truthy<number>)
  await loadTeacherHeaders()
}, { immediate: true, deep: true })
</script>
<template>
  <section
    v-if="teacherContent && teacherContent.children.length"
    id="teacher-content"
    class="mb-12 w-full overflow-hidden bg-seagreen-50 py-8 sm:py-14"
  >
    <div class="mx-auto max-w-screen-au px-4 text-white sm:px-8">
      <h2
        class="mx-auto mb-2 text-3xl font-bold sm:text-4xl"
        v-text="t('filters.forTeacher')"
      />
      <p
        class="max-w-prose text-base"
        v-text="intro"
      />
      <KsCarousel v-if="teacherContent.children.length">
        <KsCarouselItem
          v-for="item in teacherContent.children"
          :key="item.locationId"
        >
          <CardRenderer
            :resource="item"
            @click="selectHeader($event, item)"
          />
        </KsCarouselItem>
      </KsCarousel>
    </div>
  </section>
</template>
