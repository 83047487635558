/**
 * Sleep for a given amount of time in milliseconds, then resolve.
 *
 * @param ms number of milliseconds to sleep
 *
 * @returns Promise<void>
 */
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Wait for a condition to be true, or a maximum amount of time to pass.
 *
 * @param condition a function that returns a boolean
 * @param maxWaitTime maximum amount of time to wait in milliseconds
 *
 * @returns Promise<void>
 */
export const waitFor = async (condition: () => boolean, maxWaitTime: number = 200) => {
  const startTime = Date.now()
  while (!condition() && Date.now() - startTime < maxWaitTime) await sleep(10)
}
