<script setup lang="ts">
import { computed } from 'vue'
import { KsCallout } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  statusCode: number
}>()

const message = computed(() => {
  switch (props.statusCode) {
    case 400:
      return t('register.error.invalidInput')
    case 409:
      return t('register.error.alreadyExists')
    default:
      return t('register.error.internal')
  }
})
</script>

<template>
  <KsCallout
    variant="error"
    class="mb-4"
  >
    {{ message }}
  </KsCallout>
</template>
