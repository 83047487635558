import type { BaseItem } from '~/models/Content/BaseItem'
import { ref, readonly } from 'vue'
import { expandEndpoint, restClient } from '~/api/client/restClient'
import useContentMapper from '~/composables/useContentMapper'

const { mapContent } = useContentMapper()

/**
 * @param locationId Optionally provide a location to load it immediatly
 */
export function useLocation<T extends BaseItem>(locationId?: number) {
  const _isLoading = ref(false)
  const _location = ref<T>()

  const isLoading = readonly(_isLoading)

  async function processLocation(location: any) {
    const { Version } = await expandEndpoint(location.ContentInfo.Content.CurrentVersion)
    const { ContentType } = await expandEndpoint(location.ContentInfo.Content.ContentType)

    // TODO: Implement in a nicer way
    location.ContentInfo.Content.CurrentVersion = { Version }
    location.ContentInfo.Content.ContentType = { ...ContentType, _identifier: ContentType.identifier }

    _isLoading.value = false
    _location.value = mapContent(location) as T

    return _location.value
  }

  async function fetchLocation(locationId: number): Promise<T> {
    _isLoading.value = true
    try {
      const query = new URLSearchParams({ id: String(locationId) })
      const location = (await restClient.get(`/content/locations?${query}`)).data.Location

      return processLocation(location)
    }

    catch (err) {
      _isLoading.value = false
      throw err
    }
  }

  async function fetchLocationByContentId(contentId: number): Promise<T> {
    _isLoading.value = true
    try {
      const content = (await restClient.get(`/content/objects/${String(contentId)}`)).data.Content
      const location = (await restClient.get(content.MainLocation._href.replace(import.meta.env.VITE_BASE_API_PATH, ''))).data.Location

      return processLocation(location)
    }

    catch (err) {
      _isLoading.value = false
      throw err
    }
  }

  if (locationId) fetchLocation(locationId)

  return {
    fetchLocation,
    fetchLocationByContentId,
    isLoading,
    location: _location, // readOnly fails type-check against writable in TS
  }
}
