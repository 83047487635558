<script setup lang="ts">
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useI18n } from '~/composables/useI18n'
import useBlogStore from '~/stores/blog'
import useSlugify from '~/composables/useSlugify'

const { slugify } = useSlugify()
const route = useRoute()
const { t } = useI18n()
const { postTags } = storeToRefs(useBlogStore())
</script>

<template>
  <nav class="mx-auto my-8 flex max-w-screen-au justify-center px-5 sm:px-8">
    <ul class="flex flex-wrap gap-6">
      <li>
        <router-link
          class="rounded-xl px-5 py-3 font-medium text-seagreen-50 transition-all hover:bg-seagreen-10 focus-visible:ring"
          exact-active-class="bg-seagreen-10"
          :to="{ name: 'news' }"
        >
          {{ t('blog.subpages.all') }}
        </router-link>
      </li>
      <li
        v-for="(tag, i) in postTags"
        :key="i"
      >
        <router-link
          class="rounded-xl px-5 py-3 font-medium text-seagreen-50 transition-all hover:bg-seagreen-10 focus-visible:ring"
          :class="{'bg-seagreen-10': route.params.tag === tag.replace(/ +/g, '-')}"
          :to="{ name: 'newsTag', params: { tag: slugify(tag) }}"
        >
          {{ tag.charAt(0).toUpperCase() + tag.slice(1) }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>
