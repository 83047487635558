<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { useSectionStore } from '~/stores/section'
import { useAuthStore } from '~/stores/auth'
import { GradeType } from '~/models/GradeType'
import { ContentType } from '~/models/Content/ContentType'
import useSectionPath from '~/composables/useSectionPath'
import UpperPrimaryBannerArticle from '~/components/home/upperPrimary/UpperPrimaryBannerArticle.vue'
import LowerSecondaryBannerArticle from '~/components/home/lowerSecondary/LowerSecondaryBannerArticle.vue'

const sectionStore = useSectionStore()
const { isLoading, userSections } = storeToRefs(sectionStore)
const { loadSections } = sectionStore
const { homePath } = useSectionPath()
const { selectedGradeType, selectedGradeTypeGrades } = storeToRefs(useAuthStore())

void loadSections(homePath)

const limit = 2

const shownSections = computed(() => [...userSections.value]
  .filter((section) => section.contentTypeIdentifier === ContentType.SectionStandalone)
  .filter((section) => section.grades.some((grade) => selectedGradeTypeGrades.value.includes(grade)))
  .sort((a, b) => (new Date(a.publishedDate)).getTime() - (new Date(b.publishedDate)).getTime())
  .reverse()
  .slice(0, limit)
)

const sectionCount = computed(() => Math.min(shownSections.value.length, 2))

const articleType = computed(() => {
  if (selectedGradeType.value === GradeType.UpperPrimary) return UpperPrimaryBannerArticle
  if (selectedGradeType.value === GradeType.LowerSecondary) return LowerSecondaryBannerArticle
  return null
})
</script>

<template>
  <div v-if="articleType">
    <div
      v-if="!isLoading && sectionCount > 0"
      class="mb-16 mt-8 grid gap-4"
      :class="sectionCount === 1 ? 'grid-cols-1' : 'grid-cols-1 md:grid-cols-2 auto-rows-fr'"
    >
      <component
        :is="articleType"
        v-for="section in shownSections"
        :key="section.locationId"
        :section="section"
      />
    </div>

    <KsSkeletonWrapper
      v-if="isLoading"
      class="mb-16 mt-8 grid grid-cols-12 gap-4"
    >
      <KsSkeleton
        v-for="n in 2"
        :key="n"
        class="col-span-6"
        height="250px"
        width="100%"
        background="rgb(var(--au-seagreen-40))"
      />
    </KsSkeletonWrapper>
  </div>
</template>
