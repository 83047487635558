import type { AxiosInstance } from 'axios'
import axios from 'axios'
import { getAccessToken } from '~/api/client/oidc'

export const ecommerceClient: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_ECOMMERCE_API_URL,
  headers: {
    'Accept': 'application/json',
  },
  transformRequest: [(data, headers) => {
    headers['Authorization'] = `Bearer ${getAccessToken()}`
    return JSON.stringify(data)
  }],
})
