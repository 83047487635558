<script setup lang="ts">
import type { YearPlan } from '~/models/Planner/YearPlan'
import type { YearPlansBySubject } from '~/models/Planner/YearPlan'
import type { SubjectCode } from '~/models/Subject'
import { KsButton, KsDrawer, KsCallout } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { watch, computed, ref, inject } from 'vue'
import { storeToRefs } from 'pinia'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import usePlannerStore from '~/stores/planner'
import stringUtils from '~/utils/stringUtils'
import YearPlanListItem from '~/components/planner/YearPlanListItem.vue'
import YearPlanListItemSkeleton from '~/components/skeletons/YearPlanListItemSkeleton.vue'
import { sortBySubjectIndex } from '~/utils/subjectSorter'
import { sortByLowestGrade } from '~/utils/gradeSorter'
import useArrayUtils from '~/utils/arrayUtils'
import { useAuthStore } from '~/stores/auth'
import { usePlannerApi } from '~/api/plannerApi'
import useSchoolYear from '~/composables/useSchoolYear'

const props = defineProps<{
  open: boolean
}>()
const emit = defineEmits(['back', 'close'])

const { t } = useI18n()
const { lastInPath } = stringUtils()
const { groupBy } = useArrayUtils()
const { isRelevantYearPlan } = usePlannerStore()
const { activeUserGroup } = storeToRefs(useAuthStore())
const { getSuggestedYearPlansBySchoolYear } = usePlannerApi()
const { currentSchoolYearCode } = useSchoolYear()
const ksToast = <Toast>inject('ksToast')

const suggestedYearPlans = ref<YearPlan[]>([])
const isLoading = ref(false)
const showAll = ref(false)

const loading = computed(() => isLoading.value ? [1, 2, 3] : [])
const relevantSuggestedYearPlans = computed(() => suggestedYearPlans.value.filter(isRelevantYearPlan))

const missingYearPlans = computed(() =>
  Boolean(!isLoading.value && relevantSuggestedYearPlans.value.length === 0))

const relevantYearPlansBySubject = computed((): YearPlansBySubject => {
  const subjectGroup = groupBy<YearPlan, SubjectCode>(
    relevantSuggestedYearPlans.value,
      ({ subjects }: YearPlan) => lastInPath(subjects[0]),
  )

  if (missingYearPlans.value || showAll.value) return groupBy<YearPlan, SubjectCode>(
    [...suggestedYearPlans.value].sort(sortByLowestGrade),
      ({ subjects }: YearPlan) => lastInPath(subjects[0]),
  )

  return Object.keys(subjectGroup)
      .sort(sortBySubjectIndex)
      .reduce((yearPlans: YearPlansBySubject, subjectCode: SubjectCode) => {
        yearPlans[subjectCode] = subjectGroup[subjectCode]
          .filter(({ grades }) => Boolean(grades.length))
          .sort(sortByLowestGrade)
        return yearPlans
      }, {})
})

const showMore = ref<SubjectCode[]>([])

const relevantYearPlansWithShowMore = computed((): YearPlansBySubject => {
  if (isLoading.value) return {}
  return Object.keys(relevantYearPlansBySubject.value)
    .reduce((yearPlansBySubject: YearPlansBySubject, subject: SubjectCode) => {
      if (showMore.value.includes(subject)) {
        yearPlansBySubject[subject] = relevantYearPlansBySubject.value[subject]
      } else {
        yearPlansBySubject[subject] = relevantYearPlansBySubject.value[subject].slice(0, 3)
      }
      return yearPlansBySubject
    }, { ...relevantYearPlansBySubject.value })
})

const filteredBy = computed(() => {
  if (activeUserGroup.value) {
    const { grade, name } = activeUserGroup.value
    return t('planner.suggestedYearPlans.filteredByActiveGroup', {
      grade: t(`metadata.grades.${grade}`),
      group: name,
    })
  }
  return t('planner.suggestedYearPlans.filteredBySettings')
})

watch(props, async () => {
  if (props.open) {
    try {
      isLoading.value = true
      showAll.value = false
      await loadSuggestedYearPlans()
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }
  }
})

const toggleShowMore = (subject: SubjectCode) => {
  if (showMore.value.includes(subject)) {
    showMore.value = showMore.value.filter((s) => s !== subject)
  } else {
    showMore.value = [...showMore.value, subject]
  }
}

async function loadSuggestedYearPlans() {
  try {
    suggestedYearPlans.value = await getSuggestedYearPlansBySchoolYear(currentSchoolYearCode)
  } catch (error) {
    ksToast.error({ message: t('calendar.errors.loadSharedYearPlans') })
    throw error
  }
}
</script>

<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDrawer
      :title="t('settings.drawers.plans')"
      :open="props.open"
      :backdrop="false"
      size="600px"
      @close="emit('close')"
    >
      <template #header>
        <KsButton
          shape="square"
          iconLeft="arrow-left"
          :aria-label="t('back')"
          :title="t('back')"
          @click="emit('back')"
        />
      </template>
      <template #body>
        <div class="flex flex-col gap-6 border-t border-gray-10 py-6">
          <KsCallout variant="info">
            <p
              class="pb-2"
              v-text="t('planner.suggestedYearPlans.description')"
            />
            <p
              v-if="!showAll"
              v-text="filteredBy"
            />
          </KsCallout>
          <div>
            <KsButton
              v-if="!showAll && !missingYearPlans"
              class="my-4"
              variant="tertiary"
              @click="showAll = !showAll"
            >
              {{ t('planner.suggestedYearPlans.showAll') }}
            </KsButton>
          </div>
          <YearPlanListItemSkeleton
            v-for="skeleton in loading"
            :key="`skeleton-${skeleton}`"
          />
          <template
            v-for="(yearPlans, subject) in relevantYearPlansWithShowMore"
            :key="subject"
          >
            <section>
              <h2
                class="mb-2 text-base font-medium uppercase tracking-wider text-gray-40"
                v-text="t(`metadata.subjects.${lastInPath(subject)}`)"
              />
              <ul class="flex flex-col gap-4">
                <YearPlanListItem
                  v-for="yearPlan in yearPlans"
                  :key="yearPlan.identifier"
                  :yearPlan="yearPlan"
                  @close="emit('close')"
                />
              </ul>
              <KsButton
                v-if="relevantYearPlansBySubject[subject].length > relevantYearPlansWithShowMore[subject].length"
                @click="toggleShowMore(subject)"
              >
                {{ t('planner.copyYearPlan.showMore', {
                  howMany: relevantYearPlansBySubject[subject].length - relevantYearPlansWithShowMore[subject].length
                }) }}
              </KsButton>
            </section>
            <hr
              class="border-t border-gray-10"
              aria-hidden="true"
            >
          </template>
        </div>
      </template>
    </KsDrawer>
  </Teleport>
</template>
