import type { SubscriptionContractDto } from '~/models/Ecommerce/SubscriptionContractDto'
import { ecommerceClient } from '~/api/client/ecommerceClient'

export default function useEcommerceApi() {

  const getSubscriptionContracts = async (): Promise<SubscriptionContractDto[]> => {
    return (await ecommerceClient.get('/subscriptions')).data
  }

  return {
    getSubscriptionContracts,
  }
}
