<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { Group } from '~/models/Group'
import { KsButton } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '~/stores/auth'
import { useDialogStore } from '~/stores/dialog'
import { storeToRefs } from 'pinia'
import { defineAsyncComponent } from 'vue'

const { t } = useI18n()
const { setUserActiveGroup } = useAuthStore()
const { showDialog } = useDialogStore()
const { activeUserGroup, isTeacher } = storeToRefs(useAuthStore())

const changeGroup = async () => {
  const { group } = await showDialog<{ group: Group; subjects: SubjectCode[] }>(
    defineAsyncComponent(() => import('~/components/group/SelectGroupDialog.vue')), {
    groupName: activeUserGroup.value?.name,
    title: t('activity.changeGroup.title'),
    body: t('activity.changeGroup.body'),
    cancelButtonText: t('activity.changeGroup.cancel'),
    confirmButtonText: t('activity.changeGroup.confirm'),
  })
  if (group) setUserActiveGroup(group)
}

</script>
<template>
  <KsButton
    v-if="isTeacher"
    variant="secondary"
    shape="rounded"
    @click="changeGroup"
  >
    {{ t(activeUserGroup ? 'activity.changeGroup.button' : 'activity.selectGroup') }}
  </KsButton>
</template>
