<script lang="ts" setup>
import type { ContentImage } from '~/models/Content/ContentImage'
import type { ContentBookReader } from '~/models/Content/ContentBookReader'
import type { ContentAuthor } from '~/models/Content/ContentAuthor'
import type { BaseItem } from '~/models/Content/BaseItem'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsIcon, KsPopover, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import useLicenseControlStore from '~/stores/licenseControl'
import useAuthStore from '~/stores/auth'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useContentApi from '~/api/contentApi'
import CloseButton from '~/components/utils/CloseButton.vue'
import NotFound from '~/components/ResourceEngine/Components/NotFound.vue'
import CardRenderer from '~/components/cards/CardRenderer.vue'

const props = defineProps({
  locationId: {
    type: Number,
    required: true,
  }
})

const { t } = useI18n()
const { buildImageUrlByField } = useUrlBuilder()
const { findContents, findImageContent } = useContentApi()
const { checkItemLicense } = useLicenseControlStore()
const { isAuthenticated } = storeToRefs(useAuthStore())

const isLoading = ref(false)
const location = ref<ContentBookReader>()
const authors = ref<ContentAuthor[]>([])
const related = ref<BaseItem[]>([])
const image = ref<ContentImage>()
const hasError = ref(false)

onMounted(() => fetchBookReader())
watch(location, () => checkItemLicense(location.value))

const fetchBookReader = async () => {
  isLoading.value = true
  try {
    location.value = (await findContents<ContentBookReader>({
      contentTypeCriterion: [ContentType.BookReader],
      subtreeCriterion: [Subtree.Content],
      locationIdCriterion: [props.locationId],
    }, 1))[0]
    if (!location.value) throw new Error(`Book reader ${props.locationId} was not found}`)
  } catch (error) {
    hasError.value = true
    console.warn(error)
  } finally {
    isLoading.value = false
  }
}

watch(location, async (location) => {
  if (!location) return
  setTitle(location.title)
  image.value = Number(location.image?.destinationContentId) > 0
    ? await findImageContent(Number(location.image.destinationContentId))
    : undefined
})

watch(location, async (location) => {
  const authorIds = location?.authors?.destinationContentIds ?? []
  if (authorIds.length === 0) {
    authors.value = []
    return
  }
  authors.value = await findContents<ContentAuthor>({
    contentTypeCriterion: [ContentType.Author],
    contentIdCriterion: authorIds,
    mainLocationCriterion: true,
    subtreeCriterion: [Subtree.Authors],
  }, authorIds.length)
})

watch(location, async (location) => {
  const relatedIds = location?.relatedContent?.destinationContentIds ?? []
  if (!relatedIds.length) {
    related.value = []
    return
  }
  related.value = await findContents<BaseItem>({
      contentIdCriterion: relatedIds,
      mainLocationCriterion: true,
      subtreeCriterion: [Subtree.Content],
    }, relatedIds.length)
})
</script>

<template>
  <CloseButton
    v-if="location"
    :item="location"
    :disabled="!isAuthenticated"
  />
  <NotFound v-if="hasError" />
  <template v-else>
    <div
      v-if="location || isLoading"
      class="grid h-full grid-rows-[300px] text-lg md:grid-cols-[4fr,6fr] md:grid-rows-[auto]"
    >
      <div
        class="flex place-content-center items-start bg-purple-5 px-5 py-12 md:px-14"
        :style="{ background: image?.palette }"
      >
        <img
          v-if="location?.image"
          class="top-12 h-full max-h-full md:sticky md:h-auto"
          :src="buildImageUrlByField(location.contentId, 'image')"
          :alt="location.image.alternativeText"
        >
      </div>

      <div
        v-if="location"
        class="box-content flex max-w-prose flex-col gap-6 px-5 py-12 md:px-16"
      >
        <ul class="flex gap-2 border-b border-purple-5 pb-2">
          <li
            v-for="item in [...location.genres, ...location.themes]"
            :key="item"
            class="flex items-center gap-2 content-[''] after:content-['•'] last:after:content-['']"
          >
            {{ item }}
          </li>
        </ul>

        <h1
          class="text-5xl font-bold"
          style="word-break: break-word;"
        >
          {{ location.title }}
        </h1>

        <ul class="flex flex-wrap gap-4">
          <li
            v-for="author in authors"
            :key="author.locationId"
            class="flex-none"
          >
            <KsPopover :title="author.title">
              <template #toggle>
                <button class="font-bold text-purple-40 hover:text-seagreen-50 focus-visible:ring">
                  {{ author.title }}
                </button>
              </template>

              <template #content>
                <article class="flex w-96 max-w-full flex-col gap-3 rounded-md bg-purple-5 p-6">
                  <p class="text-xl">
                    {{ author.title }}
                  </p>
                  <div
                    class="contents"
                    v-html="author.description"
                  />
                </article>
              </template>
            </KsPopover>
          </li>
        </ul>

        <div v-html="location.description" />

        <RouterLink
          :to="{ name: 'bookreader', params: $route.params }"
          class="inline-flex w-fit rounded-xl transition focus-visible:ring"
          :disabled="!isAuthenticated"
        >
          <KsButton
            variant="primary"
            type="label"
            icon-left="book"
            size="large"
          >
            {{ t('bookreader.readBook') }}
          </KsButton>
        </RouterLink>

        <dl class="grid gap-6 rounded-lg bg-purple-5 p-6">
          <div class="flex gap-2">
            <dt>{{ t('content.published') }}:</dt>
            <dd class="font-bold">
              {{ location.publishYear }}
            </dd>
            <KsIcon
              icon="seedling"
              class="ml-auto text-xl"
            />
          </div>

          <div class="flex gap-2">
            <dt>{{ t('content.language') }}:</dt>
            <dd
              v-for="language in location.languages"
              :key="language"
              class="font-bold"
            >
              {{ language }}
            </dd>
            <KsIcon
              icon="earth-europe"
              class="ml-auto text-xl"
            />
          </div>

          <div class="flex gap-2">
            <dt>{{ t('content.pageCount') }}:</dt>
            <dd class="font-bold">
              {{ location.pageNumbers }}
            </dd>
            <KsIcon
              icon="glasses"
              class="ml-auto text-xl"
            />
          </div>

          <div
            v-if="location.hasAudio"
            class="flex gap-2"
          >
            <dt>{{ t('content.audio') }}:</dt>
            <dd class="font-bold">
              {{ t('content.recordedAudio') }}
            </dd>
            <KsIcon
              icon="waveform"
              class="ml-auto text-xl"
            />
          </div>

          <div
            v-if="location.series.length"
            class="flex gap-2"
          >
            <dt>{{ t('content.inSeries') }}:</dt>
            <dd
              v-for="series in location.series"
              :key="series"
              class="font-bold"
            >
              {{ series }}
            </dd>
            <KsIcon
              icon="stars"
              class="ml-auto text-xl"
            />
          </div>
        </dl>

        <template v-if="isAuthenticated & related.length > 0">
          <h2 class="text-xl font-bold">
            {{ t('relatedContent') }}
          </h2>

          <ul class="grid gap-3 md:grid-cols-2 lg:grid-cols-3">
            <li
              v-for="content in related"
              :key="content.locationId"
            >
              <CardRenderer :resource="content" />
            </li>
          </ul>
        </template>
      </div>

      <div
        v-else-if="isLoading"
        class="box-content flex max-w-prose flex-col gap-6 px-5 py-12 md:px-16"
      >
        <KsSkeleton />

        <hr class="-mt-3 border-seagreen-10">

        <KsSkeletonWrapper
          :text="t('bookreader.loading')"
          class="contents"
        >
          <KsSkeleton height="3rem" />

          <div class="flex flex-wrap gap-4">
            <KsSkeleton width="33%" />
            <KsSkeleton width="33%" />
          </div>

          <div class="flex flex-col gap-3">
            <KsSkeleton width="50ch" />
            <KsSkeleton width="40ch" />
            <KsSkeleton width="45ch" />
            <KsSkeleton width="43ch" />
            <KsSkeleton width="52ch" />
            <KsSkeleton width="35ch" />
          </div>
        </KsSkeletonWrapper>
      </div>
    </div>
  </template>
</template>
