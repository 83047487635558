import type { SectionBaseItem } from '~/models/Content/SectionBaseItem'
import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import arrayUtils from '~/utils/arrayUtils'
import useSubjectsStore from '~/stores/subjects'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import { ContentType } from '~/models/Content/ContentType'
import useContentApi from '~/api/contentApi'

export const useSectionStore = defineStore('section', () => {
  const authStore = useAuthStore()
  const { userRelevantGrades, userSubjectsByGrades } = storeToRefs(authStore)
  const { selectedGrade } = storeToRefs(useFilterStore())
  const { currentSubject } = storeToRefs(useSubjectsStore())
  const { intersect, empty } = arrayUtils()
  const { findContents } = useContentApi()

  const loading = ref(false)
  const sections = ref<Record<string, SectionBaseItem[]>>({})
  const sectionId = ref<string>('')
  const isLoading = computed(() => loading.value)

  const startLoading = () => loading.value = true
  const stopLoading = () => loading.value = false

  const setSections = (id: string, items: SectionBaseItem[]) => sections.value[id] = items
  const getSectionsById = (id: string): SectionBaseItem[] => sections.value[id] ?? []

  const loadSections = async (pathString: string) => {
    sectionId.value = pathString
    if (getSectionsById(sectionId.value).length) return
    startLoading()

    const sections = await findContents<SectionBaseItem>({
      subtreeCriterion: [pathString],
      sortField: 'priority',
      sortOrder: 'asc',
      contentTypeCriterion: [
        ContentType.SectionBox,
        ContentType.SectionExpanded,
        ContentType.SectionStandalone,
      ]
    })

    setSections(sectionId.value, sections)
    stopLoading()
  }

  const userSections = computed(() => getSectionsById(sectionId.value)
    .filter(({ grades, subjects }) => {
      const hasGrades = empty(grades) || !empty(intersect(grades, userRelevantGrades.value))
      const hasSubjects = empty(subjects) || !empty(intersect(subjects, userSubjectsByGrades.value))
      return hasGrades && hasSubjects
    })
  )

  const subjectSections = computed(() => getSectionsById(sectionId.value)
    .filter(({ grades, subjects }) => {
      const hasGrades = empty(grades) || !empty(intersect(grades, [selectedGrade.value]))
      const hasSubjects = empty(subjects) || !empty(intersect(subjects, [currentSubject.value]))
      return hasGrades && hasSubjects
    })
  )

  return {
    loading,
    isLoading,
    startLoading,
    stopLoading,
    loadSections,
    setSections,
    getSectionsById,
    userSections,
    subjectSections,
    sections,
  }
})
