import type { TextSizes } from '~/models/Presentation/BasePage'

const textPairs: Record<string, TextSizes> = {
  'small': {
    heading: 'fluid-text-3xl',
    subHeading: 'fluid-text-xl',
    paragraph: 'fluid-text-base'
  },
  'medium': {
    heading: 'fluid-text-5xl',
    subHeading: 'fluid-text-3xl',
    paragraph: 'fluid-text-lg'
  },
  'large': {
    heading: 'fluid-text-7xl',
    subHeading: 'fluid-text-5xl',
    paragraph: 'fluid-text-xl'
  },
  'xlarge': {
    heading: 'fluid-text-9xl',
    subHeading: 'fluid-text-7xl',
    paragraph: 'fluid-text-3xl'
  },
  'xxlarge': {
    heading: 'fluid-text-10xl',
    subHeading: 'fluid-text-8xl',
    paragraph: 'fluid-text-7xl'
  },
}

const textHeadingFonts: Record<string, string> = {
  'tiempos': 'font-tiempos',
  'inter': 'font-inter',
  'calistoga': 'font-calistoga',
  'playpen': 'font-playpen',
}

const textHeadingWeights: Record<string, string> = {
  'regular': 'font-regular',
  'medium': 'font-medium',
  'bold': 'font-extrabold',
}

const textMaxWidth: Record<string, string> = {
  'small': 'max-w-screen-md',
  'medium': 'max-w-screen-lg',
  'large': 'max-w-screen-xl',
  'xlarge': 'max-w-screen-2xl',
}

export default () => {
  const getTextSizes = (textSize: string) => textPairs[textSize] || textPairs['small']
  const getFontFamily = (fontName: string) => textHeadingFonts[fontName] || ''
  const getFontWeight = (fontWeight: string) => textHeadingWeights[fontWeight] || ''
  const getTextMaxWidth = (textSize: string) => textMaxWidth[textSize] || ''

  const capitalize = (text: string): string => {
    if (!text) return ''
    return text[0].toUpperCase() + text.substring(1).toLowerCase()
  }

  return {
    getTextSizes,
    getFontFamily,
    getFontWeight,
    getTextMaxWidth,
    capitalize,
  }
}
