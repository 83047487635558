<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import type { BasePageProps } from '~/models/Presentation/BasePage'
import type { RelatedContentPage } from '~/models/Presentation/Pages/RelatedContentPage'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { KsCarousel, KsCarouselItem } from '@aschehoug/kloss'
import useContentApi from '~/api/contentApi'
import useHiddenHeading from '~/composables/useHiddenHeading'
import PresentationRichText from '~/components/PresentationPages/Components/PresentationRichText.vue'
import CardRenderer from '~/components/cards/CardRenderer.vue'

const props = defineProps<BasePageProps & { page: RelatedContentPage }>()

const { findContents } = useContentApi()
const { hasHeading } = useHiddenHeading()

const heading = computed(() => props.page.heading || props.page.title)
const destinationContentIds = computed(() => props.page.relatedContent?.destinationContentIds ?? [])
const translateX = computed(() => props.animationDirection === 'left' ? '-translate-x-full' : 'translate-x-full')

const relatedContent = useQuery({
  queryKey: ['relatedContent', props.page.locationId, props.currentPageNumber ],
  queryFn: async () => await findContents<BaseItem>({
    contentIdCriterion: destinationContentIds.value,
    mainLocationCriterion: true,
  }),
  staleTime: Infinity,
})
</script>
<template>
  <div
    class="grid h-full w-full grid-rows-2"
    :style="{ background: page.colorPair.background.rgb }"
  >
    <h1
      v-if="!hasHeading"
      class="sr-only"
      v-text="page.title"
    />
    <Transition
      enter-active-class="duration-1000 ease delay-1200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      appear
    >
      <section
        class="flex h-full w-full flex-col items-center justify-center"
        :style="{ background: page.colorPair.background.rgb }"
      >
        <div
          class="px-8"
          :class="page.textMaxWidth"
        >
          <h1
            class="text-pretty"
            :class="[page.textSizes.heading, page.fontWeight, page.fontFamily]"
            :style="{ color: page.colorPair.text.rgb }"
            v-text="heading"
          />
          <PresentationRichText
            v-if="page.body"
            :page="page"
          />
        </div>
      </section>
    </Transition>
    <Transition
      enter-active-class="duration-[1500ms] ease delay-600"
      :enter-from-class="translateX"
      enter-to-class="translate-x-0"
      appear
    >
      <section
        v-if="destinationContentIds.length && relatedContent.data"
        class="flex flex-col items-center justify-center"
        :class="page.colorPair.isDark ? 'bg-black/20' : 'bg-white/20'"
      >
        <div class="w-fit max-w-[100vw] px-8">
          <KsCarousel>
            <KsCarouselItem
              v-for="item in relatedContent.data.value"
              :key="item.locationId"
            >
              <CardRenderer
                :resource="item"
                :color-pair="page.colorPair"
              />
            </KsCarouselItem>
          </KsCarousel>
        </div>
      </section>
    </Transition>
  </div>
</template>
