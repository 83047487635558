<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import type { Product } from '~/models/Product'
import type { ColorPair } from '~/models/Presentation/BasePage'
import { computed, inject, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsIcon } from '@aschehoug/kloss'
import { useContentHelper } from '~/composables/useContentHelper'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useColors from '~/composables/colors'
import useDescriptor from '~/composables/useDescriptor'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'
import CardMenu from '~/components/cards/buttons/CardMenu.vue'
import LearningPathDialog from '~/components/dialogs/LearningPathDialog.vue'
import LearningPathLink from '~/components/utils/LearningPathLink.vue'
import PlannedResourceButton from '~/components/planner/PlannedResourceButton.vue'
import LockedContentDialog from '~/components/search/LockedContentDialog.vue'

const { isLearningPath, getTitle, isFile, isPdf } = useContentHelper()
const { buildResourceUrl } = useUrlBuilder()
const { getDescriptor } = useDescriptor()
const { t } = useI18n()

const props = withDefaults(defineProps<{
  resource: BaseItem
  static?: boolean
  hasAccess?: boolean
  product?: Product
  colorPair?: ColorPair
  loading?: 'eager' | 'lazy'
}>(), {
  static: false,
  hasAccess: true,
  product: undefined,
  loading: 'lazy',
})

const elementRef = ref()
const dialogOpen = ref(false)

const { backgroundStyle } = useColors({
  identifier: props.resource.locationId!,
  elementRef,
})

const resourceIsLearningPath = computed(() => isLearningPath(props.resource))
const title = computed(() => getTitle(props.resource))
const transitionEffect = computed(() => !isFile(props.resource) && !isPdf(props.resource))

const labelName = getDescriptor(props.resource)

const link = computed(() => {
  if (!props.hasAccess) return '#'
  if (!props.static) return buildResourceUrl(props.resource)
  return '#'
})

const isAnchor = computed(() => !link.value.startsWith('/'))

// This is provided in LearningPathDialog.vue
const inStudentActivityDialog = inject('inStudentActivityDialog', false)
</script>
<template>
  <article
    class="relative h-full min-h-[190px] min-w-[215px] rounded-2xl bg-white text-base shadow-md motion-safe:animate-fade-in-down"
    :class="{'min-h-[250px] w-[200px]': colorPair}"
    @click="!hasAccess ? dialogOpen = true : () => {}"
  >
    <div class="flex flex-col-reverse">
      <div class="p-4">
        <span class="group mb-14 block text-lg font-semibold leading-tight text-blue-50">
          <LearningPathLink
            v-if="resourceIsLearningPath && hasAccess"
            :resource="resource"
            class="line-clamp-3 outline-0 before:absolute before:inset-0 before:rounded-2xl before:transition before:hover:shadow-lg before:focus-visible:ring"
          />
          <LinkOrAnchor
            v-else
            class="line-clamp-3 outline-0 before:absolute before:inset-0 before:rounded-2xl before:transition before:hover:shadow-lg before:focus-visible:ring"
            :link="link"
            :is-anchor="isAnchor"
            :transition-effect="transitionEffect"
          >
            {{ title }}
          </LinkOrAnchor>
        </span>
      </div>
      <div
        v-if="!hasAccess"
        class="absolute -right-1 -top-1 z-10 cursor-pointer rounded-full bg-blue-50 p-2 text-white shadow-md"
      >
        <KsIcon
          icon="lock"
          :scale="1.4"
        />
        <LockedContentDialog
          :open="dialogOpen"
          :product="product"
          @close-dialog="dialogOpen = false"
        />
      </div>
      <div
        ref="elementRef"
        class="aspect-[3/2] h-unset max-w-full overflow-hidden rounded-t-2xl"
      >
        <aside
          v-if="labelName"
          role="complementary"
          class="absolute left-3 top-3 rounded-md bg-gray-50/80 bg-clip-padding px-2 py-1 text-xs font-medium text-white backdrop-blur-sm"
          v-text="labelName"
        />
        <aside
          v-if="resource.new"
          class="absolute right-0 top-0 rounded-bl-2xl rounded-tr-2xl bg-green-40 p-2 text-sm font-medium uppercase tracking-wider text-white"
          v-text="t('new')"
        />
        <img
          v-if="resource.thumbnail"
          :src="resource.thumbnail"
          class="block h-full w-full overflow-hidden bg-gray-10 object-cover object-center"
          :loading
          :alt="resource.thumbnailAlt"
          aria-hidden="true"
        >
        <div
          v-else
          class="block h-full w-full overflow-hidden object-cover object-center"
          :style="backgroundStyle"
        />
        <div class="relative inset-0">
          <div
            v-if="resourceIsLearningPath"
            class="absolute bottom-0 left-3 rounded-t-2xl bg-white p-1 text-sm font-medium"
          >
            <LearningPathDialog :resource="resource" />
          </div>
        </div>
      </div>
    </div>
    <!--  Use this slot when you need to override add/remove buttons and activity button  -->
    <slot name="addButton">
      <div class="absolute bottom-4 left-4 flex w-max">
        <PlannedResourceButton :resource="resource" />
      </div>
    </slot>
    <CardMenu
      :resource="resource"
      class="absolute bottom-4 right-4"
    />
  </article>
</template>
