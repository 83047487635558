<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'
import UfoAnimation from '~/assets/lottie/404_Ufo_2.json'

defineProps<{
  noResults: boolean
  hasQuery: boolean
  hasFailed: boolean
  isLoading: boolean
  hasCriterions: boolean
  spellingSuggestion?: string
}>()

const { t } = useI18n()

const emit = defineEmits(['resetSearch', 'resetCriterions', 'suggestedSearch'])
</script>

<template>
  <div
    v-if="(noResults && hasCriterions) || (noResults && hasQuery)"
    class="mx-auto flex max-w-prose flex-col gap-6 py-6 text-center md:px-12 lg:m-0"
  >
    <LottieAnimation
      :animation-data="UfoAnimation"
      width="200px"
      :aria-label="t('search.animationLabel')"
    />
    <div class="space-y-1">
      <p
        class="text-2xl font-medium"
        v-text="t('search.noResults')"
      />
      <p
        class="text-gray-50"
        v-text="t('search.noResultsDescription')"
      />
      <p
        v-if="spellingSuggestion"
        class="text-gray-50"
      >
        {{ t('search.spellingSuggestion') }} <button
          class="font-bold underline hover:bg-white focus-visible:ring"
          @click.prevent="emit('suggestedSearch', spellingSuggestion)"
        >
          {{ spellingSuggestion }}
        </button>?
      </p>
    </div>
    <KsButton
      v-if="hasCriterions"
      variant="border"
      shape="rounded"
      icon-left="xmark"
      stretch
      center
      @click.prevent="emit('resetCriterions')"
    >
      {{ t('search.resetFilters') }}
    </KsButton>
    <KsButton
      variant="primary"
      shape="rounded"
      icon-left="arrows-rotate"
      stretch
      center
      @click.prevent="emit('resetSearch')"
    >
      {{ t('search.resetSearch') }}
    </KsButton>
  </div>
  <div
    v-if="hasFailed && !isLoading"
    class="col-span-10"
    v-text="t('search.failed')"
  />
</template>
