<script setup lang="ts">
import type { Week } from '~/models/Planner/Week'
import type { StudentTaskProgress } from '~/models/Activity'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsPopover, KsProgress, KsButton } from '@aschehoug/kloss'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import usePlannerStore from '~/stores/planner'
import CopyUrlButton from '~/components/cards/buttons/CopyUrlButton.vue'
import { useContentHelper } from '~/composables/useContentHelper'
import LearningPathLink from '~/components/utils/LearningPathLink.vue'

const { findWeeksWithResourceInSelectedYearPlan } = usePlannerStore()

const props = defineProps<{
  activity: StudentTaskProgress
}>()

const weeksWithResource = computed(() => findWeeksWithResourceInSelectedYearPlan(props.activity))

const { t } = useI18n()

const { buildResourceUrl } = useUrlBuilder()
const { isLearningPath, getTitle } = useContentHelper()

const activityIsLearningPath = computed(() => isLearningPath(props.activity))

const taskProgressLength = computed(() => {
  return Math.max(1, props.activity.learningPathPartsCount, props.activity.sectionCount)
})

const itemUrl = computed(() => buildResourceUrl(props.activity))
const title = computed(() => getTitle(props.activity))

const statusText = computed(() => {
  if (taskProgressLength.value === 1) return t('activity.visited')
  if (props.activity.read < taskProgressLength.value && taskProgressLength.value > 1) {
    return t('activity.youHaveCompletedNOfTotal', { n: props.activity.read, total: taskProgressLength.value })
  }
  if (isLearningPath(props.activity)) return t('activity.youHaveCompletedAll')
  return props.activity.status
})

</script>
<template>
  <div class="my-2 flex min-h-4 flex-row justify-center gap-2 align-middle text-lg">
    <div class="w-full rounded-md border border-gray-20 px-4 py-1">
      <div class="flex w-full">
        <div class="flex w-[29rem]">
          <div class="flex shrink gap-8 ">
            <LearningPathLink
              v-if="activityIsLearningPath"
              class="inline-block align-middle font-medium leading-10"
              :resource="activity"
            />
            <a
              v-else
              class="inline-block align-middle font-medium leading-10"
              :href="itemUrl"
              v-text="title"
            />
          </div>
        </div>
        <div class="flex w-[50%] flex-1 flex-col gap-4 md:flex-row md:items-center">
          <span
            class="my-1 inline-flex max-h-8 items-center rounded-md border-2 border-gray-50 px-1 py-0 align-middle"
            v-text="t(`contentTypes.${activity.label}`)"
          />
          <KsProgress
            class="max-w-52"
            :values="[activity.progress]"
            :max="100"
            :colors="[activity.read !== taskProgressLength ? '#A8A8A8' : undefined ]"
          />
          <span v-text="statusText" />
        </div>
        <div class="flex w-[15%] min-w-28 justify-end gap-2">
          <span
            v-if="weeksWithResource.length > 0"
            v-ks-tooltip="t('activity.plannedInWeek')"
            v-text="weeksWithResource.map((week: Week) =>
              t('time.weekWithNumber', { number:week.weekNumber })). join(', ')"
          />

          <KsPopover
            :title="t('activity.actionOnLine', { title: activity.title })"
            class="flex items-center"
            direction="down left"
          >
            <template #toggle>
              <KsButton
                icon-left="ellipsis-vertical"
                class="h-8 max-h-8 min-h-8 w-8 !min-w-8 max-w-8 !p-[6px] text-center"
              />
            </template>
            <template #content>
              <KsButton
                icon-left="link"
                :stretch="true"
                :href="itemUrl"
              >
                {{ t('activity.openActivity') }}
              </KsButton>
              <CopyUrlButton
                :url="itemUrl"
                :stretch="true"
                icon="copy"
              />
            </template>
          </KsPopover>
        </div>
      </div>
    </div>
  </div>
</template>
