<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import { storeToRefs } from 'pinia'
import { KsDropdown } from '@aschehoug/kloss'
import useSubjectsStore from '~/stores/subjects'
import { useI18n } from 'vue-i18n'
import { QueryParamKey } from '~/models/QueryParamKeys'
import useQueryParams from '~/composables/queryParams'
import { useDebounce } from '~/composables/debounce'
import { onMounted, ref, watch } from 'vue'

defineProps<{ subjects: SubjectCode[] }>()
const emit = defineEmits(['changeSubject'])

const { t } = useI18n()
const { debounce } = useDebounce()
const { currentSubject } = storeToRefs(useSubjectsStore())

const currentUrl = ref(window.location.href)

const queryParams = useQueryParams<{
  [QueryParamKey.Subject]: SubjectCode
}>(QueryParamKey.PlannedResources)

const debouncedSetSubjectQueryParam = debounce(async () => {
  await queryParams.set({
    ...queryParams.get(),
    [QueryParamKey.Subject]: currentSubject.value as SubjectCode,
  })
  currentUrl.value = window.location.href
}, 500)

watch(currentSubject, () => {
  debouncedSetSubjectQueryParam()
  emit('changeSubject')
})

onMounted(() => debouncedSetSubjectQueryParam())
</script>

<template>
  <KsDropdown
    v-model="currentSubject"
    :options="subjects"
    :option-id="(subject: SubjectCode) => subject"
    :option-label="(subject: SubjectCode) => t(`metadata.subjects.${subject}`)"
    :placeholder="$t('filters.chooseSubject')"
    :deselectable="() => false"
    shape="rounded"
    autolabel
  />
</template>
