<script setup lang="ts">
import type { ContentFile } from '~/models/Content/ContentFile'
import type { FileField } from '~/models/Content/BaseField'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { KsButton, KsIcon } from '@aschehoug/kloss'
import { Section } from '~/models/Content/Section'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useFile from '~/composables/useFile'

const props = defineProps<{ file: FileField }>()

const router = useRouter()
const { readableMimeType } = useFile()
const { buildResourceUrl } = useUrlBuilder()

const href = computed(() => {
  if (!props.file.uri) return router.resolve({ name: 'notfound' }).href
  return buildResourceUrl({
    file: props.file,
    contentTypeIdentifier: ContentType.File,
    sectionId: Section.Standard,
  } as ContentFile)
})

const fileNameAndType = computed(() => {
  const fileType = readableMimeType(props.file.mimeType)
  if (!fileType) return props.file.fileName
  return `${props.file.fileName} (${fileType})`
})
</script>

<template>
  <KsButton
    :href="href"
    variant="secondary"
    class="group flex w-full justify-between hover:!bg-gray-5"
    style="--ks-input: white; --ks-roundness: .2"
  >
    <span class="overflow-x-hidden text-ellipsis">{{ fileNameAndType }}</span>
    <KsIcon
      icon="download"
      class="group-hover:hidden group-focus-visible:hidden"
    />
    <KsIcon
      icon="download"
      variant="solid"
      bounce
      animation-duration="800ms"
      class="hidden group-hover:block group-focus-visible:block"
    />
  </KsButton>
</template>
