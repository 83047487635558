import { createI18n, useI18n } from 'vue-i18n'
import nb from './nb'
import nn from './nn'

const messages = {
  nb,
  nn,
}

const options = {
  legacy: false,
  locale: 'nb',
  fallbackLocale: 'nb',
  messages,
}

const i18n = createI18n(options)
const simpleI18n = () => useI18n(options)

export {
  messages,
  i18n,
  simpleI18n,
}
