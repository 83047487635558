export const FOCUSABLE = '[tabindex],a,button:not([disabled]),input:not([disabled]),select:not([disabled]),textarea:not([disabled]),summary,audio,video,iframe,area,[contenteditable],[draggable]'

export function htmlContainsText(html: string) {
  const el = document.createElement('span')
  el.innerHTML = html ?? ''
  return (el.textContent ?? '').trim().length > 0
}

export function setTitle(title: string) {
  document.title = `${title} – Aschehoug univers`
}
