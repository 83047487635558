import cookie from 'cookie'

const cookieName = import.meta.env.VITE_OIDC_COOKIE_NAME

export const getAccessToken = (): string => {
  const cookies = cookie.parse(document.cookie)
  return cookies[cookieName] ?? ''
}

export default {
  getAccessToken,
}
