import { externalClient } from '~/api/client/externalClient'

const useMetadataApi = () => {
  const get = async <T>(key: string): Promise<T | null> => {
    const { data } = await externalClient
      .get(`/frontend/metadata/${key}`, {
        validateStatus: (status) => (status < 400) || (status === 404)
      })

    return data?.value ? JSON.parse(data.value) : null
  }

  const set = async (key: string, value: any, userId: any): Promise<void> => {
    await externalClient
      .put(`/frontend/metadata/${key}`,  {
        userId,
        key,
        value: JSON.stringify(value),
      })
  }

  const getLearningPathProgress = async (learningPathLocationId: number): Promise<number> => {
    const { previousLocationId } = (await get<{ previousLocationId: number }>(`learningPathProgress-${learningPathLocationId}`)) || { previousLocationId: 0 }
    return previousLocationId
  }

  return {
    get,
    set,
    getLearningPathProgress
  }
}

export default useMetadataApi
