<script setup lang="ts">
import { ColorName } from '~/models/Content/Color'
import { useAppColor } from '~/composables/useAppColor'
import UpperSecondaryProducts from '~/components/home/UpperSecondaryProducts.vue'
import DefaultSections from '~/components/home/DefaultSections.vue'

useAppColor(ColorName.White)
</script>

<template>
  <UpperSecondaryProducts />
  <DefaultSections />
</template>
