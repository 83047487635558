<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import useProductStore from '~/stores/product'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useText from '~/composables/useText'
import { useStaticProduct } from '~/composables/useStaticProduct'
import SupportProductModal from '~/components/home/SupportProductModal.vue'

const { resolveBaseUrl } = useUrlBuilder()
const { t } = useI18n()
const { capitalize } = useText()
const { ALWAYS_SHOWN_PRODUCTS } = useStaticProduct()

const productStore = useProductStore()
const { hasProduct } = productStore
const {
  filteredSubjectCodes,
  selectedSupportProductName,
  filteredAddonProducts,
  filteredSupportProductNames,
  filteredSupportProducts,
} = storeToRefs(productStore)

const hasSubjectsOrProducts = computed(
  () => !!(filteredSubjectCodes.value.length || filteredAddonProducts.value.length || filteredSupportProductNames.value.length)
)
</script>

<template>
  <ul
    v-if="hasSubjectsOrProducts"
    class="flex flex-col gap-2 sm:flex-row sm:flex-wrap"
  >
    <li
      v-for="(subject) of filteredSubjectCodes"
      :key="subject"
    >
      <RouterLink
        :to="{ name: 'subject', params: { subject: subject.toLowerCase() } }"
        class="inline-flex w-full rounded-xl transition focus-visible:ring"
      >
        <KsButton
          variant="primary"
          type="label"
          stretch
          center
          class="subject-button !font-bold"
          size="medium"
        >
          {{ t(`metadata.subjects.${subject}`) }}
        </KsButton>
      </RouterLink>
    </li>
    <li
      v-for="(addon) in filteredAddonProducts"
      :key="addon.ean"
    >
      <RouterLink
        :to="{ path: resolveBaseUrl(addon) }"
        class="inline-flex w-full rounded-xl transition focus-visible:ring"
      >
        <KsButton
          variant="primary"
          type="label"
          stretch
          center
          class="subject-button !font-bold"
          size="medium"
        >
          {{ addon.aunivers.name }}
        </KsButton>
      </RouterLink>
    </li>
    <li
      v-for="(product) in ALWAYS_SHOWN_PRODUCTS"
      :key="product.ean"
    >
      <RouterLink
        :to="{ path: resolveBaseUrl(product) }"
        class="inline-flex w-full rounded-xl transition focus-visible:ring"
      >
        <KsButton
          variant="primary"
          type="label"
          stretch
          center
          :icon-left="!hasProduct(product.ean) ? 'lock' : ''"
          class="subject-button !font-bold"
        >
          {{ product.aunivers.name }}
        </KsButton>
      </RouterLink>
    </li>
    <li
      v-for="(name) of filteredSupportProductNames"
      :key="name"
    >
      <KsButton
        variant="primary"
        stretch
        center
        class="subject-button relative !font-bold after:absolute after:bottom-0 after:right-0 after:box-content after:size-[--flip-size] after:rounded-tl-[inherit] after:border-l after:border-t after:border-[#023738] after:bg-[--ks-secondary]"
        :style="[{
          '--ks-secondary': 'rgb(var(--au-seagreen-40))',
          '--flip-size': '1.0rem',
          'clip-path': 'polygon(0 0, 100% 0, 100% calc(100% - var(--flip-size)), calc(100% - var(--flip-size)) 100%, 0 100%)',
        }]"
        @click="selectedSupportProductName = name"
      >
        {{ capitalize(name) }}
      </KsButton>

      <SupportProductModal
        :name="name"
        :products="filteredSupportProducts"
        :subjects="filteredSubjectCodes"
        @close="selectedSupportProductName = undefined"
      />
    </li>
  </ul>

  <KsSkeletonWrapper
    v-else
    class="flex flex-col gap-2 sm:flex-row sm:flex-wrap"
  >
    <KsSkeleton
      v-for="n in 9"
      :key="n"
      :width="`${n % 4 * 10 + 10}ch`"
      height="3rem"
    />
  </KsSkeletonWrapper>
</template>

<style scoped>
.subject-button {
  --ks-primary: rgb(var(--au-seagreen-60) / .5);
  --ks-secondary: rgb(var(--au-seagreen-60));
  --ks-primarytext: white;
  --ks-roundness: 0.6;
  border: 1.5px solid rgb(var(--au-seagreen-40)) !important;
  padding: 1.3rem 1.1rem !important;
  font-size: 1.2rem !important;

  &:hover {
    --ks-primary: rgb(var(--au-seagreen-40));
    --ks-secondary: rgb(var(--au-seagreen-40));
  }
}
</style>
