<script setup lang="ts">
import type { ContentSubjectHeader } from '~/models/Content/ContentSubjectHeader'
import type { ContentProduct } from '~/models/Content/ContentProduct'
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { setTitle } from '~/utils/dom'
import useSubjectsStore from '~/stores/subjects'
import { useSectionStore } from '~/stores/section'
import useProductStore from '~/stores/product'
import useFilterSettingsStore from '~/stores/filterSettings'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import { ContentType } from '~/models/Content/ContentType'
import useQueryFilter from '~/composables/useQueryFilter'
import useContentApi from '~/api/contentApi'
import ProductBanner from '~/components/banners/ProductBanner.vue'
import TeacherResources from '~/components/ResourceFinder/TeacherResources.vue'
import ResourceFinder from '~/components/ResourceFinder/ResourceFinder.vue'
import SectionRenderer from '~/components/ResourceEngine/SectionRenderer.vue'

const props = defineProps<{ product: ContentProduct|undefined }>()

const { setCurrentSubject } = useSubjectsStore()
const { setFilterOptions, resetFilterOptions } = useFilterSettingsStore()
const { isTeacher } = storeToRefs(useAuthStore())

const productStore = useProductStore()
const { hasLoaded: hasLoadedProducts } = storeToRefs(productStore)

const sectionStore = useSectionStore()
const { loadSections } = sectionStore
const { userSections } = storeToRefs(sectionStore)

const filterStore = useFilterStore()
const { setSelectedAddonLocationId, filterWatchers, $reset } = filterStore
const { selectedHeaders, selectedGrade } = storeToRefs(filterStore)
const { findContents } = useContentApi()

const watchers = filterWatchers()

const productData = ref<ContentSubjectHeader[]>([])

const relevantBanner = computed(() => productData.value
  .find((header) => header.grades.includes(selectedGrade.value)) ?? productData.value[0])

const banner = computed( () => ({
  title: props.product?.title,
  text: props.product?.description,
  image: relevantBanner.value?.image,
}))

const loadContent = (product?: ContentProduct) => {
  if (!product) return
  setCurrentSubject(undefined)
  setTitle(product.title)
  setSelectedAddonLocationId(product.locationId)
  loadProductData(product.pathString)
  loadSections(product.pathString)
}

const loadProductData = async (pathString: string): Promise<void> => {
  productData.value = await findContents<ContentSubjectHeader>({
    subtreeCriterion: [pathString],
    contentTypeCriterion: [ContentType.SubjectHeader],
    sortField: 'priority',
    sortOrder: 'desc',
  })
}

onBeforeMount( () => {
  setFilterOptions({ subject: false, grade: true, header: true })
  loadContent(props.product)
})

onMounted(() => useQueryFilter())

onBeforeUnmount(() => {
  productData.value = []
  setSelectedAddonLocationId(undefined)
  resetFilterOptions()
  watchers.stopFilterWatchers()
  $reset()
})

watch(props, () => {
  loadContent(props.product)
})
</script>

<template>
  <ProductBanner
    :title="banner.title"
    :image="banner.image"
    :text="banner.text"
  />
  <ResourceFinder
    show-package-intro
    :enable-subject-filter="false"
  />
  <TeacherResources
    v-if="isTeacher && hasLoadedProducts && !selectedHeaders.length"
    :location-id="product?.locationId"
  />
  <SectionRenderer
    v-if="userSections.length && !selectedHeaders.length"
    :items="userSections"
  />
</template>

