<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject' // eslint-disable-line @typescript-eslint/no-unused-vars
import { onMounted, ref, watch } from 'vue'
import { KsDropdown } from '@aschehoug/kloss'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useAuthStore } from '~/stores/auth'
import useSubjectsStore from '~/stores/subjects'
import { useUrlBuilder } from '~/composables/useUrlBuilder'

const { t } = useI18n()
const router = useRouter()
const { currentSubject } = storeToRefs(useSubjectsStore())
const { userSubjectsByGrades } = useAuthStore()
const { inSubjectView } = useUrlBuilder()

const selectedSubject = ref<SubjectCode>()

watch(currentSubject, () => {
  selectedSubject.value = currentSubject.value
}, { immediate: true })

const onSelect = () => {
  if (!selectedSubject.value && !inSubjectView) return
  router.push({
    name: 'subject',
    params: { subject: (selectedSubject.value as string).toLowerCase() }
  })
}
</script>

<template>
  <KsDropdown
    v-if="userSubjectsByGrades.length"
    v-model="selectedSubject"
    :options="userSubjectsByGrades"
    :option-id="(subject: SubjectCode) => subject"
    :option-label="(subject: SubjectCode) => t(`metadata.subjects.${subject}`)"
    :placeholder="$t('filters.chooseSubject')"
    :deselectable="() => false"
    shape="rounded"
    autolabel
    flexible
    @select="onSelect"
  />
</template>
