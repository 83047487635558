import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { SubjectCode } from '~/models/Subject'
import { isExcludedSubject, sortBySubjectIndex, subjectsSorted } from '~/utils/subjectSorter'
import arrayUtils from '~/utils/arrayUtils'

const useSubjectsStore = defineStore('subjects', () => {

  const grepCodes = ref<string[]>([])

  const subjects = computed<SubjectCode[]>(() => {
    return subjectsSorted
      .filter((subjectCode) => !isExcludedSubject(subjectCode))
      .sort(sortBySubjectIndex)
  })

  const subjectsByGrepCode = computed<SubjectCode[]>(() => {
    return subjectsSorted
      .filter((subjectCode) => !!findGrepCode(subjectCode))
      .sort(sortBySubjectIndex)
  })

  const setGrepCodes = (newGrepCodes: string[]) => {
    const { truthy } = arrayUtils()
    grepCodes.value = newGrepCodes.filter(truthy)
  }

  const findGrepCode = (subjectCode: SubjectCode) =>
    grepCodes.value.find((grepCode: string) => grepCode.includes(<string>subjectCode))

  const currentSubject = ref<SubjectCode>()

  const setCurrentSubject = (subject?: SubjectCode) => {
    if (!subject && !currentSubject.value) return
    currentSubject.value = subject
  }

  return {
    subjects,
    grepCodes,
    subjectsSorted,
    subjectsByGrepCode,
    setGrepCodes,
    findGrepCode,
    currentSubject,
    setCurrentSubject,
  }
})

export default useSubjectsStore
