import type { Greeting } from '~/models/Greeting'
import { DateTime, Interval } from 'luxon'
import { readonly, ref } from 'vue'
import greetings from '~/assets/greetings.json'

export function useGreetings() {
  const greeting = ref<Greeting>({ text: '' })

  function load() {
    const filtered = greetings.filter(validate)
    greeting.value = filtered[Math.floor(Math.random() * filtered.length)]
  }

  function validate(greeting: Greeting) {
    if (greeting.timeranges) {
      const now = DateTime.now()
      const valid = greeting
        .timeranges
        .some(({ before = '23:59:59.99', after = '00:00' }) => {
          return Interval
            .fromDateTimes(DateTime.fromISO(after), DateTime.fromISO(before))
            .contains(now)
        })

      if (!valid) return false
    }

    return true
  }

  load()

  return {
    greeting: readonly(greeting)
  }
}
