import type { Translations } from '~/translations/Model'

const nn: Translations = {
  'aschehoug': 'Aschehoug',
  'reset': 'Start på nytt',
  'next': 'Neste',
  'loading': 'Laster...',
  'logout': 'Logg ut',
  'back': 'Tilbake',
  'save': 'Lagre',
  'cancel': 'Avbryt',
  'error': 'Noko gjekk gale!',
  'jumpToContent': 'Hopp til hovudinnhald',
  'help': 'Hjelp',
  'resource': 'Ressurs',
  'resourceCenter': 'Opne hjelpesenter',
  'close': 'Lukk',
  'open': 'Opne',
  'download': 'Last ned',
  'downloadIn': 'Last ned {in}',
  'recommendedContent': 'Foreslått innhald',
  'remove': 'Fjern',
  'unknownError': 'Noko gjekk gale dessverre. Prøv igjen seinare.',
  'relatedContent': 'Relatert innhald',
  'new': 'Nytt',
  'newShort': 'Ny',
  'preview': 'Førehandsvis',
  'breadcrumbs': 'Brødsmulesti',
  'login': {
    'brand': 'Aschehoug univers',
    'slogan': 'Med læraren. For eleven.',
    'title': 'Logg inn',
    'welcome': 'Velkommen til Aunivers',
    'intro': 'For å sjå læremidla må du logga inn',
    'withServiceProvider': 'Med Feide',
    'withLocalProvider': 'Utan Feide',
    'logout': 'Logg ut',
    'news': 'Aktuelt',
    'more': 'Meir om aunivers.no',
  },
  'time': {
    'week': 'Veke',
    'weekWithNumber': 'Veke {number}',
  },
  'http': {
    '404': {
      'code': '404',
      'message': 'Ikkje funne',
    },
  },
  'notFound': {
    'title': 'Finst ikkje',
    'heading': 'Ooops!',
    'message': 'Innhaldet kan vera fjerna, eller du kan mangla tilgang.',
    'buttonText': 'Tilbake til framsida',
    'statusCode': 'Statuskode: 404',
  },
  'calendar': {
    'title': 'Kalender',
    'messages': {
      'noResults': 'Ingen resultat',
      'noResultsInWeekSwitcher': {
        'message': 'Ingen oppgåver planlagde i veke {number} for {grade}',
        'solution': 'Prøv å byta gruppe, trinn eller veke. Legg til ved å trykkja på \'Se flere oppgaver\'',
      },
      'noResultsDescription': 'Fann ingen veker med innhald i vald periode',
      'noWeekResources': 'Fann ingen ressursar denne veka',
      'noResourcesInPeriod': 'Fann ingen ressursar i vald periode.',
      'periodUpdated': 'Periode oppdatert',
      'periodUpdateError': 'Kunne ikkje oppdatere periode',
      'periodCreated': 'Periode opprettet',
      'periodCreateError': 'Kunne ikkje opprette periode',
    },
    'updatePlan': {
      'titlePlaceholder': 'Tittel',
      'bodyPlaceholder': 'Beskrivelse',
      'dialogTitle': 'Rediger periode',
      'save': 'Lagre',
      'change': 'Rediger',
      'cancel': 'Avbryt',
      'delete': 'Slett',
    },
    'createPeriod': {
      'titlePlaceholder': 'Tittel',
      'bodyPlaceholder': 'Beskrivelse',
      'new': 'Ny periode',
      'dialogTitle': 'Opprett periode',
      'startWeek': 'Start-veke',
      'endWeek': 'Slutt-veke',
      'create': 'Opprett',
      'abort': 'Avbryt',
      'delete': 'Slett',
    },
    'note': {
      'teacher': 'Skriv notat',
      'studentShow': 'Sjå notat frå lærer',
      'studentHide': 'Skjul notat frå lærer',
    },
    'yearPlan': {
      'sharedYearPlans': 'Kollegers planer',
    },
    'errors': {
      'loadYearPlans': 'Noko gjekk gale ved lasting av årsplanar',
      'loadSharedYearPlans': 'Noko gjekk gale ved lasting av foreslåtte årsplanar',
      'loadPlans': 'Noko gjekk gale ved lasting av planar',
    },
  },
  'groups': {
    'removeSelectedGroup': 'Fjern gruppeval',
    'active': 'Aktiv',
    'activeTitle': 'Aktiv gruppe',
    'title': 'Ingen klasse valt | {count} klassar valde',
    'dialogBody': 'Vel kva fag og trinn du planlegg for.',
    'missingSubject': 'Gruppa manglar eit tilkopla fag',
    'confirmData': 'Stadfest fag og trinn',
    'readOnlyBody': 'Gruppa er koblet til {grade}. For å endra, ta kontakt med {admins}',
    'noGrades': 'Ingen trinn funne',
    'noSubjects': 'Ingen fag funne',
    'saveGroup': 'Lagre gruppe',
    'editGroup': 'Rediger gruppe',
    'deleteGroup': 'Slett gruppe',
    'newGroup': 'Ny gruppe',
    'noGroup': 'Manglar aktiv gruppe',
    'chooseGroup': 'Vel ei aktiv gruppe i toppmenyen for å sjå planlagde ressursar.',
    'cancel': 'Avbryt',
    'noSave': 'Gå til fagside',
    'saveError': 'Feil ved lagring av gruppe',
    'saveSuccess': 'Gruppa vart lagra',
    'nonWriteable': 'Grupper frå Feide kan ikkje redigerast i Aunivers',
    'nonAdminGroup': 'For å endra på gruppa må du ta kontakt med ein administrator',
    'nonAdminContact': 'For å endra på gruppa må du ta kontakt med {admins}',
    'membersCount': '{count} medlemmer',
    'members': 'Medlemmer',
    'addMember': 'Legg til medlem i gruppa',
    'addMemberShort': 'Legg til',
    'removeMember': 'Fjern medlem frå gruppa',
    'grade': 'Trinn',
    'groupName': 'Gruppenamn',
    'types': {
      'basis': 'Klassar (Hentet frå Feide)',
      'teaching': 'Undervisningsgrupper',
      'other': 'Grupper (Laget i Aunivers)',
    },
    'userSearch': {
      'search': 'Søk etter brukarar',
      'searching': 'Søker...',
      'noResults': 'Ingen resultat',
    },
    'groupDeleted': 'Gruppen vart sletta',
    'groupDeleteError': 'feil ved sletting av gruppe',
    'noGradesConnected': 'Ingen tilkopla trinn eller fag',
    'admin': 'Admin',
    'groupDeleteConfirm': 'Er du sikker på at du vil slette denne gruppa?',
    'groupNamePlaceholder': 'Skriv gruppenamn...',
    'noLocalGroups': 'Du har ingen Aunivers grupper endå. <br> Trykk på "Ny gruppe" for å lage ein.',
    'noGroups': 'Du har ingen klassar eller grupper tilkopla til brukar. Byrj å lage grupper anten i Feide eller ved å trykkje "Opprett ny gruppe" nedanfor.',
    'noGroupsTitle': 'Manglar grupper',
    'copyGroup': 'Kopier gruppe',
    'copyOf': '{groupName} (kopi)',
  },
  'header': {
    'chooseGroup': 'Vel gruppe',
    'showMenu': 'Vis sidemeny',
    'teacherGuide': {
      'teacherGuide': 'Lærarrettleiing',
      'teacherGuideFor': 'Lærarrettleiing: {title}',
      'tip': 'Tips',
    },
  },
  'settings': {
    'title': 'Innstillingar',
    'roleTeacher': 'Lærer hos',
    'roleStudent': 'Elev ved',
    'roleAtOrganization': '{role} {organization}',
    'updated': 'Innstillingar lagra!',
    'activeGroup': 'Du har ei aktiv gruppe ({groupName})',
    'missingGrade': 'Gruppa di manglar eit tilkopla trinn',
    'missingSubject': 'Gruppa di manglar eit tilkopla fag',
    'changeSubjectInfo': 'Du kan framleis redigera kva fag du vil ha synleg på framsida!',
    'groupInfo': 'Grupper gjer det enklare å navigera rett innhald! Derfor kryssar me automatisk av på innstillingane nedanfor basert på trinn og fag kopla til gruppa di',
    'chooseGrade': 'Vel skuleslag for å sjå relaterte fag',
    'chooseGradeToClose': 'Du må velje eit skuleslag',
    'chooseGradeType': 'Vel eit skuleslag',
    'gradeTypeWarning': 'Noko er ikkje heilt rett. Trykk på "Innstillinger" for å velja skuleslaget ditt.',
    'readOnlyGradesSpansAcrossgradeTypes': {
      'title': 'Feide-trinn på tvers av skuleslag',
      'info': 'Dine Feide-trinn dekkjer fleire skuleslag. Alle valde trinn blir viste når du vel skuleslag, og byter du skuleslag, blir trinna for det skuleslaget også tilgjengelege i *Aunviers, saman med trinna frå Feide.',
    },
    'sections': {
      'gradeType': 'Skuleslag',
      'language': 'Målform',
      'display': 'Visningsval',
      'instruction': 'Undervisning',
      'tts': 'Talesyntese',
    },
    'drawers': {
      'customization': 'Trinn og fag',
      'language': 'Språk',
      'groups': 'Klassar og grupper',
      'plans': 'Foreslåtte planar',
      'licenses': 'Lisensar',
      'shortcuts': 'Snarveier',
      'subscription': 'Abonnement',
    },
    'shortcuts': {
      'digitalBooks': 'Unibok - Digitale bøker',
      'oldContent': 'Innhald frå Lokus (LK-06)',
    },
    'upperSecondaryWeirdness': {
      'cantRemoveLastSelected': 'Du kan ikkje fjerne det siste valde trinnet. Vel heller eit anna skuleslag.',
      'useArrowToTheRight': 'Bruk pila til høgre for å velje Videregående-trinn',
    },
  },
  'wizard': {
    'warningTitle': 'Vent litt!',
    'warningText': 'Vel både skuleslag og fag før du lukkar vindauget.',
    'nextStep': 'Neste',
    'prevStep': 'Forrige',
    'step': 'Steg {activeStep} av {steps}',
    'finish': 'Fullfør',
    'grades': 'Vel kva skuleslag du underviser på',
    'subjects': 'Kva fag ønskjer du å sjå?',
    'language': 'Vel føretrekt målform',
  },
  'licenses': {
    'title': 'Lisensar',
  },
  'home': {
    'title': 'Heim',
    'welcome': 'Velkommen til Aschehoug univers!',
    'yourSubjects': 'Faga dine',
    'yourProducts': 'Produkta dine',
    'yourAddons': 'Tilleggsprodukta dine',
    'showMoreSubjects': 'Vis fleire fag',
    'showLessSubjects': 'Vis færre fag',
    'showMoreProducts': 'Vis fleire produkt',
    'showLessProducts': 'Vis færre produkt',
    'upperSecondaryProducts': 'VGS-produkt',
    'greetings': {
      'morning': 'God morgon',
      'afternoon': 'God ettermiddag',
      'evening': 'God kveld',
      'night': 'God natt',
    },
  },
  'notifications': {
    'title': 'Varsel',
    'notification': 'Varsel',
    'today': 'I dag',
    'thisWeek': 'Siste sju dagar',
    'markRead': 'Marker som lese',
    'markAllRead': 'Marker alle som lese',
    'allNotifications': 'Alle varslar',
    'allNotificationsMarkedAsRead': 'Alle varsel vart markerte som lese',
    'sentNotifications': 'Sende varslar',
    'receivedNotifications': 'Mottekne varsel',
    'noNotifications': 'Hurra! Du har ingen nye varsel',
    'noReceivedNotifications': 'Du har ingen nye mottekne varsel',
    'noSentNotifications': 'Du har ikkje sendt varslar nyleg',
    'chooseNotificationType': 'Vel typa varsel',
    'viewAll': 'Sjå alle',
    'goToResource': 'Gå til ressurs',
    'received': 'Motteke',
    'sent': 'Sendt',
  },
  'metadata': {
    'weekdays': {
      'monday': 'Måndag',
      'tuesday': 'Tysdag',
      'wednesday': 'Onsdag',
      'thursday': 'Torsdag',
      'friday': 'Fredag',
      'saturday': 'Laurdag',
      'sunday': 'Søndag',
    },
    'gradeTypes': {
      'lowerPrimary': 'Barneskule 1-4',
      'upperPrimary': 'Barneskule 5-7',
      'lowerSecondary': 'Ungdomsskule',
      'upperSecondary': 'Vidaregåande',
      'adultEducation': 'Vaksen',
      'prepEducation': 'Forkurs',
      'short': {
        'lowerPrimary': '1–4',
        'upperPrimary': '5–7',
        'lowerSecondary': '8–10',
        'upperSecondary': 'Videregående',
        'adultEducation': 'Voksen',
        'prepEducation': 'Forkurs',
      }
    },
    'grades': {
      'aarstrinn1': 'Trinn 1',
      'aarstrinn2': 'Trinn 2',
      'aarstrinn3': 'Trinn 3',
      'aarstrinn4': 'Trinn 4',
      'aarstrinn5': 'Trinn 5',
      'aarstrinn6': 'Trinn 6',
      'aarstrinn7': 'Trinn 7',
      'aarstrinn8': 'Trinn 8',
      'aarstrinn9': 'Trinn 9',
      'aarstrinn10': 'Trinn 10',
      'vg1': 'Vg1',
      'vg2': 'Vg2',
      'vg3': 'Vg3',
      'vok': 'Voksen',
      'prep': 'Forkurs',
      'forkurs': 'Forkurs',
    },
    'gradesPunctuated': {
      'aarstrinn1': '1. trinn',
      'aarstrinn2': '2. trinn',
      'aarstrinn3': '3. trinn',
      'aarstrinn4': '4. trinn',
      'aarstrinn5': '5. trinn',
      'aarstrinn6': '6. trinn',
      'aarstrinn7': '7. trinn',
      'aarstrinn8': '8. trinn',
      'aarstrinn9': '9. trinn',
      'aarstrinn10': '10. trinn',
      'vg1': 'Vg1',
      'vg2': 'Vg2',
      'vg3': 'Vg3',
      'vok': 'Vaksenopplæring',
      'forkurs': 'Forkurs',
      'prep': 'Forkurs',
    },
    'gradesShort': {
      'aarstrinn1': '1',
      'aarstrinn2': '2',
      'aarstrinn3': '3',
      'aarstrinn4': '4',
      'aarstrinn5': '5',
      'aarstrinn6': '6',
      'aarstrinn7': '7',
      'aarstrinn8': '8',
      'aarstrinn9': '9',
      'aarstrinn10': '10',
      'vg1': 'vg1',
      'vg2': 'vg2',
      'vg3': 'vg3',
      'vok': 'vok',
      'forkurs': 'Forkurs',
      'prep': 'forkurs',
    },
    'subjects': {
      'ENG': 'Engelsk',
      'FSP': 'Framandspråk',
      'KRO': 'Kroppsøving',
      'MAT': 'Matematikk',
      'NAT': 'Naturfag',
      'FLE': 'Fleirfagleg',
      'NOR': 'Norsk',
      'SAF': 'Samfunnsfag',
      'FYS': 'Fysikk',
      'GEO': 'Geografi',
      'HEL': 'Helse',
      'HIS': 'Historie',
      'KJE': 'Kjemi',
      'MED': 'Mediefag',
      'POL': 'Politikk',
      'PSY': 'Psykologi',
      'REL': 'Religion og etikk',
      'KRL': 'KRLE',
      'TVE': 'Året rundt',
      'INF': 'Informasjonsteknologi',
      'SPA': 'Spansk',
      'TYS': 'Tysk',
      'FRA': 'Fransk',
      'GFG': 'Geofag',
      'KHV': 'Kunst og handverk',
      'MUS': 'Musikk',
      'MHE': 'Mat og helse',
      'SOK': 'Sosialkunnskap',
      'SOS': 'Sosiologi og sosialantropologi',
      'POM': 'Politikk og menneskerettar',
      'SAK': 'Samfunnskunnskap',
      'SAG': 'Samfunnsgeografi',
      'LANG': 'Språkfag',
    },
    'language': {
      'title': 'Målform',
      'nb': 'Bokmål',
      'nn': 'Nynorsk',
    },
    'taskTypes': {
      'article': 'Artikkel',
      'video': 'Video',
      'audio': 'Lyd',
      'file': 'Fil',
      'document': 'Dokument',
      'task': 'Oppgåve',
      'resource': 'Ressurs',
      'digitalbook': 'Digitalbok',
      'game': 'Spel',
    },
  },
  'organization': {
    'title': 'Vel ein aktiv skule for økta di',
    'choose': 'Vel skule',
  },
  'filters': {
    'forTeacher': 'Til læraren',
    'content': 'Innhald',
    'emptyFilter': 'Tøm filter',
    'chooseTheme': 'Vel tema',
    'chooseTaskType': 'Vel innhaldstype',
    'chooseSubject': 'Vel fag',
    'chooseGrade': 'Vel trinn',
    'chooseGradeType': 'Vel skuleslag',
    'chooseGroup': 'Vel gruppe',
    'chooseLanguage': 'Vel målform',
    'chooseFilters': 'Vis filter',
    'chooseSorting': 'Vel sortering',
    'chooseProduct': 'Vel bok',
    'chooseWeekNumber': 'Vel uke',
    'chooseHeader': 'Vel tema',
    'chooseSchoolYear': 'Vel skuleår',
    'noResourcesFound': 'Ingen ressursar funne',
    'noProducts': 'Ingen bøker funne',
    'noSubjects': 'Ingen fag funne',
    'filteredAway': {
      'title': 'Fann ingen ressursar',
      'messageWithGrades': 'Du kan sjå innhald ved å byta til følgjande trinn:',
      'messageWithGradeTypes': 'Du kan sjå innhald ved å byta til følgjande skuleslag:',
      'messageWithoutGrades': 'Prøv å endra på filtreringa, eller ta kontakt med kundeservice',
    },
    'subject': 'Fag',
    'grade': 'Trinn',
    'product': 'Bok',
    'header': 'Overskrift',
    'taskType': 'Innhaldstype',
    'filterSettings': 'Visningsval',
    'sortSettings': 'Sorteringsval',
    'noHits': 'Oi! Ser ut som filteret ditt ikkje har nokre treff.',
    'trySomethingElse': 'Prøv andre kombinasjonar for å få fleire resultat.',
    'frontpage': 'Forside {subjectName}',
    'settings': {
      'suggested': 'Foreslått rekkjefølgje',
      'alphabetical': 'Alfabetisk',
      'studentActivity': 'Siste elevaktivitet',
      'random': 'Tilfeldig sortering',
    },
    'values': {
      'allSubjects': 'Alle fag',
      'thisWeek': 'Denne veka',
      'wholeClass': 'Hele klassen',
      'week': {
        'previous': 'Førre veke',
        'current': 'Denne veka',
        'next': 'Neste veke',
        'previousFourWeeks': 'Siste fire veker',
        'previousThreeMonths': 'Siste tre månader',
        'fromSchoolYearStart': 'Sidan skulestart',
        'yearplan': 'Sjå årsplan',
      },
    },
  },
  'favorite': {
    'save': 'Lagre favoritt',
  },
  'plans': {
    'plan': 'Plan',
    'plannedThisWeek': 'Planlagt denne veka',
    'allPlans': 'Alle planar',
    'allYears': 'Alle år',
  },
  'plan': {
    'title': 'Planlagt',
    'content': 'Planlagt innhald',
    'add': 'Legg i plan',
    'sharePlan': 'Knytt til gruppe',
    'copySelection': 'Kopieringsval',
    'copyYearPlan': 'Kopier plan',
    'deleteYearPlan': 'Slett plan',
    'copyYearPlanToGroup': 'Kopier ressursar',
    'copyYearPlanToGroupExplanation': 'Dette vil kopiere ressursane som er delte med {groupName} i {weekNumber} til ei anna gruppe',
    'chooseGroupToCopyTo': 'Vel gruppe å kopiere ressursar til',
    'noPlans': 'Fann ingen planlagde ressursar i {subject} i {weekNumber}',
    'goToResources': 'Gå til fagsida for {subject}',
    'resourcesCopied': 'Ressursane vart kopierte til {groupName}',
  },
  'yearPlan': {
    'plural': 'Planar',
  },
  'learningPath': {
    'learningPathOverview': 'Opne oversikt over læringsløp',
    'learningPathParts': '{partsCount} delar',
    'noResourcesFound': 'Fann ingen ressursar i læringsløpet',
  },
  'links': {
    'seeAll': 'Sjå alle',
    'viewTasks': 'Sjå vekas planlagde ({number})',
    'viewMoreTasks': 'Tilbake til fagside',
    'loadingTasks': 'Lastar vekas planlagde',
  },
  'contentTypes': {
    'default': 'Ressurs',
    'resource': 'Ressurs',
    'folder': 'Mappe',
    'product': 'Produkt',
    'product_container': 'Oversikt',
    'product_article': 'Artikkel',
    'product_package': 'Innholdspakke',
    'product_header': 'Tema',
    'product_tailor': 'Ressurs',
    'product_part': 'Del',
    'part': 'Del av læringsløp',
    'product_colophon': 'Kolofon',
    'article': 'Artikkel',
    'learning_path': 'Læringsløp',
    'document': 'Dokument',
    'landing_page': 'Forside',
    'cloubi_content': 'Ressurs',
    'video': 'Video',
    'image': 'Bilde',
    'upload_video': 'Video',
    'activetextbook': 'Interaktiv bok',
    'drawing_tool': 'Tavleressurs',
    'file': 'Fil',
    'audio': 'Lyd',
    'svg': 'Bilde',
    'game_board': 'Spelebrett',
    'game_part': 'Øva',
    'form_writer': 'Skriveramme',
    'epub': 'E-bok',
    'link': 'Link',
    'literary_text': 'Litterær tekst',
    'peer_gynt': 'Spel',
    'upload_file': 'Opplasting',
    'book_reader': 'Boklesar',
    'presentation': 'Presentasjon',
    'flyt_task': 'Oppgåve',
    'task': 'Oppgåve',
    'pdf_book': 'Digitalbok',
    'digitalbook': 'Digitalbok',
    'game': 'Spel',
    'cloubi': 'Ressurs',
    'blog_post': 'Blogginnlegg',
    'guide': 'Lærerveiledning',
    'model3d': '3D-modell',
    'image_360': '360-bilete',
    'image_collection': 'Bildesamling',
    'reading_aid': 'Lesehjelper',
    'colophon': 'Kolofon',
    'app_article_teacher': 'Lærerveiledning',
    'flashcard_deck': 'Flashcards',
  },
  'sections': {
    'none': 'Manglar innhald til trinna og faga dine. Prøv å endra på innstillingane.',
    'teacherResources': 'Lærarressursar for {subject}, {grade}',
  },
  'activity': {
    'groupHasNoStudents': 'Gruppa du har valt har ingen elevar i seg. Kanskje du må leggje dei til først?',
    'visited': 'Besøkt',
    'youHaveCompletedAll': 'Du har fullført heile',
    'errorLoadingActivities': 'Noko gjekk gale ved lasting av elevaktivitet. Prøv å oppfriske sidan og prøve på nytt!',
    'openActivity': 'Opne',
    'youHaveCompletedNOfTotal': 'Du har fullført {n} av {total}',
    'lastInteraction': 'Sist besøkt',
    'planActivity': 'Planlegg',
    'planActivityInMoreWeeks': 'Planlegg i fleire veker',
    'planUnplanned': 'Planlegg i ei veke',
    'notPlannedInfo': 'Ressurser som ikkje er planlagde, men som elevane har besøkt likevel',
    'removePlannedActivity': 'Fjern',
    'plannedInWeek': 'Vekene der denne ressursane er planlagt',
    'planned': 'Planlagt',
    'pageTitle': 'Elevaktivitet',
    'notPlannedActivities': 'Ressursar med elevaktivitet',
    'completed': 'Fullført',
    'actionOnLine': 'Vis val for {title}',
    'nOfTotal': 'av {total} har besøkt',
    'noActivities': {
      'title': 'Det er ingen aktivitet å vise',
      'body': 'Her er det ingen aktivitet å vise endå. Prøv å velje ei anna gruppe, eit anna fag, eller å sjå på ei anna veke.',
    },
    'noStudentActivities': {
      'title': 'Det er ingen aktivitet å vise',
      'body': 'Her er det ingen registrerte aktivitet endå. Prøv å velje ei anna veke!',
    },
    'changeGroup': {
      'button': 'Endre gruppe',
      'title': 'Vel gruppe',
      'body': 'For å sjå elevaktivitet må du velje ei gruppe med elevar å sjå elevaktivitet for.',
      'cancel': 'Avbryt',
      'confirm': 'Vel gruppe',
    },
    'selectGroup': 'Vel ei gruppe',
    'title': {
      'forStudent': 'Aktiviteten din på Aunivers',
      'forTeacher': 'Elevaktivitet',
    },
    'callout': {
      'noData': {
        'title': 'Fann ikkje aktivitet',
        'message': 'Her klarte me ikkje finna aktivitet. Om du trur dette er feil kan du prøva å lasta sidan på nytt.'
      },
    },
    'messages': {
      'studentActivity': 'Elevaktivitet',
      'activityForStudent': 'Aktivitet for {student}',
      'noAnswer': 'Finn ikkje svar',
      'progressText': '{filledFields} av {totalFields}',
    },
    'status': {
      'visited': 'Besøkt',
      'notStarted': 'Ikkje påbegynt',
    },
    'button': {
      'toAssignment': 'Vis meir',
      'openActivity': 'Vis aktivitet for ressurs {title}',
    },
  },
  'planner': {
    'changeGroup': {
      'button': 'Endre gruppe',
      'title': 'Vel gruppe',
      'body': 'For å sjå planlagte oppgåver må du velje ei gruppe med elevar å sjå oppgåver for.',
      'cancel': 'Avbryt',
      'confirm': 'Vel gruppe',
    },
    'addToWeek': {
      'title': 'Legg til i veke',
      'planTitle': 'Tema for {week} {year}',
      'notCreated': 'Ressursen vart ikkje lagt til',
      'noGroup': 'Vel ei aktiv gruppe, øvst på sida, for å planleggje',
      'noGroups': 'Du har ingen grupper, og kan derfor ikkje planleggje',
      'noGroupShort': 'Vel ei gruppe',
      'noYearPlan': 'Ingen plan funne for denne ressursen',
      'noWeek': 'Veka vart ikkje funnen i planen',
      'noRemove': 'Manglar tilgang',
      'removed': 'Ressursen vart fjerna',
      'failed': 'Noko gjekk gale. Prøv igjen seinare',
      'pastWeek': 'Kan ikkje planleggje bakover i tid',
    },
    'selectYearPlanDialog': {
      'title': 'Vel kva årsplan vi skal bruka',
      'description': 'Du har fleire årsplanar for denne gruppa. På grunn av det får du no velje kva vi skal bruke, eller om vi skal opprette ein ny. Nokre grupper har allereie fag, og då vil vi automatisk velje gruppa sitt fag for deg, men du kan velje fleire fag viss du treng det.',
    },
    'selectGradeDialog': {
      'title': 'Vel trinn',
      'description': 'Vel eit trinn for denne gruppa',
      'explanation': 'For å kunne lagre og hente ut informasjon rett må vi ha eit trinn for denne gruppa. Sidan det ikkje går an å hente det ut automatisk må du oppgi det her.',
    },
    'selectWeekNumberDialog': {
      'title': 'Vel veke',
      'selectWeek': 'Planlegg ressurs',
    },
    'addResource': {
      'error': 'Fekk ikkje lagd ressursen til i veka',
      'success': 'Ressursen vart lagt til i veke {weekNumber}',
      'resourceInWeeks': 'Ressursen «{resourceTitle}» finst allereie i følgjande veker:',
      'addToWeek': 'Legg til ressurs i veke {weekNumber}',
      'removeFromWeek': 'Fjern ressurs frå veke {weekNumber}',
      'addedToWeek': 'Lagt til i veke',
      'alreadyPlanned': 'Allereie planlagt',
      'moreWeeks': 'Vis alle veker',
    },
    'createYearPlan': {
      'title': 'Planlegger',
      'body': 'Vel kvar du vil planleggja denne ressursen',
      'missingOrganizationOrGroups': 'Utan grupper kan du ikkje planleggja',
      'missingActiveGroup': 'Vel ei aktiv gruppe først',
      'missingSubject': 'Vel eit fag å planleggja i',
      'missingGrade': 'Vel eit trinn å planleggja i',
      'missingWeekNumber': 'Manglar veke å planleggja i',
      'save': 'Lagre plan',
      'saveDisabledTooltip': 'Det er noko informasjon som manglar, og då kan vi ikkje lagre',
    },
    'createResource': {
      'title': 'Del eigen ressurs',
      'upload': 'Del ei fil',
      'url': 'URL',
      'shareUrl': 'Del ein URL',
      'save': 'Lagre ressurs',
      'isUploading': 'Laster...',
      'editFileName': 'Rediger namn på ressursen',
      'missingFileName': 'Manglar filnamn',
      'fileNamePlaceholder': 'Skriv eit namn...',
      'maxFileSize': 'Maks filstorleik: {mb}MB',
      'dragAndDrop': 'Dra og slepp ei fil frå skrivebordet eller <strong>trykk her</strong>',
      'fileIsTooLargeTitle': 'For stor fil',
      'fileIsTooLargeText': 'Denne fila er for stor! Me har ein maks filstorleik på {mb}MB. Kan du velja ei mindre fil?',
      'missingResource': 'Ingen ressurs er oppretta',
      'downloadError': 'Fila vart ikkje funnen',
      'unknownError': 'Noko gjekk gale dessverre. Prøv igjen seinare.',
      'linkError': 'Lenkja er ikkje gyldig. Prøv igjen.',
      'linkTest': 'Test at lenkja fungerer',
      'linkPlaceholder': 'Lim inn URLen her',
      'created': 'Ressursen vart oppretta og delt i uke {weekNumber}',
      'unable': 'Vel ei aktiv gruppe i toppmenyen',
    },
    'suggestedYearPlans': {
      'title': 'Foreslåtte planar',
      'description': 'Her er Aschehougs foreslåtte planar, klare for kopiering ut i veker.',
      'filteredByActiveGroup': 'Viser planar for {grade}, som er trinnet som er sett for den aktive gruppa «{group}». Faga er dei same som i innstillingane dine.',
      'filteredBySettings': 'Planane er filtrerte på valde fag og trinn frå innstillingane dine.',
      'showAll': 'Vis alle foreslåtte planar',
      'noYearPlansForYou': 'Vi har ingen planar for dei faga og trinn du har valt. Derfor viser vi deg alle planane vi har.',
    },
    'copyYearPlan': {
      'title': 'Kopierer plan: { title }',
      'body': 'Vel kvar du vil kopiera denne planen',
      'activatePreSharing': 'Del ukeplaner til elevene dine uken før de er planlagt',
      'displayToColleagues': 'Gjer planen synleg for kollegaene dine',
      'displayToColleaguesNote': 'Kollegaene dine kan sjå namna og temaa til planen. Dei kan ikkje opna planen, men be deg om tilgang til han.',
      'every': 'Kvar',
      'showMore': 'Vis {howMany} fleire årsplanar',
      'confirmSameTitle': 'Planen har same namn',
      'titleNotChanged': 'Du har ikkje endra tittelen på årsplanen. Vil du likevel kopiere han?',
      'copy': 'Kopier!',
      'cancel': 'Vent litt …',
      'goToPreview': 'Førehandsvis plan',
      'placeholders': {
        'chooseGroup': 'Vel gruppe',
        'chooseSchoolYear': 'Vel skuleår',
        'chooseGrade': 'Vel trinn',
        'chooseSubject': 'Vel fag',
      },
      'errors': {
        'noTitle': 'Du må velje ein tittel',
        'noSchoolYear': 'Du må velje eit skuleår',
        'noGrade': 'Du må velje eit trinn',
        'noGroup': 'Du må velje ei gruppe',
        'noSubject': 'Du må velje eit fag',
        'groupSubjectAndGradeDoesNotMatch': 'Faget og trinnet i gruppa ({subject}, {grade}) er ulikt det som er valt for denne planen. Har du valt rett gruppe?',
        'yearPlanSubjectAndGradeDoesNotMatch': 'Det foreslåtte faget til planen og trinn er ulikt det som er valt. Har du valt rett plan?',
      },
      'preview': {
        'yearPlanDialog': 'Førehandsviser planen «{planTitle}» før kopiering',
        'explanation': 'Dette er ei førehandsvisning av korleis denne planen ser ut. Ressursane har vorte fordelte ut over veker gjennom skuleåret.',
        'moveToWeek': 'Flytt',
        'moveToWeekNumber': 'Veke {weekNumber}',
        'plannedInWeek': 'Veke {weekNumber}',
        'copy': 'Kopier',
        'cancel': 'Avbryt',
        'missingResources': 'Ingen foreslåtte ressursar denne veka.',
        'duration': 'Veke {fromWeek} – Veke {toWeek}',
      },
      'copyYearPlanDialog': {
        'labels': {
          'title': 'Tittel',
          'group': 'Gruppe',
          'grade': 'Trinn',
          'subject': 'Fag',
        },
        'planCopied': 'Planen «{title}» er kopiert! Den er merket med {grade} og {subject}.',
        'planShared': 'Planen har blitt delt med gruppa {groupName}, og ressursene blir vist til elevene.',
        'resourcesAppear': 'Du kan nå gå til fagsiden for {subject} og se at ressursene dukker opp i riktige uker.',
        'openSubjectPage': 'Sjå oppgåver for {subject}',
      },
    },
  },
  'dialog': {
    'confirm': 'OK',
    'cancel': 'Avbryt',
    'choose': 'Vel',
    'create': 'Opprett ny',
    'add': 'Legg til',
    'on': 'På',
    'off': 'Av',
  },
  'copy': {
    'copyUrl': 'Kopier lenke',
    'copyTemplate': {
      'title': 'Veke {weekNumber}',
    },
    'messages': {
      'success': 'Kopiert',
      'error': 'Kunne ikkje kopiera',
    },
  },
  'footer': {
    'customerService': 'Kundeservice',
    'exam': 'Eksamen',
    'privacy': 'Personvern',
    'accessibility': 'Universell utforming',
    'webshop': 'Nettbutikk',
    'copyright': 'Rettar',
    'cookies': 'Cookies',
  },
  'trial': {
    'title': 'Velkommen!',
    'message': 'Her finn du innhald til alle fag og alle trinn. <a href="{href}" target="_blank">Bestill ein gratis prøvelisens</a> til vårt utforskande læringsunivers. God lærelyst!',
    'noHits': 'Hugs at du kan <a href="{href}" target="_blank">bestilla ein gratis prøvelisens</a> til det utforskande læringsuniverset vårt.',
  },
  'license': {
    'message': 'Lisensdetaljar for {name}',
    'ean': 'ISBN',
    'licenseModel': 'Type',
    'endDate': 'Gyldig til',
    'orderId': 'Ordrenr',
    'usageRights': 'Antal',
    'noLicenses': {
      'title': 'Fann ingen lisensar',
      'message': 'Om du trur dette er ein feil kan du prøva å lasta inn sida på nytt eller kontakta kundestøtte.'
    }
  },
  'licenseModel': {
    'OPEN': 'Open lisens',
    'SCHOOL': 'Skulelisens',
    'INDIVIDUAL': 'Enkeltlisens',
    'PRIVATE': 'Privatlisens',
    'TRIAL_SCHOOL': 'Prøvelisens, skule'
  },
  'colophon': {
    'title': 'Om {subject}',
    'plural': 'Kolofoner',
    'back': 'Tilbake til fagside',
    'notFoundSubject': 'Ingen kolofonar funne for {subject} i {grade}',
    'notFoundLocation': 'Ingen kolofon funne',
  },
  'search': {
    'title': 'Søk',
    'placeholder': 'Søk her...',
    'lookingFor': 'Søk innanfor {filters}...',
    'globalSearch': 'Søk i heile Aunivers',
    'resultsCount': '{count} treff',
    'currentPage': 'Side {page} av {totalPages}',
    'noResults': 'Ingen resultat funne',
    'noResultsDescription': 'Fann ingen søkjeresultat som samsvarer med søkjeordet eller filtera dine.',
    'spellingSuggestion': 'Meinte du',
    'searchingIn': 'Søkjar i {in}',
    'loadingProducts': 'Stiller inn søket',
    'failed': 'Noko gjekk gale',
    'reset': 'Nullstill',
    'resetSearch': 'Start på nytt',
    'resetFilters': 'Fjern alle filtre',
    'contentTypes': 'Innhaldstypar',
    'animationLabel': 'Animert illustrasjon som illustrerer eit søk',
    'popularSearches': 'Populære søkjeord',
    'subject': 'Søk i {subject}',
    'subjectAll': 'Søk i alle {subject} ressursar',
    'settings': {
      'mySettings': 'Om søket',
      'grade': 'Trinninnstillingar',
      'results': 'Du får resultat basert på desse bøkene',
      'editMessage': 'Du kan redigera trinna dine i <b>Innstillingar > Trinn og fag</b>',
    },
    'lockedContent': {
      'noAccess': 'Du har ikkje tilgang til dette innhaldet',
      'goToWebshop': 'Gå til nettbutikken',
      'relatedProduct': 'Dette innhaldet høyrer til produktet <b>{product}</b>.',
      'unknownProduct': 'Dette innhaldet høyrer til eit produkt som er lisensbelagt.',
      'findTeacher': 'Du finn produktet i nettbutikken vår.',
      'findStudent': 'Ta kontakt med læraren din viss du forventa å ha tilgang.',
    },
    'paginator': {
      'page': 'Side {page}',
      'nextPage': 'Neste side',
      'prevPage': 'Forrige side',
      'navigation': 'Sidenavigering',
    },
    'filters': {
      'showGradeFilter': 'Vis trinnfiltre',
      'hideGradeFilter': 'Skjul trinnfiltre',
      'showSubjectFilter': 'Vis fagfiltre',
      'hideSubjectFilter': 'Skjul fagfiltre',
      'activeFilters': 'Aktive filtre',
      'removeFilter': 'Fjern {filter} som filter',
      'removeAllFilters': 'Fjern alle filtre',
      'filterBySubject': 'Filtrer på fag',
    },
    'suggested': {
      'one': 'Rettar',
      'two': 'Berekraftig utvikling',
      'three': 'Demokrati',
      'four': 'Samfunn',
      'five': 'Identitet',
    },
    'sortField': {
      'sorting': 'Sorteringsval',
      'score': 'Mest relevant',
      'modified': 'Nyleg oppdatert',
      'published': 'Nyleg publisert',
    },
    'checkboxes': {
      'has_audio': 'Med lyd',
      'with_additions': 'Med opplegg',
    },
    'filterBoxes': {
      'target_group': 'Passar for',
      'theme': 'Tema',
      'genre': 'Sjanger',
      'series': 'Serie',
      'language': 'Språk',
      '1-4': 'Barneskule 1-4',
      '5-7': 'Barneskule 5-7',
      '8-10': 'Ungdomsskule',
      'vgs': 'Vidaregåande',
      'voksen': 'Vaksenopplæring',
      'forkurs': 'Forkurs',
    },
    'product': {
      'back': 'Tilbake til {product}',
      'placeholder': {
        'Biblioteket': 'Søk i biblioteket etter tittel eller forfattar...',
        'default': 'Søk her...',
      },
    },
  },
  'register': {
    'form': {
      'title': 'Registrer ny brukar',
      'intro': 'For å bruka digitale læremiddel på Aunivers.no/unibok.no må du registrera deg.',
      'submit': 'Registrer',
    },
    'input': {
      'firstName': 'Førenamn',
      'lastName': 'Etternamn',
      'email': 'E-postadresse',
      'password': 'Passordet ditt',
      'terms': 'Eg har lese, forstått og akseptert <a href="{url}" class="underline" target="_blank">brukarvilkåra</a>'
    },
    'invalid': {
      'firstName': 'Førenamn er ikkje gyldig',
      'lastName': 'Etternamn er ikkje gyldig',
      'email': 'E-postadressa er ikkje gyldig',
      'password': 'Passordet er ikkje gyldig',
    },
    'error': {
      'internal': 'Noko gjekk gale ved registrering av brukaren. Ver vennleg og prøv på nytt om ei lita stund.',
      'loggedIn': 'Du kan ikkje registrera ein brukar når du allereie er logga inn',
      'invalidInput': 'Feil ved validering av dataa dine. Sjå over og prøv på nytt',
      'alreadyExists': 'Ein brukar med same e-postadresse eksisterer allereie',
      'missingParams': 'Manglar nødvendig aktiveringskode',
      'activate': 'Noko gjekk gale ved aktivering av kontoen din',
    },
    'success': {
      'title': 'Vellykka registering!',
      'info': 'Brukaren din er no registrert, men må stadfestast først. Dette må du gjera no:',
      'confirm1': 'Opne e-posten du har fått',
      'confirm2': 'Klikk på lenkja <b>innan to dagar</b> for å aktivera brukaren din',
    },
    'activate': {
      'title': 'Aktivering',
      'info': 'Lag eit passord til brukaren din. Oppgi minst åtte teikn, inkludert minst eitt tal.',
      'inputText': 'Vis i klartekst',
      'inputPassword': 'Skjul tekst',
      'success': 'Passordet er lagra. Du kan no logga inn.',
    },
  },
  'access': {
    'title': 'Tilgang',
    'public': 'Open for alle',
    'teacher': 'Berre for lærer',
  },
  'blog': {
    'title': 'Siste nytt i Aunivers',
    'shortTitle': 'Nyheiter',
    'noPosts': 'Her var det tomt!',
    'seAllPosts': 'Sjå alle nyheitssaker',
    'subpages': {
      'all': 'Alle',
      'news': 'Nyheiter',
      'inspiration': 'Inspirasjon',
    }
  },
  'mimeType': {
    'pdf': 'PDF',
    'word': 'Word',
    'excel': 'Regneark',
    'video': 'Video',
    'audio': 'Lydfil',
    'image': 'Bilde',
    'powerpoint': 'Presentasjon',
    'archive': 'Arkiv',
    'file': 'Fil',
  },
  'bookreader': {
    'error': 'Kunne ikkje lasta inn boklesar',
    'loading': 'Laster inn boklesar',
    'readBook': 'Les bok',
  },
  'flashcards': {
    'error': 'Kunne ikkje lasta inn flashcards',
    'tip': 'Trykk på korta for å snu dei rundt.',
    'start': 'Start',
    'retry': 'Prøv på nytt',
    'complete': 'Då var du igjennom!',
    'stage': '{n} av {total}',
  },
  'content': {
    'published': 'Gitt ut',
    'language': 'Språk',
    'pageCount': 'Talet på sider',
    'audio': 'Lyd',
    'recordedAudio': 'Innlesen lyd',
    'inSeries': 'Inngår i serie',
  },
  'presentation': {
    'page': 'Side',
    'pageNumber': 'Side {pageNumber}',
    'pageNumberOfTotal': 'Side {pageNumber} / {totalPages}',
    'teacherGuide': 'Lærarrettleiing',
    'menu': {
      'menu': 'Meny',
      'content': 'Innhald',
    },
  },
  'media': {
    'playAudio': 'Spel av lyd',
    'pauseAudio': 'Pause lyd',
    'closeAudio': 'Lukk lyd',
    'showCopyright': 'Vis rettar',
    'hideCopyright': 'skjul rettar',
  },
  'lottie': {
    'play': 'Spel av animasjon',
    'pause': 'Pause animasjon',
  },
  'licenseControl': {
    'message': {
      'title': 'Oisann! Du har ikkje tilgang til dette innhaldet',
      'body': {
        'teacher': 'Me klarte ikkje å finna ein gyldig lisens på dette innhaldet. Om du meiner dette er ein feil, kontakt kundestøtte for hjelp',
        'student': 'Spør læraren din om hjelp',
      }
    },
    'buttons': {
      'back': 'Tilbake',
      'webshop': 'Til nettbutikk',
    },
  },
  'fullscreen': {
    'close': 'Lukk fullskjerm',
    'open': 'Åpne fullskjerm',
  },
  'pdfViewer': {
    'downloadDisabled': 'Beklagar, men du kan ikkje lagre denne PDFen.',
  },
  'labels': {
    'product_article': 'Produktartikkel',
    'learning_path': 'Læringsløp',
    'file': 'Fil',
    'product_part': 'Del av løp',
    'video': 'Video',
    'article': 'Artikkel',
    'task': 'Oppgave',
    'resource': 'Ressurs',
    'digitalbook': 'Digitalbok',
    'audio': 'Lyd',
    'audio_transcription': 'Tekst',
    'part': 'Del av løp',
    'game': 'Spill',
    'document': 'Dokument',
    'book_reader': 'Bokleser',
    'presentation': 'Presentasjon',
    'other': 'Anna',
    'app_teacher': 'Lærerveiledning',
    'podcast': 'Podkast',
    'essay': 'Fagtekst',
    'activity': 'Aktivitet',
    'map': 'Kart',
    'print': 'Utskrift',
    'fiction': 'Skjønnlitterær tekst',
    'poem': 'Dikt',
    'short': 'Novelle',
    'experiment': 'Forsøk',
    'pause': 'Avbrekk',
    'text': 'Tekst',
    'wimmelimage': 'Myldrebilde',
    'teacher': 'Lærerveiledning',
    'theme': 'Tema',
    'package': 'Innhaldspakke',
    'recommended': 'Tilrådd',
  },
  'activities': {
    'messages': {
      'title': 'Aktivitetar',
    },
    'bruke begrepene': 'Bruke omgrepa',
    'diskutere': 'Diskutere',
    'leke': 'Leike',
    'lese': 'Lese',
    'lytte': 'Lytte',
    'reflektere': 'Reflektere',
    'skrive': 'Skrive',
    'snakke': 'Snakke',
    'synge': 'Syngje',
    'utforske': 'Utforske',
    'øve': 'Øve',
    'lyd': 'Lyd',
    'video': 'Video',
    'lage': 'Lage',
  },
  'copyright': {
    'open': 'Opne bildebeskrivelse',
    'close': 'Lukk bildebeskrivelse',
    'image': 'Bilde: {copyright}',
    'animation': 'Animasjon: {copyright}',
  },
  'embeds': {
    'notSupported': 'Innebygd media er ikkje støtta'
  },
  'feedback': {
    'question': 'Fann du det du leita etter?',
    'positive': 'Tusen takk!',
    'negative': 'Huff da!',
    'message': 'Vil du gi oss ei tilbakemelding?',
    'send': 'Send inn',
    'event': {
      'rated': 'Takk!',
      'submit': 'Takk for tilbakemeldinga!',
    }
  },
  'vidstack': {
    'Caption Styles': 'Tekststilar',
    'Captions look like this': 'Teksting ser slik ut',
    'Closed-Captions Off': 'Teksting av',
    'Closed-Captions On': 'Teksting på',
    'Display Background': 'Vis bakgrunn',
    'Enter Fullscreen': 'Gå til fullskjerm',
    'Enter PiP': 'Gå til bilete i bilete',
    'Exit Fullscreen': 'Avslutt fullskjerm',
    'Exit PiP': 'Avslutt bilete i bilete',
    'Google Cast': 'Google Cast',
    'Keyboard Animations': 'Tastaturanimasjonar',
    'Seek Backward': 'Spol tilbake',
    'Seek Forward': 'Spol framover',
    'Skip To Live': 'Hopp til direktesending',
    'Text Background': 'Tekstbakgrunn',
    'Accessibility': 'Tilgjengelegheit',
    'AirPlay': 'AirPlay',
    'Announcements': 'Kunngjeringar',
    'Audio': 'Lyd',
    'Auto': 'Auto',
    'Boost': 'Forsterk',
    'Captions': 'Teksting',
    'Chapters': 'Kapittel',
    'Color': 'Farge',
    'Connected': 'Tilkopla',
    'Connecting': 'Koplar til',
    'Continue': 'Fortsett',
    'Default': 'Standard',
    'Disabled': 'Deaktivert',
    'Disconnected': 'Fråkopla',
    'Download': 'Last ned',
    'Family': 'Familie',
    'Font': 'Skrift',
    'Fullscreen': 'Fullskjerm',
    'LIVE': 'DIREKTE',
    'Loop': 'Gjenta',
    'Mute': 'Demp',
    'Normal': 'Normal',
    'Off': 'Av',
    'Opacity': 'Gjennomsiktigheit',
    'Pause': 'Pause',
    'PiP': 'Bilete i bilete',
    'Play': 'Spel av',
    'Playback': 'Avspeling',
    'Quality': 'Kvalitet',
    'Replay': 'Spel igjen',
    'Reset': 'Tilbakestill',
    'Seek': 'Søk',
    'Settings': 'Innstillingar',
    'Shadow': 'Skugge',
    'Size': 'Storleik',
    'Speed': 'Fart',
    'Text': 'Tekst',
    'Track': 'Spor',
    'Unmute': 'Slå på lyd',
    'Volume': 'Volum',
  },
  'subscription': {
    'messages': {
      'endDate': 'Lisensen på {name} går ut {date}',
      'missing': 'Ingen abonnement',
      'current': 'Du har følgjande aktive abonnement:',
      'upgrade': {
        'fromBasic': 'Ved å oppgradera til PLUSS får heile skulen tilgang til Papir+ og Biblioteket.',
        'fromPlus': '',
      },
      'webshop': {
        'internal': '<a href="https://skole.aschehoug.no" class="underline focus-visible:ring" target="_blank">skole.aschehoug.no</a>',
        'external': '<a href="https://www.pendel.no/" class="underline focus-visible:ring" target="_blank">Pendel</a> eller <a href="https://skolon.no" class="underline focus-visible:ring" target="_blank">Skolon</a>',
      },
      'readMore': 'Les meir om, og endre abonnementet her:',
    },
    'application': 'Aunivers',
    'name': {
      'PrimaryTrial': 'Aunivers 1–7 Prøvelisens',
      'PrimaryBasic': 'Aunivers 1–7 Basis',
      'PrimaryIndividual': 'Aunivers 1–7 Individuell',
      'PrimaryPlus': 'Aunivers 1–7 Pluss',
      'PrimaryPremium': 'Aunivers 1–7 Premium',
      'SecondaryTrial': 'Aunivers 8–10 Prøvelisens',
      'SecondaryBasic': 'Aunivers 8–10 Basis',
      'SecondaryIndividual': 'Aunivers 8–10 Individuell',
      'SecondaryPlus': 'Aunivers 8–10 Pluss',
      'SecondaryPremium': 'Aunivers 8–10 Premium',
      'VgsTrial': 'Aunivers VGS Prøvelisens',
      'VgsIndividual': 'Aunivers VGS Individuell',
    },
    'type': {
      'none': 'Ingen',
      'trial': 'Prøve',
      'individual': 'Individuell',
      'basic': 'Basis',
      'plus': 'Pluss',
      'premium': 'Premium',
    },
    'grades': {
      '1-7': '1–7',
      '8-10': '8–10',
    }
  }
}

export default nn
