import { onMounted, ref } from 'vue'

export default function useHiddenHeading() {
  const hasHeading = ref(true)

  const checkDocumentForHeading = () => setTimeout(() =>
    hasHeading.value = !!document.querySelectorAll('h1').length, 250)

  onMounted(checkDocumentForHeading)

  return {
    hasHeading,
  }
}
