import type { ResourceItem } from '~/models/Resource'
import type { ContentResource } from '~/models/Content/ContentResource'
import axios from 'axios'
import useResourceMapper from '~/composables/useResourceMapper'
import { contentClient } from '~/api/client/contentClient'

// @deprecated
export const useResourceApi = () => {
  const {  mapResourceToContent } = useResourceMapper()

  const getResourceByPath = async (path: string): Promise<ContentResource|undefined> => {
    try {
      const response = await contentClient.get<ResourceItem>(
        '/resource/path',
        {
          params: { path },
        },
      )
      return response.data ? mapResourceToContent(response.data) : undefined
    } catch (error) {
      if (!axios.isAxiosError(error)) throw error
      const { response } = error

      if (response && response.status >= 400 && response.status < 500) {
        const response = await contentClient.get<ResourceItem>(
          '/resource/path',
          {
            params: { path: path },
          },
        )
        return response.data ? mapResourceToContent(response.data) : undefined
      }
      throw error
    }
  }

  const getResourceByLocationId = async (locationId: number): Promise<ContentResource|undefined> => {
    const response = await contentClient.get<ResourceItem[]>(
      '/resource/location',
      {
        params: { locationIds: locationId.toString() },
      },
    )

    return response.data ? mapResourceToContent(response.data[0]) : undefined
  }

  return {
    getResourceByPath,
    getResourceByLocationId,
  }
}
