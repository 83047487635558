<script setup lang="ts">
import { ref, watch } from 'vue'
import { KsIcon } from '@aschehoug/kloss'

const props = withDefaults(defineProps<{
  title: string
  id: string
  open?: boolean
}>(), {
  open: false
})

const isOpen = ref(false)

const toggle = () => isOpen.value = !isOpen.value

watch(() => props.open, (value) => {
  isOpen.value = value
})
</script>

<template>
  <div
    :id="id"
    class="mx-auto max-w-screen-au px-4 sm:px-8"
  >
    <h2 class="w-full font-calistoga fluid-text-2xl">
      <button
        :id="`button-${id}`"
        :aria-expanded="isOpen"
        :aria-controls="`section-${id}`"
        class="group flex w-full items-center justify-between pb-8 pt-12 focus-visible:ring"
        @click="toggle"
      >
        <span
          class="transition group-hover:underline group-focus-visible:underline"
          v-text="title"
        />
        <span class="flex items-center justify-center rounded-full p-1 transition group-hover:bg-seagreen-40 group-focus-visible:bg-seagreen-40">
          <KsIcon
            class="transition-transform duration-300 ease-out"
            :class="isOpen ? 'invisible absolute rotate-90' : 'rotate-0'"
            icon="plus"
          />
          <KsIcon
            class="transition-transform duration-300 ease-out"
            :class="!isOpen ? 'invisible absolute -rotate-90' : 'rotate-0'"
            icon="minus"
          />
        </span>
      </button>
    </h2>
    <div
      :id="`section-${id}`"
      v-disable-tabindex="!isOpen"
      role="region"
      :aria-labelledby="`button-${id}`"
      class="overflow-hidden pb-16 transition-all"
      :class="!isOpen && 'pointer-events-none h-20 opacity-60'"
      :aria-hidden="!isOpen"
    >
      <slot />
    </div>
  </div>
</template>
