import type {
  Aggregation,
  AggregationEntry,
  AggregationStateEntry,
  AggregationTermEntry
} from '~/models/Content/Response'
import arrayUtils from '~/utils/arrayUtils'

export default () => {
  const { uniqueBy } = arrayUtils()

  /**
   * A "term" aggregates search by the value of the search field
   *
   * @param name
   * @param aggregations
   */
  const mapTermAggregations = (name: string, aggregations: Aggregation[]): AggregationTermEntry[] => {
    const entries = aggregations
    .filter((aggregation) => aggregation.name === name)
    .flatMap((aggregate: Aggregation) => aggregate.entries as AggregationTermEntry[])
    // Sum count for identical labels
    return entries.map((aggregation) => ({
      ...aggregation,
      key: aggregation.key,
      count: entries.reduce((acc, agg) => {
        acc += agg.key === aggregation.key ? agg.count : 0
        return acc
      }, 0)
    }))
    .filter(uniqueBy('key'))
    .sort((a, b) => b.count - a.count)
  }

  /**
   * A "state" aggregates an Ibexa object state value
   *
   * @param name
   * @param aggregations
   */
  const mapStateAggregations = (name: string, aggregations: Aggregation[]): AggregationTermEntry[] => aggregations
    .filter((aggregation) => aggregation.name === name)
    .flatMap((aggregate: Aggregation) => aggregate.entries as AggregationStateEntry[])
    .map((entry) => (({
      ...entry,
      key: entry.key.ObjectState.identifier
    })))

  const mapGroupedGradeAggregations = (gradeAggregations: AggregationEntry[], mappingFunction: Record<string, string[]>): Map<string, { key: string; count: number }[]> => {
    const gradeGroups = new Map<string, { key: string; count: number }[]>()

    gradeAggregations.forEach((grade) => {
      let group = grade.key
      for (const [key, values] of Object.entries(mappingFunction)) {
        if (values.includes(grade.key)) {
          group = key
          break
        }
      }
      if (!gradeGroups.has(group)) {
        gradeGroups.set(group, [])
      }
      gradeGroups.get(group)?.push({ key: grade.key, count: grade.count })
    })

    return gradeGroups
  }

  return {
    mapStateAggregations,
    mapTermAggregations,
    mapGroupedGradeAggregations,
  }
}
