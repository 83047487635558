import { type ComputedRef, type Ref, isRef, unref, watch } from 'vue'
import type { Media } from '~/models/Content/Media'
import { useQuery } from '@tanstack/vue-query'
import useContentApi from '~/api/contentApi'
import { Subtree } from '~/models/Content/Subtree'

export function useMedia(contentIdOrContent: number | Media | Ref<number | Media | undefined> | ComputedRef<number | Media | undefined>) {
  const { findContents } = useContentApi()
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['use-media', unref(contentIdOrContent)],
    queryFn: async (): Promise<Media | null> => {
      const value = unref(contentIdOrContent)
      return (typeof value !== 'number'
        ? value
        : (await findContents<Media>({
            contentIdCriterion: [value],
            subtreeCriterion: [Subtree.Content, Subtree.Media],
            mainLocationCriterion: true,
          }))[0]) ?? null
    }
  })

  if (isRef(contentIdOrContent)) {
    watch(contentIdOrContent, () => refetch())
  }

  return {
    media: data,
    isLoading,
  }
}
