<script setup lang="ts">
import type { Group } from '~/models/Group'
import { computed } from 'vue'
import { KsIcon } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps<{ group: Group }>()
const emit = defineEmits(['openGroup'])

const details = computed(() => {
  const details = []
  if (props.group.grade) details.push(t(`metadata.grades.${props.group.grade}`))
  if (props.group.subjects.length) details.push(t(`metadata.subjects.${props.group.subjects[0]}`))
  return details.join(', ')
})

</script>
<template>
  <button
    class="relative flex h-full w-full items-center justify-between gap-2 rounded-xl border-4 border-seagreen-20 p-4 font-medium text-black transition-colors hover:bg-seagreen-10 focus-visible:ring"
    @click.prevent="emit('openGroup', group)"
  >
    <span class="text-left">
      <span
        class="relative line-clamp-2"
        v-text="group.name"
      />
      <span
        v-if="details"
        class="font-normal text-gray-40"
        v-text="details"
      />
    </span>
    <KsIcon
      icon="chevron-right"
      scale="0.7"
      class="w-full shrink-0 text-seagreen-30"
    />
  </button>
</template>
