<script lang="ts">
const closePrevActive = ref<null | (() => Promise<void>)>(null)
</script>

<script lang="ts" setup>
import type { MediaLoadingStrategy } from 'vidstack'
import type { AudioPlayerVariant } from '~/models/Media'
import { ref, nextTick, watch, defineAsyncComponent, h, computed } from 'vue'
import 'vidstack/bundle'
import { MediaPlayer } from 'vidstack'
import { KsButton, KsIcon } from '@aschehoug/kloss'
import IconInputSwitch from '~/components/utils/IconInputSwitch.vue'
import TimeSlider from '~/components/media/TimeSlider.vue'
import Captions from '~/components/media/Captions.vue'

const props = withDefaults(defineProps<{
  mediaId?: string
  variant?: AudioPlayerVariant
  load?: MediaLoadingStrategy
}>(), {
  variant: 'inline',
  load: 'play',
})

defineOptions({
  inheritAttrs: false,
})

const emit = defineEmits([
  'update:transcription',
  'update:showTranscription',
])

const AsyncWrapper = defineAsyncComponent(async () => h('slot'))

const isPlaying = ref(false)
const isActive = ref(false)
const playerEl = ref<MediaPlayer>()
const cues = ref<VTTCue[]>([])
const showTranscription = ref(false)
const isInline = computed(() => props.variant === 'inline')

async function close() {
  await playerEl.value?.pause()
  isActive.value = false
  showTranscription.value = false
}

function activate() {
  isActive.value = true

  // We have to wait for next tick to make sure the video player is moved into the teleported slot, else it might trigger a src change event and pause itself
  nextTick(() => {
    playerEl.value?.play()
  })
}

watch(isActive, async (value) => {
  if (value && (closePrevActive.value !== close)) {
    await closePrevActive.value?.()
    closePrevActive.value = close
  }
})

watch(playerEl, (value) => {
  if (value) {
    value.textTracks.addEventListener('add', ({ target }) => {
      const [track] = target.getByKind('metadata')
      if (track) {
        track.addEventListener('load', () => {
          cues.value = track.cues
        })
      }
    })
  }
})

watch(cues, (value) => emit('update:transcription', value), { deep: true })

watch(showTranscription, (value) => {
  emit('update:showTranscription', value)
})
</script>

<template>
  <template v-if="!isInline">
    <KsButton
      v-if="variant === 'big-button-no-text'"
      size="large"
      variant="primary"
      shape="round"
      class="!h-32 !w-32 !rounded-full hover:opacity-80"
      :style="{
        '--ks-primary': 'var(--media-bg-color, currentColor)',
        '--ks-primarytext': 'var(--media-color, white)',
        '--ks-secondary': 'var(--media-bg-color, currentColor)',
      }"
      @click="isPlaying ? playerEl?.pause() : activate()"
    >
      <KsIcon
        :icon="isPlaying ? 'pause' : 'play'"
        :class="isPlaying ? '' : 'translate-x-[10%]'"
        :scale="1.5"
      />
    </KsButton>

    <KsButton
      v-else
      size="large"
      variant="border"
      shape="rounded"
      :style="{
        '--ks-border': 'var(--media-bg-color, white)',
        '--ks-borderhoverfill': 'var(--media-bg-color, currentColor)',
        '--ks-borderhovertext': 'var(--media-color, white)',
      }"
      @click="isPlaying ? playerEl?.pause() : activate()"
    >
      <template v-if="isPlaying">
        <KsIcon
          icon="pause"
          variant="solid"
        />
        {{ $t('media.pauseAudio') }}
      </template>

      <template v-else>
        <KsIcon
          icon="play"
          variant="solid"
          class="scale-95"
        />
        {{ $t('media.playAudio') }}
      </template>
    </KsButton>
  </template>

  <!-- Beause teleport expects the target to be mounted before <Teleport> is, it needs to be loaded async -->
  <AsyncWrapper>
    <Teleport
      to="#audio-embed-outlet"
      :disabled="isInline || !isActive"
    >
      <div
        v-show="isInline || isActive"
        class="flex w-full items-center gap-8"
      >
        <KsButton
          v-if="!isInline"
          icon-left="xmark"
          variant="border"
          size="large"
          shape="rounded"
          :style="{
            '--ks-border': 'var(--media-color, currentColor)',
            '--ks-borderhoverfill': 'var(--media-color, currentColor)',
            '--ks-borderhovertext': 'var(--media-bg-color, white)'
          }"
          @click="close()"
        >
          {{ $t('media.closeAudio') }}
        </KsButton>

        <media-player
          v-bind="$attrs"
          ref="playerEl"
          crossorigin="use-credentials"
          :load
          class="rounded-xl data-[focus]:ring"
          @play="isPlaying = isActive = true"
          @pause="isPlaying = false"
        >
          <slot>
            <media-provider class="!hidden">
              <Captions
                v-if="mediaId"
                :media-id
                kind="metadata"
              />
            </media-provider>

            <slot name="controls">
              <div class="m-auto flex w-full max-w-4xl items-center gap-8 tabular-nums">
                <media-play-button class="rounded-full data-[focus]:ring">
                  <KsButton
                    tabindex="-1"
                    :title="isPlaying ? $t('media.pauseAudio') : $t('media.playAudio')"
                    variant="primary"
                    size="large"
                    shape="round"
                    :style="{
                      '--ks-primary': 'var(--media-color, currentColor)',
                      '--ks-secondary': 'color-mix(in srgb, var(--media-color, currentColor) 80%, transparent)',
                      '--ks-primarytext': 'var(--media-bg-color, white)'
                    }"
                  >
                    <KsIcon
                      v-if="isPlaying"
                      icon="pause"
                      variant="solid"
                    />
                    <KsIcon
                      v-else
                      icon="play"
                      variant="solid"
                      class="translate-x-[10%] scale-95"
                    />
                  </KsButton>
                </media-play-button>

                <media-time type="current" />
                <TimeSlider />
                <media-time type="duration" />

                <IconInputSwitch
                  v-if="!isInline && cues.length > 0"
                  v-model="showTranscription"
                  :label="$t('labels.audio_transcription')"
                >
                  <KsIcon
                    icon="text"
                    :scale=".5"
                  />
                </IconInputSwitch>
              </div>
              <!-- <media-audio-layout /> -->
            </slot>
          </slot>
        </media-player>
      </div>
    </Teleport>
  </AsyncWrapper>
</template>
