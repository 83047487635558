import type { ColorTheme } from '~/models/Content/Color'
import { colorMap } from '~/utils/colors'

export function getCardStyle(colorTheme: ColorTheme|undefined, isDark: boolean|undefined) {
  if (!colorTheme) return {}
  return {
    'color': `rgb(var(${colorMap.get(colorTheme.text)?.var}))`,
    '--card-bg': `rgb(var(${colorMap.get(colorTheme.primary)?.var}))`,
    '--card-text': `rgb(var(${colorMap.get(colorTheme.text)?.var}))`,
    '--card-border': `rgb(var(${colorMap.get(colorTheme.secondary)?.var}))`,
    '--card-accent': isDark
      ? `rgb(var(${colorMap.get(colorTheme.accent)?.var}))`
      : `rgb(var(${colorMap.get(colorTheme.text)?.var}))`,
    '--card-hover': `rgb(var(${colorMap.get(colorTheme.highlight)?.var}))`
  }
}

export function getAltCardStyle(colorTheme: ColorTheme | undefined) {
  if (!colorTheme) return {}
  return {
    '--card-bg': `rgb(var(${colorMap.get(colorTheme.accentSecondary)?.var}))`,
    '--card-text': `rgb(var(${colorMap.get(colorTheme.primary)?.var}))`,
    '--card-border': `rgb(var(${colorMap.get(colorTheme.accent)?.var}))`,
    '--card-accent': `rgb(var(${colorMap.get(colorTheme.dark)?.var}))`,
    '--card-hover': `rgb(var(${colorMap.get(colorTheme.accentHighlight)?.var}))`
  }
}
