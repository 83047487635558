import axios, { AxiosInstance } from 'axios'
import { MaxTimeoutMs } from '~/constants/api'

export const selfServiceClient: AxiosInstance = axios.create({
  timeout: MaxTimeoutMs,
  baseURL: import.meta.env.VITE_SELF_SERVICE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
})
