import { Plugin } from '~/models/Plugin'
import FlytTask from '~/components/flyt/FlytTask.vue'
import EmbedVideo from '~/components/embeds/EmbedVideo.vue'
import EmbedAudio from '~/components/embeds/EmbedAudio.vue'

export const install: Plugin['fn'] = (app) => {
  app.component('FlytTask', FlytTask)
  app.component('EmbedVideo', EmbedVideo)
  app.component('EmbedAudio', EmbedAudio)
}
