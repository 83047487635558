import type { AxiosInstance } from 'axios'
import axios from 'axios'
import { getAccessToken } from '~/api/client/oidc'
import { toApiCache } from '~/utils/cache'

export const externalClient: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_CORE_EXTERNAL_API_URL,
  headers: {
    'Authorization': `Bearer ${getAccessToken()}`,
  },
})

externalClient.interceptors.response.use((response) => {
  toApiCache(`${response.config.baseURL}${response.config.url}`, response)
  return response
})
