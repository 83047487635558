<script setup lang="ts">
import type { AggregationTermEntry, SearchResult } from '~/models/Content/Response'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { sortBySubjectIndex } from '~/utils/subjectSorter'
import { gradeTypeMapping } from '~/utils/gradeSorter'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import useAuthStore from '~/stores/auth'
import useText from '~/composables/useText'
import useAggregations from '~/composables/useSearchAggregations.ts'
import useAggregationMapper from '~/composables/useAggregationMapper'
import SearchSubjectFilterBox from '~/components/search/SearchSubjectFilterBox.vue'
import FilterBox from '~/components/search/SearchFilterBox.vue'
import GlobalSearchGradeFilterBox from '~/components/search/global/GlobalSearchGradeFilterBox.vue'

const props = defineProps<{
  results: SearchResult
  isLoading: boolean
  isRefetching: boolean
  criterions: Record<string, string[]>
}>()

const emit = defineEmits(['toggleCriterion'])

const resultsRef = ref(props.results)

const { t } = useI18n()
const { unique, intersect } = arrayUtils()
const { capitalize } = useText()
const { isTeacher } = storeToRefs(useAuthStore())
const { matchingProducts } = storeToRefs(useProductStore())
const { isRelevantProduct } = useProductStore()
const { mapGroupedGradeAggregations } = useAggregationMapper()

const {
  labelAggregations,
  accessAggregations,
  activityAggregations,
  sortedGradeAggregations,
} = useAggregations(resultsRef)

const groupGradeAggregations = computed(() => mapGroupedGradeAggregations(sortedGradeAggregations.value, gradeTypeMapping))

const groupedGradeAggregations = computed(() => {
  return Array.from(groupGradeAggregations.value.entries()).map(([group, entries]) => ({
    group,
    keys: entries.flatMap(entry => ({ key: entry.key, count: entry.count })),
  }))
})

const matchingSubjects = computed(() => matchingProducts.value
  .filter((product) => props.criterions.grade.length
    ? intersect(product.grades, props.criterions.grade).length > 0
    : isRelevantProduct(product))
  .flatMap(({ subjects }) => subjects)
  .filter(unique)
  .sort(sortBySubjectIndex))

watch(() => props.results, (newResults) => {
  resultsRef.value = newResults
})
</script>

<template>
  <aside class="grid-in-filters">
    <div
      v-if="!isLoading"
      class="flex flex-col gap-4"
      :style="{'--ks-roundness': .2}"
    >
      <SearchSubjectFilterBox
        v-if="matchingSubjects.length"
        :title="t('filters.subject')"
        :subjects="matchingSubjects"
        :is-refetching="isRefetching"
        :selected-subjects="criterions['subject'] ?? []"
        @toggle-criterion="(subjectCode) => emit('toggleCriterion', 'subject', subjectCode)"
      />
      <GlobalSearchGradeFilterBox
        :title="t('filters.grade')"
        :is-refetching="isRefetching"
        :criterions="criterions['grade'] ?? []"
        :aggregations="groupedGradeAggregations"
        :translator="(entry: AggregationTermEntry) => t(`metadata.grades.${entry.key}`)"
        @toggle-criterion="(entry) => emit('toggleCriterion', 'grade', entry)"
      />
      <FilterBox
        v-if="activityAggregations.length"
        :title="t('labels.activity')"
        :is-refetching="isRefetching"
        :aggregations="activityAggregations"
        :criterions="criterions['activity'] ?? []"
        :translator="(entry: AggregationTermEntry) => t(`activities.${entry.key}`, capitalize(entry.key))"
        @toggle-criterion="(entry) => emit('toggleCriterion', 'activity', entry)"
      />
      <FilterBox
        v-if="labelAggregations.length"
        :title="t('filters.taskType')"
        :is-refetching="isRefetching"
        :aggregations="labelAggregations"
        :criterions="criterions['label'] ?? []"
        :translator="(entry: AggregationTermEntry) => t(`labels.${entry.key}`)"
        @toggle-criterion="(entry) => emit('toggleCriterion', 'label', entry)"
      />
      <FilterBox
        v-if="isTeacher && accessAggregations.length"
        :title="t('access.title')"
        :is-refetching="isRefetching"
        :aggregations="accessAggregations"
        :criterions="criterions['access'] ?? []"
        :translator="(entry: AggregationTermEntry) => t(`access.${entry.key}`)"
        @toggle-criterion="(entry) => emit('toggleCriterion', 'access', entry)"
      />
    </div>
    <KsSkeletonWrapper
      v-else
      class="flex flex-col gap-4"
    >
      <KsSkeleton
        v-for="i in 6"
        :key="i"
        height="45px"
        width="100%"
        border-radius="4px"
      />
    </KsSkeletonWrapper>
  </aside>
</template>
