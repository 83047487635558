<script setup lang="ts">
import type { ImageRelationField } from '~/models/Content/BaseField'
import { computed } from 'vue'
import useImage from '~/composables/useImage'

const props = withDefaults(defineProps<{
  title: string
  image?: ImageRelationField
  text?: string
}>(), {
  text: '',
})

const { getImageSrc } = useImage()
const imageSrc = computed(() => Number(props.image?.destinationContentId) > 0
  ? getImageSrc(props.image)
  : '')
</script>

<template>
  <header
    id="subject-banner"
    class="relative h-[245px] flex-col justify-start bg-seagreen-10 bg-cover bg-center bg-no-repeat text-white sm:h-[335px]"
    :style="{
      'background-image': `url(${imageSrc})`
    }"
  >
    <div class="relative top-[60%] z-20 mx-auto max-w-screen-au px-4 sm:top-[50%] sm:px-8">
      <h1
        class="inline-block font-semibold drop-shadow-md fluid-text-6xl"
        v-text="title"
      />
      <p v-html="text" />
    </div>
  </header>
</template>
