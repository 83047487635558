<script lang="ts" setup>
import type { SectionBaseItem } from '~/models/Content/SectionBaseItem'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import { ContentType } from '~/models/Content/ContentType'
import ResourceRenderer from '~/components/ResourceEngine/ResourceRenderer.vue'
import Standalone from '~/components/ResourceEngine/Components/Standalone.vue'
import Expanded from '~/components/ResourceEngine/Components/Expanded.vue'
import DefaultComponent from '~/components/ResourceEngine/Components/DefaultComponent.vue'
import Box from '~/components/ResourceEngine/Components/Box.vue'

const { t } = useI18n()
const { products } = storeToRefs(useProductStore())
const { truthy } = arrayUtils()

interface ResourceRendererProps {
  items: SectionBaseItem[]
}

const props = defineProps<ResourceRendererProps>()

const componentMap: Record<string, any> = {
  [ContentType.SectionExpanded]: Expanded,
  [ContentType.SectionStandalone]: Standalone,
  [ContentType.SectionBox]: Box,
}

const getComponent = (section: SectionBaseItem) => {
  return componentMap[section.contentTypeIdentifier] ?? DefaultComponent
}

const router = useRouter()
const routeName = computed(() => router.currentRoute.value.name)
const isAnchor = computed(() => ['subject'].includes(<string>routeName.value))

const productContentIds = computed(() => products.value
  .map(({ aunivers }) => aunivers.contentId)
  .filter(truthy<number>)
)

const licensedSections = computed(() => props.items.filter((item) => !item.product
    || !item.product.destinationContentId
    || productContentIds.value.includes(Number(item.product.destinationContentId))
))
</script>

<template>
  <h2
    v-if="licensedSections.length > 0"
    class="mx-auto mb-5 max-w-screen-au px-4 text-xl font-bold text-gray-50 sm:px-8"
    v-text="t('recommendedContent')"
  />
  <div class="flex flex-col gap-12">
    <component
      :is="getComponent(section)"
      v-for="section in licensedSections"
      :key="section.locationId"
      :section="section"
    >
      <template #resource="{ resourceItem }">
        <ResourceRenderer
          :items="[ resourceItem ]"
          :is-anchor="isAnchor"
        />
      </template>
    </component>
  </div>
</template>
