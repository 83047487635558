<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { PlannedItem } from '~/models/Content/PlannedItem'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import SubjectDropdown from '~/components/PlannedResources/SubjectDropdown.vue'
import WeekNavigator from '~/components/week/WeekNavigator.vue'
import CreateResourcePopover from '~/components/planner/CreateResourcePopover.vue'
import CopyPopover from '~/components/PlannedResources/CopyPopover.vue'

defineProps<{
  resources: Record<string, PlannedItem[]>
}>()

const emit = defineEmits(['reloadYearPlans'])

const { isTeacher, hasOrganization, userSubjectsByGrades } = storeToRefs(useAuthStore())

const getYearPlans = () => emit('reloadYearPlans')
</script>

<template>
  <div class="my-6 flex flex-wrap justify-between gap-y-6">
    <SubjectDropdown
      :subjects="userSubjectsByGrades"
      @change-subject="getYearPlans"
    />
    <WeekNavigator @change-week="getYearPlans" />
    <CreateResourcePopover v-if="isTeacher && hasOrganization" />
    <CopyPopover
      :resources="resources"
      @resources-copied="getYearPlans"
    />
  </div>
</template>
