import type { ActivateUser, RegisterUser } from '~/models/User/Register'
import { selfServiceClient } from '~/api/client/selfServiceClient'

export default function useSelfServiceApi() {
  const registerUser = (user: RegisterUser): Promise<void> => {
    return selfServiceClient.post('/selfservice/user/registration?clientId=aunivers_client', user)
  }

  const activateUser = (user: ActivateUser): Promise<void> => {
    return selfServiceClient.post('/selfservice/user/activate?clientId=aunivers_client', user)
  }

  return {
    registerUser,
    activateUser,
  }
}
