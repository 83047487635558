import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw, RouterScrollBehavior } from 'vue-router'
import type { ContentRoute } from '~/models/Router/Routes'
import { createRouter, createWebHistory } from 'vue-router'
import { clearApiCache } from '~/utils/cache'
import { useAuthStore } from '~/stores/auth'
import useActivity from '~/composables/useActivity'
import VideoView from '~/views/VideoView.vue'
import UserRegisterView from '~/views/UserRegisterView.vue'
import UserActivateView from '~/views/UserActivateView.vue'
import TeacherArticleView from '~/views/TeacherArticleView.vue'
import SubjectView from '~/views/SubjectView.vue'
import StudentActivityView from '~/views/StudentActivityView.vue'
import ProductView from '~/views/ProductView.vue'
import PresentationView from '~/views/PresentationView.vue'
import PlannedResourcesView from '~/views/PlannedResourcesView.vue'
import PackageView from '~/views/PackageView.vue'
import NotFoundView from '~/views/NotFoundView.vue'
import LoginView from '~/views/LoginView.vue'
import HomeView from '~/views/HomeView.vue'
import GlobalSearchView from '~/views/GlobalSearchView.vue'
import GenericView from '~/views/GenericView.vue'
import FlytTaskView from '~/views/FlytTaskView.vue'
import FlashcardsView from '~/views/FlashcardsView.vue'
import BookReaderView from '~/views/BookReaderView.vue'
import BookReaderSummaryView from '~/views/BookReaderSummaryView.vue'
import BlogView from '~/views/BlogView.vue'
import AudioView from '~/views/AudioView.vue'
import HeaderView from '~/components/subject/HeaderView.vue'

const redirectRoutes = [
  {
    path: '/minside',
    redirect: () => ({ name: 'home' }),
  },
  {
    path: '/nn/minside',
    redirect: () => ({ name: 'home' }),
  },
  {
    path: '/sommer',
    redirect: () => ({ name: 'subject', params: { subject: 'tve' } }),
  },
  {
    path: '/hoest',
    redirect: () => ({ name: 'subject', params: { subject: 'tve' } }),
  },
  {
    path: `/${encodeURI('høst')}`,
    alias: '/høst',
    redirect: () => ({ name: 'subject', params: { subject: 'tve' } }),
  },
  {
    path: '/vinter',
    redirect: () => ({ name: 'subject', params: { subject: 'tve' } }),
  },
  {
    path: '/vaar',
    redirect: () => ({ name: 'subject', params: { subject: 'tve' } }),
  },
  {
    path: `/${encodeURI('vår')}`,
    alias: '/vår',
    redirect: () => ({ name: 'subject', params: { subject: 'tve' } }),
  },
]

const navigationRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { banner: true },
  },
  {
    path: '/ikke-funnet',
    name: 'notfound',
    component: NotFoundView,
    meta: { banner: false },
  },
  {
    path: '/fag/:subject',
    name: 'subject',
    component: SubjectView,
    meta: { banner: true },
    props: ({ params }: { params: { subject: string } }) => ({
      subjectCode: params.subject.toUpperCase()
    })
  },
  {
    path: '/fag/:subject/:locationId(\\d+)-:slug([^/]+)',
    alias: '/fag/:subject/:locationId(\\d+):slug($)',
    name: 'header',
    component: HeaderView,
    meta: { banner: true },
    props: ({ params }: { params: { subject: string; locationId: string } }) => ({
      subjectCode: params.subject.toUpperCase(),
      headerLocationId: Number(params.locationId),
    })
  },
  {
    path: `/fag/:subject/${encodeURI('lærer')}/:grade`,
    alias: '/fag/:subject/lærer/:grade',
    name: 'teacher_subject',
    component: () => import('~/views/TeacherSubjectView.vue'),
    meta: { hideAppFooter: true, hideAppHeader: true, hideWizard: true },
    props: ({ params }: { params: { subject: string; grade: string } }) => ({
      subjectCode: params.subject.toUpperCase(),
      gradeCode: params.grade.toLowerCase(),
    })
  },
  {
    path: '/fag/:subject/laerer',
    name: 'teacher_subject_legacy',
    component: () => import('~/views/TeacherSubjectLegacyView.vue'),
    meta: { banner: true },
    props: ({ params }: { params: { subject: string } }) => ({
      subjectCode: params.subject.toUpperCase()
    })
  },
  {
    path: '/innhold/produkt/:locationId(\\d+)-:slug([^/]+)',
    alias: '/innhold/produkt/:locationId(\\d+):slug($)',
    name: 'product',
    component: ProductView,
    meta: { banner: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId)
    })
  },
  {
    path: `/innhold/${encodeURI('lærer')}/:locationId(\\d+)-:slug([^/]+)`,
    alias: '/innhold/lærer/:locationId(\\d+):slug($)',
    name: 'teacher_article',
    component: TeacherArticleView,
    beforeEnter: (to, from, next) => {
      const { isTeacher } = useAuthStore()
      if (!isTeacher) return next({ name: 'notfound' })
      next()
    },
    meta: { hideAppFooter: true, hideAppHeader: true, hideWizard: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId)
    })
  },
  {
    path: '/innhold/kolofon/:subject/:grade',
    name: 'colophon_subject',
    component: () => import('~/views/ColophonView.vue'),
    meta:  { hideAppFooter: true, hideAppHeader: true, hideWizard: true },
    props: ({ params }: { params: { subject: string; grade: string } }) => ({
      subjectCode: params.subject.toUpperCase(),
      gradeCode: params.grade.toLowerCase(),
    })
  },
  {
    path: '/innhold/kolofon/:locationId(\\d+)-:slug([^/]+)',
    alias: '/innhold/kolofon/:locationId(\\d+):slug($)',
    name: 'colophon_location',
    component: () => import('~/views/ColophonLocationView.vue'),
    meta:  { hideAppFooter: true, hideAppHeader: true, hideWizard: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId)
    })
  },
  {
    path: '/varsler',
    name: 'notifications',
    component: () => import('~/views/NotificationsView.vue'),
    meta: { banner: true },
  },
  {
    path: '/logg-inn',
    name: 'login',
    component: LoginView,
    meta: { banner: false },
  },
  {
    path: '/sok',
    name: 'search',
    component: GlobalSearchView,
    meta: { banner: false, headerColor: true },
  },
  {
    path: '/planlagt',
    name: 'plannedResources',
    component: PlannedResourcesView,
    meta: { banner: true }
  },
  {
    path: '/elevaktivitet',
    name: 'studentActivity',
    component: StudentActivityView,
    meta: { banner: true },
  },
  {
    path: '/nyheter',
    component: BlogView,
    meta: { banner: false },
    children: [
      {
        path: '',
        name: 'news',
        component: () => import('~/components/blog/AllBlogPosts.vue')
      },
      {
        path: ':tag',
        name: 'newsTag',
        component: () => import('~/components/blog/BlogPostsByTag.vue'),
        props: true
      },
      {
        path: ':tag/:locationId/:blogPost',
        name: 'singlePost',
        component: () => import('~/components/blog/BlogPost.vue'),
        props: true
      }
    ]
  },
  {
    path: '/registrer',
    name: 'register',
    component: UserRegisterView,
    meta: { banner: false },
  },
  {
    path: '/aktiver',
    name: 'activate',
    component: UserActivateView,
    meta: { banner: false },
  },
]

const contentRoutes: ContentRoute[] = [
  {
    path: '/innhold/presentasjon/:locationId(\\d+)-:slug([^/]+)',
    alias: '/innhold/presentasjon/:locationId(\\d+):slug($)',
    name: 'presentation',
    meta: { hideAppFooter: true, hideAppHeader: true, hideWizard: true, preview: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId),
    }),
    component: PresentationView,
  },
  {
    path: '/innhold/presentasjon/:locationId(\\d+)-:slug([^/]+)/:pageNumber(\\d+)',
    alias: '/innhold/presentasjon/:locationId(\\d+):slug(?=\\/)/:pageNumber(\\d+)',
    name: 'presentation-page',
    meta: { hideAppFooter: true, hideAppHeader: true, hideWizard: true, preview: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId),
      pageNumber: Number(params.pageNumber),
    }),
    component: PresentationView,
  },
  {
    path: '/innhold/bokleser/:locationId(\\d+)-:slug([^/]+)',
    alias: '/innhold/bokleser/:locationId(\\d+):slug($)',
    name: 'bookreader',
    component: BookReaderView,
    meta: { banner: false, hideAppHeader: true, hideAppFooter: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId)
    })
  },
  {
    path: '/innhold/bokleser/:locationId(\\d+)-:slug([^/]+)/sammendrag',
    alias: '/innhold/bokleser/:locationId(\\d+):slug(?=\\/)/sammendrag',
    name: 'bookreader_summary',
    component: BookReaderSummaryView,
    meta: { banner: false, hideAppHeader: true, hideAppFooter: true, preview: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId)
    })
  },
  {
    path: '/innhold/oppgave/:locationId(\\d+)-:slug([^/]+)',
    alias: '/innhold/oppgave/:locationId(\\d+):slug($)',
    name: 'flyt_task',
    meta: { banner: false, hideAppHeader: true, hideAppFooter: true, preview: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId),
    }),
    component: FlytTaskView,
  },
  {
    path: '/innhold/video/:locationId(\\d+)-:slug([^/]+)',
    alias: '/innhold/video/:locationId(\\d+):slug($)',
    name: 'video',
    component: VideoView,
    meta: { banner: false, hideAppHeader: true, hideAppFooter: true, preview: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId)
    })
  },
  {
    path: '/innhold/lyd/:locationId(\\d+)-:slug([^/]+)',
    alias: '/innhold/lyd/:locationId(\\d+):slug($)',
    name: 'audio',
    component: AudioView,
    meta: { banner: false, hideAppHeader: true, hideAppFooter: true, preview: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId)
    })
  },
  {
    path: '/innhold/flashcards/:locationId(\\d+)-:slug([^/]+)',
    alias: '/innhold/flashcards/:locationId(\\d+):slug($)',
    name: 'flashcards',
    component: FlashcardsView,
    meta: { banner: false, hideAppHeader: true, hideAppFooter: true, preview: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId)
    })
  },
  {
    path: '/innhold/dokument/:locationId(\\d+)-:slug([^/]+)',
    name: 'pdf',
    component: () => import('~/views/PdfView.vue'),
    meta: { banner: false, hideAppHeader: true, hideAppFooter: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId)
    })
  },
  {
    path: '/innhold/pakke/:locationId(\\d+)-:slug([^/]+)',
    alias: '/innhold/pakke/:locationId(\\d+):slug($)',
    name: 'package',
    component: PackageView,
    meta: { banner: false, hideAppHeader: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId)
    })
  },
  {
    path: '/innhold/produkt/:productLocationId(\\d+)-:productSlug([^/]+)/pakke/:locationId(\\d+)-:slug([^/]+)',
    alias: '/innhold/produkt/:productLocationId(\\d+):productSlug($)/pakke/:locationId(\\d+):slug($)',
    name: 'product_package',
    component: PackageView,
    meta: { banner: false, hideAppHeader: true },
    props: ({ params }) => ({
      locationId: Number(params.locationId),
    })
  },
]

const routes: RouteRecordRaw[] = [
  ...redirectRoutes,
  ...navigationRoutes.map((view) => ({ ...view, meta: { ...view.meta, isNavigation: true } })),
  ...contentRoutes,
  {
    path: '/:path(.*)*',
    name: 'generic',
    component: GenericView,
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      to.params.returnPath = from.fullPath
      next()
    },
    meta: { banner: false },
  }
]

const routeSavedHeight = new Map<string | symbol, number>()

function updateScroll(params: Parameters<RouterScrollBehavior>, attempts = 0): Promise<ReturnType<RouterScrollBehavior>> {
  const [to, _, scrollPosition] = params
  const height = (to.name && routeSavedHeight.get(to.name)) || 0

  return new Promise((resolve) => {
    if (height === 0) return scrollPosition

    if (attempts < 50) {
      if (height === document.body.scrollHeight) resolve(scrollPosition ?? { top: 0 })
      else setTimeout(() => resolve(updateScroll(params, ++attempts)), 100)
    }

    else {
      resolve(scrollPosition ?? { top: 0 })
    }
  })
}

const router = createRouter({
  history: createWebHistory(),
  async scrollBehavior(to, from, scrollPosition) {
    if (scrollPosition?.top || scrollPosition?.left) {
      return await updateScroll([to, from, scrollPosition])
    }

    return { top: 0 }
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  const { isAuthenticated, acquireSession } = useAuthStore()

  if (from.name && (from.name !== to.name)) {
    routeSavedHeight.set(from.name, document.body.scrollHeight)
  }

  if (['login', 'register', 'activate'].includes(to.name as string)) {
    await clearApiCache()
    return next()
  }

  if (from.meta.isNavigation) { // Set new returnPath to a navigation view
    to.meta.returnPath = from.fullPath
  } else if (from.meta.returnPath) { // Keep previous returnPath
    to.meta.returnPath = from.meta.returnPath
  }

  try {
    if (!isAuthenticated) await acquireSession(router)
    next()
  } catch (error) {
    await clearApiCache()
    if (to.meta.preview) {
      return next()
    }
    next({
      name: 'login',
      query: { redirectUri: window.location.toString() },
    })
  }
})

router.afterEach((to) => {
  if (to.params.locationId) {
    const { reportProgress } = useActivity()
    reportProgress(Number(to.params.locationId))
  }
})

// TODO: Remove this export, as it will lead to circular imports, for example this:
// router/index.ts -> components/PlannedResourcesView.vue -> stores/planner.ts ->
// composables/useUrlBuilder.ts -> router/index.ts -> components/PlannedResourcesView.vue
export default router
