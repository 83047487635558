export default () => {
  const getErrorMessage = (error: unknown) => {
    if (error instanceof Error) return error.message
    return String(error)
  }

  return {
    getErrorMessage,
  }
}
