<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { TaskProgress } from '~/models/Activity'
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsCallout } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import { waitFor } from '~/utils/asyncUtils'
import useWeekStore from '~/stores/week'
import useSubjectsStore from '~/stores/subjects'
import usePlannerStore from '~/stores/planner'
import useGroupsStore from '~/stores/groups'
import useAuthStore from '~/stores/auth'
import useActivityStore from '~/stores/activity'
import { QueryParamKey } from '~/models/QueryParamKeys'
import { ContentType } from '~/models/Content/ContentType'
import { useSchoolYear } from '~/composables/useSchoolYear'
import { useContentHelper } from '~/composables/useContentHelper'
import useQueryParams from '~/composables/queryParams'
import WeekNavigator from '~/components/week/WeekNavigator.vue'
import StudentActivitySkeleton from '~/components/skeletons/StudentActivitySkeleton.vue'
import SubjectDropdown from '~/components/PlannedResources/SubjectDropdown.vue'
import TaskProgressLine from '~/components/activity/TaskProgressLine.vue'

const queryParams = useQueryParams<{
  [QueryParamKey.Subject]: SubjectCode
}>(QueryParamKey.PlannedResources)

const { t } = useI18n()
const { currentSchoolYearCode } = useSchoolYear()
const { isLearningPath } = useContentHelper()
const groupsStore = useGroupsStore()
const { loadGroupMembers, hasGroupMembers, hasStudents } = groupsStore
const { activeGroupStudents } = storeToRefs(groupsStore)
const { activeUserGroup, isTeacher, hasOrganization, userSubjectsByGrades } = storeToRefs(useAuthStore())
const { selectedWeekNumber, selectedWeekStart, selectedWeekEnd } = storeToRefs(useWeekStore())
const { getYearPlansBySchoolYear, findWeeksWithResourceInSelectedYearPlan } = usePlannerStore()
const subjectsStore = useSubjectsStore()
const { setCurrentSubject } = subjectsStore
const { currentSubject } = storeToRefs(subjectsStore)
const ksToast = <Toast>inject('ksToast')

const taskProgress = ref<TaskProgress[]>([])
const isLoadingYearPlans = ref(false)
const isLoadingActivities = ref(false)
const isLoading = computed(() => isLoadingYearPlans.value || isLoadingActivities.value)

const fromDate = computed(() => selectedWeekStart.value?.toFormat('yyyy-MM-dd'))
const toDate = computed(() => selectedWeekEnd.value?.toFormat('yyyy-MM-dd'))
const plannedActivities = computed(() => taskProgress.value.filter((activity) => findWeeksWithResourceInSelectedYearPlan(activity).length > 0))
const unplannedActivities = computed(() => taskProgress.value.filter((activity) => findWeeksWithResourceInSelectedYearPlan(activity).length === 0))

const loadActivitiesForTeacher = async (): Promise<TaskProgress[]> => {
  if (!activeUserGroup.value) return []
  const { loadSimpleActivitiesByDates } = useActivityStore()
  if (!isTeacher.value) throw new Error('Not a teacher, but tried using teacher only function')
  if (!activeUserGroup.value) await waitFor(() => !!activeUserGroup.value)
  if (!activeUserGroup.value) throw new Error('No active user group')

  // Make sure to wait for year plans, so we can set the correct status on the activities, and separate them into different lists based on if they are planned this week or not
  if (isLoadingYearPlans.value) await waitFor(() => !isLoadingYearPlans.value, 500)

  if (!hasGroupMembers(activeUserGroup.value)) await loadGroupMembers(activeUserGroup.value)

  if (!hasStudents(activeUserGroup.value)) {
    ksToast.info(t('activity.groupHasNoStudents'))
    return []
  }

  const subject =  currentSubject.value || activeUserGroup.value.subjects[0] || userSubjectsByGrades.value[0] || ''

  if (!fromDate.value || !toDate.value) return []

  return loadSimpleActivitiesByDates([subject], fromDate.value, toDate.value)
}

const loadActivities = async () => {
  const { enrichActivitiesWithSearch } = useActivityStore()
  try {
    if (isLoadingActivities.value) return
    taskProgress.value = []
    isLoadingActivities.value = true

    taskProgress.value = (await loadActivitiesForTeacher())
      .filter((activity) => activity.contentTypeIdentifier !== ContentType.Product)
      .filter((activity, _, activities) => {
        if (activity.learningPathPartsCount > 0 && isLearningPath(activity)) {
          return true // We always want to show learning paths with activity
        }

        return !activities.filter((a) => a.locationId !== activity.locationId)
          .find((a) => a.pathArray.includes(activity.locationId) || activity.pathArray.includes(a.locationId))
      })

    isLoadingActivities.value = false
    if (taskProgress.value.length === 0) return taskProgress.value

    taskProgress.value = await enrichActivitiesWithSearch(taskProgress.value)
  } catch (error) {
    ksToast.error(t('activity.errorLoadingActivities'))
    console.error(error)
  }
}

watch(() => selectedWeekNumber.value, loadActivities)
watch(() => activeUserGroup.value, () => {
  currentSubject.value = activeUserGroup.value?.subjects[0] || currentSubject.value || ''
})
watch(() => [activeUserGroup.value, currentSubject.value], loadActivities)

const subjectInUrl = computed(() => queryParams.get()[QueryParamKey.Subject])

onMounted(async () => {
  if (subjectInUrl.value) return setCurrentSubject(subjectInUrl.value)
  isLoadingYearPlans.value = true
  getYearPlansBySchoolYear(currentSchoolYearCode).then(() => isLoadingYearPlans.value = false)
  loadActivities()
})

setTitle(t('activity.pageTitle'))

</script>
<template>
  <div class="mt-8 flex flex-wrap gap-16">
    <SubjectDropdown
      :subjects="userSubjectsByGrades"
      @change-subject="loadActivities"
    />
    <WeekNavigator />
  </div>
  <StudentActivitySkeleton v-if="isLoading" />
  <template v-else>
    <KsCallout
      v-if="!activeUserGroup && hasOrganization"
      variant="info"
      :title="t('groups.noGroup')"
      class="mt-8"
    >
      {{ t('groups.chooseGroup') }}
    </KsCallout>
    <KsCallout
      v-else-if="activeUserGroup && taskProgress.length === 0"
      variant="info"
      :title="t('activity.noActivities.title')"
      class="mt-8"
    >
      {{ t('activity.noActivities.body') }}
    </KsCallout>
    <div
      v-if="plannedActivities.length > 0"
      :key="`${currentSubject}${activeUserGroup?.groupId}`"
    >
      <h2
        class="pb-1 pt-5 text-2xl font-medium"
        v-text="t('activity.planned')"
      />
      <TaskProgressLine
        v-for="activity in plannedActivities"
        :key="activity.locationId"
        :activity="activity"
        :students="activeGroupStudents"
        :from-date="fromDate || ''"
        :to-date="toDate || ''"
      />
    </div>
    <div v-if="unplannedActivities.length > 0">
      <div class="flex">
        <h2
          class="pb-1 pt-5 text-2xl font-medium"
          v-text="t('activity.notPlannedActivities')"
        />
        <KsButton
          v-ks-tooltip="t('activity.notPlannedInfo')"
          icon-left="info"
          class="ml-2"
          shape="round"
        />
      </div>
      <TaskProgressLine
        v-for="activity in unplannedActivities"
        :key="activity.locationId"
        :activity="activity"
        :students="activeGroupStudents"
        :from-date="fromDate || ''"
        :to-date="toDate || ''"
      />
    </div>
  </template>
</template>
