<script setup lang="ts">
import type { RegisterUser } from '~/models/User/Register'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import axios from 'axios'
import { useAuthStore } from '~/stores/auth'
import useSelfServiceApi from '~/api/selfServiceApi'
import RegisterSuccess from '~/components/register/RegisterSuccess.vue'
import RegisterForm from '~/components/register/RegisterForm.vue'
import FlowHeader from '~/components/FlowHeader.vue'

const router = useRouter()
const { registerUser } = useSelfServiceApi()

const authStore = useAuthStore()
const { isAuthenticated } = storeToRefs(authStore)
const { acquireSession } = authStore

const viewMode = ref<'register'|'success'>('register')
const isLoading = ref(false)
const errorStatus = ref<number>(0)

const input = ref<RegisterUser>({
  firstName: '',
  lastName: '',
  email: '',
  terms: false
})

async function submitForm(user: RegisterUser) {
  input.value.firstName = user.firstName.replace(/<[^>]*>?/gm, '').trim()
  input.value.lastName = user.lastName.replace(/<[^>]*>?/gm, '').trim()
  input.value.email = user.email.trim()
  isLoading.value = true
  errorStatus.value = 0
  try {
    await registerUser({
      firstName: input.value.firstName,
      lastName: input.value.lastName,
      email: input.value.email,
      username: input.value.email
    })
    viewMode.value = 'success'
  } catch (error) {
    errorStatus.value = (axios.isAxiosError(error) && error.response)
      ? error.response.status
      : 500
    throw error
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  if (!isAuthenticated.value) acquireSession()
})
</script>
<template>
  <div class="flex h-full flex-col justify-between">
    <FlowHeader v-if="!isAuthenticated" />
    <section class="m-auto max-w-screen-sm px-4 sm:px-8">
      <RegisterSuccess
        v-if="viewMode === 'success'"
        :user="input"
      />
      <RegisterForm
        v-if="viewMode === 'register'"
        v-model="input"
        :is-loading="isLoading"
        :error-status="errorStatus"
        @register="submitForm(input)"
        @cancel="router.push({ name: 'login' })"
      />
    </section>
  </div>
</template>
