import type { LanguageCode } from '~/models/Content/BaseField'
import { PreferredLanguage } from '~/models/User/PreferredLanguage'

export default () => {
  const languageMap: Record<PreferredLanguage, LanguageCode> = {
    [PreferredLanguage.Bokmal]: 'nor-NO',
    [PreferredLanguage.Nynorsk]: 'nno-NO',
  }

  const reverseMap: Record<LanguageCode, PreferredLanguage> = {
    'nor-NO': PreferredLanguage.Bokmal,
    'nno-NO': PreferredLanguage.Nynorsk
  }

  return {
    languageMap,
    reverseMap,
  }
}
