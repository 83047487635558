<script setup lang="ts">
import { QueryParamKey } from '~/models/QueryParamKeys'
import { computed, onMounted, ref, watch } from 'vue'
import useSchoolYear from '~/composables/useSchoolYear'
import useTime from '~/composables/useTime'
import useQueryParams from '~/composables/queryParams'
import { KsButton } from '@aschehoug/kloss'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import { useI18n } from 'vue-i18n'
import useWeekStore from '~/stores/week'
import { debounce } from '~/utils/functionUtils'

const emit = defineEmits(['changeWeek'])

const { t } = useI18n()
const { isTeacher } = storeToRefs(useAuthStore())
const { currentSchoolYearInterval } = useSchoolYear()
const { selectedWeekNumber } = storeToRefs(useWeekStore())
const { currentWeekNumber, getWeekNumbersInInterval } = useTime()

const currentUrl = ref(window.location.href)
const queryParams = useQueryParams<{
  [QueryParamKey.WeekNumber]: number
}>(QueryParamKey.PlannedResources)

const getWeekIndexFromQueryParam = () =>
  weeks.findIndex((week) => week === (queryParams.get()[QueryParamKey.WeekNumber] || currentWeekNumber))

const weeks = getWeekNumbersInInterval(currentSchoolYearInterval)
const weekIndex = ref(getWeekIndexFromQueryParam())

const weekNumber = computed(() => weeks[weekIndex.value])

const nextWeekButtonIsDisabled = computed(() => {
  if (!isTeacher.value) {
    return weekIndex.value >= weeks.length - 1 || (
      weeks[weekIndex.value] >= currentWeekNumber + 1
      && weekIndex.value >= weeks.findIndex((week) => week === currentWeekNumber)
    )
  }
  return weekIndex.value >= weeks.length - 1
})

const previousWeekButtonIsDisabled = computed(() => weekIndex.value <= 0)

const thisWeekButtonIsDisabled = computed(() =>
  currentWeekNumber === weeks[weekIndex.value])

const goToPreviousWeek = () => {
  if (weekIndex.value <= 0) return
  weekIndex.value = weekIndex.value - 1
}

const goToNextWeek = () => {
  if (weekIndex.value >= weeks.length - 1) return
  weekIndex.value = weekIndex.value + 1
}

const jumpToThisWeek = () => {
  weekIndex.value = weeks.findIndex(week => week === currentWeekNumber)
}

const debouncedSetWeekQueryParam = debounce(async (value: number) => {
  await queryParams.set({
    ...queryParams.get(),
    [QueryParamKey.WeekNumber]: value,
  }, { updateHistory: value !== currentWeekNumber }) // this is when a user navigates weeks
  currentUrl.value = window.location.href
}, 500)

watch([weekNumber, weekIndex], () => {
  selectedWeekNumber.value = weekNumber.value
  debouncedSetWeekQueryParam(weekNumber.value)
  emit('changeWeek')
})

onMounted(() => {
  selectedWeekNumber.value = weekNumber.value
  debouncedSetWeekQueryParam(weekNumber.value)
})
</script>

<template>
  <div class="flex items-center gap-4">
    <KsButton
      v-ks-tooltip.down="t('filters.values.week.previous')"
      shape="round"
      variant="secondary"
      icon-left="arrow-left"
      :disabled="previousWeekButtonIsDisabled"
      @click="goToPreviousWeek"
    />
    <span
      class="text-lg font-medium"
      v-text="t('time.weekWithNumber', { number: weekNumber})"
    />
    <KsButton
      v-ks-tooltip.down="t('filters.values.week.next')"
      shape="round"
      variant="secondary"
      icon-left="arrow-right"
      :disabled="nextWeekButtonIsDisabled"
      @click="goToNextWeek"
    />
    <KsButton
      variant="secondary"
      shape="rounded"
      :disabled="thisWeekButtonIsDisabled"
      @click="jumpToThisWeek"
    >
      {{ t('filters.values.thisWeek') }}
    </KsButton>
  </div>
</template>
