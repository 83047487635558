import type { BaseItem } from '~/models/Content/BaseItem'
import { useI18n } from 'vue-i18n'

export default () => {
  const { t } = useI18n()

  const getDescriptor = (content: { activity?: string } & BaseItem): string => {
    if (content.activity) return t(`activities.${content.activity}`.toLowerCase(), content.activity)
    if (content.label) return t(`labels.${content.label}`)
    return t(`contentTypes.${content.contentTypeIdentifier}`)
  }

  return {
    getDescriptor,
  }
}
