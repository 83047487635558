import type { GradeCode } from '~/models/Grade'
import type { SubjectCode, SubjectMetadata } from '~/models/Subject'
import {
  GRADE_ADULT,
  GRADE_PREP,
  upperSecondaryGrades,
  lowerGrades,
  higherGrades,
  combinedGrades,
} from './gradeSorter'

const subjectMetadata: SubjectMetadata[] = [
  { subject: 'ENG', grades: combinedGrades },
  { subject: 'FSP', grades: higherGrades },
  { subject: 'KRO', grades: combinedGrades },
  { subject: 'MAT', grades: combinedGrades.concat([GRADE_PREP]) },
  { subject: 'NAT', grades: combinedGrades  },
  { subject: 'NOR', grades: combinedGrades.concat([GRADE_ADULT]) },
  { subject: 'SAF', grades: lowerGrades },
  { subject: 'FYS', grades: upperSecondaryGrades.concat([GRADE_PREP]) },
  { subject: 'GEO', grades: upperSecondaryGrades },
  { subject: 'GFG', grades: upperSecondaryGrades },
  { subject: 'HEL', grades: upperSecondaryGrades },
  { subject: 'HIS', grades: upperSecondaryGrades },
  { subject: 'KJE', grades: upperSecondaryGrades },
  { subject: 'MED', grades: upperSecondaryGrades },
  { subject: 'POL', grades: upperSecondaryGrades },
  { subject: 'PSY', grades: upperSecondaryGrades },
  { subject: 'REL', grades: upperSecondaryGrades },
  { subject: 'KRL', grades: lowerGrades },
  { subject: 'TVE', grades: combinedGrades },
  { subject: 'FLE', grades: combinedGrades },
  { subject: 'INF', grades: upperSecondaryGrades },
  { subject: 'SPA', grades: higherGrades },
  { subject: 'TYS', grades: higherGrades },
  { subject: 'FRA', grades: higherGrades },
  { subject: 'KHV', grades: lowerGrades },
  { subject: 'MUS', grades: lowerGrades },
  { subject: 'MHE', grades: lowerGrades },
  { subject: 'SAK', grades: upperSecondaryGrades },
  { subject: 'SOK', grades: upperSecondaryGrades },
  { subject: 'SAG', grades: upperSecondaryGrades },
  { subject: 'SOS', grades: upperSecondaryGrades },
  { subject: 'POM', grades: upperSecondaryGrades },
]

export const findSubjectsByGrade = (grade: GradeCode): SubjectCode[] => {
  return subjectMetadata
    .filter((metadata) => metadata.grades.some((code) => grade === code))
    .map((metadata) => metadata.subject)
}

export const findGradesBySubject = (subject: SubjectCode): GradeCode[] => {
  return subjectMetadata
    .find((metadata) => metadata.subject === subject)?.grades || []
}
