<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { KsDropdown } from '@aschehoug/kloss'
import { computed, onUnmounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import arrayUtils from '~/utils/arrayUtils'
import useFilterStore from '~/stores/filter'

const { t } = useI18n()
const { truthy, unique } = arrayUtils()
const filterStore = useFilterStore()
const { selectedTaskType, contentFilters, selectedHeaders, productHeaders } = storeToRefs(filterStore)

const resources = computed<BaseItem[]>(() => {
  if (selectedHeaders.value.length === 0) return [
    ...productHeaders.value.learningPath,
    ...(productHeaders.value?.forTeacher ?? []),
  ]
  return selectedHeaders.value[selectedHeaders.value.length - 1].children
})
const { gradeFilterFunc  } = contentFilters.value

const taskTypes = computed(() => resources.value
  .filter(gradeFilterFunc)
  .map((resource) => resource.contentTypeIdentifier)
  .filter(unique).filter(truthy))

watch(taskTypes, () => {
  if (!selectedTaskType.value) return
  if (!taskTypes.value.includes(selectedTaskType.value)) {
    selectedTaskType.value = ''
  }
}, { deep: true })

onUnmounted(() => selectedTaskType.value = '')

function getTaskTypeLabel(taskType: string) {
  if (!taskType) return t('contentTypes.default')
  return t(`contentTypes.${taskType}`)
}

const hasTaskTypes = computed(() => taskTypes.value.length > 0)

</script>

<template>
  <KsDropdown
    v-if="hasTaskTypes"
    v-model="selectedTaskType"
    :options="taskTypes"
    :placeholder="t('filters.chooseTaskType')"
    :option-label="getTaskTypeLabel"
    :option-id="(taskType: string) => taskType"
    :multiple="false"
    shape="rounded"
    autolabel
    flexible
    resettable
  />
</template>
