import type { Media } from '~/models/Content/Media'
import type { ContentFlashcard, ContentFlashcardDeck } from '~/models/Content/ContentFlashcards'
import type { ContentAudio } from '~/models/Content/ContentAudio'
import { ref } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import useContentApi from '~/api/contentApi'

export function useFlashcardsQuery(locationId: number) {
  const { findContents } = useContentApi()
  const images = ref<Map<number, Media>>(new Map())
  const audioFiles = ref<Map<number, ContentAudio>>(new Map())

  const fetchLocation = async () => {
    const [location] = await findContents<ContentFlashcardDeck>({
      contentTypeCriterion: [ContentType.Flashcards],
      subtreeCriterion: [Subtree.Content],
      locationIdCriterion: [locationId],
    }, 1)
    return location
  }

  const fetchCards = async (location: ContentFlashcardDeck) => await findContents<ContentFlashcard>({
    contentTypeCriterion: [ContentType.Flashcard],
    subtreeCriterion: [Subtree.Content],
    contentIdCriterion: location.cards.destinationContentIds,
    mainLocationCriterion: true,
  })

  const fetchMedia = async (cards: ContentFlashcard[]) => {
    for (const card of cards) {
      if (card.imageFront?.destinationContentId) {
        const contentId = Number(card.imageFront.destinationContentId)
        const [image] = await findContents<Media>({
          subtreeCriterion: [Subtree.Content, Subtree.Media],
          contentIdCriterion: [contentId],
          mainLocationCriterion: true,
        })
        images.value.set(contentId, image)
      }

      if (card.audioFront?.destinationContentId) {
        const contentId = Number(card.audioFront.destinationContentId)
        const [audioContent] = await findContents<ContentAudio>({
          subtreeCriterion: [Subtree.Content, Subtree.Media],
          contentTypeCriterion: [ContentType.Audio],
          contentIdCriterion: [contentId],
          mainLocationCriterion: true,
        })
        audioFiles.value.set(contentId, audioContent)
      }
    }
  }

  const { data, isLoading } = useQuery({
    queryKey: ['flashcard-deck', locationId],
    enabled: !!locationId,
    queryFn: async () => {
      const location = await fetchLocation()
      if (location.cards.destinationContentIds.length === 0) {
        return { location, cards: [] }
      }
      const cards = await fetchCards(location)
      await fetchMedia(cards)
      return { location, cards }
    },
  })

  return {
    data,
    isLoading,
    images,
    audioFiles,
  }
}
