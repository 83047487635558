<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import { ColorName } from '~/models/Content/Color'
import { useAppColor } from '~/composables/useAppColor'
import UpperPrimaryBanner from '~/components/home/upperPrimary/UpperPrimaryBanner.vue'
import BlogPostSection from '~/components/blog/BlogPostSection.vue'

useAppColor(ColorName.Seagreen50)
const { t } = useI18n()
const { isTeacher } = storeToRefs(useAuthStore())
</script>

<template>
  <div class="flex h-full flex-col">
    <h1
      class="sr-only"
      v-text="t('home.title')"
    />
    <UpperPrimaryBanner />
    <div
      v-if="isTeacher"
      class="grow bg-[#f6f9f9]"
    >
      <BlogPostSection :limit="3" />
    </div>
  </div>
</template>
