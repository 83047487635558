import type { ContentProduct } from '~/models/Content/ContentProduct'
import type { Product } from '~/models/Product'
import { LicenseModel } from '~/models/Product'
import useContentApi from '~/api/contentApi'
import useArrayUtils from '~/utils/arrayUtils'

export default () => {
  const { findContents } = useContentApi()
  const { truthy } = useArrayUtils()

  const mapProduct = async (content: ContentProduct): Promise<Product> => {
    const relationsIds = [
      ...[content.flexibleContentRelation && Number(content.flexibleContentRelation.destinationContentIds[0])],
      ...[content.learningPathRelation && Number(content.learningPathRelation.destinationContentIds[0])],
      ...[content.teacherContentRelation && Number(content.teacherContentRelation.destinationContentIds[0])],
      ...[content.interdisciplinaryContentRelation && Number(content.interdisciplinaryContentRelation.destinationContentId)],
      ...[content.colophonContentRelation && Number(content.colophonContentRelation.destinationContentId)]
    ].filter(truthy<number>)

    const relatedContent = await findContents({
      contentIdCriterion: relationsIds,
      mainLocationCriterion: true,
    }, relationsIds.length)

    return {
      name: content.title,
      ean: content.ean,
      grades: content.grades,
      subjects: content.subjects,
      site: 'AUNIVERS',
      productType: 'COMMON',
      licenseModel: LicenseModel.School,
      aunivers: {
        locationId: content.locationId,
        name: content.title,
        remoteId: content.remoteId,
        productVariation: content.variation,
        pathString: content.pathString,
        relatedLocations: {
          flexibleContentLocationId: relatedContent
            .find(({ contentId }) => contentId === content.flexibleContentRelation?.destinationContentIds[0])?.locationId,
          learningPathsLocationId: relatedContent
            .find(({ contentId }) => contentId === content.learningPathRelation?.destinationContentIds[0])?.locationId,
          teacherContentLocationId: relatedContent
            .find(({ contentId }) => contentId === content.teacherContentRelation?.destinationContentIds[0])?.locationId,
          interdisciplinaryContentLocationId: relatedContent
            .find(({ contentId }) => contentId === content.interdisciplinaryContentRelation?.destinationContentId)?.locationId,
          colophonLocationId: relatedContent
            .find(({ contentId }) => contentId === content.colophonContentRelation?.destinationContentId)?.locationId,
        }
      }
    }
  }

  return {
    mapProduct,
  }
}
