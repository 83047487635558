<script lang="ts" setup>
import type { ContentSectionExpanded } from '~/models/Content/ContentSectionExpanded'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsCarousel, KsCarouselItem } from '@aschehoug/kloss'
import arrayUtils from '~/utils/arrayUtils'
import useAuthStore from '~/stores/auth'
import useColors from '~/composables/colors'
import useContentApi from '~/api/contentApi'

const { section } = defineProps<{ section: ContentSectionExpanded }>()

const { findContents } = useContentApi()
const { truthy, empty, intersect } = arrayUtils()
const { userRelevantGrades } = storeToRefs(useAuthStore())

const backgroundElement = ref()

const { backgroundStyle, needsDarkText } = useColors({
  identifier: section.locationId!,
  backgroundColor: section.color[0],
  backgroundFormatter: (color) => `linear-gradient(180deg, ${color}, ${color}E6)`,
  elementRef: backgroundElement,
})

const textStyle = computed(() => needsDarkText.value ? 'text-black' : 'text-white')

const filteredContent = computed(() => (data.value ?? [])
  .filter(({ grades }) => empty(grades) || !empty(intersect(grades, userRelevantGrades.value)))
)

const { data } = useQuery({
  staleTime: Infinity,
  enabled: Number(section.resources?.destinationContentIds?.length) > 0,
  queryKey: ['section-expanded', section.contentId],
  queryFn: async () => {
    const relations = await findContents({
      contentIdCriterion: section.resources.destinationContentIds,
      mainLocationCriterion: true,
    }, section.resources.destinationContentIds.length)

    return section.resources.destinationContentIds
      .map((id) => relations.find(({ contentId }) => contentId === id))
      .filter(truthy<BaseItem>)
  }
})
</script>

<template>
  <section
    v-if="filteredContent.length"
    ref="backgroundElement"
    class="overflow-hidden py-8 sm:py-14"
    :style="backgroundStyle"
  >
    <div
      class="m-auto max-w-screen-au px-4 sm:px-8"
      :class="textStyle"
    >
      <h2
        class="mb-2 text-center text-3xl font-bold sm:text-4xl"
        v-text="section.title"
      />
      <p
        v-if="section.description"
        class="m-auto mb-2 max-w-prose text-center text-base"
        v-html="section.description"
      />
      <KsCarousel>
        <KsCarouselItem
          v-for="(item, i) in filteredContent"
          :key="i"
        >
          <slot
            name="resource"
            :resource-item="item"
          />
        </KsCarouselItem>
      </KsCarousel>
    </div>
  </section>
</template>
