import type { Plugin } from '~/models/Plugin'
import * as Sentry from '@sentry/vue'

export const install: Plugin['fn'] = (app) => {
  if (!['test', 'stage', 'prod'].includes(import.meta.env.VITE_ENV)) {
    return
  }

  Sentry.init({
    app,
    dsn:  import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_GIT_SHA,
    environment: import.meta.env.VITE_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/(api|innhold|admin)\.aunivers\.no/,
      /^https:\/\/(app|web)\.(local|test|stage)\.aunivers\.cloud/,
    ],
    tracesSampleRate: 0.1,
    logErrors: true,
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications.',
      'Failed to fetch',
      'Request aborted',
      'Network Error',
      'Request failed with status code 400',
      'Request failed with status code 403',
      'undefined',
      'ResizeObserver loop limit exceeded',
      'The operation is insecure.',
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ]
  })
}
