<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { ContentType } from '~/models/Content/ContentType'
import type { ColorTheme } from '~/models/Content/Color'
import { computed } from 'vue'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { useQuery } from '@tanstack/vue-query'
import useContentApi from '~/api/contentApi'
import { useContentHelper } from '~/composables/useContentHelper'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import SimpleCard from '~/components/cards/SimpleCard.vue'
import { colorMap } from '~/utils/colors'
import useColorPair from '~/composables/useColorPair'

const { findContents } = useContentApi()
const { getColorTheme } = useColorPair()
const { packageTypes } = useContentHelper()

const props = defineProps<{
  header: BaseItem
  showTitle?: boolean
}>()

const { data, isLoading } = useQuery({
  staleTime: Infinity,
  queryKey: ['teacher-header', props.header.contentId],
  queryFn: async () => {
    const children = await findContents<BaseItem>({
      parentLocationIdCriterion: [props.header.mainLocationId],
      sortField: props.header.sortField,
      sortOrder: props.header.sortOrder,
    })

    const headers = children
      .filter(({ contentTypeIdentifier }) => packageTypes.includes(contentTypeIdentifier))
    const cards = children
      .filter(({ contentTypeIdentifier }) => !packageTypes.includes(contentTypeIdentifier))
    const isFileCards = cards
      .every(({ contentTypeIdentifier }) => contentTypeIdentifier === ContentType.File)

    return { headers, cards, isFileCards }
  },
})

function getColor(key: keyof Omit<ColorTheme, 'isDark'>) {
  const color = getColorTheme('black orange light')[key]
  return colorMap.get(color)
}

const altCardStyle = computed(() => ({
  '--card-bg': `rgb(var(${getColor('accentSecondary')?.var}))`,
  '--card-text': `rgb(var(${getColor('text')?.var}))`,
  '--card-border': `rgb(var(${getColor('accent')?.var}))`,
  '--card-accent': `rgb(var(${getColor('dark')?.var}))`,
  '--card-hover': `rgb(var(${getColor('accentHighlight')?.var}))`
}))

</script>
<template>
  <h3
    v-if="showTitle"
    v-text="props.header.title"
  />

  <RichTextRenderer
    v-if="props.header.intro"
    :text="props.header.intro"
  />

  <KsSkeletonWrapper
    v-if="isLoading"
    class="grid border border-transparent sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
  >
    <KsSkeleton
      v-for="i in 12"
      :key="`skeleton-${i}`"
      height="80px"
      background="rgb(var(--au-blue-20))"
      class="!rounded-none border border-transparent"
    />
  </KsSkeletonWrapper>
  <ul
    v-else-if="data && data.cards.length > 0"
    class="grid gap-[1px] pl-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
  >
    <li
      v-for="card in data.cards"
      :key="`card-${card.locationId}`"
      class="m-0 list-none p-0"
    >
      <SimpleCard
        :resource="card"
        :show-label="!data.isFileCards"
        :style="!data.isFileCards ? altCardStyle : null"
      />
    </li>
  </ul>

  <TeacherSubitems
    v-for="header in data.headers"
    v-if="data && data.headers.length > 0"
    :key="`header-${header.locationId}`"
    :header="header"
    :show-title="true"
  />
</template>
