<script lang="ts">
  const lastTrackIndex = new Map<any, number>()
</script>

<script setup lang="ts">
import type { AudioPage } from '~/models/Presentation/Pages/AudioPage'
import type { BasePageProps } from '~/models/Presentation/BasePage'
import type { ContentAudio } from '~/models/Content/ContentAudio'
import type { AudioTrack } from '~/models/AudioTrack'
import { computed, ref, watch } from 'vue'
import 'vidstack/bundle'
import { renderVTTCueString } from 'media-captions'
import { useQuery } from '@tanstack/vue-query'
import { KsDropdown, KsIcon } from '@aschehoug/kloss'
import { rewriteMediaURL } from '~/utils/media'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import useImage from '~/composables/useImage'
import useHiddenHeading from '~/composables/useHiddenHeading'
import { useContentHelper } from '~/composables/useContentHelper'
import useContentApi from '~/api/contentApi'
import { rdfRestClient } from '~/api/client/rdfRestClient'
import IconInputSwitch from '~/components/utils/IconInputSwitch.vue'
import PresentationRichText from '~/components/PresentationPages/Components/PresentationRichText.vue'
import TimeSlider from '~/components/media/TimeSlider.vue'
import LottiePlayer from '~/components/media/LottiePlayer.vue'
import AudioPlayer from '~/components/media/AudioPlayer.vue'

const props = defineProps<BasePageProps & { page: AudioPage }>()
const queryKey = ['audio-page', props.page.contentId, props.currentPageNumber]

const { findContents } = useContentApi()
const { isPlaylist } = useContentHelper()
const { getSvgOrImage } = useImage()
const { hasHeading } = useHiddenHeading()

const relationContentId = computed(() => props.page.metadata ? props.page.contentId : props.page.audio?.destinationContentId)

const { data } = useQuery({
  enabled: Boolean(relationContentId.value),
  queryKey,
  queryFn: async () => {
    const [content] = await findContents<ContentAudio|AudioPage>({
      subtreeCriterion: [Subtree.Content, Subtree.Media],
      contentTypeCriterion: [ContentType.Audio, ContentType.AudioPage],
      contentIdCriterion: [Number(relationContentId.value)],
      mainLocationCriterion: true,
    })

    const { data } = await rdfRestClient.get(`/resources/contentItems/${content.metadata?.contentItemId}`)
    const title: string | undefined = data
      ?.results
      ?.bindings
      ?.find(({ element }: any) => element?.value === import.meta.env.VITE_RDF_REST_PROP_TITLE)
      ?.litteral
      ?.value

    return { content, title }
  },
  staleTime: Infinity,
})

const { data: playlist, refetch } = useQuery<AudioTrack[]>({
  queryKey: ['audio-page-playlist', props.page.contentId, props.currentPageNumber],
  queryFn: async () => {
    const url = data.value?.content?.metadata?.elementURI

    if (!url) return null

    return (await rdfRestClient.get(url))
      .data
      ?.results
      ?.bindings
      ?.map((item: any) => ({
        title: item?.title?.value,
        src: rewriteMediaURL(item?.url?.value),
      }))
      ?? null
  },
})

const currentTrack = ref<AudioTrack>()

watch(data, async (value) => {
  if (!value) return

  if (isPlaylist(value.content)) {
    await refetch()
    currentTrack.value = playlist.value?.[lastTrackIndex.get(queryKey.join()) ?? 0]
  }

  else {
    currentTrack.value = {
      title: value.title,
      src: value.content?.metadata?.elementURI,
    }
  }
}, { immediate: true })

watch(currentTrack, (track) => {
  lastTrackIndex.set(queryKey.join(), playlist.value?.findIndex((track2) => track === track2) ?? 0)
})

const showTranscript = ref(false)
const transcription = ref<VTTCue[]>([])
const image = getSvgOrImage(props.page.svg, props.page.image)

const lottieId = computed( () => Number(props.page.lottie?.destinationContentId))
</script>

<template>
  <div
    class="audio-slide-grid h-full font-inter text-white"
    :style="{ background: page.colorPair.background.rgb }"
  >
    <h1
      v-if="!hasHeading"
      class="sr-only"
      v-text="page.title"
    />
    <LottiePlayer
      v-if="lottieId"
      :content-id="lottieId"
      :color-pair="page.colorPair"
    />
    <img
      v-else
      class="w-full self-stretch object-cover"
      style="grid-area: img"
      :src="image?.src"
      :alt="image?.altText"
    >

    <div
      :class="page.colorPair.isDark ? (showTranscript ? 'bg-black/60' : 'bg-black/20') : (showTranscript ? 'bg-white/60' : 'bg-white/20')"
      style="grid-area: audio / img"
    />

    <div
      class="grid max-h-full min-h-0 px-16 py-8 lg:px-36 2xl:px-48"
      :class="[page.colorPair.isDark ? 'text-white' : 'text-black', { 'overflow-y-auto': showTranscript }]"
      style="grid-area: text;"
      :style="{
        'background': page.colorPair.background.rgb,
        'grid-column-start': showTranscript ? 'img' : 'text'
      }"
    >
      <div class="grid auto-rows-max grid-cols-[minmax(0,80ch)] place-content-center">
        <template v-if="showTranscript">
          <h1 class="mb-4 pb-2 text-sm uppercase tracking-wider underline decoration-2 underline-offset-8">
            {{ $t('labels.audio_transcription') }}
          </h1>
          <div class="flex flex-col gap-1 text-2xl">
            <p
              v-for="cue in transcription"
              :key="cue.id"
              v-html="renderVTTCueString(cue)"
            />
          </div>
        </template>

        <template v-else>
          <h1
            v-if="page.header"
            class="text-pretty"
            :class="[page.textSizes.heading, page.fontWeight, page.fontFamily]"
            :style="{ color: page.colorPair.text.rgb }"
          >
            {{ page.header }}
          </h1>
          <PresentationRichText
            v-if="page.body"
            :page="page"
          />
          <KsDropdown
            v-if="data?.content && isPlaylist(data.content)"
            v-model="currentTrack"
            :options="playlist ?? []"
            :option-label="(opt: AudioTrack) => opt.title"
            placeholder="Velg spor"
            searchable
          />
        </template>
      </div>
    </div>

    <div
      class="relative flex items-center"
      :class="page.colorPair.isDark ? 'bg-black/20 text-white' : 'bg-white/20'"
      style="grid-area: audio"
      :style="{
        color: !page.colorPair.isDark && page.colorPair.text.rgb,
        '--media-color': page.colorPair.isDark ? 'currentColor' : page.colorPair.text.rgb,
        '--media-bg-color': page.colorPair.background.rgb,
      }"
    >
      <AudioPlayer
        class="grid grid-rows-3 gap-4 pl-28 pr-16 text-3xl tabular-nums lg:px-36 2xl:px-48"
        :media-id="data?.content?.metadata?.contentItemId"
        :src="currentTrack?.src"
        :title.attr="currentTrack?.title ?? '\u00A0'"
        :poster="image?.src"
        load="eager"
        @update:transcription="transcription = $event"
      >
        <template #controls>
          <media-play-button
            class="group absolute left-0 grid size-40 -translate-x-1/2 place-content-center rounded-full border border-4 bg-white text-5xl shadow-lg hover:bg-white data-[focus]:ring lg:size-52 lg:text-6xl"
            :style="{ color: page.colorPair.isDark ? page.colorPair.background.rgb : page.colorPair.text.rgb }"
          >
            <KsIcon
              icon="play"
              variant="solid"
              class="hidden translate-x-[10%] group-data-[paused]:block"
            />
            <KsIcon
              icon="pause"
              variant="solid"
              class="group-data-[paused]:hidden"
            />
          </media-play-button>

          <div
            class="flex items-center gap-8 text-clip"
            style="overflow-clip-margin: 1em"
          >
            <media-title class="flex-auto select-text overflow-hidden break-words font-semibold" />
            <IconInputSwitch
              v-if="transcription.length > 0"
              v-model="showTranscript"
              :label="$t('labels.audio_transcription')"
            >
              <KsIcon
                class="block"
                icon="text"
                :scale=".5"
              />
            </IconInputSwitch>
          </div>

          <div class="flex items-center gap-8">
            <media-time
              type="current"
              class="font-medium"
            />

            <TimeSlider />

            <media-time
              type="duration"
              class="font-medium"
            />
          </div>
        </template>
      </AudioPlayer>
    </div>
  </div>
</template>

<style scoped>
.audio-slide-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 7fr minmax(16rem, 4fr);
  grid-template-areas:
    'img text'
    'img audio';
}
</style>
