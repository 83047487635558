import type { Ref } from 'vue'
import type { SubjectCode } from '~/models/Subject'
import type { ProductHeaders } from '~/models/ProductHeaders'
import type { Product } from '~/models/Product'
import type { Grade, GradeCode } from '~/models/Grade'
import type { FiltersQueryParams } from '~/models/Filter'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import type { BaseItem } from '~/models/Content/BaseItem'
import type { ViewMode } from '~/models/Content/BaseField'
import { computed, isRef, ref, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { gradesSorted } from '~/utils/gradeSorter'
import { waitFor } from '~/utils/asyncUtils'
import arrayUtils from '~/utils/arrayUtils'
import useSubjectsStore from '~/stores/subjects'
import useProductStore from '~/stores/product'
import useLoadersStore from '~/stores/loaders'
import { useAuthStore } from '~/stores/auth'
import { QueryParamKey } from '~/models/QueryParamKeys'
import { MaxTimeoutMs } from '~/constants/api'
import useSearchHelper from '~/composables/useSearchHelper'
import { useSearchClient } from '~/composables/useSearchClient'
import useContentMapper from '~/composables/useContentMapper'
import { useContentHelper } from '~/composables/useContentHelper'
import useContentApi from '~/api/contentApi'

const LOADER_QUEUE_NAME = 'SubjectView:filteredSubjects'

const useFilterStore = defineStore('filter', () => {
  const { startLoading, stopLoading, onLoadingStarted, onLoadingFinished, isLoading } = useLoadersStore()
  const { productsWithRelatedLocations, hasProducts } = storeToRefs(useProductStore())
  const { userRelevantGrades, userPreferredGrade } = storeToRefs(useAuthStore())
  const { currentSubject } = storeToRefs(useSubjectsStore())
  const { searchPath, emptyQuery, cardFields } = useSearchHelper()
  const {
    getProductHeadersBySubjectAndGrade,
    getProductHeadersByLocationId,
    findChildren,
  } = useContentApi()

  const { isPackage, isTeacherArticle } = useContentHelper()
  const { mapContents } = useContentMapper()

  const selectedGrade = ref<GradeCode>(userPreferredGrade.value || userRelevantGrades.value[0])
  const selectedProduct = ref<Product>()
  const selectedAddonLocationId = ref<number | undefined>(undefined)

  const viewMode = ref<ViewMode>('default')

  const loading = ref(false)
  onLoadingFinished(LOADER_QUEUE_NAME, () => {
    loading.value = false
    onLoadingStarted(LOADER_QUEUE_NAME, () => loading.value = true)
  })
  onLoadingStarted(LOADER_QUEUE_NAME, () => loading.value = true)

  const selectedHeaderChildren = computed(() => {
    return (selectedHeaders.value
      .map((header) => header.children.filter(isPackage))
    ).filter((children: BaseItem[]) => children.length > 0)
  })

  const lastSelectedHeader = computed(() => selectedHeaders.value[selectedHeaders.value.length - 1])

  const setSelectedGrade = (newGrade?: GradeCode) => {
    if (!newGrade) return
    selectedGrade.value = newGrade
  }

  const setSelectedAddonLocationId = (newAddonLocation: number|undefined) => {
    selectedAddonLocationId.value = newAddonLocation
  }

  const setSelectedProduct = (newProduct?: Product | string): void => {
    if (!newProduct) {
      selectedProduct.value = undefined
      return
    }
    if (!hasProducts.value) {
      setTimeout(() => setSelectedProduct(newProduct), 100)
      return
    }
    if (typeof newProduct === 'string') {
      return setSelectedProduct(productsWithRelatedLocations.value
        .find(({ ean }) => ean === newProduct))
    }
    selectedProduct.value = newProduct
  }

  const selectedHeaders = ref<ContentProductHeader[]>([])
  const productHeaders = ref<ProductHeaders>({
    learningPath: [],
    flexibleContent: [],
    colophons: [],
    forTeacher: [],
  })

  const packageHeader = ref<BaseItem>()

  // finds the intersection between headers and selectedHeaders and returns true if there is an intersection
  const selectedHeadersContains = (headers: BaseItem[]) => headers
    .some((header) => selectedHeaders.value
      .some((selectedHeader) => selectedHeader.mainLocationId === header.mainLocationId))

  // safeguard to prevent race conditions in setSelectedHeader
  const setSelectedHeaderLocked = ref(false)
  const setSelectedHeader = (newHeaders: BaseItem[]) => {
    if (!selectedHeaders.value.length) {
      selectedHeaders.value = newHeaders
      return
    }
    if (setSelectedHeaderLocked.value) return
    setSelectedHeaderLocked.value = true
    if (!newHeaders || newHeaders.length === 0) {
      selectedHeaders.value = []
      setSelectedHeaderLocked.value = false
      return
    }

    if (selectedHeadersContains(newHeaders)) {
      setSelectedHeaderLocked.value = false
      return
    }

    // We use a reduce to search for the header with the deepest pathString
    const youngestDescendant = newHeaders.reduce((searchResult, header) => {
      if (!searchResult) return header
      if (searchResult.pathString.split('/').length > header.pathString.split('/').length) {
        return searchResult
      }
      return header
    })

    selectedHeaders.value = [ ...selectedHeaders.value,...newHeaders ]
      .filter(({ locationId }) => youngestDescendant.pathString.includes(`/${locationId}/`))
      .filter(gradeFilterFunc)

    setSelectedHeaderLocked.value = false
  }

  const removeSelectedHeader = (header: BaseItem) => {
    const headerIndex = selectedHeaders.value.findIndex(({ mainLocationId }) => mainLocationId === header.mainLocationId)
    if (headerIndex === -1) return
    selectedHeaders.value = selectedHeaders.value.filter((h, i) => i < headerIndex)
  }

  const selectedTaskType = ref<string>()

  const setSelectedTaskType = (newTaskType: string) => selectedTaskType.value = newTaskType

  const filterWatchers = () => ({
    stopFilterWatchers() {
      this.subjectAndGradeWatcher()
      this.selectedHeadersWatcher()
      this.addonLocationIdWatcher()
    },

    addonLocationIdWatcher: watch(selectedAddonLocationId, () => {
      if (selectedAddonLocationId.value) {
        getHeadersByLocationId(selectedAddonLocationId.value)
        selectedHeaders.value = selectedHeaders.value.filter(gradeFilterFunc)
        viewMode.value = findViewMode(lastSelectedHeader.value) ?? 'default'
      }
    }),

    subjectAndGradeWatcher: watch([currentSubject, selectedGrade], ([newSubject], [oldSubject]) => {
      if (currentSubject.value && selectedGrade.value) {
        getHeadersBySubjectAndGrade(currentSubject.value, selectedGrade.value)
      }
      if (newSubject !== oldSubject) {
        setSelectedHeader([])
        selectedProduct.value = undefined
      }
      selectedHeaders.value = selectedHeaders.value.filter(gradeFilterFunc)
      viewMode.value = findViewMode(lastSelectedHeader.value) ?? 'default'
    }, { immediate: true }),

    // makes sure that we always load the children the selectedHeaders
    selectedHeadersWatcher: watch(selectedHeaders, () => {
      selectedHeaders.value.forEach(async (header) => {
        viewMode.value = findViewMode(header) ?? 'default'
        if (header.children.length > 0) return
        await getHeaderChildren(header)
      })
    }),
  })

  const getHeaderChildren = async (header: Ref<BaseItem> | BaseItem) => {
    if (isRef(header)) header = header.value
    if (isLoading(LOADER_QUEUE_NAME)) {
      await waitFor(() => !isLoading(LOADER_QUEUE_NAME), MaxTimeoutMs)
    }
    if (header.children.length > 0) return // already loaded
    startLoading(LOADER_QUEUE_NAME)
    header.children = await findChildren([header.mainLocationId], [], [], header.sortField, header.sortOrder)
    stopLoading(LOADER_QUEUE_NAME)
  }

  const getMultipleHeaderChildren = async (headers: (Ref<BaseItem> | BaseItem)[], grades: GradeCode[] = []) => {
    const headerValues = headers.map(header => isRef(header) ? header.value : header)
    const headersToLoad = headerValues.filter(header => header.children.length === 0)

    if (headersToLoad.length === 0) return // All headers already loaded

    startLoading(LOADER_QUEUE_NAME)

    try {
      // Fetch children for all headers in parallel
      const childrenPromises = headersToLoad.map(header =>
        findChildren([header.mainLocationId], [], grades, header.sortField, header.sortOrder)
      )
      const childrenResults = await Promise.all(childrenPromises)

      // Assign children to each header
      headersToLoad.forEach((header, index) => {
        header.children = childrenResults[index]
      })
    } finally {
      stopLoading(LOADER_QUEUE_NAME)
    }
  }

  const getHeadersBySubjectAndGrade = async (subject: SubjectCode, grade: GradeCode): Promise<void> => {
    try {
      startLoading(LOADER_QUEUE_NAME)
      const { learningPath, flexibleContent } = await getProductHeadersBySubjectAndGrade(subject, grade)
      productHeaders.value.learningPath = learningPath
      productHeaders.value.flexibleContent = flexibleContent
    } catch (error) {
      console.warn(`Failed to get headers for ${subject} and ${grade}: ` + error)
      throw error
    } finally {
      stopLoading(LOADER_QUEUE_NAME)
    }
  }

  const getHeadersByLocationId = async (locationId: number): Promise<void> => {
    try {
      startLoading(LOADER_QUEUE_NAME)
      const { learningPath, flexibleContent } = await getProductHeadersByLocationId(locationId)
      productHeaders.value.learningPath = learningPath
      productHeaders.value.flexibleContent = flexibleContent
    } catch (error) {
      console.warn(`Failed to get headers for location ${locationId}: ` + error)
      throw error
    } finally {
      stopLoading(LOADER_QUEUE_NAME)
    }
  }

  const gradeFilterFunc = (content: BaseItem) => content.grades.length === 0
    || content.grades.includes(selectedGrade.value)

  const removeTeacherArticlesFunc = (content: BaseItem) => !isTeacherArticle(content)

  const contentFilters = computed(() => ({ gradeFilterFunc, removeTeacherArticlesFunc }))

  const initFiltersLocked = ref(false)

  const initFilters = async (filters: FiltersQueryParams) => {
    if (Object.keys(filters).length === 0) return
    if (initFiltersLocked.value) return
    initFiltersLocked.value = true

    const filterGrade = filters[QueryParamKey.Grade]
    const filterHeaders = filters[QueryParamKey.Headers]
    const { truthy } = arrayUtils()

    // set grade from filters or to the user's default grade
    setSelectedGrade(filterGrade || userPreferredGrade.value || (userRelevantGrades.value[0] || gradesSorted[0]))

    // reset selected headers and load new headers
    selectedHeaders.value = []
    if (filterHeaders && filterHeaders.length > 0) {
      try {
        startLoading(LOADER_QUEUE_NAME)

        const { fetchResults, results  } = useSearchClient<BaseItem[]>(searchPath.value, {
          transformData: (response) => mapContents(response.View.Result),
        })

        await fetchResults({
          ...emptyQuery,
          fields: [...cardFields, ...['intro', 'background_media', 'background_color', 'color_pair', 'teacher_relation', 'collapsible']],
        }, {
          locationIdCriterion: filterHeaders,
          sortField: 'priority',
          sortOrder: 'asc',
        }, 100, 0)

        const sortedHeaders = filterHeaders
          .map((headerLocationId) => (results.value || [])
          .find(({ locationId }) => locationId === headerLocationId))
          .filter(truthy<BaseItem>)

        setSelectedHeader(sortedHeaders)
      } finally {
        stopLoading(LOADER_QUEUE_NAME)
      }
    }
    setTimeout(() => initFiltersLocked.value = false, 200)
  }

  const $reset = () => {
    resetViewMode()
    //setSelectedGrade(userRelevantGrades.value[0] || gradesSorted[0])
    setSelectedHeader([])
    setSelectedProduct(undefined)
    setSelectedTaskType('')
    selectedHeaders.value = []
    packageHeader.value = undefined
    productHeaders.value = {
      learningPath: [],
      flexibleContent: [],
      colophons: [],
      forTeacher: [],
    }
  }

  const fileView = computed(() => isFileView(viewMode.value))

  const findViewMode = (content: BaseItem|ContentProductHeader): ViewMode =>
    (content as ContentProductHeader)?.viewMode ?? 'default'

  const resetViewMode = () => viewMode.value = 'default'

  const isFileView = (viewMode: ViewMode) => viewMode === 'filelist'
  const isListView = (viewMode: ViewMode) => viewMode === 'list'
  const isGridView = (viewMode: ViewMode) => viewMode === 'grid'

  return {
    filterWatchers,
    isLoading: loading,
    lastSelectedHeader,
    productHeaders,
    packageHeader,
    contentFilters,
    selectedGrade,
    selectedHeaderChildren,
    selectedHeaders,
    selectedProduct,
    selectedTaskType,
    setSelectedGrade,
    setSelectedHeader,
    setSelectedProduct,
    setSelectedTaskType,
    initFilters,
    removeSelectedHeader,
    setSelectedAddonLocationId,
    $reset,
    getHeaderChildren,
    getMultipleHeaderChildren,
    viewMode,
    isFileView,
    isListView,
    isGridView,
    fileView,
    selectedAddonLocationId,
    resetViewMode,
  }
})

export default useFilterStore
