export default () => {
  const washPage = () => {
    /**
     * This function first forces scroll to top of page for more consistent results,
     * then it removes elements that are not visible in the top half of the view
     */
    window.scrollTo(0, 0)
    const parentElement = document.getElementById('app-content')
    if (!parentElement) return
    const elements = parentElement.querySelectorAll('*')

    Array.from(elements).forEach(element => {
      observer.observe(element)
    })
  }

  const isIgnored = (element: IntersectionObserverEntry): boolean => {
    const elementIgnored = element.target.hasAttribute('paywall-overlay-ignore')
    const parentIgnored = element.target.parentElement?.hasAttribute('paywall-overlay-ignore') ?? false
    return elementIgnored || parentIgnored
  }

  const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if(isIgnored(entry)) {
          // Add attribute to allow inheritance of ignore
          entry.target.setAttribute('paywall-overlay-ignore', '')
          return
        }
        if (!entry.isIntersecting && !isIgnored(entry)) {
          entry.target.remove()
        }
      })},
    {
      root: null,
      rootMargin: '0px 0px -50% 0px',
    }
  )

  return { washPage }
}
