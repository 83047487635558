<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsCallout } from '@aschehoug/kloss'
import { sortByGradeIndex } from '~/utils/gradeSorter'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'

const { t } = useI18n()
const { setSelectedGrade } = useFilterStore()

const props = defineProps<{ contents: BaseItem[] }>()

const { userGradesByGradeType } = storeToRefs(useAuthStore())

const availableGrades = computed(() => {
  const grades = props.contents
    .flatMap((resource) => resource.grades)
    .filter((grade) => userGradesByGradeType.value.includes(grade))

  return [...new Set(grades)].sort(sortByGradeIndex)
})

const message = computed(() => {
  if (availableGrades.value.length > 0) return t('filters.filteredAway.messageWithGrades')
  return t('filters.filteredAway.messageWithoutGrades')
})
</script>
<template>
  <KsCallout
    variant="info"
    :title="t('filters.filteredAway.title')"
  >
    <p v-text="message" />

    <div
      v-if="availableGrades.length > 0"
      class="flex flex-row flex-wrap gap-4 pt-4"
    >
      <KsButton
        v-for="grade in availableGrades"
        :key="grade"
        variant="secondary"
        size="medium"
        @click="setSelectedGrade(grade)"
      >
        {{ t(`metadata.grades.${grade}`) }}
      </KsButton>
    </div>
  </KsCallout>
</template>
