<script setup lang="ts">
import SettingsGroupLine from '~/components/settings/SettingsGroups/SettingsGroupLine.vue'
import useGroupsStore from '~/stores/groups'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'

const {
  basisGroups,
  teachingGroups,
  otherGroups,
} = storeToRefs(useGroupsStore())

const { t } = useI18n()
const emit = defineEmits(['openGroup'])
</script>
<template>
  <div
    v-if="basisGroups.length"
    class="border-t border-gray-10 py-6"
  >
    <h3
      class="mb-2 text-base font-medium uppercase tracking-wide text-gray-40"
      v-text="t('groups.types.basis')"
    />
    <ul class="flex flex-col gap-4">
      <li
        v-for="group in basisGroups"
        :key="group.groupId"
      >
        <SettingsGroupLine
          :group="group"
          @open-group="emit('openGroup', group)"
        />
      </li>
    </ul>
  </div>

  <div
    v-if="teachingGroups.length"
    class="border-t border-gray-10 py-6"
  >
    <h3
      class="mb-2 text-base font-medium uppercase tracking-wide text-gray-40"
      v-text="t('groups.types.teaching')"
    />
    <ul class="flex flex-col gap-4">
      <li
        v-for="group in teachingGroups"
        :key="group.groupId"
      >
        <SettingsGroupLine
          :group="group"
          @open-group="emit('openGroup', group)"
        />
      </li>
    </ul>
  </div>

  <div class="border-t border-gray-10 py-6">
    <div class="mb-3 flex items-center justify-between">
      <h3
        class="text-base font-medium uppercase tracking-wide text-gray-40"
        v-text="t('groups.types.other')"
      />
      <KsButton
        variant="primary"
        shape="rounded"
        icon-left="plus"
        size="small"
        @click="emit('openGroup', {})"
      >
        {{ t('groups.newGroup') }}
      </KsButton>
    </div>
    <ul
      v-if="otherGroups.length"
      class="grid grid-cols-1 gap-4 md:grid-cols-2"
    >
      <li
        v-for="group in otherGroups"
        :key="group.groupId"
        class="animate-fade"
      >
        <SettingsGroupLine
          :group="group"
          @open-group="emit('openGroup', group)"
        />
      </li>
    </ul>
    <p
      v-else
      class="text-gray-40"
      v-html="t('groups.noLocalGroups')"
    />
  </div>
</template>
