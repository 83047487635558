<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton, KsIcon, KsInput } from '@aschehoug/kloss'

const props = defineProps<{
  isRefetching: boolean
  subjects: string[]
  selectedSubjects: string[]
  title?: string
}>()

const { t } = useI18n()

const emit = defineEmits(['toggleCriterion'])

const open = ref(false)

onMounted(() => {
  !props.selectedSubjects.length && (open.value = true)
})

watch(() => props.selectedSubjects.length, () => {
  open.value = !open.value
})
</script>

<template>
  <div class="rounded bg-white">
    <button
      class="group flex w-full items-center justify-between rounded px-4 py-3 focus-visible:ring"
      @click="open = !open"
    >
      <span
        class="font-bold underline-offset-4 group-hover:underline"
        v-text="title"
      />
      <KsIcon :icon="open ? 'minus' : 'plus'" />
    </button>
    <Transition
      :duration="500"
      name="nested"
    >
      <div
        v-show="open"
        class="outer mx-4 border-t border-t-gray-10 py-3"
      >
        <ul class="inner flex flex-col gap-2">
          <li
            v-for="(subject, i) in subjects"
            :key="i"
          >
            <KsButton
              type="label"
              stretch
              size="small"
            >
              <KsInput
                type="checkbox"
                :checked="selectedSubjects.includes(subject)"
                @click="emit('toggleCriterion', subject)"
              />
              <span v-text="t(`metadata.subjects.${subject}`)" />
            </KsButton>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.nested-enter-active, .nested-leave-active {
  transition: all 0.3s linear;
  max-height: 500px;
}
/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.2s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(-5px);
  opacity: 0;
  max-height: 0;
}

.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  will-change: transform, opacity;
}
/* delay enter of nested element */
.nested-enter-active .inner {
  transition-delay: 0.2s;
}

.nested-enter-from .inner {
  transform: translateY(-5px);
  opacity: 0.001;
}

.nested-leave-to .inner {
  transform: translateY(-5px);
  opacity: 0.001;
}
</style>
