<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsIcon } from '@aschehoug/kloss'
import useAuthStore from '~/stores/auth'
import useWeekStore from '~/stores/week'
import ChangeGroupButton from '~/components/group/ChangeGroupButton.vue'
import { waitFor } from '~/utils/asyncUtils'
import useGroupsStore from '~/stores/groups'

const { t } = useI18n()
const authStore = useAuthStore()
const { activeUserGroup, isTeacher } = storeToRefs(authStore)
const { selectedWeekStart, selectedWeekEnd } = storeToRefs(useWeekStore())
const groupsStore = useGroupsStore()
const { loadGroupMembers } = groupsStore
const { groupMembers } = storeToRefs(groupsStore)

const numberOfGroupMembers = computed(() =>{
  if (!activeUserGroup.value || !groupMembers.value || !groupMembers.value[activeUserGroup.value.groupId]) return 0
  return groupMembers.value[activeUserGroup.value.groupId].length
})

onMounted(async () => {
  await waitFor(() => Boolean(activeUserGroup.value), 5000)
  if (!activeUserGroup.value) return
  await loadGroupMembers(activeUserGroup.value)
})

const fromDate = computed(() => selectedWeekStart.value?.toFormat('d. LLLL, yyyy'))
const toDate = computed(() => selectedWeekEnd.value?.toFormat('d. LLLL, yyyy'))
const headerText = computed(() => {
  if (!isTeacher) return t('activity.title.forStudent')
  return t('activity.title.forTeacher')
})

</script>
<template>
  <header
    class="
      relative flex-col justify-center overflow-hidden bg-gradient-to-tr
      from-seagreen-40 to-custom-green to-60% text-white
    "
  >
    <div
      class="
        relative z-10 mx-auto h-full w-full max-w-screen-au
        px-4 pb-5 pt-40 transition-all sm:px-8
      "
    >
      <h1
        class="relative z-10 inline-block font-semibold fluid-text-3xl"
        v-text="headerText"
      />
      <div class="flex justify-between">
        <p
          v-if="activeUserGroup"
          class="relative z-10 mt-2 flex items-center gap-3 text-xl"
        >
          <b class="flex items-center gap-2">
            <KsIcon
              icon="users"
              :scale="1.4"
            />
            {{ activeUserGroup.name }}
          </b>
          <span
            v-if="numberOfGroupMembers > 0"
            v-text="`(${t('groups.membersCount', { count: numberOfGroupMembers})})`"
          />
          <ChangeGroupButton />
        </p>
        <ChangeGroupButton v-else />
      </div>
      <p
        v-if="selectedWeekStart && selectedWeekEnd"
        class="relative z-10 mt-2 flex items-center gap-3 text-xl"
      >
        <span
          v-if="fromDate"
          class="flex items-center gap-2"
          v-text="fromDate"
        />
        <KsIcon icon="long-arrow-right" />
        <span
          v-if="toDate"
          class="flex items-center gap-2"
          v-text="toDate"
        />
      </p>
    </div>
  </header>
</template>
