<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import { onBeforeUnmount, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '~/stores/auth'
import useSubjectsStore from '~/stores/subjects'
import { setTitle } from '~/utils/dom'
import StudentActivityBanner from '~/components/banners/StudentActivityBanner.vue'
import StudentActivitiesForTeacher from '~/components/activity/StudentActivitiesForTeacher.vue'
import StudentActivitiesForStudent from '~/components/activity/StudentActivitiesForStudent.vue'

const { t } = useI18n()
const { setCurrentSubject } = useSubjectsStore()
const { isTeacher } = storeToRefs(useAuthStore())
const selectedSubjects = ref<SubjectCode[]>([])

const setSelectedSubjects = (subjects: SubjectCode[]) => {
  selectedSubjects.value = [...subjects]
}

onBeforeUnmount(() => {
  setCurrentSubject('')
})

setTitle(t('activity.pageTitle'))
</script>
<template>
  <StudentActivityBanner @select:subjects="setSelectedSubjects" />
  <div class="mx-auto max-w-screen-au px-4 sm:px-8">
    <StudentActivitiesForTeacher v-if="isTeacher" />
    <StudentActivitiesForStudent v-else-if="!isTeacher" />
  </div>
</template>
