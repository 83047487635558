<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'
import { sortByGradeIndex } from '~/utils/gradeSorter'
import useText from '~/composables/useText'

const props = withDefaults(defineProps<{
  labelCriterion?: string[]
  activityCriterion?: string[]
  gradeCriterion?: string[]
  accessCriterion?: string[]
  selectedSubjects?: string[]
}>(), {
  labelCriterion: () => ([]),
  activityCriterion: () => ([]),
  gradeCriterion: () => ([]),
  accessCriterion: () => ([]),
  selectedSubjects: () => ([]),
})

const sortedGradeCriterion = computed(() => [...props.gradeCriterion].sort(sortByGradeIndex))

const emit = defineEmits([
  'resetLabelCriterion',
  'resetActivityCriterion',
  'addGradeCriterion',
  'addSubjectCriterion',
  'resetCriterions',
  'resetAccessCriterion',
])

const { t } = useI18n()
const { capitalize } = useText()
</script>

<template>
  <ul
    class="flex flex-wrap gap-3"
    :aria-label="t('search.filters.activeFilters')"
  >
    <li
      v-for="subject in selectedSubjects"
      :key="subject"
    >
      <KsButton
        variant="primary"
        icon-right="xmark"
        shape="rounded"
        size="small"
        :aria-label="t('search.filters.removeFilter', { filter: t(`metadata.subjects.${subject}`) })"
        @click="emit('addSubjectCriterion', subject)"
      >
        {{ t(`metadata.subjects.${subject}`) }}
      </KsButton>
    </li>
    <li
      v-for="grade in sortedGradeCriterion"
      :key="grade"
    >
      <KsButton
        variant="primary"
        icon-right="xmark"
        shape="rounded"
        size="small"
        :aria-label="t('search.filters.removeFilter', { filter: t(`metadata.grades.${grade}`) })"
        @click="emit('addGradeCriterion', grade)"
      >
        {{ t(`metadata.grades.${grade}`) }}
      </KsButton>
    </li>
    <li
      v-for="label in labelCriterion"
      :key="label"
    >
      <KsButton
        variant="primary"
        icon-right="xmark"
        shape="rounded"
        size="small"
        :aria-label="t('search.filters.removeFilter', { filter: t(`labels.${label}`) })"
        @click="emit('resetLabelCriterion')"
      >
        {{ t(`labels.${label}`) }}
      </KsButton>
    </li>
    <li
      v-for="activity in activityCriterion"
      :key="activity"
    >
      <KsButton
        variant="primary"
        icon-right="xmark"
        shape="rounded"
        size="small"
        :aria-label="t('search.filters.removeFilter', { filter: t(`activities.${activity}`) })"
        @click="emit('resetActivityCriterion')"
      >
        {{ t(`activities.${activity}`, capitalize(activity)) }}
      </KsButton>
    </li>
    <li
      v-for="access in accessCriterion"
      :key="access"
    >
      <KsButton
        variant="primary"
        icon-right="xmark"
        shape="rounded"
        size="small"
        :aria-label="t('search.filters.removeFilter', { filter: t(`access.${access}`) })"
        @click="emit('resetAccessCriterion')"
      >
        {{ t(`access.${access}`) }}
      </KsButton>
    </li>
    <li>
      <KsButton
        variant="border"
        shape="rounded"
        size="small"
        :aria-label="t('search.filters.removeAllFilters')"
        @click="emit('resetCriterions')"
      >
        {{ t('search.reset') }}
      </KsButton>
    </li>
  </ul>
</template>
