<script setup lang="ts">
import type { YearPlan } from '~/models/Planner/YearPlan'
import { KsButton, KsPopover } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { computed, defineAsyncComponent } from 'vue'
import stringUtils from '~/utils/stringUtils'
import useDialogStore from '~/stores/dialog'

interface YearPlanListItemProps {
  yearPlan: YearPlan
}

const props = defineProps<YearPlanListItemProps>()
const emit = defineEmits(['close'])

const { t } = useI18n()
const { lastInPath } = stringUtils()
const { showDialog } = useDialogStore()

const canDelete = computed(() => props.yearPlan['@id'].includes('/api/year_plans/'))

const copyYearPlan = () => {
    showDialog(defineAsyncComponent(() => import('./CopyYearPlanDialog.vue')), { yearPlan: props.yearPlan })
    .then(() => emit('close'))
    .catch(() => {}) // noop here if the dialog is closed
}
</script>
<template>
  <li class="flex items-center justify-between">
    <div class="grid w-full grid-cols-12 gap-2">
      <span
        class="col-span-2 overflow-hidden text-ellipsis font-medium"
        v-text="t(`metadata.grades.${lastInPath(props.yearPlan.grades[0] ?? '')}`)"
      />
      <span
        class="col-span-10 w-fit"
        v-text="props.yearPlan.title"
      />
    </div>
    <KsPopover
      :title="t('plans.plan')"
      direction="down left"
    >
      <template #toggle>
        <KsButton
          variant="secondary"
          shape="square"
          size="small"
          iconLeft="ellipsis"
        />
      </template>
      <template #content>
        <KsButton
          iconLeft="share"
          stretch
          @click="copyYearPlan"
        >
          {{ t('plan.copyYearPlan') }}
        </KsButton>
        <KsButton
          v-if="canDelete"
          iconLeft="trash"
          stretch
        >
          {{ t('plan.deleteYearPlan') }}
        </KsButton>
      </template>
    </KsPopover>
  </li>
</template>
