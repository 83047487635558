<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { KsButton } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import animation from '~/assets/lottie/404_Ufo_2.json'

const { t } = useI18n()
setTitle(t('notFound.title'))
</script>

<template>
  <div class="mx-0 flex max-w-full flex-col items-center justify-between gap-4 sm:mx-auto sm:max-w-screen-md md:flex-row">
    <div class="space-y-6 text-gray-50">
      <h1
        class="text-6xl font-bold sm:text-7xl"
        v-text="t('notFound.heading')"
      />
      <p class="text-base sm:text-lg">
        {{ t('notFound.message') }}
      </p>
      <p v-text="t('notFound.statusCode')" />
      <KsButton
        variant="primary"
        shape="rounded"
        href="/"
        :icon-left="faArrowLeft"
      >
        {{ t('notFound.buttonText') }}
      </KsButton>
    </div>
    <LottieAnimation
      :animation-data="animation"
      class="w-full sm:w-[480px]"
    />
  </div>
</template>
