<script setup lang="ts">
import type { SortField } from '~/models/Content/Sort'
import { useI18n } from 'vue-i18n'
import { KsButton, KsInput, KsPopover } from '@aschehoug/kloss'

defineProps<{ field: SortField }>()

const { t } = useI18n()
const fields: SortField[] = ['score', 'modified', 'published']

const emit = defineEmits(['set-sort-field'])
</script>
<template>
  <KsPopover
    :title="t('search.sortField.sorting')"
    direction="down left"
    :autoposition="false"
  >
    <template #toggle>
      <KsButton
        v-ks-tooltip.left="t('search.sortField.sorting')"
        icon-left="list"
        size="small"
        shape="square"
      />
    </template>
    <template #content>
      <KsButton
        v-for="sortField in fields"
        :key="sortField"
        variant="tertiary"
        size="small"
        type="label"
        stretch
        @click.prevent="emit('set-sort-field', sortField)"
      >
        <KsInput
          :checked="sortField === field"
          type="radio"
        />
        {{ t(`search.sortField.${sortField}`) }}
      </KsButton>
    </template>
  </KsPopover>
</template>
