import type { Ref } from 'vue'
import type { SearchResult } from '~/models/Content/Response'
import { computed } from 'vue'
import { sortByGradeIndex } from '~/utils/gradeSorter'
import useAggregationMapper from '~/composables/useAggregationMapper'

export default function useAggregations(response: Ref<SearchResult>) {
  const { mapTermAggregations, mapStateAggregations } = useAggregationMapper()

  const labelAggregations = computed(() => mapTermAggregations('labels', response.value?.aggregations || []))
  const gradeAggregations = computed(() => mapTermAggregations('grades', response.value?.aggregations || []))
  const activityAggregations = computed(() => mapTermAggregations('activities', response.value?.aggregations || []))
  const accessAggregations = computed(() => mapStateAggregations('access', response.value?.aggregations || []))
  const languageAggregations = computed(() => mapTermAggregations('languages', response.value?.aggregations || []))
  const themeAggregations = computed(() => mapTermAggregations('themes', response.value?.aggregations || []))
  const genreAggregations = computed(() => mapTermAggregations('genres', response.value?.aggregations || []))
  const seriesAggregations = computed(() => mapTermAggregations('series', response.value?.aggregations || []))
  const hasAudioAggregations = computed(() => mapTermAggregations('has_audio', response.value?.aggregations || []))
  const hasMoreAggregations = computed(() => mapTermAggregations('has_more', response.value?.aggregations || []))

  const sortedGradeAggregations = computed(() => [...gradeAggregations.value]
    .sort((a, b) => sortByGradeIndex(a.key, b.key)))

  return {
    labelAggregations,
    gradeAggregations,
    sortedGradeAggregations,
    activityAggregations,
    accessAggregations,
    languageAggregations,
    themeAggregations,
    genreAggregations,
    seriesAggregations,
    hasAudioAggregations,
    hasMoreAggregations,
  }
}
