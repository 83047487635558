import type { Product } from '~/models/Product'
import { sortBySubjectIndex } from '~/utils/subjectSorter'
import {
  adultEducationGrades,
  prepEducationGrades,
  sortByGradeIndex,
  upperSecondaryGrades,
} from '~/utils/gradeSorter'
import useArrayUtils from '~/utils/arrayUtils'

const { intersect } = useArrayUtils()

export const SUBJECT_MULTI = 'FLE'

export const getFirstGrade = (product: Product) => [...product?.grades].sort()[0] ?? ''
export const getFirstSubject = (product: Product) => product?.subjects[0] ?? ''
export const getMultiSubject = (product: Product) => product.subjects.length > 1
  ? SUBJECT_MULTI
  : getFirstSubject(product)

const productsExistsGuard = (firstProduct: Product, secondProduct: Product) => firstProduct && secondProduct
const productSubjectsExistsGuard = (firstProduct: Product, secondProduct: Product) =>
  firstProduct.subjects && secondProduct.subjects
const productGradesExistsGuard = (firstProduct: Product, secondProduct: Product) =>
  firstProduct.grades && secondProduct.grades
const sortProductBySubject = (firstProduct: Product, secondProduct: Product) =>
  sortBySubjectIndex(getMultiSubject(firstProduct), getMultiSubject(secondProduct))
const sortProductByGrade = (firstProduct: Product, secondProduct: Product) =>
  sortByGradeIndex(getFirstGrade(firstProduct), getFirstGrade(secondProduct))
const sortProductByName = (firstProduct: Product, secondProduct: Product) =>
  firstProduct.name.localeCompare(secondProduct.name)

export const sortByNameGradeSubject = (firstProduct: Product, secondProduct: Product) => {
  if (!productsExistsGuard(firstProduct, secondProduct)) return 0
  if (!productGradesExistsGuard(firstProduct, secondProduct))
    return secondProduct.grades.length - firstProduct.grades.length
  if (!productSubjectsExistsGuard(firstProduct, secondProduct))
    return secondProduct.subjects.length - firstProduct.subjects.length

  const byName = sortProductByName(firstProduct, secondProduct)
  const byGrade = sortProductByGrade(firstProduct, secondProduct)
  const bySubject = sortProductBySubject(firstProduct, secondProduct)

  return (byName * 100) + (byGrade * 10) + bySubject
}

export const isUpperSecondaryProduct = (product: Product) =>
  intersect(product.grades, upperSecondaryGrades).length > 0

export const isAdultProduct = (product: Product) =>
  intersect(product.grades, adultEducationGrades).length > 0

export const isPrepProduct = (product: Product) =>
  intersect(product.grades, prepEducationGrades).length > 0
