<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import { useGreetings } from '~/composables/useGreetings'
import { useAppColor } from '~/composables/useAppColor'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import SubscriptionBox from '~/components/home/SubscriptionBox.vue'
import LowerPrimarySubjects from '~/components/home/lowerPrimary/LowerPrimarySubjects.vue'
import LowerPrimaryFeaturedCard from '~/components/home/lowerPrimary/LowerPrimaryFeaturedCard.vue'
import DefaultMessages from '~/components/home/DefaultMessages.vue'

const { t } = useI18n()
const { user, isTeacher } = storeToRefs(useAuthStore())
const { greeting } = useGreetings()
const { themeColor } = useAppColor()
</script>

<template>
  <DefaultMessages class="-mb-24 mt-24 w-full" />
  <section :class="`bg-${themeColor?.name} text-white`">
    <div class="relative z-20 mx-auto grid size-full max-w-screen-au grid-cols-1 items-center text-clip px-4 pb-20 pt-28 transition-all sm:px-8 sm:pt-32 md:pb-40 lg:grid-cols-2">
      <div class="space-y-10 lg:pr-6">
        <div class="flex gap-6">
          <SubscriptionBox v-if="isTeacher" />
          <div class="content-center">
            <h1
              class="sr-only"
              v-text="t('home.title')"
            />
            <p class="fluid-text-lg">
              {{ greeting.text }}<br>
              <span
                v-if="user?.userData"
                class="font-calistoga fluid-text-5xl"
                v-text="user.userData.firstName"
              />
            </p>
          </div>
        </div>
        <LowerPrimarySubjects />
      </div>
      <div class="pointer-events-none relative bottom-0 right-0 size-full lg:absolute lg:w-1/2 2xl:-right-12">
        <div class="relative flex size-full items-center justify-center md:justify-start">
          <LowerPrimaryFeaturedCard />
          <div class="absolute bottom-0 right-8 hidden md:block">
            <CopyrightButton
              class="pointer-events-auto"
              :copyright="t('aschehoug')"
              is-animation
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
