<script setup lang="ts">
import { KsButton, KsCallout } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
<template>
  <div>
    <h1
      class="mb-8 text-center text-4xl font-semibold"
      v-text="t('register.success.title')"
    />
    <div>
      <KsCallout
        variant="success"
        :title="t('register.success.info')"
      >
        <ol class="mt-4 list-decimal space-y-2">
          <li v-text="t('register.success.confirm1')" />
          <li v-html="t('register.success.confirm2')" />
        </ol>
      </KsCallout>
    </div>
    <div class="my-4 text-right">
      <KsButton
        href="/"
        variant="primary"
        size="large"
      >
        {{ t('back') }}
      </KsButton>
    </div>
  </div>
</template>
