<script setup lang="ts">
import type { ContentResource } from '~/models/Content/ContentResource'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { KsSpinner } from '@aschehoug/kloss'
import { prepEducationGrades, upperSecondaryGrades } from '~/utils/gradeSorter'
import { waitFor } from '~/utils/asyncUtils'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useSlugify from '~/composables/useSlugify'
import { useContentHelper } from '~/composables/useContentHelper'
import { useResourceApi } from '~/api/resourceApi'
import Redirect from '~/components/ResourceEngine/Components/Redirect.vue'
import NotFound from '~/components/ResourceEngine/Components/NotFound.vue'

const route = useRoute()
const router = useRouter()
const { getResourceByPath, getResourceByLocationId } = useResourceApi()
const { isProduct, isPackage } = useContentHelper()
const { isAuthenticated, userGrades } = storeToRefs(useAuthStore())
const { intersect } = arrayUtils()
const { hasProducts } = storeToRefs(useProductStore())
const { buildResourceUrl } = useUrlBuilder()
const { slugify } = useSlugify()

const isLoading = ref(true)
const resource = ref<ContentResource>()
const shouldRedirect = ref(false)

// Check if path is shaped as location/{locationId}
const isLocationPath = (path: string) => path.includes('location/')

// Get the location from a path shaped as location/{locationId}
const getLocationIdFromLocationPath = (path: string) => Number(path.split('location/')[1])

const stripChars = (from: string) => from.replace(/[^a-zA-Z ]/g, '')

const redirectGrades = [...upperSecondaryGrades, ...prepEducationGrades]
const resourceGrades = computed(() => resource.value?.grades || [])
const userShouldRedirect = computed(() => intersect(redirectGrades, userGrades.value).length > 0)

const redirectToResourceUrl = computed(() => resourceGrades.value.length > 0
    ? intersect(redirectGrades, resourceGrades.value).length > 0
    : userShouldRedirect.value)

const checkIfPathExists = async (path: string) => {
  isLoading.value = true

  if(isLocationPath(path)) {
    const locationId = getLocationIdFromLocationPath(path)
    try {
      resource.value = await getResourceByLocationId(locationId)
    } catch (error) {
      console.error(`Resource not found by locationId: ${locationId}`)
      resource.value = undefined
      throw error
    } finally {
      isLoading.value = false
    }
  }

  if(!isLocationPath(path)) {
    try {
      resource.value = await getResourceByPath(path)
    } catch (error) {
      console.error(`Resource not found by path: ${path}`)
      resource.value = undefined
      throw error
    } finally {
      isLoading.value = false
    }
  }
}

onMounted(async () => {
  await checkIfPathExists(route.path)
  await waitFor(() => hasProducts.value && isAuthenticated.value, 1000)

  // 404 path not found
  if (!resource.value) return

  // VGS resources should always redirect
  if (redirectToResourceUrl.value) {
    shouldRedirect.value = true
    return
  }

  if (isPackage(resource.value)) {
    await router.replace({ name: 'package', params: {
      locationId: resource.value.locationId,
      slug: slugify(resource.value.title),
    } })
    return
  }

  if (isProduct(resource.value)) {
    await router.replace({ name: 'product', params: {
        locationId: resource.value.locationId,
        slug: slugify(resource.value.title),
      } })
    return
  }

  // Redirect if product url differs from path
  const packageUrl = buildResourceUrl(resource.value).replace('/nn/', '/')
  shouldRedirect.value = stripChars(packageUrl) !== stripChars(route.path)
})

onBeforeUnmount(() => {
  const { $reset } = useFilterStore()
  $reset()
})
</script>

<template>
  <div v-if="isAuthenticated">
    <KsSpinner
      v-if="isLoading"
      position="center"
    />
    <NotFound v-if="!isLoading && !resource" />
    <Redirect
      v-if="!isLoading && resource && shouldRedirect"
      :resource="resource"
    />
  </div>
</template>
