<script setup lang="ts">
import { onBeforeMount } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from '~/composables/useI18n'
import { setTitle } from '~/utils/dom'
import useBlogStore from '~/stores/blog'
import BlogNavigation from '~/components/blog/BlogNavigation.vue'
import BlogContent from '~/components/blog/BlogContent.vue'

const { t } = useI18n()
const { getPosts } = useBlogStore()
const { postTags } = storeToRefs(useBlogStore())

setTitle(t('blog.shortTitle'))

onBeforeMount(() => getPosts())
</script>

<template>
  <h1
    class="mt-4 px-5 text-center text-3xl font-bold sm:px-8 sm:text-4xl"
    v-text="t('blog.title')"
  />
  <BlogNavigation v-if="postTags.length > 1" />
  <BlogContent />
</template>
