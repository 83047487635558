<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useDescriptor from '~/composables/useDescriptor'
import { useContentHelper } from '~/composables/useContentHelper'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'
import LearningPathLink from '~/components/utils/LearningPathLink.vue'
import CardMenu from '~/components/cards/buttons/CardMenu.vue'

const props = withDefaults(defineProps<{
  resource: BaseItem
  isAnchor?: boolean
  loading?: 'eager' | 'lazy'
}>(), {
  loading: 'lazy',
})

const { getTitle, isLearningPath } = useContentHelper()
const { buildResourceUrl } = useUrlBuilder()
const { getDescriptor } = useDescriptor()
const { t } = useI18n()

const title = computed(() => getTitle(props.resource))
const link = computed(() => buildResourceUrl(props.resource))
const label = getDescriptor(props.resource)

const attributes = computed(() => isLearningPath(props.resource)
  ? {
    resource: props.resource
  }
  : {
    link: link.value,
    isAnchor: props.isAnchor
  }
)
</script>

<template>
  <div class="group relative animate-fade border border-[--card-border] bg-[--card-bg] ring-inset focus:ring">
    <div
      :id="`card-${resource.locationId}`"
      class="contents"
    >
      <aside
        v-if="resource.new"
        class="pointer-events-none absolute right-0 top-0 z-20 rounded-bl-2xl bg-green-40 p-1.5 pl-2 text-sm font-medium uppercase tracking-wider text-white"
        v-text="t('newShort')"
      />
      <div class="aspect-video h-full w-full overflow-hidden">
        <img
          v-if="resource.thumbnail"
          :src="resource.thumbnail"
          class="block h-full w-full overflow-hidden object-cover p-3 transition-transform group-hover:scale-125"
          :loading
          :alt="resource.thumbnailAlt"
          aria-hidden="true"
        >
      </div>

      <div class="pointer-events-none absolute bottom-6 left-0 flex flex-col items-start">
        <div class="bg-[--card-text] py-1 pl-3 pr-2 text-xs uppercase text-white">
          {{ label }}
        </div>

        <div class="break-words text-lg font-medium text-[--card-text]">
          <span class="bg-white box-decoration-clone px-3 py-1">
            {{ title }}
          </span>
        </div>
      </div>
    </div>

    <component
      :is="isLearningPath(resource) ? LearningPathLink : LinkOrAnchor"
      :aria-labelledby="`card-${resource.locationId}`"
      class="absolute inset-0 focus-visible:ring"
      v-bind="attributes"
    />

    <CardMenu
      :resource="resource"
      class="absolute bottom-0 right-0"
      button-classes="!rounded-none !rounded-tl-lg"
      style="--ks-inputhover: rgb(var(--au-white))"
    />
  </div>
</template>
