<script setup lang="ts">
import type { BasePageProps } from '~/models/Presentation/BasePage'
import type { GridPage, TextAndImage } from '~/models/Presentation/Pages/GridPage'
import { computed, provide } from 'vue'
import useHiddenHeading from '~/composables/useHiddenHeading'
import TextAndImageComponent from '~/components/PresentationPages/Components/TextAndImage.vue'

const props = defineProps<BasePageProps & { page: GridPage }>()

const { hasHeading } = useHiddenHeading()
provide('embedAudioVariant', props.page.audioEmbedVariant)

const colorPair = computed(() => props.page.colorPair)
const textAndImages = computed<TextAndImage[]>(() => Array.isArray(props.page.textAndImages)
  ? props.page.textAndImages
  : [])
</script>
<template>
  <div :style="{ background: colorPair.background.rgb, color: colorPair.text.rgb }">
    <h1
      v-if="!hasHeading"
      class="sr-only"
      v-text="page.title"
    />
    <TransitionGroup
      tag="ul"
      name="fade"
      class="flex"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      appear
    >
      <TextAndImageComponent
        v-for="(textAndImage, i) in textAndImages"
        :key="textAndImage.contentId"
        :text-and-image="textAndImage"
        :index="i"
        :page="page"
      />
    </TransitionGroup>
  </div>
</template>

<style scoped>
.fade-enter-active {
  transition-duration: 1000ms;
  transition-delay: calc(.8s + (0.4s * var(--i)));
  transition-timing-function: ease;
}
</style>
