<script setup lang="ts">
import type { Presentation } from '~/models/Presentation/Presentation'
import type { PresentationPage } from '~/models/Presentation/BasePage'
import { computed, ref } from 'vue'
import { RouterLink } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsIcon, KsPopover } from '@aschehoug/kloss'
import { useAuthStore } from '~/stores/auth'
import { PendoFeature } from '~/models/Pendo'
import useSlugify from '~/composables/useSlugify'
import ScrollBox from '~/components/utils/ScrollBox.vue'
import PresentationTeacherGuide from '~/components/PresentationPages/Components/PresentationTeacherGuide.vue'

const props = defineProps<{
  pageNumber: number
  pages: PresentationPage[]
  presentation: Presentation
}>()

const { t } = useI18n()
const { isTeacher } = storeToRefs(useAuthStore())
const { slugify } = useSlugify()

const teacherDialogOpen = ref(false)

const currentPage = computed(() => props.pages[props.pageNumber - 1])
const colorPair = computed(() => currentPage.value.colorPair)
const teacherGuideId = computed(() => currentPage.value.teacherGuide?.destinationContentId
  || props.presentation.teacherGuide?.destinationContentId)
</script>

<template>
  <KsPopover
    :title="t('labels.presentation')"
    :autoposition="false"
    direction="up left"
  >
    <template #toggle>
      <KsButton
        icon-left="list"
        shape="round"
        size="large"
        :style="{ background: colorPair.background.rgb, color: colorPair.text.rgb, border: `1.5px solid ${colorPair.text.rgb}` }"
        :data-pendo="PendoFeature.Presentation.Overview"
      />
    </template>
    <template #content>
      <div
        class="grid max-h-[min(42rem,75vh)] w-96 grid-rows-[max-content_minmax(0,1fr)]"
        :style="{ background: colorPair.text.rgb, color: colorPair.background.rgb }"
      >
        <header
          class="p-6 pt-8 text-3xl font-semibold"
          v-text="t('presentation.menu.content')"
        />
        <ScrollBox class="flex flex-col p-6 pt-0">
          <template v-if="isTeacher && teacherGuideId">
            <button
              class="mb-8 flex items-center gap-4 rounded-lg border bg-orange-20 px-4 py-2 font-medium focus-visible:ring"
              :style="{ color: colorPair.isDark ? colorPair.background.rgb : colorPair.text.rgb }"
              @click="teacherDialogOpen = true"
            >
              <span class="flex h-7 w-7 shrink-0 items-center justify-center rounded-full border">
                <KsIcon
                  icon="info"
                  :scale=".8"
                />
              </span>
              {{ t('presentation.teacherGuide') }}
            </button>
            <PresentationTeacherGuide
              :is-open="teacherDialogOpen"
              :content-id="teacherGuideId"
              @close-guide="teacherDialogOpen = false"
            />
          </template>
          <div v-if="pages.length > 1">
            <ul class="space-y-3">
              <li
                v-for="(page, index) in pages"
                :key="`contentMenu-${page.contentId}`"
                :aria-current="pageNumber === index + 1"
              >
                <RouterLink
                  :to="{ name: 'presentation-page', params: {
                    locationId: presentation.locationId,
                    slug: slugify(presentation.title),
                    pageNumber: index + 1,
                  } }"
                  class="flex items-center gap-4 rounded-lg border px-4 py-2 font-medium focus-visible:ring"
                  :class="{ active: pageNumber === index + 1 }"
                  :style="{ borderColor: `rgb(var(${colorPair.background.var}) / .31)` }"
                >
                  <span
                    aria-hidden="true"
                    class="flex h-7 w-7 shrink-0 items-center justify-center rounded-full border"
                    v-text="index + 1"
                  />
                  <p v-text="page.shortTitle || page.title || t('presentation.pageNumber', { pageNumber: index + 1 })" />
                </RouterLink>
              </li>
            </ul>
          </div>
        </ScrollBox>
      </div>
    </template>
  </KsPopover>
</template>
