<script setup lang="ts">
import type { RegisterUser } from '~/models/User/Register'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton, KsInput } from '@aschehoug/kloss'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import ErrorCallout from '~/components/register/ErrorCallout.vue'
import LoggedInCallout from '~/components/register/LoggedInCallout.vue'
import InputError from '~/components/register/InputError.vue'

const props = withDefaults(defineProps<{
  modelValue: RegisterUser
  isLoading?: boolean
  errorStatus?: number
}>(), {
  isLoading: false,
  errorStatus: 0,
})

const { t } = useI18n()
const emit = defineEmits(['register', 'cancel'])
const { isAuthenticated } = storeToRefs(useAuthStore())

const firstNameBlurred = ref(false)
const lastNameBlurred = ref(false)
const emailBlurred = ref(false)

const termsUrl = import.meta.env.VITE_AUNIVERS_PRIVACY

const isEmailValid = computed(() => /^[^,\s]*@[^,\s]+\.[^,\s]+$/.test(props.modelValue.email))
const isEmailLength = computed(() => props.modelValue.email.length >= 4 && props.modelValue.email.length <= 500)
const isFirstNameValid = computed(() => props.modelValue.firstName.length >= 2)
const isLastNameValid = computed(() => props.modelValue.lastName.length >= 2)
const canInput = computed(() => !props.isLoading && !isAuthenticated.value)

const canSubmit = computed(() => canInput.value
  && isEmailValid.value
  && isEmailLength.value
  && isFirstNameValid.value
  && isLastNameValid.value
  && props.modelValue.terms
)
</script>
<template>
  <div class="mb-6 space-y-4 text-center">
    <h1
      class="text-4xl font-semibold text-gray-50"
      v-text="t('register.form.title')"
    />
    <p
      v-if="!isAuthenticated"
      class="text-gray-40"
      v-text="t('register.form.intro')"
    />
  </div>
  <ErrorCallout
    v-if="props.errorStatus > 0"
    :status-code="props.errorStatus"
  />
  <LoggedInCallout v-if="isAuthenticated" />
  <form class="flex flex-col gap-5">
    <label>
      <span
        class="mb-2 inline-block"
        v-text="t('register.input.firstName')"
      />
      <KsInput
        id="firstName"
        v-model="props.modelValue.firstName"
        autocomplete="given-name"
        type="text"
        :disabled="!canInput"
        @blur="firstNameBlurred = true"
        @input="firstNameBlurred = false"
      />
    </label>
    <InputError
      v-if="!isFirstNameValid && firstNameBlurred && props.modelValue.firstName"
      :text="t('register.invalid.firstName')"
    />
    <label>
      <span
        class="mb-2 inline-block"
        v-text="t('register.input.lastName')"
      />
      <KsInput
        id="lastName"
        v-model="props.modelValue.lastName"
        autocomplete="family-name"
        type="text"
        :disabled="!canInput"
        @blur="lastNameBlurred = true"
        @input="lastNameBlurred = false"
      />
    </label>
    <InputError
      v-if="!isLastNameValid && lastNameBlurred && props.modelValue.lastName"
      :text="t('register.invalid.lastName')"
    />
    <label>
      <span
        class="mb-2 inline-block"
        v-text="t('register.input.email')"
      />
      <KsInput
        id="email"
        v-model="props.modelValue.email"
        type="email"
        :disabled="!canInput"
        @blur="emailBlurred = true"
        @input="emailBlurred = false"
      />
    </label>
    <InputError
      v-if="!(isEmailValid && isEmailLength) && emailBlurred && props.modelValue.email"
      :text="t('register.invalid.email')"
    />
    <label class="flex items-center gap-2">
      <KsInput
        v-model="props.modelValue.terms"
        type="checkbox"
        shape="rounded"
        :disabled="!canInput"
      /> <span
        class="au-cms"
        v-html="t('register.input.terms', { url: termsUrl })"
      />
    </label>
  </form>
  <div class="mt-10 flex justify-end gap-4">
    <KsButton
      href="/"
      variant="secondary"
      size="large"
      :disabled="isLoading"
    >
      {{ t('cancel') }}
    </KsButton>
    <KsButton
      variant="primary"
      size="large"
      :disabled="!canSubmit"
      @click.prevent="emit('register', props.modelValue)"
    >
      {{ t('register.form.submit') }}
    </KsButton>
  </div>
</template>
