import type { User } from '~/models/User/User'
import type { SessionUserInfo } from '~/models/User/SessionUserInfo'
import type { Organization } from '~/models/Organization'
import { contentClient } from '~/api/client/contentClient'

export default function useUserApi() {
  const getUser = (): Promise<User> => {
    return contentClient.get('/user')
      .then(({ data }) => data)
  }

  const postUser = (sessionInfo: SessionUserInfo): Promise<User> => {
    return contentClient.post('/user', sessionInfo)
      .then(({ data }) => data)
  }

  const postUserOrganization = (organization: Organization): Promise<Organization> => {
    return contentClient.post('/user/organization', organization)
      .then(({ data }) => data.organization)
  }

  return { getUser, postUser, postUserOrganization }
}
