export enum Icon {
  Archive = 'archive',
  ArrowLeft = 'arrow-left',
  ArrowLongLeft = 'arrow-long-left',
  ArrowLongRight = 'arrow-long-right',
  ArrowRight = 'arrow-right',
  ArrowsRotate = 'arrows-rotate',
  Atom = 'atom',
  Audio = 'audio',
  AudioXmark = 'audio-xmark',
  Bag = 'bag',
  Ballot = 'ballot',
  Bell = 'bell',
  Book = 'book',
  Books = 'books',
  Brain = 'brain',
  Browser = 'browser',
  Calendar = 'calendar',
  CaretDown = 'caret-down',
  CaretUp = 'caret-up',
  Carrot = 'carrot',
  ChatBubble = 'chat-bubble',
  Check = 'check',
  CheckCircle = 'check-circle',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  CodeScreen = 'code-screen',
  Cog = 'cog',
  Collection = 'collection',
  Compress = 'compress',
  Copy = 'copy',
  Copyright = 'copyright',
  Croissant = 'croissant',
  Cross = 'cross',
  Download = 'download',
  Ear = 'ear',
  EarthEurope = 'earth-europe',
  Ellipsis = 'ellipsis',
  EllipsisVertical = 'ellipsis-vertical',
  Establishment = 'establishment',
  ExclamationTriangle = 'exclamation-triangle',
  ExpandedSquare = 'expand-square',
  Eye = 'eye',
  EyeSlash = 'eye-slash',
  Feide = 'feide',
  Flask = 'flask',
  FloppyDisk = 'floppy-disk',
  Function = 'function',
  Gem = 'gem',
  GlobeStand = 'globe-stand',
  Grid = 'grid',
  Hamburger = 'hamburger',
  HandsPraying = 'hands-praying',
  Headphones = 'headphones',
  Health = 'health',
  Heart = 'heart',
  HeartFilled = 'heart-filled',
  House = 'house',
  Info = 'info',
  InfoCircle = 'info-circle',
  Lamp = 'lamp',
  Layer = 'layer',
  Leaf = 'leaf',
  Link = 'link',
  List = 'list',
  Lock = 'lock',
  LogoAco = 'logo-aco',
  LogoAunivers = 'logo-aunivers',
  LogoSimple = 'logo-simple',
  Logout = 'logout',
  MagnifyingGlass = 'magnifying-glass',
  Media = 'media',
  Minus = 'minus',
  Music = 'music',
  Newspaper = 'newspaper',
  PaintbrushPencil = 'paintbrush-pencil',
  Pause = 'pause',
  Pencil = 'pencil',
  PencilBoard = 'pencil-board',
  People = 'people',
  PersonChalkboard = 'person-chalkboard',
  PersonSign = 'person-sign',
  Play = 'play',
  Plus = 'plus',
  Print = 'print',
  Question = 'question',
  QuestionCircle = 'question-circle',
  Running = 'running',
  Share = 'share',
  Sliders = 'sliders',
  SlidersUp = 'sliders-up',
  Timeline = 'timeline',
  Trash = 'trash',
  UniversalAccess = 'universal-access',
  User = 'user',
  Users = 'users',
  Video = 'video',
  Whistle = 'whistle',
  Xmark = 'xmark',
  XmarkCircle = 'xmark-circle',
  Yinyang = 'yinyang',
}
