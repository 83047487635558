export function useDebounce() {
  const debounce = (fn: Function, ms = 300, immediate = false) => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null
    return function (this: any, ...args: any[]) {
      const doLater = () => {
        timeoutId = null
        if (immediate) return
        fn.apply(this, args)
      }

      if (immediate && timeoutId === null) {
        fn.apply(this, args)
      }

      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }

      timeoutId = setTimeout(doLater, ms)
    }
  }

  return {
    debounce,
  }
}
export default useDebounce
