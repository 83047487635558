import { computed, ComputedRef } from 'vue'

interface ClipboardApiFeatures {
  apiEnabled: boolean
  read: boolean
  readText: boolean
  write: boolean
  writeText: boolean
}

export interface CopyItem {
  text: string
  html?: string
}

export interface CopyTemplateData {
  title: string
  url: string
  description?: string
  resourceData?: CopyTemplateData[]
}

export function useClipboardApi() {

  const clipboardApiEnabled: ComputedRef<boolean> = computed((): boolean => 'clipboard' in navigator)
  const clipboardFeatureDetection: ComputedRef<ClipboardApiFeatures> = computed((): ClipboardApiFeatures => {
     return {
        apiEnabled: clipboardApiEnabled.value,
        read: typeof navigator.clipboard?.read === 'function',
        readText: typeof navigator.clipboard?.readText === 'function',
        write: typeof navigator.clipboard?.write === 'function',
        writeText: typeof navigator.clipboard?.writeText === 'function',
    }
  })

  // Copy to clipboard based on available Clipboard API features
  const writeToClipboard = async (copyItem: CopyItem): Promise<void> => {
    if (!clipboardApiEnabled.value) {
      throw new Error('Clipboard API not available')
    }

    if(clipboardFeatureDetection.value.write) {
      useClipboard(copyItem)
      return
    }

    if (clipboardFeatureDetection.value.writeText) {
      useSimpleClipboard(copyItem)
      return
    }

    throw new Error('Clipboard API write not available')
  }

  // Fallback for browsers that don't support Clipboard API write
  const useSimpleClipboard = (copyItem: CopyItem): void => {
    navigator.clipboard.writeText(copyItem.text)
      .then(
        (): void => {return},
        (error): void => {throw new Error(`Clipboard API writeText error: ${error}`)},
      )
  }

  // Clipboard API write, preferred way copying to clipboard
  const useClipboard = (copyItem: CopyItem): void => {
    navigator.clipboard.write([
      new ClipboardItem({
        'text/plain': new Blob([copyItem.text], { type: 'text/plain' }),
        'text/html': new Blob([copyItem.html || copyItem.text], { type: 'text/html' }),
      }),
    ])
      .then(
        (): void => {return},
        (error): void => {throw new Error(`Clipboard API write error: ${error}`)},
      )
  }

  const fillHtmlCopyTemplate = (templateData: CopyTemplateData): string => {

    if (!templateData.url.length) {
      throw new Error('Missing url')
    }

    if (!templateData.title.length) {
      return templateData.url
    }

    // Important that the template starts with a meta tag
    let template: string = '<meta charset="utf-8">'
    if (templateData.title.length && templateData.url.length) {
      template = `<a href="${templateData.url}">${templateData.title} @ Aunivers</a>`
    }

    if (typeof templateData.description !== 'undefined' && templateData.description?.length > 0) {
      template = template + `<br>${templateData.description}`
    }

    if (typeof templateData.resourceData !== 'undefined' && templateData.resourceData?.length > 0) {
      template = template + '<ul>'
      templateData.resourceData?.forEach((resource: CopyTemplateData) => {
        template = template + `<li><a href="${resource.url}">${resource.title}</a></li>`
      })
      template = template + '</ul>'
    }

    return template
  }

  return {
    writeToClipboard,
    fillHtmlCopyTemplate,
  }
}
