import type { UserRole } from '~/models/User/UserRole'
import type { GradeCode } from '~/models/Grade'
import type { SubjectCode } from '~/models/Subject'

export enum GroupSource {
  Lokus = 'LOKUS',
  Local = 'LOCAL',
  Feide = 'FEIDE',
}

export enum GroupType {
  Basis = 'BASIS_CLASS',
  Teacher = 'TEACHING_GROUP',
  Other = 'OTHER',
  Adhoc = 'AD_HOC',
}

export enum GroupRole {
  Member = 'MEMBER',
  Admin = 'ADMIN',
}

export interface Group {
  groupId: string
  groupSource: GroupSource
  groupType: GroupType
  userRole: UserRole
  name: string
  description: string
  grepCode: string
  ownerUserId: string
  ownerOrganizationNumber: string
  ownerOrganizationGroupId: string
  active: boolean
  colorId: null | number
  grade: GradeCode | null
  yearPlan: string | null
  subjects: SubjectCode[]
}

export interface GroupMember {
  userId: string
  firstName: string
  lastName: string
  fullName: string
  role: UserRole
  groupRole: GroupRole
}
