import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default () => {
  const route = useRoute()

  const banner = computed(() => route.meta.banner)
  const headerColor = computed(() => route.meta.headerColor)

  return {
    banner,
    headerColor
  }
}
