import type { WeekResponse, YearPlanResponse } from '~/models/Planner/Response'
import type { AddToWeek, AddToWeekResponse } from '~/models/AddToWeek'
import type { Plan } from '~/models/Planner/Plan'
import type { PrePersistWeek, Week } from '~/models/Planner/Week'
import type { YearPlan } from '~/models/Planner/YearPlan'
import type { FileForm, PageInfo, SignedFile } from '~/models/Planner/FileForm'
import type { PlannedItem } from '~/models/Content/PlannedItem'
import type { Group } from '~/models/Group'
import type { SubjectCode } from '~/models/Subject'
import { apiClient } from '~/api/client/apiClient'
import { contentClient } from '~/api/client/contentClient'
import { useSchoolYear } from '~/composables/useSchoolYear'
import usePlannerMapper from '~/composables/usePlannerMapper'

export function usePlannerApi() {
  const { mapPlannedItems, mapWeek, mapYearPlan } = usePlannerMapper()

  const validateStatus = (status: number) => status >= 200 && status < 300

  const getYearPlans = async (
    schoolYear?: string,
    subjectCode?: string,
    weekNumber?: number,
  ): Promise<YearPlan[]> => {
    const { currentSchoolYearCode } = useSchoolYear()
    if (!schoolYear) schoolYear = currentSchoolYearCode
    const response = (await apiClient.get( 'planner/year_plans', {
      params: {
        ...(schoolYear ? { 'schoolYear.identifier': schoolYear } : {}),
        ...(subjectCode ? { 'subjects.identifier': subjectCode } : {}),
        ...(weekNumber ? { 'plans.weeks.weekNumber': weekNumber } : {}),
      },
      validateStatus,
    })).data['hydra:member'] ?? []
    return response.map(mapYearPlan)
  }

  const getYearPlan = async (yearPlan: string): Promise<YearPlan> => {
    const response = (await apiClient.get(`planner/year_plans/${yearPlan}`, { validateStatus })).data as YearPlanResponse
    return mapYearPlan(response)
  }

  const getSuggestedYearPlansBySchoolYear = async (schoolYear: string): Promise<YearPlan[]> => {
      return contentClient.get(
        `/planner/year_plans?schoolYear.identifier=${schoolYear}`,
        { validateStatus },
      ).then((response) => ((response?.data['hydra:member'] ?? []) as Array<YearPlanResponse>).map(mapYearPlan))
  }

  const getSuggestedResourcesByPlan = async (plan: Plan): Promise<PlannedItem[]> => {
      const response = await contentClient.get(`/planner/plans/${plan.identifier}/resources`, { validateStatus })
      const data = await response.data
      return mapPlannedItems(data['hydra:member'] ?? [])
  }

  const getSuggestedWeeksByPlan = async (plan: Plan): Promise<Week[]> => {
      const response = await contentClient.get(`/planner/plans/${plan.identifier}/weeks`, { validateStatus })
      const data = await response.data
      return ((data['hydra:member'] ?? []) as Array<WeekResponse>).map(mapWeek)
  }

  const copyYearPlan = async (yearPlan: YearPlanResponse, group: Group): Promise<string> => {
    return (await apiClient.post(('planner/year_plans/copy'), { yearPlan, group }, { validateStatus })).data.identifier
  }

  const copyYearPlansToGroup = async (group: Group, identifiers: string[], weekNumber: number, subject?: SubjectCode ): Promise<void> => {
    await apiClient.post(('planner/year_plans/copy_to_group'), { group, identifiers, weekNumber, subject }, { validateStatus })
    return
  }

  const putWeek = async (week: Partial<PrePersistWeek>): Promise<Week> => {
    const response = (await apiClient.put(`planner/weeks/${week.identifier}`, week)).data as WeekResponse
    return mapWeek(response)
  }

  const deleteResource = (resourceIdentifier: string): Promise<void> => {
    return apiClient.delete(`planner/resources/${resourceIdentifier}`, { validateStatus })
  }

  const createFileForm = async (): Promise<FileForm> => {
    return (await apiClient.post('file/form', {})).data
  }

  const postSignedFile = async (key: string): Promise<SignedFile> => {
    return (await apiClient.post('file/sign', { key })).data
  }

  const crawlPageInfo = async (url: string): Promise<PageInfo> => {
    return (await contentClient.get(`/crawler/pageinfo?url=${encodeURIComponent(url)}`)).data
  }

  const postToWeek = async (addToWeek: AddToWeek): Promise<AddToWeekResponse> => {
    return (await apiClient.post('planner/addToWeek/resource', addToWeek)).data
  }

  const updateYearPlansByGroupId = (groupId: string): Promise<void> => {
    return apiClient.post('/planner/year_plans/groups/update/groupId', { 'groupId': groupId })
  }

  return {
    getYearPlans,
    getSuggestedYearPlansBySchoolYear,
    getSuggestedResourcesByPlan,
    getSuggestedWeeksByPlan,
    copyYearPlan,
    copyYearPlansToGroup,
    deleteResource,
    getYearPlan,
    createFileForm,
    postSignedFile,
    crawlPageInfo,
    putWeek,
    postToWeek,
    updateYearPlansByGroupId,
  }
}
