<script setup lang="ts">
import { inject, ref } from 'vue'
import { usePlannerApi } from '~/api/plannerApi'
import { useI18n } from 'vue-i18n'

const props = withDefaults(defineProps<{
  fileKey: string
  title?: string
}>(), {
  title: ''
})

const { t } = useI18n()
const ksNotify = <Toast>inject('ksToast')
const { postSignedFile } = usePlannerApi()

const signedUri = ref('')
const isLoading = ref(false)

function downloadFile() {
  if (isLoading.value) return
  isLoading.value = true
  postSignedFile(props.fileKey)
    .then((response) => {
      signedUri.value = response.uri
      clickUrl(signedUri.value)
    })
    .catch((error) => {
      ksNotify.error(t('planner.createResource.downloadError'))
      throw error
    })
    .finally(() => {
      setTimeout(() => {
        isLoading.value = false
      }, 200)
    })
}

function clickUrl(url: string) {
  const anchor = document.createElement('a')
  anchor.href = url
  document.body.appendChild(anchor)
  anchor.click()
  anchor.remove()
}
</script>
<template>
  <a
    :href="fileKey"
    :class="{ disabled: isLoading }"
    @click.stop.prevent="downloadFile"
  >
    <slot>{{ title }}</slot>
  </a>
</template>
