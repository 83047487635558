<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { Product } from '~/models/Product'
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsInput } from '@aschehoug/kloss'
import { sortBySubjectIndex } from '~/utils/subjectSorter'
import { isUpperSecondaryProduct, sortByNameGradeSubject } from '~/utils/productSorter'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import { useAuthStore } from '~/stores/auth'
import DefaultMessages from '~/components/home/DefaultMessages.vue'
import DefaultIllustration from '~/components/home/DefaultIllustration.vue'
import DefaultGreetings from '~/components/home/DefaultGreetings.vue'

const subjectGroup: { [key: string]: Array<SubjectCode> } = {
  'NOR': ['NOR'],
  'MAT': ['MAT'],
  'LANG': ['ENG', 'SPA', 'TYS', 'FRA', 'FSP' ],
  'NAT': ['NAT', 'GFG', 'KJE', 'INF', 'FYS'],
  'SAF': ['SAF', 'HIS', 'SAK', 'SOS', 'SAG', 'SOK', 'POM', 'HEL', 'PSY', 'REL', 'KRL', 'GEO'],
  'VOK': ['VOK'],
}

const { t } = useI18n()
const router = useRouter()
const { intersect, unique } = arrayUtils()
const authStore = useAuthStore()
const { userSubjectsByGrades, userGrades } = storeToRefs(authStore)
const { products } = storeToRefs(useProductStore())

const selectedSubjectGroup = ref<string>()

const allProducts = computed(() => products.value.filter(isUpperSecondaryProduct))
const filteredSubjects = computed(() => selectedSubjectGroup.value ? (subjectGroup[selectedSubjectGroup.value] ?? []) : userSubjectsByGrades.value)

const productSubjects = computed(() => allProducts.value
  .flatMap(({ subjects }) => subjects).filter(unique).sort(sortBySubjectIndex))

const filteredGroups = computed(() => Object.keys(subjectGroup)
  .filter((key) => intersect(subjectGroup[key], productSubjects.value).length > 0))

const hasUserGrades = (product: Product) => !product.grades.length || intersect(product.grades, userGrades.value).length > 0
const hasUserSubjects = (product: Product) => !product.subjects.length || intersect(product.subjects, filteredSubjects.value).length > 0
const hasUserSettings = (product: Product) => hasUserGrades(product) && hasUserSubjects(product)

const filteredProducts = computed(() => allProducts.value
  .filter((product) => hasUserSettings(product))
  .sort(sortByNameGradeSubject))

const setSubjectGroup = (group: string) => {
  localStorage.setItem('subject-group', group)
  selectedSubjectGroup.value = group
}

const buildLegacyProductUrl = (product: Product) => {
  if (!product.aunivers || !product.aunivers.urlAlias) {
    return router.resolve({ name: 'notfound' }).href
  }
  const url = new URL(product.aunivers.urlAlias)
  return `${import.meta.env.VITE_AUNIVERS_SITEURL}${url.pathname}`
}

onMounted(() => {
  const cachedGroup = localStorage.getItem('subject-group')
  selectedSubjectGroup.value = cachedGroup ?? undefined
})

watch(userGrades, () => {
  localStorage.removeItem('subject-group')
  selectedSubjectGroup.value = undefined
})
</script>
<template>
  <header class="relative mb-12 flex-col justify-center bg-gradient-to-tr from-seagreen-40 to-custom-green to-60% text-white">
    <div class="relative z-20 mx-auto size-full max-w-screen-au px-4 pb-10 pt-40 transition-all xs:pb-14 sm:px-8 sm:pt-28">
      <DefaultMessages class="mb-6 !px-0" />
      <h1
        class="sr-only"
        v-text="t('home.title')"
      />
      <DefaultGreetings />
      <div
        v-if="filteredProducts.length"
        class="mt-4 md:max-w-[74%]"
      >
        <div class="max-w-max rounded-lg bg-seagreen-50 p-6">
          <p class="mb-3 font-medium">
            {{ t('search.filters.filterBySubject') }}
          </p>
          <ul
            v-if="filteredGroups.length"
            class="flex flex-wrap items-center gap-3"
          >
            <li
              v-for="groupCode in filteredGroups"
              :key="`group-${groupCode}`"
            >
              <KsButton
                type="label"
                class="!px-4"
                :variant="groupCode === selectedSubjectGroup ? 'secondary' : 'border'"
                @click="setSubjectGroup(groupCode)"
              >
                <KsInput
                  v-model="selectedSubjectGroup"
                  type="radio"
                  name="subjectGroup"
                  :checked="groupCode === selectedSubjectGroup"
                  :value="groupCode"
                />
                {{ t(`metadata.subjects.${groupCode}`) }}
              </KsButton>
            </li>
          </ul>
        </div>
        <p
          v-if="filteredProducts.length"
          class="my-4 font-medium"
          v-text="t('home.yourProducts')"
        />
        <ul
          v-if="filteredProducts.length"
          class="relative z-10 -mx-4 flex h-fit flex-wrap gap-3 overflow-x-visible px-4 pb-4"
        >
          <li
            v-for="product in filteredProducts"
            :key="product.ean"
          >
            <KsButton
              variant="secondary"
              shape="rounded"
              class="sm:text-base"
              style="--ks-input: white;"
              :href="buildLegacyProductUrl(product)"
            >
              {{ product.aunivers.name }}
            </KsButton>
          </li>
        </ul>
        <DefaultIllustration />
      </div>
    </div>
  </header>
</template>
