import type { AxiosInstance } from 'axios'
import axios from 'axios'
import { MaxTimeoutMs } from '~/constants/api'

export const xApiClient: AxiosInstance = axios.create({
  timeout: MaxTimeoutMs,
  baseURL: import.meta.env.VITE_XAPI_URL,
  headers: {
    'X-Experience-API-Version': '1.0.3',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Basic ${import.meta.env.VITE_XAPI_TOKEN}`
  },
})
