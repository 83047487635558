export enum GradeType {
  /** Barneskole 1-4 */
  LowerPrimary = 'lowerPrimary',

  /** Barneskole 5-7 */
  UpperPrimary = 'upperPrimary',

  /** Ungdomskole 8-10 */
  LowerSecondary = 'lowerSecondary',

  /** Videregående vg1-vg3 */
  UpperSecondary = 'upperSecondary',

  /** Voksenopplæring */
  AdultEducation = 'adultEducation',

  /** Forkurs */
  PrepEducation = 'prepEducation',
}
