<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import AuLogo from '~/components/utils/AuLogo.vue'

const { t } = useI18n()
</script>
<template>
  <div class="flex w-full justify-center py-8">
    <a
      href="/"
      class="inline-flex focus-visible:ring"
      :aria-label="t('home')"
    >
      <AuLogo class="h-14 w-auto text-seagreen-50" />
    </a>
  </div>
</template>
