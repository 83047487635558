<script setup lang="ts">
import type { BasePageProps } from '~/models/Presentation/BasePage'
import type { ThreeStepPage } from '~/models/Presentation/Pages/ThreeStepPage'
import { computed } from 'vue'
import PresentationRichText from '~/components/PresentationPages/Components/PresentationRichText.vue'
import useImage from '~/composables/useImage'
import useHiddenHeading from '~/composables/useHiddenHeading'
import LottiePlayer from '~/components/media/LottiePlayer.vue'
import ImageWithCaption from '~/components/media/ImageWithCaption.vue'

const props = defineProps<BasePageProps & { page: ThreeStepPage }>()
const { getSvgOrImage, getImageSizeClass } = useImage()
const { hasHeading } = useHiddenHeading()

const lineHeights: Record<string,string> = {
  'font-tiempos': '!leading-[1.14]',
  'font-inter': '!leading-[1.2]',
  'font-calistoga': '!leading-tight',
  'font-playpen': '!leading-normal',
}

const image = getSvgOrImage(props.page.svg, props.page.image)

const lottieId = computed( () => Number(props.page.lottie.destinationContentId))
</script>
<template>
  <div
    class="relative grid h-screen w-full grid-cols-2 overflow-hidden px-16"
    :style="{ background: page.colorPair.background.rgb, color: page.colorPair.text.rgb }"
  >
    <h1
      v-if="!hasHeading"
      class="sr-only"
      v-text="page.title"
    />
    <Transition
      enter-active-class="duration-700 ease delay-1000"
      enter-from-class="-translate-y-1/2 transform opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="duration-500 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="transform -translate-x-full opacity-0"
      appear
    >
      <h1
        v-show="page.step === 1"
        class="relative z-10 m-auto pr-16"
        :class="[page.textSizes.heading, page.fontFamily, page.fontWeight, lineHeights[page.fontFamily]]"
      >
        <span
          :style="{ background: page.colorPair.background.rgb }"
          v-text="page.header"
        />
      </h1>
    </Transition>

    <div aria-hidden="true" />

    <Transition
      enter-active-class="ease duration-[900ms] delay-200"
      enter-from-class="transition translate-x-[20%] opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="duration-1000 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="transform translate-x-full opacity-0"
    >
      <div
        v-show="page.step === 3"
        class="m-auto max-h-[calc(100%-9rem)] overflow-y-hidden pl-16 md:max-h-[calc(100%-11rem)]"
      >
        <h2
          v-if="page.subHeader"
          :class="[page.textSizes.subHeading, page.fontFamily, page.fontWeight]"
          v-text="page.subHeader"
        />
        <PresentationRichText
          v-if="page.body"
          :page="page"
        />
      </div>
    </Transition>

    <Transition
      enter-active-class="ease duration-1000"
      enter-from-class="transition opacity-0"
      enter-to-class="opacity-100"
    >
      <div
        class="absolute aspect-auto h-full w-full translate-x-1/2 transition duration-1000"
        :class="{
          '!translate-x-0': page.step === 2,
          '!-translate-x-1/2': page.step === 3,
        }"
      >
        <LottiePlayer
          v-if="lottieId"
          :content-id="lottieId"
          :pause-animation="page.step !== 2"
          :auto-play="page.step === 2"
          :color-pair="page.colorPair"
        />
        <ImageWithCaption
          v-else-if="image"
          :image="image"
          :classes="`h-full w-full ${getImageSizeClass(page.imageSize)}`"
          :color-pair="page.colorPair"
        />
      </div>
    </Transition>
  </div>
</template>
