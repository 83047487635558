<script setup lang="ts">
import type { GradeCode } from '~/models/Grade'
import { KsButton, KsInput, KsPopover } from '@aschehoug/kloss'
import { computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '~/stores/auth'
import { findGradesByGradeType } from '~/utils/gradeSorter'
import { GradeType } from '~/models/GradeType'
import { storeToRefs } from 'pinia'

const ksToast = inject<Toast>('ksToast')

const { t } = useI18n()
const authStore = useAuthStore()
const { toggleGrade, isReadOnlyGrade, setGradeType } = authStore
const { userGrades } = storeToRefs(authStore)

const grades = computed(() => findGradesByGradeType(GradeType.UpperSecondary))

const safeToggleGrade = (event: MouseEvent, grade: GradeCode) => {
  if (userGrades.value.length === 1 && userGrades.value[0] === grade) {
    event.preventDefault()
    event.stopPropagation()
    ksToast?.info(t('settings.upperSecondaryWeirdness.cantRemoveLastSelected'))
    return
  }
  toggleGrade(grade)
  setGradeType(GradeType.UpperSecondary, userGrades.value)
}

</script>
<template>
  <KsPopover :title="t('filters.chooseGrade')">
    <template #toggle>
      <KsButton
        icon-left="chevron-down"
        shape="square"
      />
    </template>

    <template #content>
      <form class="flex flex-col">
        <KsButton
          v-for="grade in grades"
          :key="grade"
          type="label"
        >
          <KsInput
            type="checkbox"
            :disabled="isReadOnlyGrade(grade)"
            :checked="userGrades.includes(grade)"
            @click="safeToggleGrade($event, grade)"
          />
          {{ t(`metadata.grades.${grade}`) }}
        </KsButton>
      </form>
    </template>
  </KsPopover>
</template>
