<script setup lang="ts">
import type { Product } from '~/models/Product'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton } from '@aschehoug/kloss'
import { isPrepProduct, sortByNameGradeSubject } from '~/utils/productSorter'
import useProductStore from '~/stores/product'
import { ColorName } from '~/models/Content/Color'
import { useAppColor } from '~/composables/useAppColor'
import DefaultSections from '~/components/home/DefaultSections.vue'
import DefaultMessages from '~/components/home/DefaultMessages.vue'
import DefaultIllustration from '~/components/home/DefaultIllustration.vue'
import DefaultGreetings from '~/components/home/DefaultGreetings.vue'

useAppColor(ColorName.White)

const { t } = useI18n()
const { products } = storeToRefs(useProductStore())
const router = useRouter()

const filteredProducts = computed(() => products.value
  .filter(isPrepProduct).sort(sortByNameGradeSubject))

const buildLegacyProductUrl = (product: Product) => {
  if (!product.aunivers || !product.aunivers.urlAlias) {
    return router.resolve({ name: 'notfound' }).href
  }
  const url = new URL(product.aunivers.urlAlias)
  return `${import.meta.env.VITE_AUNIVERS_SITEURL}${url.pathname}`
}
</script>
<template>
  <header class="relative mb-12 flex-col justify-center bg-gradient-to-tr from-seagreen-40 to-custom-green to-60% text-white">
    <div class="relative z-20 mx-auto size-full max-w-screen-au px-4 pb-10 pt-40 transition-all xs:pb-14 sm:px-8 sm:pt-28">
      <h1
        class="sr-only"
        v-text="t('home.title')"
      />
      <DefaultMessages class="mb-6 !px-0" />
      <DefaultGreetings />
      <p
        v-if="filteredProducts.length"
        class="mb-2 mt-4 font-medium"
        v-text="t('home.yourProducts')"
      />
      <div
        v-if="filteredProducts.length"
        class=" xs:pb-0 md:max-w-[70%]"
      >
        <ul
          v-if="filteredProducts.length"
          class="relative z-10 -mx-4 flex h-fit gap-3 overflow-x-scroll px-4 pb-4 xs:flex-wrap xs:overflow-x-visible"
        >
          <li
            v-for="product in filteredProducts"
            :key="product.ean"
          >
            <KsButton
              variant="secondary"
              shape="rounded"
              class="sm:text-base"
              style="--ks-input: white;"
              :href="buildLegacyProductUrl(product)"
            >
              {{ product.aunivers.name }}
            </KsButton>
          </li>
        </ul>
        <DefaultIllustration />
      </div>
    </div>
  </header>
  <DefaultSections />
</template>
