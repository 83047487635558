<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import slugify from 'slugify'
import { storeToRefs } from 'pinia'
import { KsDropdown } from '@aschehoug/kloss'
import useFilterStore from '~/stores/filter'
import { useContentHelper } from '~/composables/useContentHelper'

const props = withDefaults(defineProps<{
  resources?: BaseItem[]
  autoSelect?: boolean
  index: number
  enableGradeFilter?: boolean
  fullWidth?: boolean
  showNumberedHeaders?: boolean
  navigate?: boolean
  resettable?: boolean
}>(), {
  enableGradeFilter: true,
  showNumberedHeaders: true,
})

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const filterStore = useFilterStore()
const { contentFilters, setSelectedHeader, removeSelectedHeader } = filterStore
const { gradeFilterFunc } = contentFilters
const { productHeaders, isLoading, selectedHeaders } = storeToRefs(filterStore)
const { findSubjectFromContent } = useContentHelper()

const currentHeader = computed(() =>
  selectedHeaders.value[props.index])

const numberedHeaders = computed(() => {
  if (props.resources) return []
  return productHeaders.value.learningPath
})

const headers = computed(() =>
  props.resources
    ? props.resources
    : productHeaders.value.flexibleContent)

const { isHeader } = useContentHelper()

const allHeaders = computed(() => [
  ...numberedHeaders.value,
  ...headers.value,
].filter((header: BaseItem) => !props.enableGradeFilter || gradeFilterFunc(header)).filter(isHeader))

const showFilter = ref(true)
const hasHeaders = computed(() => showFilter.value && allHeaders.value.length > 0)

const selectHeader = async (header: BaseItem) => {
  const subject = findSubjectFromContent(header)
  const headerIndex = selectedHeaders.value
    .findIndex(({ locationId }) => locationId === header.locationId)

  if (props.navigate && subject) {
    await router.push({
      name: 'header',
      params: {
        subject: subject.toLowerCase(),
        locationId: header.locationId,
        slug: slugify(header.title)
      },
      query: route.query,
    })
  }

  if (headerIndex > -1) {
    removeSelectedHeader(header)
    return
  }

  setSelectedHeader([header])
}

const threeNonBreakingSpaces = '   '
const getHeaderName = (header: BaseItem) => {
  if (props.showNumberedHeaders && productHeaders.value.learningPath.includes(header)) {
    const index = allHeaders.value.findIndex(({ locationId }) => locationId === header.locationId)
    return `${index + 1}${threeNonBreakingSpaces}${header.title}`
  }
  return header.title
}

onMounted(() => {
  if (props.autoSelect && allHeaders.value.length > 0) {
    showFilter.value = false
    selectHeader(allHeaders.value[0])
  }
})

watch(selectedHeaders, () => {
  showFilter.value = !(currentHeader.value && allHeaders.value.length === 1)
}, { immediate: true })
</script>

<template>
  <KsDropdown
    v-if="hasHeaders"
    :model-value="currentHeader"
    :options="allHeaders"
    :option-id="(header: BaseItem) => header.locationId"
    :option-label="getHeaderName"
    :selectable="() => !isLoading"
    :placeholder="t('filters.chooseTheme')"
    shape="rounded"
    autolabel
    flexible
    :class="fullWidth && 'w-full'"
    :resettable
    @select="(header: BaseItem) => selectHeader(header)"
    @reset="() => removeSelectedHeader(currentHeader)"
  />
</template>
