<script setup lang="ts">
import { LoginSource } from '~/models/Login'
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '~/stores/auth'
import { KsButton, KsCallout, KsInput } from '@aschehoug/kloss'
import { useRoute } from 'vue-router'
import useSelfServiceApi from '~/api/selfServiceApi'
import FlowHeader from '~/components/FlowHeader.vue'
import InputError from '~/components/register/InputError.vue'
import useLogin from '~/composables/useLogin'

const { t } = useI18n()
const route = useRoute()
const { isAuthenticated } = storeToRefs(useAuthStore())
const { activateUser } = useSelfServiceApi()
const { createLoginUrl } = useLogin()

const viewMode = ref<'activate'|'success'>('activate')
const inputType = ref<'password'|'text'>('password')
const password = ref('')
const error = ref('')
const blurred = ref(false)
const isLoading = ref(false)

const username = computed(() => route.query?.username as string || '')
const token = computed(() => route.query?.code as string || '')

const validPassword = computed(() => password.value.length >= 8
  &&  /^(?=[^A-ZÅØÆa-zåøæ\s]*[A-ZÅØÆa-zåøæ])(?=[^\d\s]*\d)\S*$/.test(password.value))

const changeInput = computed(()=> inputType.value === 'password'
  ? t('register.activate.inputText')
  : t('register.activate.inputPassword'))

const hasParams = computed(() => username.value && token.value)
const canActivate = computed(() => validPassword.value && hasParams.value)

function toggleInput() {
  inputType.value = inputType.value === 'password' ? 'text' : 'password'
}

async function activatePassword() {
  if (!canActivate.value) {
    return
  }

  error.value = ''
  isLoading.value = true
  try {
    await activateUser({
      username: username.value,
      password: password.value,
      token: token.value
    })
    viewMode.value = 'success'
  } catch(e) {
    error.value = t('register.error.activate')
    throw e
  } finally {
    isLoading.value = false
  }
}
</script>
<template>
  <FlowHeader v-if="!isAuthenticated" />
  <section class="relative m-auto mt-8 max-w-screen-au px-4 sm:px-8">
    <div class="grid grid-cols-1 place-items-center">
      <div>
        <h1
          class="mb-8 text-center text-4xl font-semibold"
          v-text="t('register.activate.title')"
        />
        <p
          v-if="username"
          class="mb-8 text-center"
          v-text="t('register.activate.info')"
        />
        <KsCallout
          v-if="!hasParams"
          class="mb-4"
          variant="error"
        >
          {{ t('register.error.missingParams') }}
        </KsCallout>
        <KsCallout
          v-if="error"
          class="mb-4"
          variant="error"
        >
          {{ error }}
        </KsCallout>
        <KsCallout
          v-if="viewMode === 'success'"
          variant="success"
        >
          {{ t('register.activate.success') }}
        </KsCallout>

        <div v-if="viewMode === 'activate'">
          <label>
            <span
              class="mb-2 inline-block"
              v-text="t('register.input.password')"
            />
            <KsInput
              v-model="password"
              :type="inputType"
              :disabled="!hasParams"
              @blur="blurred = true"
              @input="blurred = false"
            />
          </label>
          <div class="flex justify-between">
            <InputError
              v-if="!validPassword && blurred && password.length > 0"
              class="order-last"
              :text="t('register.invalid.password')"
            />
            <p class="pt-2 text-right text-gray-40">
              <a
                href="#"
                class="underline"
                @click.prevent="toggleInput"
                v-text="changeInput"
              />
            </p>
          </div>
        </div>

        <div class="mt-4 text-right">
          <KsButton
            href="/"
            variant="secondary"
            size="large"
            :disabled="isLoading"
          >
            {{ viewMode === 'success' ? t('back') : t('cancel') }}
          </KsButton>
          <KsButton
            v-if="viewMode === 'activate'"
            variant="primary"
            size="large"
            :disabled="!canActivate"
            class="ml-4"
            @click.prevent="activatePassword"
          >
            {{ t('register.form.submit') }}
          </KsButton>
          <KsButton
            v-if="viewMode === 'success'"
            :href="createLoginUrl(LoginSource.Local)"
            variant="primary"
            size="large"
            class="ml-4"
          >
            {{ t('login.title') }}
          </KsButton>
        </div>
      </div>
    </div>
  </section>
</template>
