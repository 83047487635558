export enum QueryParamKey {
  Subject = 's',
  Grade = 'g',
  TaskType = 'tt',
  Headers = 'hs',
  SubHeaders = 'shs',
  Product = 'p',
  WeekNumber = 'w',
  Filters = 'f',
  PlannedResources = 'p',
}
