import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import useSchoolYear from '~/composables/useSchoolYear'
import useTime from '~/composables/useTime'

export const useWeekStore = defineStore('week', () => {
  const { weekNumberToDateTime } = useTime()
  const { schoolYearByWeek } = useSchoolYear()
  const selectedWeekNumber = ref<number>(0)

  const selectedWeekStart = computed(() => {
    if (!selectedWeekNumber.value) return
    return weekNumberToDateTime(selectedWeekNumber.value, schoolYearByWeek(selectedWeekNumber.value)).startOf('day')
  })

  const selectedWeekEnd = computed(() => {
    if (!selectedWeekNumber.value) return
    return weekNumberToDateTime(selectedWeekNumber.value, schoolYearByWeek(selectedWeekNumber.value)).plus({ days: 6 }).endOf('day')
  })

  return {
    selectedWeekNumber,
    selectedWeekStart,
    selectedWeekEnd,
  }
})

export default useWeekStore
