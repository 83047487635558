import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import useFilterStore from '~/stores/filter'
import { useContentHelper } from '~/composables/useContentHelper'
import { storeToRefs } from 'pinia'

export function useContentFilter(
  showFilter: boolean = true,
  enableGradeFilter: boolean = true
) {
  const {
    selectedHeaders,
    productHeaders,
    packageHeader,
    contentFilters,
  } = storeToRefs(useFilterStore())
  const { isPackage, getParentLocationId } = useContentHelper()

  const sortContents = (a: BaseItem, b: BaseItem) =>
    (getParentLocationId(a) - getParentLocationId(b))

  const contents = computed<BaseItem[]>(() => {
    if (selectedHeaders.value.length === 0) return [
      ...productHeaders.value.learningPath,
      ...(packageHeader.value ? packageHeader.value.children : []),
    ]
    return selectedHeaders.value[selectedHeaders.value.length - 1].children
  })

  const filteredContents = computed<BaseItem[]>(() => [...contents.value].sort(sortContents)
    .filter((content) => Object.values(contentFilters.value)
      .filter(() => enableGradeFilter)
      .every((filter: CallableFunction) => !showFilter || filter(content))
  ))

  const filteredProductPackages = computed(() => filteredContents.value
    .filter((content) => isPackage(content)))

  return {
    contents,
    filteredContents,
    filteredProductPackages
  }
}
