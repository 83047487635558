<script setup lang="ts">
import { KsButton, KsInput } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '../../stores/auth'
import { PreferredLanguage } from '../../models/User/PreferredLanguage'

const { t } = useI18n()
const authStore = useAuthStore()
const {
  isPreferredLanguage,
  setPreferredLanguage,
} = authStore
</script>
<template>
  <p
    class="text-center text-gray-40"
    v-text="t('filters.chooseLanguage')"
  />
  <form class="mt-6">
    <fieldset class="flex justify-center gap-2">
      <KsButton
        type="label"
        variant="secondary"
        shape="rounded"
      >
        <KsInput
          :value="PreferredLanguage.Bokmal"
          type="radio"
          :checked="isPreferredLanguage(PreferredLanguage.Bokmal)"
          @click="setPreferredLanguage(PreferredLanguage.Bokmal)"
        />
        {{ t(`metadata.language.${PreferredLanguage.Bokmal}`) }}
      </KsButton>
      <br>
      <KsButton
        type="label"
        variant="secondary"
        shape="rounded"
      >
        <KsInput
          :value="PreferredLanguage.Nynorsk"
          type="radio"
          :checked="isPreferredLanguage(PreferredLanguage.Nynorsk)"
          @click="setPreferredLanguage(PreferredLanguage.Nynorsk)"
        />
        {{ t(`metadata.language.${PreferredLanguage.Nynorsk}`) }}
      </KsButton>
    </fieldset>
  </form>
</template>
