<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton } from '@aschehoug/kloss'
import useBlogStore from '~/stores/blog'
import BlogPostSimpleCard from '~/components/cards/BlogPostSimpleCard.vue'

const props = defineProps<{ limit: number }>()

const { t } = useI18n()
const { getPosts } = useBlogStore()
const { postsByGrades } = storeToRefs(useBlogStore())

const posts = computed(() => postsByGrades.value.slice(0, props.limit))

onMounted(() => getPosts(props.limit * 3))
</script>

<template>
  <section
    v-if="postsByGrades.length"
    class="mx-auto w-full max-w-screen-au space-y-5 px-4 py-12 sm:px-8"
  >
    <h2
      class="text-xl font-bold text-gray-50"
      v-text="t('blog.title')"
    />
    <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <BlogPostSimpleCard
        v-for="post in posts"
        :key="`Nyhetsartikkel-${post.contentId}`"
        :blog-post="post"
      />
    </ul>
    <RouterLink
      :to="{ name: 'news' }"
      class="inline-flex rounded-xl focus-visible:ring"
    >
      <KsButton
        variant="primary"
        type="label"
      >
        {{ t('blog.seAllPosts') }}
      </KsButton>
    </RouterLink>
  </section>
</template>
