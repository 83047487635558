import { ref } from 'vue'
import { defineStore } from 'pinia'

interface Subscriptions {
  onLoadingStarted?: Array<() => void>
  onLoadingUpdate?: Array<() => void>
  onLoadingFinished?: Array<() => void>
}

export default defineStore('loaders', () => {
  const loaders = ref<Record<string, boolean>[]>([])
  const subscriptions = ref<Record<string, Subscriptions>>({})

  const runQueues = (key: string) => {
    const loader = loaders.value.find((loader) => loader[key])
    if (loader && !!subscriptions.value[key]?.onLoadingStarted) {
      subscriptions.value[key]?.onLoadingStarted?.forEach((callback) =>
        callback())
      delete subscriptions.value[key].onLoadingStarted

      return
    }

    if (loader) {
      subscriptions.value[key]?.onLoadingUpdate?.forEach((callback) =>
        callback())

      return
    }

    subscriptions.value[key]?.onLoadingFinished?.forEach((callback) =>
      callback())
  }

  const isLoading = (key?: string) =>
    key
      ? !!loaders.value.find((loader) => loader[key]) || false
      : loaders.value.length > 0

  const startLoading = (key: string) => {
    loaders.value = [...loaders.value, { [key]: true }]
    runQueues(key)
  }

  const stopLoading = (key: string) => {
    loaders.value = loaders.value.filter((loader) => !loader[key])
    runQueues(key)
  }

  const getQueueSize = (key: string) =>
    loaders.value.filter((loader) => loader[key]).length

  const addSubscription = (key: string, subscription: keyof Subscriptions, callback: () => void) =>
    subscriptions.value = {
      ...subscriptions.value,
      [key]: {
        ...subscriptions.value[key],
        [subscription]: [
          ...(subscriptions.value[key]?.[subscription] || []),
          callback,
        ],
      },
    }
  const onLoadingStarted = (key: string, callback: () => void) =>
    addSubscription(key, 'onLoadingStarted', callback)

  const onLoadingFinished = (key: string, callback: () => void) =>
    addSubscription(key, 'onLoadingFinished', callback)

  const onLoadingUpdate = (key: string, callback: () => void) =>
    addSubscription(key, 'onLoadingUpdate', callback)

  return {
    loaders,
    isLoading,
    startLoading,
    stopLoading,
    onLoadingStarted,
    onLoadingFinished,
    onLoadingUpdate,
    getQueueSize,
  }
})
