<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import useMetadataApi from '~/api/metadataApi'
import useTransition from '~/composables/useTransition'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import { useContentHelper } from '~/composables/useContentHelper'

const { resource } = defineProps<{
  resource: BaseItem
}>()

const { buildResourceUrl, buildLearningPathUrl } = useUrlBuilder()
const { getLearningPathProgress } = useMetadataApi()
const { fadeOut } = useTransition()
const { getTitle } = useContentHelper()

const getLearningPathLink = async () => {
  const resourceUrl = buildResourceUrl(resource)
  if (!resource.locationId) return resourceUrl
  const previousLocationId = await getLearningPathProgress(resource.locationId)
  if (previousLocationId === resource.locationId) return resourceUrl
  if (previousLocationId) return buildLearningPathUrl(resource, previousLocationId)
  return resourceUrl
}

const getUrlTarget = (event: MouseEvent) => {
  const newWindowByKey = event.ctrlKey || event.shiftKey || event.metaKey || (event.button && event.button === 1)
  return newWindowByKey ? '_blank' : '_self'
}

const openLearningPathLink = async (event: PointerEvent) => {
  event.preventDefault()
  fadeOut(event)
  let learningPathLink = await getLearningPathLink()

  learningPathLink = learningPathLink.includes('?')
    ? learningPathLink + '&f=1'
    : learningPathLink + '?f=1'

  window.open(learningPathLink, getUrlTarget(event))
}

const url = computed(() => buildResourceUrl(resource))
const title = computed(() => getTitle(resource))
</script>

<template>
  <a
    :href="url"
    @click="openLearningPathLink"
    v-text="title"
  />
</template>
