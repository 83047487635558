export default () => {
  const getEnv = () => <string>import.meta.env.VITE_ENV
  const isDev = () => getEnv() === 'dev'
  const isTest = () => getEnv() === 'test'
  const isStage = () => getEnv() === 'stage'
  const isProd = () => getEnv() === 'prod'

  return {
    getEnv,
    isDev,
    isTest,
    isStage,
    isProd,
  }
}
