<script lang="ts" setup>
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import 'vidstack/bundle'
import Captions from '~/components/media/Captions.vue'

const { tm } = useI18n()

defineProps({
  src: {
    type: String,
    required: true,
  },
  posterSrc: String,
  autoplay: Boolean,
  mediaId: String,
})

onMounted(() => {
  const layout = document.querySelector('media-video-layout')
  if (!layout) return
  layout.translations = tm('vidstack')
})
</script>

<template>
  <!-- [playsinline] tells the player to not enter fullscreen automaticlly when playing. If this is a desired effect, it could be moved to a property -->
  <media-player
    :src="src"
    :autoplay="autoplay"
    crossorigin="use-credentials"
    hide-controls-on-mouse-leave
    playsinline
  >
    <slot>
      <!-- If overwriting the default slot, a custom media provider must be provided. This is where the media renders (<video> tag etc.)-->
      <media-provider @contextmenu.prevent>
        <media-poster
          class="absolute inset-0 block size-full rounded-md opacity-0 transition-opacity data-[visible]:opacity-100 [&>img]:size-full [&>img]:object-cover"
          :src="posterSrc"
        />

        <template v-if="mediaId">
          <Captions
            :media-id
            kind="subtitles"
          />
          <Captions
            :media-id
            kind="chapters"
          />
        </template>
      </media-provider>

      <slot name="controls">
        <!-- Default controls -->
        <media-video-layout />
      </slot>
    </slot>
  </media-player>
</template>
