<script setup lang="ts">
import { KsButton, KsInput } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '~/stores/auth'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const authStore = useAuthStore()
const { updateUserOrganization } = authStore
const { userOrganization, userOrganizations } = storeToRefs(authStore)

</script>
<template>
  <p
    class="text-center text-gray-40"
    v-text="t('organization.title')"
  />
  <form class="mt-6">
    <fieldset class="flex flex-wrap justify-center gap-2">
      <KsButton
        v-for="organization in userOrganizations"
        :key="organization.number"
        type="label"
        variant="secondary"
        shape="rounded"
      >
        <KsInput
          type="radio"
          name="organization"
          :checked="organization.number === userOrganization?.number"
          :value="organization.number"
          @click="updateUserOrganization(organization)"
        />
        {{ organization.name }}
      </KsButton>
    </fieldset>
  </form>
</template>
