import { ref } from 'vue'
import { defineStore } from 'pinia'

const useSettingsStore = defineStore('settings', () => {

  const drawerOpen = ref<boolean>(false)
  const groupsOpen = ref<boolean>(false)
  const subscriptionsOpen = ref<boolean>(false)
  const plansOpen = ref<boolean>(false)

  return {
    drawerOpen,
    groupsOpen,
    subscriptionsOpen,
    plansOpen,
  }
})

export default useSettingsStore
