import type { CompoundedProgress, SimpleProgress } from '~/models/Activity'
import type { xAPIParams, xAPIStatement } from '~/models/xAPIStatement'
import type { SubjectCode } from '~/models/Subject'
import { activityClient } from '~/api/client/activityClient'
import { xApiClient } from '~/api/client/xApiClient'
import { contentClient } from '~/api/client/contentClient'

const env = import.meta.env.VITE_ACTIVITY_API_ENV

const validateStatus = (status: number) => status >= 200 && status < 300

export const useActivityApi = () => {
  const loadStatement = async (locationId: number, params: xAPIParams = {}): Promise<xAPIStatement> => {
    // @ts-ignore - URLSearchParams says that it requires Record<string, string> but it also accepts Record<string, number>
    const queryParams = (new URLSearchParams(params)).toString()
    const { data } = await contentClient.get(`/activity/statement/${locationId}?${queryParams}`)
    return data
  }

  const postStatement = (statement: xAPIStatement) => xApiClient.post('/statements', statement)

  // WARNING: locationIds has to be sent as strings. If you send as numbers, it will silently fail
  const getCompoundProgress = async (userIds: string[], locationIds: string[], fromDate?: string): Promise<CompoundedProgress> => {
    if (typeof locationIds[0] !== 'string') throw new Error(`locationIds must be strings, ${typeof locationIds[0]} given`)
    const query = JSON.stringify({ users: userIds, pageIDs: locationIds })

    const { data } = await activityClient.get('/progress/compounded', { validateStatus, params: { query, env, fromDate } })
    return data
  }

  const getCompoundedProgressBySubjects = async (userIds: string[], subjects: SubjectCode[], fromDate?: string): Promise<CompoundedProgress> => {
    if (subjects.length === 0) throw new Error('Subjects must be provided')
    if (userIds.length === 0) throw new Error('Users must be provided')
    const query = JSON.stringify({ subjects, users: userIds })
    const { data } = await activityClient.get('/progress/compounded', { validateStatus, params: { query, env, fromDate } })
    return data
  }

  const getSimpleActivity = async (userIds: string[], subjects: SubjectCode[], fromDate: string): Promise<SimpleProgress> => {
    if (subjects.length === 0) throw new Error('Subjects must be provided')
    if (userIds.length === 0) throw new Error('Users must be provided')
    const query = JSON.stringify({ subjects, users: userIds })
    const { data } = await activityClient.get('/progress/simple', { validateStatus, params: { query, env, fromDate } })
    return data
  }

  return {
    getCompoundProgress,
    loadStatement,
    postStatement,
    getCompoundedProgressBySubjects,
    getSimpleActivity,
  }
}

export default useActivityApi
