<script setup lang="ts">
import type { PlannedItem } from '~/models/Content/PlannedItem'
import type { Group } from '~/models/Group'
import { KsButton, KsPopover } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { computed, defineAsyncComponent, inject, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import CopyUrlButton from '~/components/cards/buttons/CopyUrlButton.vue'
import { usePlannerApi } from '~/api/plannerApi'
import useDialogStore from '~/stores/dialog'
import arrayUtils from '~/utils/arrayUtils'
import useWeekStore from '~/stores/week'

const props = defineProps<{ resources: Record<string, PlannedItem[]> }>()
const emit = defineEmits(['resourcesCopied'])

const { t } = useI18n()
const { showDialog } = useDialogStore()
const { truthy, unique } = arrayUtils()
const { copyYearPlansToGroup } = usePlannerApi()
const { selectedWeekNumber } = storeToRefs(useWeekStore())
const { isTeacher, activeUserGroup } = storeToRefs(useAuthStore())

const currentUrl = ref<string>(window.location.href)
const ksToast = <Toast>inject('ksToast')

const allResources = computed(() =>
  Object.values(props.resources).flatMap((resources) => resources))

const weekNumber = computed(() => t('time.weekWithNumber', { number: selectedWeekNumber.value }).toLowerCase())

const copyToAnotherGroup = async (resources: PlannedItem[], groupName: string) => {
  const group = await showDialog<Group>(
    defineAsyncComponent(() => import('~/components/group/SelectGroupDialog.vue')), {
    groupName,
    body: t('plan.copyYearPlanToGroupExplanation', { weekNumber: weekNumber.value, groupName }),
    title: t('plan.chooseGroupToCopyTo'),
    cancelButtonText: t('planner.copyYearPlan.preview.cancel'),
    confirmButtonText: t('planner.copyYearPlan.preview.copy'),
  }
  )
  const yearPlanIdsToCopy = resources.map(r => r.yearPlan).filter(truthy).filter(unique)

  await copyYearPlansToGroup(group, yearPlanIdsToCopy, selectedWeekNumber.value)
    .finally(() => ksToast.success({
      message: t('plan.resourcesCopied', { groupName: group.name }),
      duration: 8000
    }))

  emit('resourcesCopied')
}
</script>

<template>
  <KsPopover
    :title="t('plan.copySelection')"
    direction="down left"
  >
    <template #toggle>
      <KsButton
        v-ks-tooltip.left="t('plan.copySelection')"
        variant="secondary"
        shape="round"
        icon-left="copy"
      />
    </template>
    <template #content>
      <CopyUrlButton
        :url="currentUrl"
        stretch
      />
      <KsButton
        v-if="isTeacher"
        stretch
        @click="copyToAnotherGroup(allResources, activeUserGroup.name)"
      >
        {{ t('plan.copyYearPlanToGroup') }}
      </KsButton>
    </template>
  </KsPopover>
</template>
