<script setup lang="ts">
import { KsButton, KsInput } from '@aschehoug/kloss'
import { useAuthStore } from '~/stores/auth'
import { useI18n } from 'vue-i18n'
import { GradeType } from '~/models/GradeType'
import { storeToRefs } from 'pinia'
import UpperSecondaryGradeSelectorPopover from '~/components/settings/UpperSecondaryGradeSelectorPopover.vue'

const { t } = useI18n()
const authStore = useAuthStore()
const { selectedGradeType, hasGrades } = storeToRefs(authStore)
</script>
<template>
  <div>
    <p
      class="text-center text-gray-40"
      v-text="t('filters.chooseGradeType')"
    />
    <form class="mt-6 flex flex-wrap justify-center gap-3">
      <KsButton
        v-for="gradeType in GradeType"
        :key="gradeType"
        variant="secondary"
        shape="rounded"
        type="label"
      >
        <KsInput
          type="radio"
          :checked="hasGrades && (selectedGradeType === gradeType)"
          @click="authStore.setGradeType(gradeType)"
        />
        {{ t(`metadata.gradeTypes.${gradeType}`) }}
        <UpperSecondaryGradeSelectorPopover v-if="gradeType === GradeType.UpperSecondary" />
      </KsButton>
    </form>
  </div>
</template>
