<script setup lang="ts">
import type { WindowWith } from '~/models/Window'
import { computed, ref } from 'vue'

/* NOTE:
 * As browser don't run <script> tags after page load, they have to be loaded in a running script (like this one).
 * Which means we need to load the scripts manually. This also lets us control what scripts we load.
 */

const props = defineProps<{ code: string }>()
const isSupported = ref(false)

/** NRK Video embed */
if (props.code.includes('https://static.nrk.no/ludo/latest/video-embed.js')) {
  isSupported.value = true
  //@ts-expect-error
  import('https://static.nrk.no/ludo/latest/video-embed.js')
    .then(() => {
      (window as WindowWith<{ nrk: any }>).nrk?.embed?.video?.initialize?.()
    })
}

// Browsers will never load script tag inserted this way, but inserting unknown script tags is a bad idea
const safeEmbedCode = computed(() => props
  .code
  .replaceAll('<script', '<template')
  .replaceAll('script>', 'template>')
  .replaceAll(/\son(\w+)=/g, ' data-on$1=')
)
</script>

<template>
  <div
    v-if="isSupported"
    v-html="safeEmbedCode"
  />

  <div
    v-else
    class="grid place-content-center bg-[black] text-white"
  >
    {{ $t('embeds.notSupported') }}
  </div>
</template>
