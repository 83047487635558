<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useContentHelper } from '~/composables/useContentHelper'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'

const props = defineProps<{ resource: BaseItem; isAnchor?: boolean }>()

const { getTitle } = useContentHelper()
const { buildResourceUrl } = useUrlBuilder()
const { t } = useI18n()

const title = computed(() => getTitle(props.resource))
const link = computed(() => buildResourceUrl(props.resource))
</script>
<template>
  <aside
    v-if="resource.new"
    class="pointer-events-none absolute right-0 top-0 z-20 rounded-bl-2xl bg-green-40 p-1.5 pl-2 text-sm font-medium uppercase tracking-wider text-white"
    v-text="t('newShort')"
  />
  <LinkOrAnchor
    :link="link"
    class="flex h-full w-full items-center border border-[--card-border] bg-[--card-bg] p-6 text-xl font-bold text-[--card-text] ring-inset transition duration-100 hover:bg-white hover:ring hover:ring-[--card-border] focus-visible:ring"
    :is-anchor="props.isAnchor"
  >
    {{ title }}
  </LinkOrAnchor>
</template>
