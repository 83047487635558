import { onBeforeUnmount, onMounted } from 'vue';

export function useScrollBehavior() {
  const setScrollBehavior = (behavior: 'smooth' | 'auto') => {
    const htmlElement = document.querySelector('html');
    if (htmlElement) {
      htmlElement.style.scrollBehavior = behavior;
    }
  };

  const enableSmoothScroll = () => setScrollBehavior('smooth');
  const disableSmoothScroll = () => setScrollBehavior('auto');

  onMounted(enableSmoothScroll);
  onBeforeUnmount(disableSmoothScroll);

  return { enableSmoothScroll, disableSmoothScroll };
}
