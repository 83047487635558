<script setup lang="ts">
import type { PlannedItem } from '~/models/Content/PlannedItem'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsIcon } from '@aschehoug/kloss'
import useWeekStore from '~/stores/week'
import useSubjectsStore from '~/stores/subjects'
import usePlannerStore from '~/stores/planner'
import { useAuthStore } from '~/stores/auth'
import CardSkeleton from '~/components/skeletons/CardSkeleton.vue'
import CardRenderer from '~/components/cards/CardRenderer.vue'

const props = defineProps<{ groupedResources: Record<string, PlannedItem[]> }>()

const { t } = useI18n()
const { currentSubject } = storeToRefs(useSubjectsStore())
const { activeUserGroup, isTeacher } = storeToRefs(useAuthStore())
const { selectedWeekNumber } = storeToRefs(useWeekStore())
const { isLoadingYearPlans } = storeToRefs(usePlannerStore())

const activeGroupResources = computed(() =>
  props.groupedResources[activeUserGroup.value?.name] || [])

const allResources = computed(() =>
  Object.values(props.groupedResources).flatMap((resources) => resources))

const studentWithNoResources = computed(() => !isTeacher.value && !allResources.value.length)

const noPlannedResourcesMessage = computed(() => t('plan.noPlans', {
  subject: t(`metadata.subjects.${currentSubject.value}`).toLowerCase(),
  weekNumber: t('time.weekWithNumber', { number: selectedWeekNumber.value }).toLowerCase() }))
</script>

<template>
  <ul
    v-if="isLoadingYearPlans"
    class="mb-6 grid grid-cols-1 gap-6 pt-6 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
  >
    <CardSkeleton
      v-for="i in 10"
      :key="i"
    />
  </ul>
  <ul
    v-if="!isLoadingYearPlans && isTeacher && activeGroupResources.length"
    class="mb-6 grid grid-cols-1 gap-6 pt-6 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
  >
    <li
      v-for="(resource) in activeGroupResources"
      :key="resource.identifier"
    >
      <CardRenderer
        :resource="resource"
        is-anchor
      />
    </li>
  </ul>
  <div v-if="!isLoadingYearPlans && !isTeacher">
    <div
      v-for="(resources, groupName) in groupedResources"
      :key="groupName"
      :class="{'border-b border-b-gray-20 last:border-0': resources.length}"
    >
      <div v-if="resources.length">
        <h3 class="mt-8 flex items-center gap-2 text-2xl font-bold text-black">
          <KsIcon icon="users" />
          <span v-text="groupName" />
        </h3>
        <ul class="my-8 grid grid-cols-1 gap-6 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          <li
            v-for="(resource) in resources"
            :key="resource.identifier"
          >
            <CardRenderer
              :resource="resource"
              is-anchor
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    v-if="isTeacher && !isLoadingYearPlans && !activeGroupResources.length && currentSubject"
    class="mt-10 text-xl"
  >
    <p
      class="mb-5"
      v-text="noPlannedResourcesMessage"
    />
    <RouterLink
      :to="{ name: 'subject', params: { subject: currentSubject.toLowerCase() } }"
      class="inline-flex rounded-xl focus-visible:ring"
    >
      <KsButton
        variant="border"
        type="label"
        icon-right="arrow-right"
      >
        {{ t('plan.goToResources', { subject: t(`metadata.subjects.${currentSubject}`) }) }}
      </KsButton>
    </RouterLink>
  </div>
  <div
    v-if="studentWithNoResources && !isLoadingYearPlans && currentSubject"
    class="mt-10 text-xl"
  >
    <p
      class="mb-5"
      v-text="noPlannedResourcesMessage"
    />
    <RouterLink
      :to="{ name: 'subject', params: { subject: currentSubject.toLowerCase() } }"
      class="inline-flex rounded-xl focus-visible:ring"
    >
      <KsButton
        variant="border"
        type="label"
        icon-right="arrow-right"
      >
        {{ t('plan.goToResources', { subject: t(`metadata.subjects.${currentSubject}`) }) }}
      </KsButton>
    </RouterLink>
  </div>
</template>
