<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton } from '@aschehoug/kloss'
import useSettingsStore from '~/stores/settings'
import { PendoFeature } from '~/models/Pendo'
import useSettings from '~/composables/useSettings'
import SettingsDrawer from '~/components/settings/SettingsDrawer.vue'

const emit = defineEmits(['close'])

const { t } = useI18n()
const { toggleSettings } = useSettings()
const { drawerOpen } = storeToRefs(useSettingsStore())


const close = () => {
  toggleSettings()
  emit('close')
}
</script>

<template>
  <div class="block sm:hidden">
    <KsButton
      stretch
      icon-left="cog"
      size="large"
      :data-pendo="PendoFeature.Navigation.Settings"
      @click.prevent="toggleSettings()"
    >
      {{ t('settings.title') }}
    </KsButton>
  </div>
  <div class="hidden sm:block">
    <KsButton
      v-ks-tooltip="t('settings.title')"
      variant="secondary"
      shape="round"
      size="large"
      icon-left="cog"
      :data-pendo="PendoFeature.Navigation.Settings"
      @click.prevent="toggleSettings()"
    />
  </div>
  <SettingsDrawer
    :open="drawerOpen"
    @close="close"
  />
</template>
