import type { FilterOptions } from '~/models/FilterOption'
import { ref } from 'vue'
import { defineStore } from 'pinia'

const useFilterSettingsStore = defineStore('filterSettings', () => {

  const defaultFilterOptions: FilterOptions = {
    subject: true,
    grade: true,
    header: true,
  }

  const filterOptions = ref<FilterOptions>({ ...defaultFilterOptions })

  const setFilterOptions = (options: FilterOptions) =>
    filterOptions.value = { ...defaultFilterOptions, ...options }

  const resetFilterOptions = () => filterOptions.value = defaultFilterOptions

  return {
    filterOptions,
    defaultFilterOptions,
    setFilterOptions,
    resetFilterOptions,
  }
})

export default useFilterSettingsStore
