import type { BaseItem } from '~/models/Content/BaseItem'
import type { MinimalResourceItem } from '~/models/AddToWeek'
import type { PlannedItem } from '~/models/Content/PlannedItem'
import type { CreateResource } from '~/models/Content/CreateResource'
import type { ContentResource } from '~/models/Content/ContentResource'
import type { ContentLink } from '~/models/Content/ContentLink'
import type { ResourceItem } from '~/models/Resource'
import type { PlanResponse, WeekResponse, YearPlanResponse } from '~/models/Planner/Response'
import type { Week } from '~/models/Planner/Week'
import type { Plan } from '~/models/Planner/Plan'
import type { YearPlan } from '~/models/Planner/YearPlan'
import type { Location } from '~/models/Content/Response'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useResourceMapper from '~/composables/useResourceMapper'
import useContentMapper from '~/composables/useContentMapper'

export default () => {
  const { buildResourceUrl } = useUrlBuilder()
  const { mapResourceToLocation } = useResourceMapper()
  const { mapBaseFields, getFieldValue } = useContentMapper()

  const mapResourceItem = (
    resource: BaseItem|ContentResource|ContentLink|PlannedItem|CreateResource
  ): Required<MinimalResourceItem> => ({
    ...resource,
    ...{
      contentTypeName: resource.contentTypeIdentifier,
      url: (resource as ContentResource|ContentLink|PlannedItem)?.url ?? buildResourceUrl(resource),
      imageSrc: resource.thumbnail,
      imageAltText: resource.thumbnailAlt
    } })

  // Maps a planned Resource from aunivers-api
  const mapPlannedItem = (location: Location): PlannedItem => ({
    ...mapBaseFields(location),
    '@id': getFieldValue<string>(location, '@id', ''),
    identifier: getFieldValue<string>(location, 'identifier', ''),
    yearPlan: getFieldValue<string>(location, 'year_plan_id', ''),
    thumbnail: getFieldValue<string>(location, 'image', ''),
    thumbnailAlt: getFieldValue<string>(location, 'image_alt_text', ''),
    image: getFieldValue<string>(location, 'image', ''),
    imageAltText: getFieldValue<string>(location, 'image_alt_text', ''),
    url: getFieldValue<string>(location, 'url', ''),
    parentLocationId: null,
    activity: getFieldValue<string>(location, 'activity', ''),
  })

  const mapPlannedItems = (resources: ResourceItem[]) => resources
    .map((resource) => mapPlannedItem(mapResourceToLocation(resource)))

  const mapWeek = (week: WeekResponse) => ({
    ...week,
    resources: mapPlannedItems(week?.resources || []),
  }) as Week

  const mapPlan = (plan: PlanResponse) => ({
    ...plan,
    resources: mapPlannedItems(plan?.resources || []),
    weeks: (plan?.weeks || []).map(mapWeek)
  }) as Plan

  const mapYearPlan = (yearPlan: YearPlanResponse) => ({
    ...yearPlan,
    plans: (yearPlan?.plans || []).map(mapPlan)
  }) as YearPlan

  return {
    mapResourceItem,
    mapPlannedItems,
    mapWeek,
    mapPlan,
    mapYearPlan,
  }
}
