import type { DirectiveBinding } from 'vue'
import { FOCUSABLE } from '~/utils/dom'

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const focusableElements = el.querySelectorAll(FOCUSABLE)
    focusableElements.forEach((element) => {
      (element as HTMLElement).tabIndex = binding.value ? -1 : 0
    })
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    const focusableElements = el.querySelectorAll(FOCUSABLE)
    focusableElements.forEach((element) => {
      (element as HTMLElement).tabIndex = binding.value ? -1 : 0
    })
  }
}
