<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import { useI18n } from 'vue-i18n'
import { KsCallout } from '@aschehoug/kloss'

defineProps<{ subjectCode: SubjectCode }>()
const { t } = useI18n()
</script>

<template>
  <div class="relative mx-auto mb-20 size-full max-w-screen-au px-4 sm:px-8">
    <div class="mx-auto mb-16 flex-1 space-y-16 pt-24 transition-all">
      <section class="mx-auto max-w-screen-lg space-y-12">
        <KsCallout
          variant="info"
          :title="t('settings.chooseGradeType')"
        >
          {{ t('settings.gradeTypeWarning') }}
        </KsCallout>
      </section>
    </div>
  </div>
</template>
