import { i18n } from '../translations'
const { t: trans } = i18n.global

export function useI18n() {
  const t = (translationKey: string, options: any = null): string => {
    return options === null ? trans(translationKey) : trans(translationKey, options)
  }

  return {
    t,
  }
}
