<script setup lang="ts">
const props = defineProps< {
  text: string
}>()
</script>
<template>
  <p
    class="pt-2 text-right font-bold text-coral-50"
    v-text="props.text"
  />
</template>
