<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { StudentTaskProgress } from '~/models/Activity'
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsCallout } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import useArrayUtils from '~/utils/arrayUtils'
import useWeekStore from '~/stores/week'
import usePlannerStore from '~/stores/planner'
import useActivityStore from '~/stores/activity'
import { ContentType } from '~/models/Content/ContentType'
import { useSchoolYear } from '~/composables/useSchoolYear'
import { useContentHelper } from '~/composables/useContentHelper'
import WeekNavigator from '~/components/week/WeekNavigator.vue'
import StudentActivitySkeleton from '~/components/skeletons/StudentActivitySkeleton.vue'
import StudentTaskProgressLine from '~/components/activity/StudentTaskProgressLine.vue'

const { t } = useI18n()
const { currentSchoolYearCode } = useSchoolYear()
const { loadActivitiesByDatesForStudent, enrichActivitiesWithSearch } = useActivityStore()
const { selectedWeekNumber, selectedWeekStart, selectedWeekEnd } = storeToRefs(useWeekStore())
const { getYearPlansBySchoolYear } = usePlannerStore()
const { groupBy } = useArrayUtils()
const { isLearningPath } = useContentHelper()
const ksToast = <Toast>inject('ksToast')

const taskProgress = ref<[]>([])
const isLoadingYearPlans = ref(false)
const isLoadingActivities = ref(false)
const studentActivity = ref<StudentTaskProgress[]>([])

const fromDate = computed(() => selectedWeekStart.value?.toFormat('yyyy-MM-dd'))
const toDate = computed(() => selectedWeekEnd.value?.toFormat('yyyy-MM-dd'))
const studentActivityGroupedBySubjects = computed(() => {
  return groupBy(studentActivity.value, (activity) => activity.subjects[0] as SubjectCode)
})

const loadActivities = async () => {
  try {
    if (isLoadingActivities.value) return
    taskProgress.value = []
    isLoadingActivities.value = true
    if (!fromDate.value || !toDate.value) return // If dates are not set, we can't load activities

    studentActivity.value = (await loadActivitiesByDatesForStudent(fromDate.value, toDate.value))
      .filter((activity) => activity.contentTypeIdentifier !== ContentType.Product)
      .filter((activity, _, activities) => {
        if (activity.learningPathPartsCount > 0 && isLearningPath(activity)) {
          return true // We always want to show learning paths with activity
        }

        return !activities.filter((a) => a.locationId !== activity.locationId)
          .find((a) => a.pathArray.includes(activity.locationId) || activity.pathArray.includes(a.locationId))
      })

    studentActivity.value = await enrichActivitiesWithSearch(studentActivity.value)
    isLoadingActivities.value = false
  } catch (error) {
    ksToast.error(t('activity.errorLoadingActivities'))
    console.error(error)
  }
}

watch(() => selectedWeekNumber.value, loadActivities)

onMounted(async () => {
  getYearPlansBySchoolYear(currentSchoolYearCode)
  isLoadingYearPlans.value = false
  loadActivities()
})

setTitle(t('activity.pageTitle'))

</script>
<template>
  <WeekNavigator class="mt-8" />
  <StudentActivitySkeleton v-if="isLoadingActivities" />
  <KsCallout
    v-else-if="studentActivity.length === 0"
    variant="info"
    :title="t('activity.noStudentActivities.title')"
    class="mt-8"
  >
    {{ t('activity.noStudentActivities.body') }}
  </KsCallout>
  <template v-else>
    <template
      v-for="(activities, subject) in studentActivityGroupedBySubjects"
      :key="subject"
    >
      <h2
        v-if="subject"
        class="py-4 text-2xl font-medium"
        v-text="t(`metadata.subjects.${subject}`)"
      />
      <StudentTaskProgressLine
        v-for="activity in activities"
        :key="activity.locationId"
        :activity="activity"
        :from-date="fromDate"
        :to-date="toDate"
      />
    </template>
  </template>
</template>
