const oldUrls = [
  'media.lokus.no',
  'media.aschehoug.cloud',
  'media.prod.aschehoug.cloud',
]

export function rewriteMediaURL(urlString: string): string {
  try {
    const url = new URL(urlString)

    if (oldUrls.includes(url.host)) {
      url.host = import.meta.env.VITE_MEDIASERVER_URL
    }

    return url.toString()
  }

  // The URL constructor will throw if `urlString` is not a valid url
  catch {
    return urlString
  }
}
