<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useDialogStore from '../../stores/dialog'
import { TeleportationTarget } from '~/models/TeleportationTarget'
const { dialogs } = storeToRefs(useDialogStore())
</script>
<template>
  <Teleport
    v-if="dialogs.length > 0"
    :to="TeleportationTarget.AppTop"
  >
    <component
      :is="dialog.component"
      v-for="dialog in dialogs"
      :key="typeof dialog.component === 'string'
        ? dialog.component
        : dialog.component.name"
      v-bind="dialog.props"
    />
  </Teleport>
</template>
