import type { LicenseDetails } from '~/models/License'
import type { EAN } from '~/models/Ean'
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import useTime from '~/composables/useTime'
import useLicenseApi from '~/api/licenseApi'

const useLicenseStore = defineStore('license', () => {
  const { isoStringInFuture } = useTime()

  const licenses = ref<LicenseDetails[]>([])
  const isLoadingLicenses = ref(false)
  const hasLoadedLicenses = ref(false)

  const loadLicenses = async (): Promise<void> => {
    const { getLicenseDetails } = useLicenseApi()
    isLoadingLicenses.value = true
    licenses.value = await getLicenseDetails()
      .finally(() => {
        isLoadingLicenses.value = false
        hasLoadedLicenses.value = true
      })
  }

  const allLicenses = computed(() => licenses.value)

  const hasLicenses = computed(() => allLicenses.value.length > 0)

  const getLatestEndDateByEan = (ean: EAN): string => {
    return  allLicenses.value
      .filter((license) => license.ean === ean)
      .sort((a, b) => (new Date(a.endDate)).getTime() - (new Date(b.endDate)).getTime())
      .reverse()
      .at(0)?.endDate || ''
  }

  const validLicenses = computed(() => licenses.value.filter((license) => isoStringInFuture(license.endDate)))

  const $reset = (): void => {
    licenses.value = []
    isLoadingLicenses.value = false
    hasLoadedLicenses.value = false
  }

  return {
    getLatestEndDateByEan,
    licenses,
    loadLicenses,
    isLoadingLicenses,
    hasLoadedLicenses,
    allLicenses,
    hasLicenses,
    validLicenses,
    $reset,
  }
})

export default useLicenseStore
