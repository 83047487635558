import type { Ref } from 'vue'
import { ref, watch } from 'vue'

import { colorIsLight, colors, getColorForId } from '~/utils/colors'

/**
* Returns a background style and boolean value for wether the text should be dark or not
*
* background is either a given background value or a selection from a palette based on the given location ID.
*/
export default ({
  identifier,
  backgroundColor,
  backgroundFormatter = (color) => `background: ${color}`,
  elementRef
}: {
  identifier: number | string
  backgroundColor?: string
  backgroundFormatter?: (color: string) => string
  elementRef: Ref<HTMLElement>
}) => {
  const paletteColor = getColorForId(identifier, colors)
  const backgroundStyle = ref<string>(backgroundColor
    ? `background: ${backgroundFormatter(backgroundColor)}`
    : `background: rgb(var(${paletteColor}))`,
  )
  const needsDarkText = ref<boolean>(backgroundColor ? colorIsLight(backgroundColor) : false)

  watch(elementRef, () => {
    if (!elementRef.value || !!backgroundColor) {
      return
    }
    needsDarkText.value = backgroundColor
      ? colorIsLight(backgroundColor)
      : colorIsLight(window.getComputedStyle(elementRef.value).getPropertyValue(paletteColor))
  }, { immediate: true })

  return {
    backgroundStyle,
    paletteColor,
    needsDarkText,
  }
}
