<script setup lang="ts">
import type { AggregationTermEntry } from '~/models/Content/Response'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsIcon, KsInput } from '@aschehoug/kloss'
import { GRADE_ADULT, GRADE_PREP, gradeTypeMapping } from '~/utils/gradeSorter'
import AggregationButton from '~/components/search/AggregationButton.vue'

const props = defineProps<{
  title: string
  isRefetching: boolean
  criterions: string[]
  aggregations: AggregationTermEntry[]
  translator: (a: AggregationTermEntry) => string
  openOnMount?: boolean
}>()

const emit = defineEmits(['toggleCriterion'])

const open = ref(false)

const { t } = useI18n()

const isChecked = (entryKey: string) => props.criterions.includes(entryKey)

const isGroupChecked = (group: string) => {
  const keys = gradeTypeMapping[group] ?? []
  return keys.every(key => props.criterions.includes(key))
}

const handleCheckboxClick = (entryKey: string) => emit('toggleCriterion', entryKey)

const handleGroupCheckboxClick = (group: string) => {
  const keys = gradeTypeMapping[group] ?? []
  const allChecked = isGroupChecked(group)
  keys.forEach(key => {
    if (allChecked) {
      emit('toggleCriterion', key)
    } else if (!props.criterions.includes(key)) {
      emit('toggleCriterion', key)
    }
  })
}

const showConnectedGrades = (entry) => {
  return entry.keys.some(key => ![GRADE_ADULT, GRADE_PREP].includes(key.key))
}

onMounted(() => {
  if (props.openOnMount) {
    open.value = true
  }
})
</script>

<template>
  <div class="rounded bg-white">
    <button
      class="group flex w-full items-center justify-between rounded px-4 py-3 focus-visible:ring"
      @click="open = !open"
    >
      <span
        class="font-bold underline-offset-4 group-hover:underline"
        v-text="title"
      />
      <KsIcon
        :icon="open ? 'minus' : 'plus'"
        :spin="open"
        animation-duration="200ms"
      />
    </button>
    <Transition
      :duration="500"
      name="nested"
    >
      <div
        v-show="open"
        class="outer mx-4 border-t border-t-gray-10 py-3"
      >
        <ul class="inner flex flex-col gap-3">
          <li
            v-for="(entry) in aggregations"
            :key="entry.group"
          >
            <AggregationButton
              :name="t(`search.filterBoxes.${entry.group}`)"
              :entry="entry"
              type="label"
              :disabled="isRefetching"
              stretch
              size="small"
              class="!font-bold"
            >
              <KsInput
                :id="entry.group"
                type="checkbox"
                class="shrink-0"
                :checked="isGroupChecked(entry.group)"
                :disabled="isRefetching"
                @click="handleGroupCheckboxClick(entry.group)"
              />
            </AggregationButton>
            <ul
              v-if="showConnectedGrades(entry)"
              class="ml-2"
            >
              <li
                v-for="key in entry.keys"
                :key="key.key"
              >
                <AggregationButton
                  :name="translator(key)"
                  :entry="key"
                  type="label"
                  :disabled="isRefetching"
                  stretch
                  size="small"
                >
                  <KsInput
                    :id="key.key"
                    type="checkbox"
                    class="shrink-0"
                    :checked="isChecked(key.key)"
                    :disabled="isRefetching"
                    @click="handleCheckboxClick(key.key)"
                  />
                </AggregationButton>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.nested-enter-active, .nested-leave-active {
  transition: all 0.3s linear;
  max-height: 1000px;
}
.nested-leave-active {
  transition-delay: 0.2s;
}
.nested-enter-from,
.nested-leave-to {
  transform: translateY(-5px);
  opacity: 0;
  max-height: 0;
}
.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  will-change: transform, opacity;
}
.nested-enter-active .inner {
  transition-delay: 0.2s;
}
.nested-enter-from .inner {
  transform: translateY(-5px);
  opacity: 0.001;
}
.nested-leave-to .inner {
  transform: translateY(-5px);
  opacity: 0.001;
}
</style>
