<script setup lang="ts">
import type { AudioPlayerVariant } from '~/models/Media';
import { inject } from 'vue'
import AudioPlayer from '~/components/media/AudioPlayer.vue'

defineProps({
  src: {
    type: String,
    required: true,
  },
  mediaId: String,
})

const variant = inject<AudioPlayerVariant>('embedAudioVariant') ?? 'button-with-text'
const onEmbedTranscription = inject<(event: any) => void>('onEmbedTranscription')
const onEmbedShowTranscription = inject<(event: any) => void>('onEmbedShowTranscription')
</script>

<template>
  <AudioPlayer
    :src
    :media-id
    :variant
    @update:transcription="onEmbedTranscription?.($event)"
    @update:show-transcription="onEmbedShowTranscription?.($event)"
  />
</template>
