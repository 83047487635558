<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import type { ContentFile } from '~/models/Content/ContentFile'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'
import { useContentHelper } from '~/composables/useContentHelper'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import { useFile } from '~/composables/useFile'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'
import CardMenu from '~/components/cards/buttons/CardMenu.vue'
import PlannedResourceButton from '~/components/planner/PlannedResourceButton.vue'

const props = defineProps<{
  resource: BaseItem|ContentFile
}>()

const { t } = useI18n()
const { buildResourceUrl } = useUrlBuilder()
const { isFile, getTitle, isPdf } = useContentHelper()
const { readableMimeType, readableFileSize } = useFile()

const title = computed(() => getTitle(props.resource))
const link = computed(() => buildResourceUrl(props.resource))
const downloadable = computed(() => isFile(props.resource))
const transitionEffect = computed(() => !isFile(props.resource) && !isPdf(props.resource))

const mimeType = computed(() => (props.resource as ContentFile)?.file?.mimeType
  ? readableMimeType((props.resource as ContentFile)?.file?.mimeType || '')
 : '')

const fileSize = computed(() => (props.resource as ContentFile).file?.fileSize
  ? readableFileSize((props.resource as ContentFile)?.file?.fileSize || 0)
  : '')

const fileInfo = computed(() => [mimeType.value, fileSize.value].filter(Boolean).join(' '))
</script>

<template>
  <div class="flex items-center justify-between px-4 py-3">
    <div class="space-y-1">
      <span class="hyphens-auto text-base font-semibold text-blue-50 sm:text-lg">
        <LinkOrAnchor
          class="hover:underline focus-visible:underline"
          :link="link"
          :transition-effect="transitionEffect"
        >
          {{ title }}
        </LinkOrAnchor>
      </span>
      <p
        v-if="fileInfo"
        class="text-sm text-gray-40"
        v-text="fileInfo"
      />
    </div>
    <div class="flex gap-2">
      <KsButton
        v-if="downloadable"
        icon-left="download"
        class="invisible md:visible"
        size="small"
        shape="square"
        :title="t('download')"
        :href="link"
      />
      <PlannedResourceButton :resource="resource" />
      <CardMenu :resource="resource" />
    </div>
  </div>
</template>
