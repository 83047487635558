import type { MaybeRef } from 'vue'
import type { GradeCode } from '~/models/Grade'
import { unref } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import useFilterStore from '~/stores/filter'
import { useContentFilter } from '~/composables/useContentFilter'

export function useHeaderQuery(grade: MaybeRef<GradeCode>) {
  const { getMultipleHeaderChildren } = useFilterStore()
  const { filteredProductPackages } = useContentFilter()

  return useQuery({
    queryKey: ['header-view-query', grade, ...filteredProductPackages.value.map(({ locationId }) => locationId)],
    queryFn: () => Promise.all(filteredProductPackages.value
      .map((content) => getMultipleHeaderChildren([content], [unref(grade)]))),
    staleTime: Infinity,
  })
}
