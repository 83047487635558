<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { KsButton } from '@aschehoug/kloss'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  subjectCode: SubjectCode
  gradeCode: GradeCode
}>()

const { t } = useI18n()
const router = useRouter()
const { truthy } = arrayUtils()
const { getRelatedLocationsByGradeAndSubject } = useProductStore()

const locationIds = ref<number[]>([])

watch(props, async () => {
  if (!(props.gradeCode && props.subjectCode)) return
  const { colophonLocationIds } = await getRelatedLocationsByGradeAndSubject(props.gradeCode, props.subjectCode)
  locationIds.value = [...colophonLocationIds].filter(truthy<number>)
}, { immediate: true })
</script>
<template>
  <KsButton
    v-if="locationIds.length > 0"
    variant="border"
    icon-left="info"
    :aria-label="t('colophon.plural')"
    shape="round"
    @click.prevent="router.push({ name: 'colophon_subject', params: {
      subject: props.subjectCode.toLowerCase(),
      grade: props.gradeCode.toLowerCase()
    } })"
  />
</template>
