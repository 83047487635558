<script lang="ts" setup>
import { useClipboardApi } from '~/composables/useClipboardApi'
import { computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'
import { PendoFeature } from '~/models/Pendo'

const ksToast = inject('ksToast') as Toast
const { t } = useI18n()
const { writeToClipboard } = useClipboardApi()

const props = withDefaults(defineProps<{
  url?: string
  stretch?: boolean
  icon?: string
}>(), {
  url: '',
  stretch: false,
  icon: '',
})

const relevantUrl = computed(() => {
  if (!props.url) {
    return '#'
  }

  if (props.url.startsWith('/')) {
    return `${import.meta.env.VITE_HOST}${props.url}`
  }

  return props.url
})

const activateCopy = () => {
  try {
    writeToClipboard({ text: relevantUrl.value })
    ksToast.success({
      message: t('copy.messages.success'),
      duration: 2000,
    })
  } catch (e) {
    ksToast.error({
      message: t('copy.messages.error'),
      duration: 5000,
    })
    throw e
  }
}

</script>
<template>
  <KsButton
    variant="tertiary"
    :stretch="props.stretch"
    :data-pendo="PendoFeature.Card.CopyUrl"
    :icon-left="props.icon"
    @click="activateCopy"
  >
    {{ t('copy.copyUrl') }}
  </KsButton>
</template>
