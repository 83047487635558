<script setup lang="ts">
import type { ImageRelationField } from '~/models/Content/BaseField'
import { computed } from 'vue'
import useImage from '~/composables/useImage'

const props = withDefaults(defineProps<{
  title?: string
  text?: string
  image?: ImageRelationField
}>(), {
  title: '',
  text: '',
})

const { getImageSrc } = useImage()
const imageSrc = computed(() => props.image ? getImageSrc(props.image) : '')
</script>

<template>
  <header
    class="
      relative h-[285px] flex-col justify-center bg-seagreen-10
      bg-cover bg-center bg-no-repeat text-white sm:h-[324px]
    "
    :style="{
      'background-image': `url(${imageSrc})`
    }"
  >
    <div class="relative top-2/3 z-20 mx-auto max-w-screen-au px-4 sm:top-1/2 sm:px-8">
      <h1
        class="inline-block font-semibold drop-shadow-md fluid-text-6xl"
        v-text="title"
      />
      <p v-html="text" />
    </div>
  </header>
</template>
