import { ref } from 'vue'

export default () => {
  // Some old browsers has limited support (looking at you Safari)
  const isSupported = () => {
    return document.fullscreenElement !== undefined
  }

  const isFullscreen = ref<boolean>(false)

  const setCurrentFullscreenState = () => {
    isFullscreen.value = document.fullscreenElement !== null
  }

  const onFullscreenChange = (event: Event) => {
    if (!event.isTrusted) return
    setCurrentFullscreenState()
  }

  const enableFullscreen = () => document.documentElement.requestFullscreen()

  const disableFullscreen = () => {
    if (document.fullscreenElement !== null) {
      document.exitFullscreen()
    }
    return
  }

  const toggleFullscreen = () => {
    if (isFullscreen.value) {
      disableFullscreen()
    } else {
      enableFullscreen()
    }
  }

  return {
    isSupported,
    toggleFullscreen,
    disableFullscreen,
    isFullscreen,
    onFullscreenChange,
    setCurrentFullscreenState,
  }
}
