<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'
import { KsIcon, KsInput } from '@aschehoug/kloss'
import { computed, ref } from 'vue'
import SubjectSearchDrawer from '~/components/subject/SubjectSearchDrawer.vue'
import { debounce } from '~/utils/functionUtils'
import { useI18n } from 'vue-i18n'
import { colorMap, getColorAndShade } from '~/utils/colors'

const DEBOUNCE_MS = 750

const props = defineProps<{ subjectCode: SubjectCode; gradeCode: GradeCode; themeColor: string }>()

const { t } = useI18n()

const searchOpen = ref(false)
const initialSearchQuery = ref('')

const backgroundColor = computed(() => colorMap.get(getColorAndShade(props.themeColor, '40'))?.rgb)

const onInput = debounce(() => {
  if (initialSearchQuery.value.length > 2) searchOpen.value = true
}, DEBOUNCE_MS)

const onCloseDialog = () => {
  searchOpen.value = false
  initialSearchQuery.value = ''
}
</script>
<template>
  <section
    class="space-y-6 rounded-lg px-4 py-14 transition-all sm:px-8"
    :style="{ backgroundColor }"
  >
    <SubjectSearchDrawer
      :open="searchOpen"
      :grade-code="gradeCode"
      :subject-code="subjectCode"
      :initial-search-query="initialSearchQuery"
      :theme-color="themeColor"
      @close-dialog="onCloseDialog"
    />
    <div class="mx-auto max-w-xl space-y-4">
      <h2
        class="text-center font-medium text-white fluid-text-base"
        v-text="t('search.subject', { subject: t(`metadata.subjects.${subjectCode}`).toLowerCase() })"
      />
      <form
        role="search"
        class="relative"
        @submit.prevent="searchOpen = true"
      >
        <KsIcon
          icon="magnifying-glass"
          class="absolute left-7 top-1/2 z-10 -translate-y-1/2 text-xl"
        />
        <KsInput
          v-model="initialSearchQuery"
          type="text"
          maxlength="200"
          name="search"
          :aria-label="t('search.title')"
          :placeholder="t('search.placeholder')"
          shape="rounded"
          class="!bg-white !pl-16 !text-xl placeholder:text-lg placeholder:text-gray-40"
          @click="searchOpen = true"
          @input="onInput"
        />
      </form>
    </div>
  </section>
</template>
