export default function useTransition() {
  const fadeOut = (event?: PointerEvent | undefined): void => {
    if (event && (!event.isTrusted || event.shiftKey || event.metaKey || event.ctrlKey || event.altKey)) {
      return
    }

    document.body.style.transition = 'opacity .2s'
    document.body.style.opacity = '0'

    // Restore opacity after 5 seconds as fallback
    setTimeout(() => fadeIn(), 5000)
  }

  const fadeIn = (): void => {
    document.body.style.transition = 'opacity .1s'
    document.body.style.opacity = '1'
  }

  return {
    fadeOut,
    fadeIn,
  }
}
