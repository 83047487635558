<script setup lang="ts">
import type { CaptionsKind } from '~/models/Caption'
import { watch } from 'vue'
import { useCaptions } from '~/composables/useCaptions'

const props = withDefaults(defineProps<{
  mediaId: string,
  kind?: CaptionsKind,
}>(), {
  kind: 'subtitles'
})

const { captions, fetchCaptions } = useCaptions(props.kind)

fetchCaptions(props.mediaId)
watch(() => props.mediaId, (id) => fetchCaptions(id))
</script>

<template>
  <track
    v-for="(caption, index) in captions"
    :key="caption.url"
    :src="caption.url"
    :label="caption.label"
    :srclang="caption.language"
    :default="index === 0"
    :kind
    data-type="vtt"
  >
</template>
